import {Component, OnDestroy, ViewChild} from '@angular/core';
import {ContentService} from "../_services/content.service";
import {MatDialog, MatDialogRef} from "@angular/material/dialog";
import {SnackbarService} from "../_services/snackbar.service";
import {ActivatedRoute, Router} from "@angular/router";
import {DomSanitizer, SafeResourceUrl} from "@angular/platform-browser";
import {AuthService} from "../_services/auth.service";
import {LightboxComponent} from "../shared/lightbox/lightbox.component";
import {MatPaginator, PageEvent} from "@angular/material/paginator";
import {MatTableDataSource} from "@angular/material/table";
import {MatSort, Sort} from "@angular/material/sort";
import {AdminService} from "../_services/admin.service";
import {Subscription} from "rxjs";

@Component({
    selector: 'app-hidden-media',
    templateUrl: './hidden-media.component.html',
    styleUrl: './hidden-media.component.scss'
})
export class HiddenMediaComponent implements OnDestroy {
    displayedColumns: string[] = ['author', 'img', 'title', 'file_type', 'hidden_status', 'created_date', 'hide_until', 'view'];
    dataSource: MatTableDataSource<any> = new MatTableDataSource();
    @ViewChild(MatPaginator) paginator: MatPaginator | null = null;
    @ViewChild(MatSort) sort: MatSort | null = null;
    order_by: string = 'created_date';
    direction: "" | "asc" | "desc" = "desc";
    tableSizeSubscription: Subscription;
    id: number = 0;
    timelineId: number = 0;
    media: any[] = [];
    permission: string = 'No permission';
    page: number = 0;
    limit: number = 15;
    user: any;
    loading: boolean = true;
    count: number = 0;
    subtitle: string = 'Hidden Media';
    status: string = 'All'
    largerTable: boolean = false;

    constructor(
        private contentService: ContentService,
        public dialog: MatDialog,
        private snackBarService: SnackbarService,
        private route: ActivatedRoute,
        private sanitizer: DomSanitizer,
        private snackbarService: SnackbarService,
        private router: Router,
        private authService: AuthService,
        private adminService: AdminService
    ) {
        if (window.innerWidth < 1600 && window.innerWidth > 1100) {
            this.limit = 16;
        }
        route.queryParams.subscribe(
            (data: any): void => {
                if (data['hidden_status']) {
                    this.status = data['hidden_status']
                }
                if (data['direction']) {
                    this.direction = data['direction']
                }
                if (data['order_by']) {
                    this.order_by = data['order_by']
                }
                if (data['page']) {
                    this.page = data['page']
                }
                if (data['limit']) {
                    this.limit = data['limit']
                }
            })
        this.route.params
            .subscribe(params => {
                if (params && params['id']) {
                    this.id = params['id'];
                    this.timelineId = params['timeline'];
                    this.getMedia();
                    if (this.timelineId) {
                        this.contentService.viewTimeline(this.timelineId).subscribe(r => {
                            this.subtitle = r.child.full_name + '\'s Hidden Media';
                        }, e => {
                            this.snackBarService.openSnackBar(e, 'error')
                        })
                    }
                    if (params['index']) {
                        this.viewImage(params['index'])
                    }
                } else {
                    // this.status = 'approval'
                    this.getMedia();
                }
            }, error => {
                this.snackBarService.openSnackBar(error, 'error')
                console.error(error);
            });

        this.user = this.authService.getUser();
        this.largerTable = this.adminService.largeTable;
        this.tableSizeSubscription = this.adminService.largeTableChange.subscribe((value: boolean): void => {
            this.largerTable = value;
        });
    }

    ngOnDestroy() {
        if (this.tableSizeSubscription) {
            this.tableSizeSubscription.unsubscribe();
        }
    }

    getMedia(): Promise<any> {
        return new Promise((resolve, reject): void => {
            this.loading = true;
            let filters: any = {
                hidden_status: null,
            }

            if (this.status && this.status != 'All') {
                filters.hidden_status = this.status;
            }

            if (this.order_by) {
                filters.order_by = this.order_by
            }
            if (this.direction) {
                filters.direction = this.direction
            }

            filters.page = this.page;
            filters.limit = this.limit;
            this.router.navigate([], {
                relativeTo: this.route,
                queryParams: filters,
                queryParamsHandling: 'merge',
            });

            if (this.id) {
                this.contentService.getHiddenMedia(this.timelineId, filters).subscribe(r => {
                    this.processMedia(r)
                    resolve('Success')
                }, e => {
                    this.snackbarService.openSnackBar(e, 'error')
                    this.loading = false;
                    reject(e)
                })
            } else {
                // TODO
                this.displayedColumns.unshift('memoryBox')
                this.adminService.getAllHiddenMedia(filters).subscribe(r => {
                    this.processMedia(r)
                    resolve('Success')
                }, e => {
                    this.snackbarService.openSnackBar(e, 'error')
                    this.loading = false;
                    reject(e)
                })
            }
        });
    }

    processMedia(r: any): void {
        this.count = r.count;
        this.media = [];
        for (let media of r.results) {
            if (media.document) {
                // Remove the ability to download the file to stop s3 timeout issue
                media.safeDocument = this.safeUrl(media.document + "#toolbar=0");
            }
            this.media.push(media)
        }
        this.dataSource = new MatTableDataSource(this.media);

        this.loading = false;
        if (this.timelineId && !this.permission) {
            this.contentService.viewTimelinePermissions(this.timelineId).subscribe(
                r => {
                    for (let permission of r) {
                        if (permission.user.id == this.user.id) {
                            this.permission = permission.permission
                        }
                    }
                    if (!this.permission || this.permission == 'No permission' && this.user?.role?.role_type !== 'admin') {
                        this.snackbarService.openSnackBar('Sorry you do not have permission to view this memory', 'error');
                        this.router.navigate(['/noAccess']);
                    }
                }, e => {
                    this.snackbarService.openSnackBar(e, 'error');
                    this.router.navigate(['/noAccess']);
                })
        }
    }

    back(): void {
        history.back();
    }

    viewImageWithoutIndex(media: any) {
        let i: number = this.media.findIndex(x => x == media);
        if (i >= 0) {
            this.viewImage(i);
        }

    }

    viewImage(i: number): void {
        // stop all active media
        document.querySelectorAll('video').forEach(vid => vid.pause());
        document.querySelectorAll('audio').forEach(vid => vid.pause());

        let isUsersOwnFavourites: boolean = this.id == null;
        this.dialog.closeAll();
        if (this.media[i]) {
            const dialogRef: MatDialogRef<LightboxComponent> = this.dialog.open(LightboxComponent, {
                data: {
                    media: this.media,
                    index: i,
                    permission: this.permission,
                    canFavourite: isUsersOwnFavourites,
                },
                panelClass: 'fullWidthWindow',
            });
            dialogRef.componentInstance.refresh.subscribe((index): void => {
                // Refresh favourites if when media changes
                this.getMedia().then((): void => {
                    dialogRef.close();
                    this.viewImage(index)
                });
            });
            dialogRef.componentInstance.reFetch.subscribe((): void => {
                // Refresh favourites if when media changes
                this.getMedia().then((): void => {
                    dialogRef.close();
                });
            });
        }
    }

    safeUrl(url: any): SafeResourceUrl {
        return this.sanitizer.bypassSecurityTrustResourceUrl(url);
    }

    handlePageEvent(e: PageEvent): void {
        this.page = e.pageIndex;
        this.getMedia();
    }


    sortData(event: Sort): void {
        this.order_by = event.active;
        this.direction = event.direction;
        this.getMedia()
    }

}
