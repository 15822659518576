import {Component, EventEmitter, Input, Output} from '@angular/core';
import {FormGroup} from "@angular/forms";

@Component({
    selector: 'app-emoji-menu',
    templateUrl: './emoji-menu.component.html',
    styleUrl: './emoji-menu.component.scss'
})
export class EmojiMenuComponent {
    @Input() input: string | null = null;
    @Input() form: FormGroup | null = null;
    @Output() setEmoji: EventEmitter<any> = new EventEmitter();

    addEmoji(event: any, menuItem: any): void {
        menuItem.closeMenu()
        if (this.input && this.form) {
            let value = event.emoji.native;
            if (this.form.value[this.input]) {
                value = this.form.value[this.input] + event.emoji.native;
            }
            this.form.controls[this.input]?.setValue(value);
        } else {
            this.setEmoji.emit(event.emoji.native)
        }
    }

    stopClose(event: any): void {
        event.stopPropagation()
    }
}
