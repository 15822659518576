<mat-dialog-content class="d-flex flex-col" role="dialog" aria-modal="true">
    <div class="flex-row flex-space-between">
        <div>
            <mat-card-header class="p-0">
                <mat-card-title>
                    <h3 class="m-0">{{ title }}</h3>
                </mat-card-title>
            </mat-card-header>
        </div>
        <div class="float-end">
            <button class="mat-dialog-close" mat-dialog-close mat-icon-button matTooltip="Close Window"
                    (keyup.enter)="this.dialogRef.close();">
                <mat-icon>close</mat-icon>
            </button>
        </div>
    </div>
    <app-manage-access-table *ngIf="permissions && this.id" [child]="user.id" [canRequest]="true"
                             [permissions]="permissions" [timeline]="this.id"></app-manage-access-table>
</mat-dialog-content>
