<main class="loginBannerBg" role="main">
    <div class="container d-flex justify-content-center">
        <div class="d-flex flex-column">
            <div>
                <mat-card *ngIf="view == 'Login'" class="mat-elevation-z0">
                    <template [ngTemplateOutlet]="logos"></template>
                    <mat-card-content>
                        <form [formGroup]="loginForm" class="d-flex flex-col">
                            <mat-form-field appearance="outline" color="primary">
                                <mat-label>Username</mat-label>
                                <input (keyup.enter)="passwordField.focus()" formControlName="username" matInput
                                       type="text"/>
                                <mat-error *ngIf="loginForm.controls['username'].hasError('required')">
                                    This field is required
                                </mat-error>
                                <mat-error *ngIf="loginForm.controls['username'].hasError('pattern')">
                                    No spaces allowed in this field
                                </mat-error>
                            </mat-form-field>
                            <mat-form-field appearance="outline" color="primary">
                                <mat-label>Password</mat-label>
                                <input (keyup.enter)="loginIfValid(loginForm.invalid || loading)" #passwordField
                                       [type]="passwordType" formControlName="password" matInput/>
                                <button (click)="showPassword()" (keyup.enter)="showPassword()"
                                        [attr.aria-label]="passwordType == 'password' ? 'Show Password' : 'Hide Password'"
                                        [matTooltip]="passwordType == 'password' ? 'Show Password' : 'Hide Password'"
                                        class="h-fit-content p0" mat-icon-button
                                        matSuffix
                                        type="button">
                                    <mat-icon *ngIf="passwordType == 'password'" color="primary">remove_red_eye
                                    </mat-icon>
                                    <mat-icon *ngIf="passwordType == 'text'" color="primary">highlight_off</mat-icon>
                                </button>
                                <mat-error *ngIf="loginForm.controls['password'].hasError('required')">
                                    This field is required
                                </mat-error>
                            </mat-form-field>
                            <div class="justify-content-center flex-col">
                                <button class="w-content mx-auto" (click)="this.view = 'Email'" (keyup.enter)="this.view = 'Email'" mat-button
                                        type="button">
                                    <small>Forgot Username?</small>
                                </button>
                                <button class="w-content mx-auto" (click)="this.view = 'Forgot'" (keyup.enter)="this.view = 'Forgot'" mat-button
                                        type="button">
                                    <small>Forgot Password?</small>
                                </button>
                            </div>
                        </form>
                    </mat-card-content>
                    <mat-card-actions class="text-center">
                        <button (click)="login()" (keyup.enter)="login()" [disabled]="loginForm.invalid || loading"
                                class="mx-auto my-10"
                                color="primary" mat-flat-button type="submit">
                            Login
                            <mat-icon *ngIf="loading">
                                <mat-spinner color="primary" width="40px"></mat-spinner>
                            </mat-icon>
                        </button>
                    </mat-card-actions>
                    <div class="p-10">
                        <div class="f-12 text-center" *ngIf="login_support_text_rendered"
                             [innerHTML]="login_support_text_rendered"></div>
                    </div>
                </mat-card>
                <mat-card *ngIf="view == 'Email'">
                    <template [ngTemplateOutlet]="logos"></template>
                    <mat-card-content>
                        <form [formGroup]="requestUsernameForm">
                            <mat-form-field appearance="outline" class="w-100" color="primary">
                                <mat-label>Email</mat-label>
                                <input (keyup.enter)="usernameRequestIfValid(requestUsernameForm.invalid)"
                                       formControlName="email" matInput type="email"/>
                                <mat-error *ngIf="requestUsernameForm.controls['email'].hasError('required')">
                                    This field is required
                                </mat-error>
                                <mat-error *ngIf="requestUsernameForm.controls['email'].hasError('email')">
                                    Please enter a valid email address
                                </mat-error>
                            </mat-form-field>
                            <div class="d-flex justify-content-center">
                                <button (click)="this.view = 'Login'" (keyup.enter)="this.view = 'Login'" type="button"
                                        mat-button>
                                    <small>Remembered Your Username?</small>
                                </button>
                            </div>
                        </form>
                    </mat-card-content>
                    <mat-card-actions class="text-center">
                        <button (click)="usernameRequest()" type="submit" (keyup.enter)="usernameRequest()"
                                [disabled]="requestUsernameForm.invalid || loading"
                                class="mx-auto my-10"
                                color="primary" mat-flat-button>
                            Send email with associated usernames
                            <mat-icon *ngIf="loading">
                                <mat-spinner color="primary"></mat-spinner>
                            </mat-icon>
                        </button>
                    </mat-card-actions>
                    <div class="p-10">
                        <div class="f-12 text-center" *ngIf="login_support_text_rendered"
                             [innerHTML]="login_support_text_rendered"></div>
                    </div>
                </mat-card>
                <mat-card *ngIf="view == 'Forgot'">
                    <template [ngTemplateOutlet]="logos"></template>
                    <mat-card-content>
                        <form [formGroup]="requestPasswordForm">
                            <mat-form-field appearance="outline" class="w-100" color="primary">
                                <mat-label>Username</mat-label>
                                <input (keyup.enter)="passwordResetRequestIfValid(requestPasswordForm.invalid)"
                                       formControlName="username" matInput type="text"/>
                                <mat-error *ngIf="requestPasswordForm.controls['username'].hasError('required')">
                                    This field is required
                                </mat-error>
                                <mat-error *ngIf="requestPasswordForm.controls['username'].hasError('pattern')">
                                    No spaces allowed in this field
                                </mat-error>
                            </mat-form-field>
                            <div class="d-flex justify-content-center">
                                <button (click)="this.view = 'Login'" type="button" (keyup.enter)="this.view = 'Login'"
                                        mat-button>
                                    <small>Remembered Your Password?</small>
                                </button>
                            </div>
                        </form>
                    </mat-card-content>
                    <mat-card-actions class="text-center">
                        <button (click)="passwordResetRequest()" type="submit" (keyup.enter)="passwordResetRequest()"
                                [disabled]="requestPasswordForm.invalid || loading"
                                class="mx-auto my-10"
                                color="primary" mat-flat-button>
                            Recover Password
                            <mat-icon *ngIf="loading">
                                <mat-spinner color="primary"></mat-spinner>
                            </mat-icon>
                        </button>
                    </mat-card-actions>
                    <div class="p-10">
                        <div class="f-12 text-center" *ngIf="login_support_text_rendered"
                             [innerHTML]="login_support_text_rendered"></div>
                    </div>
                </mat-card>
                <mat-card *ngIf="view == 'Code'">
                    <template [ngTemplateOutlet]="logos"></template>
                    <mat-card-header>
                        <mat-card-title class="pb-10">A code has been sent to {{ sentTo?.email }}
                            <ng-container *ngIf="sentTo?.secondary_email"> and {{ sentTo.secondary_email }}
                            </ng-container>
                        </mat-card-title>
                    </mat-card-header>
                    <mat-card-content>
                        <form [formGroup]="otpForm">
                            <mat-form-field appearance="outline" class="w-100" color="primary">
                                <mat-label>Code</mat-label>
                                <input (keyup.enter)="finishLoginIfValid(otpForm.invalid)" formControlName="code"
                                       matInput type="text"/>
                                <mat-error *ngIf="otpForm.controls['code'].hasError('required')">
                                    This field is required
                                </mat-error>
                            </mat-form-field>
                        </form>
                    </mat-card-content>
                    <mat-card-actions class="text-center flex-row flex-space-between">
                        <button (click)="request2fa()" [disabled]="loading || timer > 0" (keyup.enter)="request2fa()"
                                class="mx-auto my-10 mx-10"
                                color="accent" mat-flat-button type="button">
                            <ng-container *ngIf="timer">Code request available again in {{ timer }} seconds
                            </ng-container>
                            <ng-container *ngIf="!timer">Re-Send Code</ng-container>
                        </button>
                        <button (click)="finishLogin()" [disabled]="otpForm.invalid || loading"
                                (keyup.enter)="finishLogin()"
                                class="mx-auto my-10 mx-10"
                                color="primary" mat-flat-button type="submit">
                            Continue
                            <mat-icon *ngIf="loading">
                                <mat-spinner color="primary"></mat-spinner>
                            </mat-icon>
                        </button>
                    </mat-card-actions>
                    <div class="p-10">
                        <div class="f-12 text-center loginText" *ngIf="login_support_text_rendered"
                             [innerHTML]="login_support_text_rendered"></div>
                    </div>
                </mat-card>
            </div>
        </div>
    </div>
</main>
<app-footer></app-footer>

<ng-template #logos>
    <div class="col-row logosGrid">
        <img alt="Virtual Memory Box Logo" class="my-10 logo"
             src="assets/images/vmb.png">
        <img *ngIf="councilLogo" [alt]=" authorityName + ' Logo'" class="my-10 logo"
             [src]="councilLogo">
    </div>
</ng-template>
