<mat-toolbar class="d-flex flex-space-between mat-elevation-z3" color="primary">
    <button [routerLink]="['/login']" aria-label="Home" mat-icon-button matTooltip="Home">
        <img alt="Virtual Memory Box Logo" src="assets/images/vmb.svg">
    </button>
</mat-toolbar>
<main class="notFoundBannerBg minFullHeight" role="main">
    <div class="container d-flex justify-content-center">
        <div class="d-flex flex-column">

            <div class="text-center">
                <h1 class="mx-auto">Timeout</h1>
                <h2>You have been timed out of the system, please log back in</h2>
                <button [routerLink]="['/login']" class="mx-auto my-3" color="primary" mat-flat-button>Login
                </button>
            </div>
        </div>
    </div>
</main>
<app-footer></app-footer>
