import {Component, Inject} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {AdminService} from "../../../../_services/admin.service";
import {SnackbarService} from "../../../../_services/snackbar.service";
import {ContentService} from "../../../../_services/content.service";

@Component({
    selector: 'app-new-memory-box-access',
    templateUrl: './new-memory-box-access.component.html',
    styleUrl: './new-memory-box-access.component.scss'
})
export class NewMemoryBoxAccessComponent {
    permissionForm: FormGroup
    userSearchControl: FormControl = new FormControl();
    loadingUsers: boolean = false;
    filteredUsers: any[] = [];
    currentPermissions: any[] = [];
    timeline_id: any;
    title: string = ''
    user: any;

    constructor(protected dialogRef: MatDialogRef<NewMemoryBoxAccessComponent>,
                @Inject(MAT_DIALOG_DATA) private data: any,
                private formBuilder: FormBuilder,
                private adminService: AdminService,
                private snackBarService: SnackbarService,
                private contentService: ContentService) {

        this.userSearchControl.valueChanges.subscribe(
            (value): void => {
                if (value.length > 2) {
                    this.loadingUsers = true;
                    this.contentService.getRelatedUsers(value).subscribe(r => {
                        let users: any[] = [];
                        users = r.results.filter((item: any, index: any): boolean => {
                            return (r.results.indexOf(item) == index);
                        });
                        this.loadingUsers = false;
                        this.filteredUsers = users
                    }, e => {
                        this.snackBarService.openSnackBar(e, 'error')
                        this.loadingUsers = false;
                        this.filteredUsers = []
                    })
                }
            }
        );

        if (data.access) {
            this.currentPermissions = data.access
        }
        if (data.title) {
            this.title = data.title
        }
        if (data.user) {
            this.user = data.user
        }
        this.permissionForm = this.formBuilder.group({
            permission: ['', Validators.required],
        });

    }

    createPermission(): void {
        this.adminService.newPermission(this.userSearchControl.value.id, this.user.id, this.permissionForm.value.permission).subscribe(r => {
            this.snackBarService.openSnackBar('New relationship created', 'success')
            this.dialogRef.close(r);
        }, e => {
            this.snackBarService.openSnackBar(e, 'error')
        })
    }

    displayFn(user: any): string {
        if (user.child) {
            if (user.child.full_name && user.child.full_name != ' ') {
                return user.child.full_name + ' (' + user.child.username + ')'
            } else {
                return user.title || user.child.username || user.child.email
            }
        } else {
            return user.title
        }
    }

    checkIfInArray(item: any, otherArray: any): boolean {
        let i
        if (otherArray) {
            i = otherArray.findIndex((x: any): boolean => x.timeline === item.id);
        }
        return (i != null && i >= 0);
    }
}
