import {APP_INITIALIZER, CSP_NONCE, ErrorHandler, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {JwtModule, JwtModuleOptions} from '@auth0/angular-jwt';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {MatIconModule} from "@angular/material/icon";
import {MatMenuModule} from "@angular/material/menu";
import {MatButtonModule} from "@angular/material/button";
import {MatToolbarModule} from "@angular/material/toolbar";
import {FooterComponent} from "./shared/footer/footer.component";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {MatInputModule} from "@angular/material/input";
import {MatTooltipModule} from "@angular/material/tooltip";
import {MatFormFieldModule} from "@angular/material/form-field";
import {LoginComponent} from "./login/login.component";
import {AuthviewComponent} from "./authview/authview.component";
import {NotfoundComponent} from "./notfound/notfound.component";
import {MatCardModule} from "@angular/material/card";
import {MatBadgeModule} from "@angular/material/badge";
import {HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi} from "@angular/common/http";
import {MatProgressSpinner} from "@angular/material/progress-spinner";
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE, MatNativeDateModule} from "@angular/material/core";
import {ErrorInterceptor} from "./_guards/error.interceptor";
import {MAT_DIALOG_DATA, MatDialogModule, MatDialogRef} from "@angular/material/dialog";
import {environment} from "../environments/environment";
import {MatSidenavModule} from "@angular/material/sidenav";
import {MatListItem, MatListModule, MatNavList} from "@angular/material/list";
import {TimelineComponent} from "./timeline/timeline.component";
import {
    MatDatepickerModule,
    MatDatepickerToggle,
    MatDateRangeInput,
    MatDateRangePicker
} from "@angular/material/datepicker";
import {MatSlideToggle} from "@angular/material/slide-toggle";
import {MatAutocompleteModule} from "@angular/material/autocomplete";
import {MatChipsModule} from "@angular/material/chips";
import {MatExpansionModule} from "@angular/material/expansion";
import {MemoryComponent} from "./memory/memory.component";
import {MatDividerModule} from "@angular/material/divider";
import {MatProgressBarModule} from "@angular/material/progress-bar";
import {PickerComponent} from "@ctrl/ngx-emoji-mart";
import {GoogleMapsModule} from "@angular/google-maps";
import {MemoryFormComponent} from './shared/memory-form/memory-form.component';
import {PeopleComponent} from './people/people.component';
import {FaqsComponent} from './trainingCentre/faqs/faqs.component';
import {ProfileComponent} from './profile/profile.component';
import {PrivacyComponent} from './privacy/privacy.component';
import {TermsComponent} from './terms/terms.component';
import {TimeoutComponent} from './timeout/timeout.component';
import {MatTableModule} from "@angular/material/table";
import {SettingsComponent} from "./settings/settings.component";
import {MatTabsModule} from "@angular/material/tabs";
import {PasswordValidatorComponent} from "./shared/password-validator/password-validator.component";
import {LightboxComponent} from './shared/lightbox/lightbox.component';
import {MediaFormComponent} from './shared/media-form/media-form.component';
import {DropzoneCdkModule} from "@ngx-dropzone/cdk";
import {DropzoneMaterialModule} from "@ngx-dropzone/material";
import {ImageCropperModule} from "ngx-image-cropper";
import {EditItemComponent} from './shared/edit-item/edit-item.component';
import {MatSelectModule} from "@angular/material/select";
import {MatSliderModule} from "@angular/material/slider";
import {ManageAccessComponent} from "./shared/manage-access/manage-access.component";
import {ManageAccessTableComponent} from './shared/manage-access-table/manage-access-table.component';
import {FeedbackComponent} from './shared/feedback/feedback.component';
import {AudioPlayerComponent} from './shared/audio-player/audio-player.component';
import {EmojiMenuComponent} from './shared/emoji-menu/emoji-menu.component';
import {NoAccessComponent} from './noAccess/noAccess.component';
import {FavouritesComponent} from './favourites/favourites.component';
import {MatPaginator} from "@angular/material/paginator";
import {DuplicateFormComponent} from './shared/duplicate-form/duplicate-form.component';
import {SignupComponent} from './signup/signup.component';
import {PassthroughComponent} from "./passthrough/passthrough.component";
import {CommentsComponent} from './shared/comments/comments.component';
import {provideAnimationsAsync} from '@angular/platform-browser/animations/async';
import {AdminViewComponent} from './admin/admin-view/admin-view.component';
import {UsersComponent} from './admin/users/users.component';
import {ListFeedbackComponent} from './admin/list-feedback/list-feedback.component';
import {DateAgoPipe} from "./_pipes/date-ago.pipe";
import {GreetingPipe} from "./_pipes/greeting.pipe";
import {MediaDisplayComponent} from './shared/media-display/media-display.component';
import {CreateMediaComponent} from './shared/create-media/create-media.component';
import {PasswordResetComponent} from './password-reset/password-reset.component';
import {AcceptInviteComponent} from './accept-invite/accept-invite.component';
import {EllipsisPipe} from "./_pipes/ellipsis.pipe";
import {createErrorHandler, TraceService} from "@sentry/angular";
import {Router} from "@angular/router";
import {InviteesComponent} from './admin/invitees/invitees.component';
import {MatSortModule} from "@angular/material/sort";
import {MatCheckboxModule} from "@angular/material/checkbox";
import {FlaggedTableComponent} from './admin/list-feedback/flagged-table/flagged-table.component';
import {UserComponent} from './admin/users/user/user.component';
import {FlaggedMediaComponent} from './admin/flagged-media/flagged-media.component';
import {ResolvedFeedbackComponent} from './admin/resolved-feedback/resolved-feedback.component';
import {InviteeComponent} from './admin/invitees/invitee/invitee.component';
import {PermissionRequestsComponent} from './admin/permission-requests/permission-requests.component';
import {PermissionRequestComponent} from './admin/permission-requests/permission-request/permission-request.component';
import {CohortsComponent} from './admin/cohorts/cohorts.component';
import {LocationsComponent} from './admin/locations/locations.component';
import {
    PermissionRequestsTableComponent
} from './admin/permission-requests/permission-requests-table/permission-requests-table.component';
import {MoveMediaComponent} from './admin/flagged-media/move-media/move-media.component';
import {NewPermissionComponent} from './admin/users/user/new-permission/new-permission.component';
import {RemoveBracketsPipe} from "./_pipes/remove-brackets.pipe";
import {DownloadTimelineComponent} from './download-timeline/download-timeline.component';
import {ExportRequestsComponent} from './admin/export-requests/export-requests.component';
import {ReportingComponent} from './admin/reporting/reporting.component';
import {NgApexchartsModule} from "ng-apexcharts";
import {CohortComponent} from './admin/cohorts/cohort/cohort.component';
import {LocationComponent} from './admin/locations/location/location.component';
import {KeyboardShortcutsComponent} from './shared/lightbox/keyboard-shortcuts/keyboard-shortcuts.component';
import {UserSelectComponent} from './admin/shared/user-select/user-select.component';
import {CdkCopyToClipboard} from "@angular/cdk/clipboard";
import {ResourcesComponent} from './trainingCentre/resources/resources.component';
import {VideoguidesComponent} from './trainingCentre/videoguides/videoguides.component';
import {WrittenGuidesComponent} from './trainingCentre/written-guides/written-guides.component';
import {ViewGuideComponent} from './trainingCentre/written-guides/view-guide/view-guide.component';
import {ConfirmDialogComponent} from './shared/confirm-dialog/confirm-dialog.component';
import {NotificationComponent} from './shared/notification/notification.component';
import {TimeFormatPipe} from "./_pipes/time-format.pipe";
import {MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter} from "@angular/material-moment-adapter";
import {EditNameComponent} from './admin/shared/edit-name/edit-name.component';
import {UpdatePasswordComponent} from './admin/shared/update-password/update-password.component';
import {StatusPageComponent} from './status-page/status-page.component';
import {NewMemoryBoxAccessComponent} from './admin/users/user/new-memory-box-access/new-memory-box-access.component';
import {HiddenMediaComponent} from './hidden-media/hidden-media.component';
import {NotificationSidebarComponent} from './shared/notification-sidebar/notification-sidebar.component';
import {ReportsComponent} from './admin/reports/reports.component';
import {DragDropModule} from "@angular/cdk/drag-drop";
import {UserInviteComponent} from './admin/user-invite/user-invite.component';
import {MatStepperModule} from "@angular/material/stepper";
import {AddAccessComponent} from './admin/user-invite/add-access/add-access.component';
import {InviteJobComponent} from './admin/user-invite/invite-job/invite-job.component';
import {InviteJobsComponent} from './admin/user-invite/invite-jobs/invite-jobs.component';
import {RemoveSnakecasePipe} from "./_pipes/remove-snakecase.pipe";
import { HiddenMediaDetailsComponent } from './hidden-media/hidden-media-details/hidden-media-details.component';
import { DeletionQueueComponent } from './admin/deletion-queue/deletion-queue.component';

export const DateFormats = {
    parse: {
        dateInput: 'DD/MM/YYYY',
    },
    display: {
        dateInput: 'DD/MM/YYYY',
        monthYearLabel: 'MMMM YYYY',
        dateA11yLabel: 'DD/MM/YYYY',
        monthYearA11yLabel: 'MMMM YYYY',
    },
};

export function jwtTokenGetter(): string | null {
    return localStorage.getItem('access_token');
}

const jwtConfig: JwtModuleOptions = {
    config: {
        tokenGetter: jwtTokenGetter,
        allowedDomains: environment.whitelist,
        disallowedRoutes: environment.blacklist,
        skipWhenExpired: false,
    }
};
const nonce: string = (
    document.querySelector('meta[name="CSP_NONCE"]') as HTMLMetaElement
)?.content;

@NgModule({
    declarations: [
        AppComponent,
        FooterComponent,
        LoginComponent,
        AuthviewComponent,
        NotfoundComponent,
        TimelineComponent,
        MemoryComponent,
        MemoryFormComponent,
        PeopleComponent,
        FaqsComponent,
        ProfileComponent,
        PrivacyComponent,
        TermsComponent,
        TimeoutComponent,
        SettingsComponent,
        PasswordValidatorComponent,
        LightboxComponent,
        MediaFormComponent,
        EditItemComponent,
        ManageAccessComponent,
        ManageAccessTableComponent,
        FeedbackComponent,
        AudioPlayerComponent,
        EmojiMenuComponent,
        NoAccessComponent,
        FavouritesComponent,
        DuplicateFormComponent,
        SignupComponent,
        PassthroughComponent,
        CommentsComponent,
        AdminViewComponent,
        UsersComponent,
        ListFeedbackComponent,
        DateAgoPipe,
        MediaDisplayComponent,
        CreateMediaComponent,
        PasswordResetComponent,
        AcceptInviteComponent,
        InviteesComponent,
        FlaggedTableComponent,
        UserComponent,
        FlaggedMediaComponent,
        ResolvedFeedbackComponent,
        InviteeComponent,
        PermissionRequestsComponent,
        PermissionRequestComponent,
        CohortsComponent,
        LocationsComponent,
        PermissionRequestsTableComponent,
        MoveMediaComponent,
        NewPermissionComponent,
        DownloadTimelineComponent,
        ExportRequestsComponent,
        ReportingComponent,
        CohortComponent,
        LocationComponent,
        KeyboardShortcutsComponent,
        UserSelectComponent,
        ResourcesComponent,
        VideoguidesComponent,
        WrittenGuidesComponent,
        ViewGuideComponent,
        ConfirmDialogComponent,
        NotificationComponent,
        TimeFormatPipe,
        EditNameComponent,
        UpdatePasswordComponent,
        UserInviteComponent,
        StatusPageComponent,
        NewMemoryBoxAccessComponent,
        AddAccessComponent,
        HiddenMediaComponent,
        NotificationSidebarComponent,
        ReportsComponent,
        InviteJobComponent,
        InviteJobsComponent,
        HiddenMediaDetailsComponent,
        DeletionQueueComponent,
    ],
    bootstrap: [AppComponent],
    imports: [
        JwtModule.forRoot(jwtConfig),
        BrowserModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        MatIconModule,
        MatToolbarModule,
        MatMenuModule,
        MatButtonModule,
        FormsModule,
        ReactiveFormsModule,
        MatInputModule,
        MatTooltipModule,
        MatFormFieldModule,
        MatCardModule,
        MatProgressSpinner,
        MatSidenavModule,
        MatNavList,
        MatListItem,
        MatDateRangeInput,
        MatDatepickerToggle,
        MatDateRangePicker,
        MatNativeDateModule,
        MatDatepickerModule,
        MatSlideToggle,
        MatBadgeModule,
        MatAutocompleteModule,
        MatChipsModule,
        MatExpansionModule,
        MatDividerModule,
        MatProgressBarModule,
        PickerComponent,
        MatListModule,
        GoogleMapsModule,
        MatDialogModule,
        MatTableModule,
        MatTabsModule,
        DropzoneCdkModule,
        DropzoneMaterialModule,
        ImageCropperModule,
        MatSelectModule,
        MatSliderModule,
        MatPaginator,
        GreetingPipe,
        EllipsisPipe,
        MatSortModule,
        MatCheckboxModule,
        RemoveBracketsPipe,
        NgApexchartsModule,
        CdkCopyToClipboard,
        DragDropModule,
        MatStepperModule,
        RemoveSnakecasePipe
    ],
    providers: [
        MatDatepickerModule,
        provideAnimationsAsync(),
        {provide: MAT_DIALOG_DATA, useValue: {}},
        {provide: MatDialogRef, useValue: {}},
        {provide: MAT_DATE_LOCALE, useValue: 'en-GB'},
        {provide: MAT_DATE_FORMATS, useValue: DateFormats},
        {provide: DateAdapter, useClass: MomentDateAdapter},
        {provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: {useUtc: true}},
        {provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true},
        {provide: CSP_NONCE, useValue: nonce},
        {
            provide: ErrorHandler,
            useValue: createErrorHandler({
                showDialog: false,
            }),
        },
        {
            provide: TraceService,
            deps: [Router],
        },
        {
            provide: APP_INITIALIZER,
            useFactory: () => (): void => {
            },
            deps: [TraceService],
            multi: true,
        },
        provideHttpClient(withInterceptorsFromDi()),
    ]
})

export class AppModule {
}
