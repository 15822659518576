import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {ContentService} from "../../../_services/content.service";
import {SnackbarService} from "../../../_services/snackbar.service";

@Component({
    selector: 'app-move-media',
    templateUrl: './move-media.component.html',
    styleUrl: './move-media.component.scss'
})
export class MoveMediaComponent {
    currentMedia: any;
    album: any;
    albums: any[] = [];
    unhide: boolean = false;

    constructor(@Inject(MAT_DIALOG_DATA) private data: any,
                private dialogRef: MatDialogRef<MoveMediaComponent>,
                public contentService: ContentService,
                private snackBarService: SnackbarService,) {
        if (data.media) {
            this.currentMedia = data.media;
            this.album = data.album
            this.unhide = data.unhide
            this.contentService.viewTimelineList(data.timelineId).subscribe(r => {
                for (let year of r.years) {
                    for (let memory of year.memories) {
                        this.albums.push(memory);
                    }
                }
            }, e => {
                this.snackBarService.openSnackBar(e, 'error')
            })
        }
    }


    moveMedia(): void {
        this.contentService.moveMedia(this.currentMedia.id, this.album, this.unhide).subscribe(r => {
            this.snackBarService.openSnackBar('Media Successfully moved to new album', 'success')
            this.dialogRef.close()
        }, e => {
            this.snackBarService.openSnackBar(e, 'error')
        })

    }
}

