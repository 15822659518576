<div>
    <mat-card-header *ngIf="data?.showHeader" class="flex-row flex-space-between p-20 bg-accent">
        <mat-card-title>
            {{ data?.title | titlecase }}
        </mat-card-title>
        <div class="float-end">
            <button [mat-dialog-close]="false" class="mat-dialog-close close-icon-button" mat-icon-button
                    matTooltip="Close Modal"
                    tabindex="-1">
                <mat-icon class="accent-text">close</mat-icon>
            </button>
        </div>
    </mat-card-header>
    <mat-dialog-content>
        <div class="px-20 pb-10">
            <p [innerHTML]="data?.message" class="content-message"></p>
        </div>
        <ng-container *ngIf="data?.textConfirm && data?.user">
            <small>The following fields are required to confirm a user deletion</small>
            <mat-form-field appearance="outline" color="primary" class="w-100 mt-10">
                <mat-label>Enter user's username</mat-label>
                <input [(ngModel)]="confirm" matInput type="text" (paste)="false">
            </mat-form-field>
            <mat-form-field appearance="outline" color="primary" class="w-100">
                <mat-label>Enter verification code</mat-label>
                <input [(ngModel)]="verificationCode" matInput type="text">
                <mat-hint>Please enter the verification code sent to your email</mat-hint>
            </mat-form-field>
        </ng-container>
    </mat-dialog-content>
</div>
<mat-dialog-actions>
    <div class="w-100 d-flex flex-space-around pb-15">
        <button *ngIf="data?.showCancelBtn" [mat-dialog-close]="false" color="warn" mat-raised-button>
            {{ data?.cancelBtnText ?? 'Cancel' }}
        </button>
        <button *ngIf="data?.showSubmitBtn && !(data?.textConfirm && data?.user)" [mat-dialog-close]="true"
                color="accent"
                mat-raised-button>
            {{ data?.submitBtnText ?? 'Confirm' }}
        </button>
        <button *ngIf="data?.showSubmitBtn && data?.textConfirm && data?.user" (click)="submitVerification()"
                color="accent"
                mat-raised-button
                [disabled]="!checkConfirm() || !verificationCode">
            {{ data?.submitBtnText ?? 'Confirm' }}
        </button>
    </div>
</mat-dialog-actions>
