import {AfterViewInit, Component, OnDestroy, ViewChild} from '@angular/core';
import {AdminService} from "../../_services/admin.service";
import {ActivatedRoute, Router} from "@angular/router";
import {SnackbarService} from "../../_services/snackbar.service";
import {MatTableDataSource} from "@angular/material/table";
import {MatPaginator, PageEvent} from "@angular/material/paginator";
import {Subscription} from "rxjs";
import {MatTabChangeEvent, MatTabGroup} from "@angular/material/tabs";
import {MatSort, Sort} from "@angular/material/sort";

@Component({
    selector: 'app-deletion-queue',
    templateUrl: './deletion-queue.component.html',
    styleUrl: './deletion-queue.component.scss'
})
export class DeletionQueueComponent implements AfterViewInit, OnDestroy {
    @ViewChild('MatTabGroup') tabs: MatTabGroup | null = null;

    largerTable: boolean = false;
    tableSizeSubscription: Subscription;

    userLoading: boolean = false;
    userColumns: string[] = ['name', 'username', 'role', 'cohort', 'locality', 'deletion_requested_date', 'deletion_date', 'view', 'restore'];
    userDataSource: MatTableDataSource<any> = new MatTableDataSource();
    @ViewChild('userPaginator') userPaginator: MatPaginator | null = null;
    @ViewChild('userSort') userSort: MatSort | null = null;
    userSortDefault: string = 'deletion_date';
    userSortDirectionDefault: "asc" | "desc" = "asc";
    usersDirection: string | undefined = 'asc';
    usersOrder: string | undefined = 'deletion_requested_date';
    userLimit: number = 15;
    userPage: number = 0;
    userCount: number = 0;

    mediaLoading: boolean = false;
    mediaColumns: string[] = ['img', 'title', 'deletion_requested_date', 'deletion_date', 'view', 'restore'];
    mediaDataSource: MatTableDataSource<any> = new MatTableDataSource();
    @ViewChild('mediaPaginator') mediaPaginator: MatPaginator | null = null;
    @ViewChild('mediaSort') mediaSort: MatSort | null = null;
    mediaSortDefault: string = 'deletion_date';
    mediaSortDirectionDefault: "asc" | "desc" = "asc";
    mediaDirection: string | undefined = 'asc';
    mediaOrder: string | undefined = 'deletion_requested_date';
    mediaLimit: number = 15;
    mediaPage: number = 0;
    mediaCount: number = 0;

    albumLoading: boolean = false;
    albumColumns: string[] = ['title', 'deletion_requested_date', 'deletion_date', 'view', 'restore'];
    albumDataSource: MatTableDataSource<any> = new MatTableDataSource();
    @ViewChild('albumPaginator') albumPaginator: MatPaginator | null = null;
    @ViewChild('albumSort') albumSort: MatSort | null = null;
    albumSortDefault: string = 'deletion_date';
    albumSortDirectionDefault: "asc" | "desc" = "asc";
    albumsDirection: string | undefined = 'asc';
    albumsOrder: string | undefined = 'deletion_requested_date';
    albumLimit: number = 15;
    albumPage: number = 0;
    albumCount: number = 0;

    constructor(private adminService: AdminService,
                private route: ActivatedRoute,
                private router: Router,
                private snackbarService: SnackbarService) {
        this.largerTable = this.adminService.largeTable;
        this.tableSizeSubscription = this.adminService.largeTableChange.subscribe((value: boolean): void => {
            this.largerTable = value;
        });
        this.getUserDeletionQueue();
    }

    ngAfterViewInit(): void {
        this.route.queryParams.subscribe(
            (data: any): void => {
                if (data['tab'] && this.tabs) {
                    this.tabs.selectedIndex = data['tab']
                }
            })
        this.userDataSource.paginator = this.userPaginator;
        this.userDataSource.sort = this.userSort;
        this.mediaDataSource.paginator = this.mediaPaginator;
        this.mediaDataSource.sort = this.mediaSort;
        this.albumDataSource.paginator = this.albumPaginator;
        this.albumDataSource.sort = this.albumSort;
    }

    ngOnDestroy(): void {
        if (this.tableSizeSubscription) {
            this.tableSizeSubscription.unsubscribe();
        }
    }


    getData(event: MatTabChangeEvent): void {
        this.router.navigate([], {
            relativeTo: this.route,
            queryParams: {tab: event.index},
            queryParamsHandling: 'merge',
        });
        switch (event.index) {
            case 0:
                this.getUserDeletionQueue()
                break
            case 1:
                this.getAlbumDeletionQueue()
                break
            case 2:
                this.getMediaDeletionQueue()
                break
        }
    }

    getUserDeletionQueue(): void {
        this.userLoading = true;
        let filters: any = {};
        filters.page = this.userPage;
        filters.limit = this.userLimit;
        if (this.usersOrder && this.usersDirection) {
            filters.order_by = this.usersOrder
        }
        if (this.usersDirection) {
            filters.direction = this.usersDirection
        }
        this.adminService.getUserDeletionItems(this.userPage, this.userLimit, filters).subscribe(r => {
            this.userCount = r.count;
            this.userDataSource = new MatTableDataSource(r.results);
            this.userLoading = false;
        }, e => {
            this.userLoading = false;
            this.snackbarService.openSnackBar(e, 'error');
        })
    }


    handleUserPageEvent(e: PageEvent): void {
        this.userPage = e.pageIndex;
        this.userLimit = e.pageSize;
        this.getUserDeletionQueue();
    }

    restoreUser(item: any): void {
        this.adminService.restoreUser(item.id).subscribe((r: any): void => {
            this.snackbarService.openSnackBar('User restored successfully', 'success');
            this.getUserDeletionQueue();
        }, e => {
            this.snackbarService.openSnackBar(e, 'error');
        })
    }

    sortUsers(event: Sort): void {
        this.usersOrder = event.active;
        this.usersDirection = event.direction;
        if (this.usersOrder == 'deletion_date') {
            this.usersOrder = 'deletion_requested_date'
        } else if (this.usersOrder == 'name') {
            this.usersOrder = 'full_name'
        }
        this.getUserDeletionQueue()
    }

    getAlbumDeletionQueue(): void {
        this.albumLoading = true;
        let filters: any = {};
        filters.page = this.albumPage;
        filters.limit = this.albumLimit;
        if (this.albumsOrder && this.albumsDirection) {
            filters.order_by = this.albumsOrder
        }
        if (this.albumsDirection) {
            filters.direction = this.albumsDirection
        }
        this.adminService.getAlbumDeletionItems(this.albumPage, this.albumLimit, filters).subscribe(r => {
            this.albumCount = r.count;
            this.albumDataSource = new MatTableDataSource(r.results);
            this.albumLoading = false;
        }, e => {
            this.albumLoading = false;
            this.snackbarService.openSnackBar(e, 'error');
        })
    }

    sortAlbums(event: Sort): void {
        this.albumsOrder = event.active;
        this.albumsDirection = event.direction;
        if (this.albumsOrder == 'deletion_date') {
            this.albumsOrder = 'deletion_requested_date'
        }
        this.getAlbumDeletionQueue()
    }


    handleAlbumPageEvent(e: PageEvent): void {
        this.albumCount = e.pageIndex;
        this.albumLimit = e.pageSize;
        this.getAlbumDeletionQueue();
    }

    restoreAlbum(item: any): void {
        this.adminService.restoreAlbum(item.id).subscribe((r: any): void => {
            this.snackbarService.openSnackBar('Album restored successfully', 'success');
            this.getAlbumDeletionQueue();
        }, e => {
            this.snackbarService.openSnackBar(e, 'error');
        })
    }

    getMediaDeletionQueue(): void {
        this.mediaLoading = true;
        let filters: any = {};
        filters.page = this.mediaPage;
        filters.limit = this.mediaLimit;
        if (this.mediaOrder && this.mediaDirection) {
            filters.order_by = this.mediaOrder
        }
        if (this.mediaDirection) {
            filters.direction = this.mediaDirection
        }
        this.adminService.getMediaDeletionItems(this.mediaPage, this.mediaLimit, filters).subscribe(r => {
            this.mediaCount = r.count;
            this.mediaDataSource = new MatTableDataSource(r.results);
            this.mediaLoading = false;
        }, e => {
            this.mediaLoading = false;
            this.snackbarService.openSnackBar(e, 'error');
        })
    }


    handleMediaPageEvent(e: PageEvent): void {
        this.mediaPage = e.pageIndex;
        this.mediaLimit = e.pageSize;
        this.getMediaDeletionQueue();
    }

    restoreMedia(item: any): void {
        this.adminService.restoreMedia(item.id).subscribe((r: any): void => {
            this.snackbarService.openSnackBar('Memory restored successfully', 'success');
            this.getMediaDeletionQueue();
        }, e => {
            this.snackbarService.openSnackBar(e, 'error');
        })
    }

    sortMedia(event: Sort): void {
        this.mediaOrder = event.active;
        this.mediaDirection = event.direction;
        if (this.mediaOrder == 'deletion_date') {
            this.mediaOrder = 'deletion_requested_date'
        }
        this.getMediaDeletionQueue()
    }


}
