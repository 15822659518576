<main>
    <div class="d-flex flex-row justify-content-center">
        <h3 class="my-10">
            Video Guides
        </h3>
    </div>


    <div class="mediumContainer">
        <div class="w-100 d-flex flex-col align-items-center" *ngIf="loading || !loading && trainingCentre.length == 0">
            <mat-spinner color="primary" *ngIf="loading"></mat-spinner>
            <h2 *ngIf="!loading && trainingCentre.length == 0">Sorry no content has been created for your user type yet
                please try again later</h2>
        </div>
        <div class="memoriesContainer">
            <mat-card *ngFor="let resource of trainingCentre; let i = index" class="spaceContent">
                <mat-card-header>
                    <mat-card-title class="mb-0">{{ i + 1 }}. {{ resource.title }}</mat-card-title>
                </mat-card-header>
                <mat-card-content>
                    <video class="videoControl" controls crossorigin="anonymous">
                        <source [src]="resource.attachment">
                        <track *ngIf="resource.vtt_file"
                               label="English"
                               kind="subtitles"
                               srclang="en"
                               [src]="resource.vtt_file"
                               default/>
                        Your browser does not support the video tag.
                    </video>
                    <p>
                        {{ resource.description }}
                    </p>
                </mat-card-content>
                <mat-card-actions class="d-flex flex-row flex-space-around">
                    <button color="primary" mat-raised-button (click)="openGuide(resource)">
                        Play
                    </button>
                </mat-card-actions>
            </mat-card>
        </div>
    </div>
</main>
