import {Component, EventEmitter, Input, OnChanges, Output} from '@angular/core';
import {SnackbarService} from "../../_services/snackbar.service";
import {NotificationsService} from "../../_services/notifications.service";

@Component({
    selector: 'app-notification-sidebar',
    templateUrl: './notification-sidebar.component.html',
    styleUrl: './notification-sidebar.component.scss'
})
export class NotificationSidebarComponent implements OnChanges {

    @Input() getNotifications: boolean = true;
    readNotifications: any[] = [];
    unreadNotifications: any[] = [];
    notificationsRetrieved: boolean = false;
    notificationsPage: number = 0;
    notificationsLimit: number = 10;
    canLoadMore: boolean = true;
    notificationsLoading: boolean = true;
    @Output() getCount: EventEmitter<any> = new EventEmitter();

    constructor(private notificationService: NotificationsService,
                private snackBarService: SnackbarService,) {

    }

    ngOnChanges(): void {
        if (this.getNotifications && !this.notificationsRetrieved) {
            this.fetchNotifications();
        }
    }

    fetchNotifications(): void {
        this.notificationsLoading = true;
        this.notificationService.getActiveNotifications().subscribe(r => {
            this.unreadNotifications = r.results;
            this.notificationsLoading = false;
            this.notificationsRetrieved = true;
        }, e => {
            console.error(e);
            this.notificationsLoading = false;
            this.snackBarService.openSnackBar(e, 'error')
        })
        this.getUnreadNotes()
    }

    getUnreadNotes(): void {
        this.notificationsLoading = true;
        this.notificationService.getNotifications(this.notificationsPage, this.notificationsLimit, true).subscribe(r => {
            if (r.results.length > 0) {
                this.readNotifications = this.readNotifications.concat(r.results);
            }
            this.notificationsPage++
            if (r.count <= this.readNotifications?.length) {
                this.canLoadMore = false;
            }
            this.notificationsLoading = false;
        }, e => {
            this.notificationsLoading = false;
            console.error(e);
            this.snackBarService.openSnackBar(e, 'error')
        })
    }

    markRead(): void {
        this.fetchNotifications();
        this.getCount.emit();
    }
}
