<mat-dialog-content class="d-flex flex-col">

    <div class="flex-row flex-space-between">
        <div>
            <mat-card-header class="p-0">
                <mat-card-title>
                    <h3 class="m-0">New Access Permission for {{ title }}</h3>
                </mat-card-title>
            </mat-card-header>
        </div>
        <div class="float-end">
            <button class="mat-dialog-close " mat-dialog-close mat-icon-button matTooltip="Close Modal"
                    tabindex="-1">
                <mat-icon>close</mat-icon>
            </button>
        </div>
    </div>
    <div class="d-flex flex-row mobile-col justify-content-center">
        <form [formGroup]="permissionForm">
            <mat-form-field appearance="outline" color="primary" class="noBottomPadding w-100">
                <mat-label>Select Users</mat-label>
                <input #userInput [formControl]="userSearchControl"
                       [matAutocomplete]="auto" matInput placeholder="Select Users">
                <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
                    <ng-container *ngFor="let user of filteredUsers ">
                        <mat-option [value]="user" *ngIf="!checkIfInArray(user, this.currentPermissions)">
                            {{ user.full_name && user.full_name != ' '? user.full_name + ' (' + user.username + ')' :  (user.username || user.email)}}
                        </mat-option>
                    </ng-container>
                </mat-autocomplete>


                <mat-icon *ngIf="loadingUsers" matSuffix>
                    <mat-spinner color="primary"></mat-spinner>
                </mat-icon>
            </mat-form-field>

            <mat-form-field appearance="outline" class=" w-100 pt-10 noPaddingBottom" color="primary">
                <mat-label>Permission</mat-label>
                <mat-select formControlName="permission">
                    <mat-option value="View Only">View Only</mat-option>
                    <mat-option value="View and Comment">
                        View and Comment
                    </mat-option>
                    <mat-option value="View, Comment and Add">
                        View, Comment and Add
                    </mat-option>
                    <mat-option value="Add but not View or Comment">
                        Add Only
                    </mat-option>
                    <mat-option value="No Permission"> No Access</mat-option>
                </mat-select>
            </mat-form-field>
        </form>
    </div>
</mat-dialog-content>
<mat-dialog-actions align="center">
    <button [disabled]="this.permissionForm.invalid || !userSearchControl.value" color="primary" mat-raised-button
            (click)="createPermission()">Create
    </button>
</mat-dialog-actions>

