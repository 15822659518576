import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {AuthService} from "../_services/auth.service";

@Injectable({
    providedIn: 'root'
})
export class AuthGuard implements CanActivate {

    constructor(
        private router: Router,
        private authService: AuthService
    ) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        // If user is not authenticated redirect to login screen
        if (!this.authService.isLoggedIn()) {
            const currentUrl = state.url;
            const url = new URL(currentUrl, window.location.origin);
            // Remove the 'returnUrl' query parameter if it exists
            url.searchParams.delete('returnUrl');
            this.authService.logout(encodeURIComponent(url.pathname + url.search));
            return false;
        } else {
            return true;
        }
    }
}
