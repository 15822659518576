<main class="mediumContainer">
    <div class="d-flex flex-row flex-space-between">
        <button (click)="back()" aria-label="Back" mat-icon-button matTooltip="Back">
            <mat-icon>keyboard_backspace</mat-icon>
        </button>
        <div class="d-flex flex-row justify-content-center">
            <h3 class="my-10">
                Hidden Media
            </h3>
        </div>
        <div></div>
    </div>
    <div class="profileContainer">
        <div class="media">
            <mat-card aria-label="View Picture" class="clickable imageCard" *ngIf="media">
                <app-media-display (viewImage)="viewImage()" [file]="media" [small]="true"></app-media-display>
                <mat-card-header (click)="viewImage()" class="pb-16">
                    <mat-card-title>{{ media.title }}</mat-card-title>
                    <mat-card-subtitle *ngIf="media.description">{{ media.description }}
                    </mat-card-subtitle>
                </mat-card-header>
                <mat-card-actions class="flex-row flex-space-around">
                    <button (click)="hide()" *ngIf="media && !this.media.hidden" color="warn" mat-raised-button>Hide
                    </button>
                    <button (click)="unhide()" *ngIf="media && this.media.hidden" color="primary" mat-raised-button>
                        Reveal Media
                    </button>
                    <a *ngIf="media.memory" mat-raised-button color="primary" class="text-center"
                       [routerLink]="['/memory', media.memory] ">
                        View Album
                    </a>
                    <button (click)="delete()" *ngIf="media" color="warn" mat-raised-button>Delete</button>
                </mat-card-actions>
            </mat-card>
        </div>
        <div *ngIf="media" class="d-flex flex-col gap-15 flex-grow details">
            <mat-card>
                <mat-card-header class="flex-space-between">
                    <mat-card-title>Details</mat-card-title>
                </mat-card-header>
                <mat-card-content>
                    <p *ngIf="this.media?.timeline?.id"><b>Memory Box</b>: <a
                            [routerLink]="['/timeline', media.timeline.id]">{{ media.timeline.title }}</a>
                    </p>
                    <p><b>Upload Date</b>: {{ media.created_date | date }}</p>
                    <p><b>Uploader</b>: <a [routerLink]="['/user', media.user.id]">{{ media.user.full_name }}</a>

                    <form [formGroup]="hiddenForm" class="flex-col mt-10" *ngIf="media.hidden">
                        <mat-form-field appearance="outline" class="w-100" color="primary">
                            <mat-label>Category:</mat-label>
                            <select formControlName="hidden_status" matNativeControl>
                                <option [value]="'hidden'">Hidden</option>
                                <option [value]="'approval'">Approval</option>
                                <option [value]="'flagged'">Flagged</option>
                            </select>
                        </mat-form-field>
                        <mat-form-field appearance="outline" class="w-100">
                            <mat-label>Reveal date</mat-label>
                            <input [matDatepicker]="picker" formControlName="hide_until" matInput>
                            <mat-datepicker-toggle [for]="picker" matIconSuffix></mat-datepicker-toggle>
                            <mat-datepicker #picker></mat-datepicker>
                            <mat-error *ngIf="hiddenForm.controls['hide_until'].hasError('matDatepickerParse')">
                                Invalid date format. Please use DD/MM/YYYY.
                            </mat-error>
                            <mat-hint>Media will display on date selected</mat-hint>
                        </mat-form-field>
                    </form>
                </mat-card-content>
                <mat-card-actions class="flex-row flex-space-around" *ngIf="media.hidden">

                    <button (click)="patchMedia()" color="accent" mat-raised-button
                            [disabled]="hiddenForm.invalid || loading">
                        Edit Media
                        <mat-icon *ngIf="loading">
                            <mat-spinner color="primary"></mat-spinner>
                        </mat-icon>
                    </button>
                </mat-card-actions>
            </mat-card>
        </div>
        <div class="chat">
            <mat-card>
                <mat-card-header>
                    <mat-card-title>
                        <h4 class="mt-0 mb-10"> Hidden Media Notes</h4>
                    </mat-card-title>
                </mat-card-header>
                <div #chatContent class="chatContent" id="chatContent">

                    <div class="d-flex flex-row mb-10 gap-25" *ngFor="let message of this.notes;">
                        <a class="d-flex userPicContainer"
                           [class.clickable]="user.role.can_access_profiles"
                           (click)="viewProfile(message.user.id)" (keyup.enter)="viewProfile(message.user.id)"
                           *ngIf="message.user.id !== user.id">
                            <img [alt]="message.user.full_name" *ngIf="message.user.thumbnail || message.user.photo"
                                 [src]="message.user.thumbnail || message.user.photo || 'assets/images/blankProfile.png'"
                                 class="userPic" loading="lazy" fetchPriority="low"
                                 [matTooltip]="user.role.can_access_profiles ? 'View ' +  message.user.full_name + '\'s Profile' : message.user.full_name + '\'s Profile Picture'">
                            <p class="initial m-10"
                               *ngIf="!message.user.thumbnail && !message.user.photo">{{ message.user.first_name | slice: 0 : 1 }} {{ message.user.last_name | slice: 0 : 1 }}</p>
                        </a>
                        <div class="d-flex flex-grow flex-col mw-95">
                            <div class="talk-bubble p-10">
                                <strong class="primaryContrast f-14">{{ message.user.full_name }}</strong>
                                <p class="m-0">{{ message.text }}</p>
                                <small *ngIf="receivedToday(message.created_at)"
                                       class="extraSmall m-5">{{ message.created_at|date:"HH:mm" }}</small>
                                <small *ngIf="!receivedToday(message.created_at)"
                                       class="extraSmall m-5">{{ message.created_at|date:"dd/MM/yyyy" }}</small>
                            </div>
                        </div>
                        <div class="d-flex userPicContainer" *ngIf="message.user.id == user.id">
                            <img [alt]="message.user.full_name"
                                 [src]="message.user.photo || 'assets/images/blankProfile.png'"
                                 class="userPic" loading="lazy" fetchPriority="low"
                                 matTooltip="{{message.user.full_name}}"
                                 *ngIf="message.user.thumbnail || message.user.photo">
                            <p class="initial m-10"
                               *ngIf="!message.user.thumbnail && !message.user.photo">{{ message.user.first_name | slice: 0 : 1 }} {{ message.user.last_name | slice: 0 : 1 }}</p>
                        </div>
                    </div>
                </div>
                <mat-card-actions class="mb-0">
                    <mat-card-footer *ngIf="reLoading">
                        <mat-progress-bar color="primary" mode="indeterminate"></mat-progress-bar>
                    </mat-card-footer>
                    <form [formGroup]="messageForm" class="d-flex flex-row w-100 gap10">
                        <div class="flex-auto">
                            <mat-form-field appearance="outline" class="w-100"
                                            color="primary">
                                <textarea #message aria-label="Comment content"
                                          formControlName="message" matInput maxlength="400"
                                          name="message"></textarea>
                                <mat-hint align="end">{{ message?.value?.length }} / 400</mat-hint>
                            </mat-form-field>
                        </div>
                        <div class="flex-col">
                            <app-emoji-menu [form]="messageForm" [input]="'message'" matSuffix></app-emoji-menu>
                            <button (click)="addNote()" (keyup.enter)="addNote()"
                                    [disabled]="message && message.value && this.reLoading"
                                    aria-label="Submit Comment"
                                    class="ms-auto col-12 text-right"
                                    color="primary" mat-icon-button>
                                <mat-icon>keyboard_arrow_right</mat-icon>
                            </button>
                        </div>
                    </form>
                </mat-card-actions>
            </mat-card>
        </div>
    </div>
</main>
