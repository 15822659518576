import {Component} from '@angular/core';
import {AuthService} from "../_services/auth.service";
import {Router} from "@angular/router";

@Component({
    selector: 'app-notfound',
    templateUrl: './notfound.component.html',
    styleUrls: ['./notfound.component.scss']
})
export class NotfoundComponent {

    constructor(private authService: AuthService, private router: Router) {
    }

    goToHome(): void {
        if (this.authService.isLoggedIn()) {
            //  LoginUser has token can go to auth view
            this.router.navigate(['/timeline']);
        } else {
            //  LoginUser has no token can't go to auth view
            this.router.navigate(['/login']);
        }
    }

}
