import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {ContentService} from "../../_services/content.service";
import {AuthService} from "../../_services/auth.service";
import {SnackbarService} from "../../_services/snackbar.service";

@Component({
    selector: 'app-manage-access',
    templateUrl: './manage-access.component.html',
    styleUrl: './manage-access.component.scss'
})
export class ManageAccessComponent {
    permissions: any[] | null = null;

    id: any;
    user: any;
    title: string = "Manage Access"

    constructor(@Inject(MAT_DIALOG_DATA) private data: any,
                protected dialogRef: MatDialogRef<ManageAccessComponent>,
                public dialog: MatDialog,
                private contentService: ContentService,
                private snackBarService: SnackbarService,
                private authService: AuthService) {
        this.user = this.authService.getUser();

        if (data.context) {
            this.title = 'Manage ' + data.context + ' Access';
        }

        if (data.id) {
            this.id = data.id;
            this.contentService.viewTimelinePermissions(this.data.id).subscribe(res => {
                this.permissions = res;
                // Filter out current user
                // this.permissions = [];
                // for (let permission of res) {
                //     if (permission.user.id !== data.userId || !data.userId) {
                //         this.permissions.push(permission)
                //     }
                // }

            }, e => {
                this.snackBarService.openSnackBar(e, 'error')
            })
        }
    }
}
