import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'greeting',
    standalone: true
})
export class GreetingPipe implements PipeTransform {

    transform(value: string, ...args: unknown[]): string {
        // Pipe that returns correct greeting depending on the time of day
        if (value) {
            let now: string = new Date().getHours().toString();
            let hour = JSON.parse(now);
            if (hour < 12) {
                return 'Good morning, ' + value + '!'
            } else if (hour > 12 && hour < 17) {
                return 'Good afternoon, ' + value + '!'
            } else {
                return 'Good evening, ' + value + '!'
            }
        } else {
            return '';
        }
    }
}
