import {Component} from '@angular/core';
import {MatTableDataSource} from "@angular/material/table";
import {AdminService} from "../../_services/admin.service";
import {SnackbarService} from "../../_services/snackbar.service";
import {ContentService} from "../../_services/content.service";

@Component({
    selector: 'app-export-requests',
    templateUrl: './export-requests.component.html',
    styleUrl: './export-requests.component.scss'
})
export class ExportRequestsComponent {
    displayedColumns: string[] = ['name', 'expiry', 'actions'];
    loading: boolean = true;
    dataSource: MatTableDataSource<any> = new MatTableDataSource();
    today: Date = new Date();

    constructor(private adminService: AdminService,
                private snackbarService: SnackbarService,
                private contentService: ContentService,) {
        this.adminService.exportRequests().subscribe(r => {
            this.dataSource = new MatTableDataSource(r.results);
            this.loading = false;
        }, e => {
            this.loading = false;
            this.snackbarService.openSnackBar(e, 'error');
        })
    }

    isExpired(expiry: string): boolean {
        let expiryDate: Date = new Date(expiry)
        return expiryDate < this.today
    }

    exportTimeline(uuid: string): void {
        this.contentService.downloadTimelineExport(uuid).subscribe(r => {
            if (r.success && r.url) {
                window.open(r.url, '_blank')?.focus();
                this.snackbarService.openSnackBar('Download in progress', 'success')
            }
        }, e => {
            this.snackbarService.openSnackBar(e, 'error')
        })
    }
}
