<main *ngIf="profile" class="mediumContainer">
    <div class="d-flex flex-row flex-space-between">
        <button [routerLink]="['/users']" aria-label="Back" mat-icon-button matTooltip="Back">
            <mat-icon>keyboard_backspace</mat-icon>
        </button>
        <div class="flex-row gap-15 ">
            <div>
                <button mat-raised-button color="primary" [matMenuTriggerFor]="actions">
                    Actions
                </button>
                <mat-menu #actions="matMenu">
                    <button *ngIf="timelineId" mat-menu-item
                            [routerLink]="['/timeline', this.timelineId]">
                        View Memory Box
                    </button>
                    <button *ngIf="timelineId" mat-menu-item (click)="exportTimeline()">
                        Export Memory Box
                    </button>
                    <button mat-menu-item (click)="updatePassword()"
                            *ngIf="!profile.is_superuser || currentUser.is_superuser">
                        Update Password
                    </button>
                    <button mat-menu-item (click)="logOutUser()">
                        Log Out User
                    </button>
                    <button mat-menu-item (click)="lock(true)" *ngIf="!this.profile?.locked">
                        Lock Account
                    </button>
                    <button mat-menu-item *ngIf="this.profile?.locked" (click)="lock(false)">
                        Un-lock Account
                    </button>
                    <button mat-menu-item (click)="deactivate(false)" *ngIf="!this.profile?.deactivated">
                        Deactivate Account
                    </button>
                    <button mat-menu-item (click)="deactivate(true)"
                            *ngIf="this.profile?.deactivated">
                        Re-activate Account
                    </button>
                </mat-menu>
            </div>
        </div>
    </div>
    <div class="flex-col gap-25">
        <mat-card>
            <mat-card-header>
                <mat-card-title>
                    {{ profile.first_name || profile.username || profile.email }}'s General Settings
                </mat-card-title>
            </mat-card-header>
            <mat-card-content>
                <form [formGroup]="profileForm" class="fourthGrid">
                    <div class="pb-10 p-20">
                        <div *ngIf="showCropper" style="width: 250px;margin:auto">
                            <image-cropper
                                    (imageCropped)="imageCropped($event)"
                                    [aspectRatio]="1"
                                    [imageChangedEvent]="imageChangedEvent"
                                    [maintainAspectRatio]="true"
                                    [resizeToWidth]="250"
                                    format="png"
                            ></image-cropper>
                        </div>
                        <img [src]="imgURL" alt="Profile Picture" class="w-100 imgControl">
                        <div class="flex-col gap10 align-items-center">
                            <div>
                                <button (click)="fileInput.click()" color="primary" mat-raised-button>
                                    <span *ngIf="!showCropper">Select New Profile Picture</span>
                                    <span *ngIf="showCropper">Change Selection</span>
                                    <input #fileInput (change)="onFileChanged($event)"
                                           accept="image/png, image/jpeg" class="d-none"
                                           type="file"/>
                                </button>
                            </div>
                            <div *ngIf="imgURL != 'assets/images/blankProfile.png' && imgURL != profile.photo">
                                <button (click)="removeImage()" color="warn" mat-raised-button>Cancel
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="formGrid mb-10">
                        <mat-form-field appearance="outline" color="primary">
                            <mat-label>First Name</mat-label>
                            <input #first_name formControlName="first_name" matInput type="text">
                            <mat-error (click)="first_name.focus()"
                                       *ngIf="profileForm.controls['first_name'].hasError('required')">
                                This field is required
                            </mat-error>
                        </mat-form-field>
                        <mat-form-field appearance="outline" color="primary">
                            <mat-label>Last Name</mat-label>
                            <input #last_name formControlName="last_name" matInput type="text">
                            <mat-error (click)="last_name.focus()"
                                       *ngIf="profileForm.controls['last_name'].hasError('required')">
                                This field is required
                            </mat-error>
                        </mat-form-field>
                        <mat-form-field appearance="outline" color="primary">
                            <mat-label>Username</mat-label>
                            <input formControlName="username" matInput type="text">
                            <mat-error *ngIf="profileForm.controls['username'].hasError('required')">
                                This field is required
                            </mat-error>
                        </mat-form-field>
                        <mat-form-field *ngIf="profile.role.role_type !== 'young_person'" appearance="outline"
                                        color="primary">
                            <mat-label>Job title</mat-label>
                            <input formControlName="jobTitle" matInput type="text">
                            <mat-error *ngIf="profileForm.controls['jobTitle'].hasError('required')">
                                This field is required
                            </mat-error>
                        </mat-form-field>
                        <mat-form-field appearance="outline" class="w-100" color="primary">
                            <mat-label>Role</mat-label>
                            <select matNativeControl formControlName="role">
                                <option *ngFor="let role of roles" [value]="role.id">{{ role.name }}
                                </option>
                            </select>
                        </mat-form-field>
                        <mat-form-field appearance="outline" color="primary">
                            <mat-label>Email Address</mat-label>
                            <input formControlName="email" matInput type="email">
                            <mat-error *ngIf="profileForm.controls['email'].hasError('required')">
                                This field is required
                            </mat-error>
                            <mat-error *ngIf="profileForm.controls['email'].hasError('email')">
                                Please enter a valid email address
                            </mat-error>
                        </mat-form-field>
                        <mat-form-field appearance="outline" color="primary">
                            <mat-label>Secondary Email Address</mat-label>
                            <input formControlName="secondEmail" matInput type="email">
                            <mat-error *ngIf="profileForm.controls['secondEmail'].hasError('required')">
                                This field is required
                            </mat-error>
                            <mat-error *ngIf="profileForm.controls['secondEmail'].hasError('email')">
                                Please enter a valid email address
                            </mat-error>
                            <button aria-label="More info" mat-icon-button matSuffix
                                    matTooltip="This is an optional extra email to recieve 2 factor authentification emails">
                                <mat-icon>info</mat-icon>
                            </button>
                        </mat-form-field>
                        <mat-form-field appearance="outline" color="primary">
                            <mat-label>Contact Number</mat-label>
                            <input formControlName="phone" matInput type="number">
                            <mat-error *ngIf="profileForm.controls['phone'].hasError('required')">
                                This field is required
                            </mat-error>
                            <mat-error *ngIf="profileForm.controls['phone'].hasError('pattern')">
                                Incorrect format
                            </mat-error>
                        </mat-form-field>
                        <mat-form-field [hidden]="profile.role.role_type !== 'young_person'" appearance="outline"
                                        color="primary">
                            <mat-label>Reference Number</mat-label>
                            <input formControlName="reference_number" matInput type="text">
                            <mat-error *ngIf="profileForm.controls['reference_number'].hasError('required')">
                                This field is required
                            </mat-error>
                        </mat-form-field>
                        <mat-form-field appearance="outline" class="w-100">
                            <mat-label>About Me</mat-label>
                            <textarea #description formControlName="about" matInput
                                      maxlength="400"></textarea>
                            <app-emoji-menu [form]="profileForm" [input]="'about'" matSuffix></app-emoji-menu>
                            <mat-hint align="end">{{ description?.value?.length }} / 400</mat-hint>
                        </mat-form-field>
                        <mat-form-field appearance="outline" class="w-100">
                            <mat-label>Date of Birth</mat-label>
                            <input [matDatepicker]="picker" formControlName="dob" matInput>
                            <mat-datepicker-toggle [for]="picker" matIconSuffix></mat-datepicker-toggle>
                            <mat-datepicker #picker></mat-datepicker>
                            <mat-error *ngIf="profileForm.controls['dob'].hasError('matDatepickerParse')">
                                Invalid date format. Please use DD/MM/YYYY.
                            </mat-error>
                        </mat-form-field>
                        <mat-form-field appearance="outline" class="w-100 noBottomPadding" color="primary">
                            <mat-label>Locality</mat-label>
                            <select matNativeControl formControlName="locality">
                                <option *ngFor="let local of localities" [value]="local.id">{{ local.name }}
                                </option>
                            </select>
                            <button (click)="newLocalityModal()" aria-label="Add Locality" mat-icon-button matPrefix
                                    matTooltip="Add a new locality">
                                <mat-icon>add</mat-icon>
                            </button>
                        </mat-form-field>
                        <div>
                            <mat-form-field appearance="outline" class="w-100 noBottomPadding" color="primary">
                                <mat-label>Add Cohort</mat-label>
                                <select matNativeControl [ngModel]="newCohort" [ngModelOptions]="{standalone: true}"
                                        (ngModelChange)="selectCohort($event)">
                                    <option></option>
                                    <ng-container *ngFor="let cohort of cohorts">
                                        <option *ngIf="!checkIfInArray(cohort, this.selectedCohorts)"
                                                [value]="cohort.id">{{ cohort.name }}
                                        </option>
                                    </ng-container>
                                </select>
                                <button (click)="newCohortModal()" aria-label="Add Cohort" mat-icon-button matPrefix
                                        matTooltip="Add a new cohort">
                                    <mat-icon>add</mat-icon>
                                </button>
                            </mat-form-field>
                            <div class="mt-20 flex-row flex-wrap gap10">
                                <mat-chip (removed)="removeCohort(cohort)"
                                          *ngFor="let cohort of this.selectedCohorts"
                                          class="smChip">
                                    {{ cohort.name }}
                                    <button matChipRemove>
                                        <mat-icon>cancel</mat-icon>
                                    </button>
                                </mat-chip>
                            </div>
                        </div>

                    </div>
                </form>

                <div class="w-100 text-center flex-space-evenly flex-row mt-10">
                    <button (click)="saveProfile()" [disabled]="profileForm.invalid || loading" color="primary"
                            mat-raised-button>
                        Save
                        <mat-icon *ngIf="loading">
                            <mat-spinner color="primary"></mat-spinner>
                        </mat-icon>
                    </button>
                </div>
            </mat-card-content>
        </mat-card>
        <mat-card>
            <mat-card-header>
                <mat-card-title>
                    {{ profile.first_name }}'s Key Information
                </mat-card-title>
            </mat-card-header>
            <mat-card-content class="overflow-scroll">
                <table class="normalTable mt-10">
                    <tr>
                        <td *ngIf="this.profile?.role?.role_type == 'admin'">Superuser:</td>
                        <th *ngIf="timelineId">Memory Box</th>
                        <th>Accepted Terms of Service</th>
                        <th>Invite Date</th>
                        <th>Latest Invite</th>
                        <th>Number of Invites</th>
                        <th>Registration Date</th>
                        <th>Last Login</th>
                        <th *ngIf="timelineId">Memory Box Last Uploaded to</th>
                    </tr>
                    <tr>
                        <td *ngIf="this.profile?.role?.role_type == 'admin'">
                            {{ profile.is_superuser }}
                        </td>
                        <td *ngIf="timelineId">
                            <div class="d-flex flex-row flex-wrap">

                                <a mat-button class="d-flex align-items-center clickable" role="button"
                                   [cdkCopyToClipboard]="this.timelineLink"
                                   matTooltip="Copy timeline link to clipboard">
                                    <mat-icon class="h-auto">filter_none</mat-icon>
                                    Copy
                                </a> &nbsp;
                                <a mat-button [routerLink]="['/timeline', this.timelineId]">
                                    <mat-icon>link</mat-icon>
                                    View
                                </a>
                            </div>
                        </td>
                        <td>{{ profile.agreed_default_tos ? "Yes" : "No" }}</td>
                        <td>
                            <ng-container *ngIf="profile.invite_date else na">
                                {{ profile.invite_date | date }}
                            </ng-container>
                        </td>
                        <td>
                            <ng-container *ngIf="profile.latest_invite_date else na">
                                {{ profile.latest_invite_date | date }}
                            </ng-container>
                        </td>
                        <td>
                            <ng-container *ngIf="profile.number_of_invites_sent else na">
                                {{ profile.number_of_invites_sent }}
                            </ng-container>
                        </td>
                        <td>
                            <ng-container *ngIf="profile.registration_date else na">
                                {{ profile.registration_date | date }}
                            </ng-container>
                        </td>
                        <td>
                            <ng-container *ngIf="profile.last_login else na">
                                {{ profile.last_login | date }}
                            </ng-container>
                        </td>
                        <td *ngIf="timelineId">
                            <ng-container *ngIf="profile.timeline?.last_uploaded else na">
                                {{ profile.timeline?.last_uploaded | date }}
                            </ng-container>
                        </td>
                    </tr>
                </table>
            </mat-card-content>
        </mat-card>
        <mat-card *ngIf="this.boxPermissions">
            <mat-card-header class="fullWidth">
                <mat-card-title class="w-100">
                    <div class="d-flex flex-space-between w-100 align-items-center">
                        Users with access to {{ profile.first_name }}'s Memory Box
                        <button (click)="newRelationship()" matTooltip="Add new permission" mat-raised-button
                                color="primary">
                            Add new permission
                            <mat-icon>add</mat-icon>
                        </button>
                    </div>
                </mat-card-title>
            </mat-card-header>
            <mat-card-content>
                <app-manage-access-table [child]="profile.id" [permissions]="boxPermissions" [canRequest]="true"
                                         [timeline]="this.timelineId"></app-manage-access-table>
            </mat-card-content>
        </mat-card>
        <mat-card>
            <mat-card-header class="fullWidth">
                <mat-card-title>
                    <div class="d-flex flex-space-between w-100 align-items-center">
                        Memory Boxes {{ profile.first_name }} has access to
                        <button (click)="newMemoryBoxAccess()" matTooltip="Add new permission" mat-raised-button
                                color="primary">
                            Add new access
                            <mat-icon>add</mat-icon>
                        </button>
                    </div>
                </mat-card-title>
            </mat-card-header>
            <mat-card-content>
                <app-manage-access-table [child]="profile.id" [permissions]="permissions" [currentAccess]="true"
                                         [canRequest]="true"></app-manage-access-table>
            </mat-card-content>
        </mat-card>
        <mat-card *ngIf="permissionRequests && permissionRequests.length">
            <mat-card-header>
                <mat-card-title>
                    Permission Requests
                </mat-card-title>
            </mat-card-header>
            <mat-card-content>
                <app-permission-requests-table [data]="permissionRequests" [timeline]="timeline"
                                               [count]="permissionRequestsCount"
                                               [includeOld]="true" [limit]="changeRequestLimit"
                                               (handlePageEvent)="permissionRequestsPageChange($event)"
                                               (orderChange)="changePermissionRequestsOrder($event)"></app-permission-requests-table>
            </mat-card-content>
        </mat-card>
    </div>
</main>

<div *ngIf="!profile" class="centerLoading">
    <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
</div>
<ng-template #na>N/A</ng-template>
