<mat-tab-group mat-align-tabs="start" mat-stretch-tabs="true">
    <mat-tab label="Unread">
        <mat-progress-bar *ngIf="notificationsLoading" mode="indeterminate"></mat-progress-bar>
        <p *ngIf="this.unreadNotifications && this.unreadNotifications.length <= 0&& !notificationsLoading"
           class="text-center muted">Looks Like You Are All Caught Up!</p>
        <mat-accordion>
            <app-notification (markReadOutput)="markRead()"
                              *ngFor="let notification of this.unreadNotifications"
                              [notification]="notification" [read]="false"></app-notification>
        </mat-accordion>
    </mat-tab>
    <mat-tab label="Read">
        <mat-progress-bar *ngIf="notificationsLoading" mode="indeterminate"></mat-progress-bar>
        <p *ngIf="this.readNotifications && this.readNotifications.length <= 0 && !notificationsLoading"
           class="text-center muted">Looks Like You Are All Caught Up!</p>
        <mat-accordion>
            <app-notification *ngFor="let notification of this.readNotifications"
                              [notification]="notification" [read]="true"></app-notification>
        </mat-accordion>
        <div *ngIf="this.canLoadMore && this.readNotifications.length > 0" class="w-100 text-center">
            <button (click)="this.getUnreadNotes()" (keyup.enter)="getUnreadNotes()" color="primary"
                    mat-raised-button>Load More
            </button>
        </div>
    </mat-tab>
</mat-tab-group>
