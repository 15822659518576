<main class="mediumContainer">
    <div class="d-flex flex-row">
        <button (click)="history.back()" aria-label="Back" mat-icon-button matTooltip="Back">
            <mat-icon>keyboard_backspace</mat-icon>
        </button>
    </div>
    <div class="flex-col gap-25">
        <mat-card>
            <mat-card-header>
                <mat-card-title>
                    Invite
                    <ng-container *ngIf="id">Details</ng-container>
                </mat-card-title>
                <mat-card-subtitle *ngIf="id">
                    (Sent  {{ invite?.invite_sent_date | date }})
                    (Status  {{ invite?.status }})
                </mat-card-subtitle>
            </mat-card-header>
            <mat-card-content>
                <form [formGroup]="inviteForm">
                    <div class="formGrid mt-10">
                        <mat-form-field appearance="outline" color="primary">
                            <mat-label>First Name</mat-label>
                            <input #first_name formControlName="first_name" matInput required type="text">
                            <mat-error (click)="first_name.focus()"
                                       *ngIf="inviteForm.controls['first_name'].hasError('required')">
                                This field is required
                            </mat-error>
                        </mat-form-field>
                        <mat-form-field appearance="outline" color="primary">
                            <mat-label>Last Name</mat-label>
                            <input #last_name formControlName="last_name" matInput required type="text">
                            <mat-error (click)="last_name.focus()"
                                       *ngIf="inviteForm.controls['last_name'].hasError('required')">
                                This field is required
                            </mat-error>
                        </mat-form-field>
                        <mat-form-field appearance="outline" color="primary">
                            <mat-label>Email Address</mat-label>
                            <input formControlName="email" matInput required type="email">
                            <mat-error *ngIf="inviteForm.controls['email'].hasError('required')">
                                This field is required
                            </mat-error>
                            <mat-error *ngIf="inviteForm.controls['email'].hasError('email')">
                                Please enter a valid email address
                            </mat-error>
                        </mat-form-field>
                        <mat-form-field appearance="outline" color="primary">
                            <mat-label>Secondary Email Address</mat-label>
                            <input formControlName="secondEmail" matInput type="email">
                            <mat-error *ngIf="inviteForm.controls['secondEmail'].hasError('email')">
                                Please enter a valid email address
                            </mat-error>
                            <button aria-label="More info" mat-icon-button matSuffix
                                    matTooltip="If present this email will also recieve the invite email">
                                <mat-icon>info</mat-icon>
                            </button>
                        </mat-form-field>

                        <mat-form-field appearance="outline" class="w-100" color="primary">
                            <mat-label>Role</mat-label>
                            <select (change)="checkRole()" formControlName="role" matNativeControl required>
                                <option *ngFor="let role of roles" [value]="role.id">{{ role.name }}</option>
                            </select>
                            <mat-error *ngIf="inviteForm.controls['role'].hasError('required')">
                                This field is required
                            </mat-error>
                        </mat-form-field>
                        <mat-form-field appearance="outline" class="w-100">
                            <mat-label>Date of Birth</mat-label>
                            <input [matDatepicker]="picker" [required]="childRole" formControlName="dob" matInput>
                            <mat-datepicker-toggle [for]="picker" matIconSuffix></mat-datepicker-toggle>
                            <mat-datepicker #picker></mat-datepicker>
                            <mat-error *ngIf="inviteForm.controls['dob'].hasError('required')">
                                This field is required for child users
                            </mat-error>
                            <mat-error *ngIf="inviteForm.controls['dob'].hasError('matDatepickerParse')">
                                Invalid date format. Please use DD/MM/YYYY.
                            </mat-error>
                        </mat-form-field>
                        <mat-form-field *ngIf="inviteForm.value.role == 'Child'" appearance="outline" color="primary">
                            <mat-label>Reference Number</mat-label>
                            <input formControlName="reference_number" matInput type="text">
                        </mat-form-field>
                        <mat-form-field appearance="outline" class="w-100" color="primary">
                            <mat-label>Locality</mat-label>
                            <select formControlName="locality" matNativeControl>
                                <option *ngFor="let locality of localities" [value]="locality.id">{{ locality.name }}
                                </option>
                            </select>
                            <button (click)="newLocalityModal()" aria-label="Add Locality" mat-icon-button matPrefix
                                    matTooltip="Add a new Locality">
                                <mat-icon>add</mat-icon>
                            </button>
                        </mat-form-field>
                        <div>
                            <mat-form-field appearance="outline" class="w-100" color="primary">
                                <mat-label>Add Cohort</mat-label>
                                <select [ngModel]="newCohort" (ngModelChange)="selectCohort($event)"
                                        [ngModelOptions]="{standalone: true}"

                                        matNativeControl>
                                    <ng-container *ngFor="let cohort of cohorts">
                                        <option *ngIf="!checkIfInArray(cohort, this.selectedCohorts)"
                                                [value]="cohort.id">{{ cohort.name }}
                                        </option>
                                    </ng-container>
                                </select>
                                <button (click)="newCohortModal()" aria-label="Add Cohort" mat-icon-button matPrefix
                                        matTooltip="Add a new cohort">
                                    <mat-icon>add</mat-icon>
                                </button>
                            </mat-form-field>
                            <div class="mt-20 flex-row flex-wrap gap10">
                                <mat-chip (removed)="removeCohort(cohort)"
                                          *ngFor="let cohort of this.selectedCohorts"
                                          class="smChip">
                                    {{ cohort.name }}
                                    <button matChipRemove>
                                        <mat-icon>cancel</mat-icon>
                                    </button>
                                </mat-chip>
                            </div>
                        </div>
                        <mat-slide-toggle
                                *ngIf="inviteForm.value.secondEmail && inviteForm.value.secondEmail?.length > 0"
                                class="center"
                                color="primary"
                                formControlName="sendToSecondary"
                                ngDefaultControl>
                            Send invite email to secondary email only
                        </mat-slide-toggle>
                    </div>
                </form>
            </mat-card-content>
            <mat-card-actions>
                <div class="w-100 text-center flex-space-evenly flex-row">
                    <div *ngIf="!this.id" class="flex-col">
                        <button (click)="fileInput.click()" (keyup.enter)="fileInput.click()"
                                color="accent" mat-raised-button>
                            <span>Bulk Invite From CSV</span>
                            <mat-icon>attach_file</mat-icon>
                            <input #fileInput (change)="onFileChanged($event)"
                                   accept="text/csv" class="d-none"
                                   type="file"/>
                        </button>
                        <small class="mt-5"><a download href="assets/VMB_users_invite_template.csv">Download CSV
                            template</a></small>
                    </div>

                    <button *ngIf="this.id && this.invite?.status != 'Accepted'" color="warn"
                            mat-raised-button (click)="deleteUser()">
                        Delete Invite
                    </button>
                    <button (click)="reSendInvite()" *ngIf="this.id && this.invite?.status != 'Accepted'"
                            color="primary" mat-raised-button>
                        Re-send Invite
                    </button>
                    <button *ngIf="this.id" [routerLink]="['/user',this.invite?.user?.id]" color="accent"
                            mat-raised-button>
                        View User
                    </button>
                    <button (click)="saveInvite()" [disabled]="inviteForm.invalid || this.invite?.status == 'Accepted'"
                            color="primary" mat-raised-button>
                        Save
                    </button>
                </div>
            </mat-card-actions>
        </mat-card>
    </div>
</main>

