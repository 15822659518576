<mat-dialog-content class="d-flex flex-col" role="dialog" aria-modal="true" *ngIf="data">
    <div class="flex-row flex-space-between">
        <div>
            <mat-card-header class="p-0">
                <mat-card-title *ngIf="data.title">
                    <h3 class="m-0">{{ data.title }}</h3>
                </mat-card-title>
            </mat-card-header>
        </div>
        <div class="float-end">
            <button class="mat-dialog-close " mat-dialog-close mat-icon-button matTooltip="Close Window"
                    (keyup.enter)="this.dialogRef.close();">
                <mat-icon>close</mat-icon>
            </button>
        </div>
    </div>
    <mat-card-content>
        <div class="content" *ngIf="data.content_rendered || data.content" [innerHTML]="data.content_rendered || data.content"></div>

        <ng-container *ngIf="data.isVideo">
            <div class="text-center w-100 d-flex justify-content-center" >
                <video class="videoControl" controls *ngIf="data.attachment" crossorigin="anonymous">
                    <source [src]="data.attachment">
                    <track *ngIf="data.vtt_file"
                           label="English"
                           kind="subtitles"
                           srclang="en"
                           [src]="data.vtt_file"
                           default/>
                    Your browser does not support the video tag.
                </video>
            </div>
            <p *ngIf="data.description">
                {{ data.description }}
            </p>
        </ng-container>
    </mat-card-content>
</mat-dialog-content>
<mat-dialog-actions *ngIf="!data.isVideo">
    <button *ngIf="data.attachment" (click)="link(data.attachment, false, data.title)"
            (keyup.enter)="link(data.attachment, false, data.title)"
            color="primary" mat-raised-button>Download
    </button>
</mat-dialog-actions>

