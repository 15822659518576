import {Component} from '@angular/core';
import {ContentService} from "../_services/content.service";
import {AuthService} from "../_services/auth.service";

@Component({
    selector: 'app-people',
    templateUrl: './people.component.html',
    styleUrl: './people.component.scss'
})
export class PeopleComponent {
    loading: boolean = true;
    user: any;
    timelines: any[] = []
    typingTimer: any;
    searchTerm: any;
    contactEmail: string | null = null

    constructor(private contentService: ContentService,
                private authService: AuthService,) {

        this.getTimelines();
        this.authService.refreshUser().then(
            this.user = this.authService.getUser()
        );
        this.authService.getDynamicSettings().then((r): void => {
            this.contactEmail = r.contact_email;
        });
    }

    keyup(): void {
        clearTimeout(this.typingTimer);
        const that: this = this;
        this.typingTimer = setTimeout((): void => {
            that.loading = true;
            that.getTimelines();
        }, 1000);
    }


    clearSearch(): void {
        this.searchTerm = '';
        this.getTimelines();
    }

    keydown(): void {
        clearTimeout(this.typingTimer);
    }

    getTimelines() {
        this.contentService.getRelatedUsers(this.searchTerm).subscribe(r => {
            this.timelines = r.results;
            this.loading = false;
        }, e => {
            console.error(e)
        })
    }

}
