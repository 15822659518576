<main>
    <div class="d-flex flex-row justify-content-center">
        <h3 class="my-10">
            {{ subtitle }}
        </h3>
    </div>
    <div class="largeContainer">
        <div *ngIf="loading" class="centerLoading">
            <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
        </div>
        <div *ngIf="!loading" class="mat-elevation-z6">
            <div class="flex-row gap-15 bg-white align-items-center flex-space-around filterExtraItems">
                <mat-form-field class="w-100 noBottomPadding" color="primary">
                    <mat-label>Category</mat-label>
                    <select [(ngModel)]="status" (ngModelChange)="getMedia()" matNativeControl>
                        <option [value]="'All'">All</option>
                        <option [value]="'hidden'">Hidden</option>
                        <option [value]="'approval'">Approval</option>
                        <option [value]="'flagged'">Flagged</option>
                    </select>
                </mat-form-field>
            </div>
            <table [dataSource]="dataSource" mat-table matSort class="responsiveTable massiveTable"
                   [matSortActive]="order_by" [matSortDirection]="direction"
                   [class.openSidebar]="largerTable"
                   (matSortChange)="sortData($event)">
                <ng-container matColumnDef="memoryBox">
                    <th *matHeaderCellDef mat-header-cell> Memory Box</th>
                    <td *matCellDef="let media" mat-cell><b class="d-none columnTitle">Memory Box
                        :</b>
                        <a mat-button
                           [routerLink]="['/timeline', this.media.timeline.id]">{{ media.timeline.title }}</a>
                    </td>
                </ng-container>
                <ng-container matColumnDef="author">
                    <th *matHeaderCellDef mat-header-cell> Uploader</th>
                    <td *matCellDef="let media" mat-cell><b class="d-none columnTitle">Uploader
                        :</b>
                        <a mat-button *ngIf="this.user?.role?.role_type == 'admin'"
                           [routerLink]="media.user.timeline?.id ? ['/user', media.user.id, media.user.timeline?.id] : ['/user', media.user.id]">{{ media.user.full_name }}</a>
                        <a mat-button *ngIf="this.user?.role?.role_type !== 'admin'"
                           [routerLink]="['/profile',media.user.id ]">{{ media.user.full_name }}</a>
                    </td>
                </ng-container>
                <ng-container matColumnDef="img">
                    <th *matHeaderCellDef mat-header-cell></th>
                    <td *matCellDef="let media" mat-cell>
                        <img alt="Media Thumbnail" [src]="media.thumbnail||'assets/images/image.png'"
                             class="smallPreview">
                    </td>
                </ng-container>
                <ng-container matColumnDef="title">
                    <th *matHeaderCellDef mat-header-cell mat-sort-header> Title</th>
                    <td *matCellDef="let media" mat-cell><b
                            class="d-none columnTitle">Title:</b> {{ media.title | ellipsis:30 }}
                    </td>
                </ng-container>
                <ng-container matColumnDef="file_type">
                    <th *matHeaderCellDef mat-header-cell mat-sort-header> File Type</th>
                    <td *matCellDef="let media" mat-cell><b class="d-none columnTitle">File
                        Type:</b> {{ media.file_type| titlecase }}
                    </td>
                </ng-container>

                <ng-container matColumnDef="description">
                    <th *matHeaderCellDef mat-header-cell> Description</th>
                    <td *matCellDef="let media" mat-cell><b class="d-none columnTitle">Display
                        Date:</b> {{ media.description }}
                    </td>
                </ng-container>
                <ng-container matColumnDef="hidden_status">
                    <th *matHeaderCellDef mat-header-cell mat-sort-header> Category</th>
                    <td *matCellDef="let media" mat-cell><b
                            class="d-none columnTitle">Category:</b> {{ media.hidden_status | titlecase }}
                    </td>
                </ng-container>
                <ng-container matColumnDef="hide_until">
                    <th *matHeaderCellDef mat-header-cell mat-sort-header> Reveal Date</th>
                    <td *matCellDef="let media" mat-cell><b class="d-none columnTitle">Reveal
                        Date:</b> {{ media.hide_until | date }}
                    </td>
                </ng-container>
                <ng-container matColumnDef="created_date">
                    <th *matHeaderCellDef mat-header-cell mat-sort-header> Upload Date</th>
                    <td *matCellDef="let media" mat-cell><b class="d-none columnTitle">Upload
                        Date:</b> {{ media.created_date | date }}
                    </td>
                </ng-container>

                <ng-container matColumnDef="view">
                    <th *matHeaderCellDef mat-header-cell></th>
                    <td *matCellDef="let media" mat-cell>
                        <a mat-raised-button color="primary" class="text-center"
                           *ngIf="(this.user?.role?.can_manage_hidden_media || this.user?.role.role_type =='admin')"
                           [routerLink]="['/hiddenMedia', media.id]">
                            View
                        </a>
                    </td>
                </ng-container>
                <tr *matHeaderRowDef="displayedColumns" mat-header-row></tr>
                <tr *matRowDef="let row; columns: displayedColumns;" mat-row></tr>
                <tr *matNoDataRow class="mat-row">
                    <td *ngIf="status.length > 0 else notFound"
                        [attr.colspan]="this.displayedColumns.length"
                        class="mat-cell text-center">No data matching these filters
                    </td>
                </tr>
            </table>
            <mat-paginator (page)="handlePageEvent($event)" [length]="count"
                           [pageSizeOptions]="[10, 15, 25, 50]"
                           [pageSize]="limit" aria-label="Select page"></mat-paginator>
        </div>
    </div>
</main>

<ng-template #notFound>
    <td *ngIf="!loading" [attr.colspan]="this.displayedColumns.length" class="mat-cell text-center">No hidden media
        found
    </td>
</ng-template>
