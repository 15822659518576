import {Component, Inject} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";

@Component({
    selector: 'app-add-access',
    templateUrl: './add-access.component.html',
    styleUrl: './add-access.component.scss'
})
export class AddAccessComponent {
    permissionForm: FormGroup
    userSearchControl: FormControl = new FormControl();
    loadingUsers: boolean = false;
    filteredUsers: any[] = [];
    currentPermissions: any[] = [];
    timeline_id: any;
    users: any[] = [];
    user: any

    constructor(protected dialogRef: MatDialogRef<AddAccessComponent>,
                @Inject(MAT_DIALOG_DATA) private data: any,
                private formBuilder: FormBuilder) {

        this.userSearchControl.valueChanges.subscribe(
            (value): void => {
                this.filteredUsers = [];
                if (value && typeof value == 'string') {
                    value = value?.toLowerCase()
                }
                for (let user of this.users) {
                    if ((user['First Name']?.toLowerCase().includes(value) || user['Last Name']?.toLowerCase().includes(value) || user['Email']?.toLowerCase().includes(value)) && !this.alreadyHasUser(user)) {
                        this.filteredUsers.push(user)
                    }
                }
            }
        );

        if (data.existingPermissions) {
            this.currentPermissions = data.existingPermissions
        }
        if (data.user) {
            this.user = data.user
        }
        if (data.possibleUsers) {
            this.users = data.possibleUsers
        }

        this.permissionForm = this.formBuilder.group({
            permission: ['', Validators.required],
        });

    }

    createPermission(): void {
        this.dialogRef.close({
            email: this.userSearchControl.value.Email,
            permission: this.permissionForm.value.permission
        });
    }

    displayFn(user: any): string {
        return user['Email']
    }

    checkIfInArray(item: any, otherArray: any): boolean {
        let i
        if (otherArray) {
            i = otherArray.findIndex((x: any): boolean => x.timeline === item.id);
        }
        return (i != null && i >= 0);
    }

    alreadyHasUser(user: any): boolean {
        var i;
        for (i = 0; i < this.currentPermissions.length; i++) {
            if (this.currentPermissions[i] === user) {
                return true;
            }
        }

        return false;
    }
}
