<div>
    <mat-card-header class="flex-row flex-space-between p-20 bg-accent" *ngIf="data?.showHeader">
        <mat-card-title>
            {{ data?.title | titlecase }}
        </mat-card-title>
        <div class="float-end">
            <button class="mat-dialog-close close-icon-button" [mat-dialog-close]="false" mat-icon-button
                    matTooltip="Close Modal"
                    tabindex="-1">
                <mat-icon class="accent-text">close</mat-icon>
            </button>
        </div>
    </mat-card-header>
    <mat-dialog-content>
        <div class="p-20 pb-10">
            <p class="content-message" [innerHTML]="data?.message"></p>
        </div>
    </mat-dialog-content>
</div>
<mat-dialog-actions>
    <div class="w-100 d-flex flex-space-around pb-15">
        <button mat-raised-button color="warn" [mat-dialog-close]="false" *ngIf="data?.showCancelBtn">
            {{ data?.cancelBtnText ?? 'Cancel' }}
        </button>
        <button mat-raised-button color="accent" *ngIf="data?.showSubmitBtn"
                [mat-dialog-close]="true">
            {{ data?.submitBtnText ?? 'Confirm' }}
        </button>
    </div>
</mat-dialog-actions>
