import {Component, Input, OnChanges} from '@angular/core';
import {AuthService} from "../../_services/auth.service";

@Component({
    selector: 'app-password-validator',
    templateUrl: './password-validator.component.html',
    styleUrls: ['./password-validator.component.scss']
})
export class PasswordValidatorComponent implements OnChanges {
    @Input() password: string = '';
    @Input() matching: boolean = false;
    oneNumber: boolean = false;
    oneLower: boolean = false;
    oneUpper: boolean = false;
    whiteSpace: boolean = false;
    minLength: boolean = false;
    oneSpecial: boolean = false;

    length: number = 8;

    constructor(private authService: AuthService) {
        this.checkPassword();
        this.authService.getDynamicSettings().then((r): void => {
            if (r.minimum_password_length) {
                this.length = r.minimum_password_length
            }
        });
    }

    checkPassword(): void {
        const oneNumber: RegExp = /\d/;
        this.oneNumber = oneNumber.test(this.password);

        const oneLower: RegExp = /[a-z]/;
        this.oneLower = oneLower.test(this.password);

        const oneUpper: RegExp = /[A-Z]/;
        this.oneUpper = oneUpper.test(this.password);

        const whiteSpace: RegExp = /\s/;
        this.whiteSpace = whiteSpace.test(this.password);

        const oneSpecial: RegExp = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/;
        this.oneSpecial = oneSpecial.test(this.password);

        this.minLength = this.password?.length >= this.length;
    }

    ngOnChanges(): void {
        this.checkPassword();
    }
}
