import {Component} from '@angular/core';
import {MatDialog, MatDialogRef} from "@angular/material/dialog";
import {LightboxComponent} from "../../shared/lightbox/lightbox.component";
import {MoveMediaComponent} from "./move-media/move-media.component";
import {ActivatedRoute} from "@angular/router";
import {SnackbarService} from "../../_services/snackbar.service";
import {AdminService} from "../../_services/admin.service";
import {ContentService} from "../../_services/content.service";
import {ConfirmDialogComponent} from "../../shared/confirm-dialog/confirm-dialog.component";
import {ConfirmationDialogData} from "../../_types/shared";

@Component({
    selector: 'app-flagged-media',
    templateUrl: './flagged-media.component.html',
    styleUrl: './flagged-media.component.scss'
})
export class FlaggedMediaComponent {
    resolution: any;
    media: any;
    flag: any;
    id: any;

    constructor(public dialog: MatDialog,
                private route: ActivatedRoute,
                private snackbarService: SnackbarService,
                private contentService: ContentService,
                private adminService: AdminService) {
        this.route.params
            .subscribe(params => {
                this.id = params['id'];

                this.adminService.getUserFeedback(this.id).subscribe(r => {
                    this.flag = r;
                    if (r.action) {
                        this.resolution = r.action;
                    }
                    if (r.content) {
                        this.media = r.content;
                    }
                }, e => {
                    this.snackbarService.openSnackBar(e, 'error')
                    console.error(e)
                })

            }, error => {
                this.snackbarService.openSnackBar(error, 'error')
                console.error(error);
            });
    }

    updateUserFeedback(): void {
        let resolution_status: string = 'Pending';
        let processed_by_admin: boolean = false;

        if (this.resolution && !this.flag.action) {
            // marked as actioned if adding action to it
            resolution_status = 'Actioned'
            processed_by_admin = true;
        } else if (this.flag.resolution_status == 'Pending') {
            // marked as resolved if not adding action to it
            resolution_status = 'Resolved'
            processed_by_admin = false;
        } else {
            // mark as pending if not marking as other two
            resolution_status = 'Pending'
            processed_by_admin = false;
        }
        let body = {
            resolution_status,
            action: this.resolution,
            processed_by_admin
        }
        this.adminService.updateUserFeedback(this.id, body).subscribe(r => {
            this.snackbarService.openSnackBar('Status Updated', 'success')
            location.reload();
        }, e => {
            this.snackbarService.openSnackBar(e, 'error')
            console.error(e)
        })
    }

    viewImage(): void {
        // stop all active media
        document.querySelectorAll('video').forEach(vid => vid.pause());
        document.querySelectorAll('audio').forEach(vid => vid.pause());
        const dialogRef: MatDialogRef<LightboxComponent> = this.dialog.open(LightboxComponent, {
            data: {
                media: [this.media],
                index: 0,
                permission: 'View, Comment and Add',
            },
            panelClass: 'fullWidthWindow',
        });
    }

    moveMedia(): void {
        const dialogRef: MatDialogRef<MoveMediaComponent> = this.dialog.open(MoveMediaComponent, {
            data: {
                media: this.media,
                timelineId: this.flag.timeline.id,
                album: this.flag.content?.memory?.id
            },
            panelClass: 'fullWidthWindow',
        });
    }

    hide(): void {
        const dialogRef: MatDialogRef<ConfirmDialogComponent> = this.dialog.open<ConfirmDialogComponent, ConfirmationDialogData>(ConfirmDialogComponent, {
            panelClass: 'mediumWindow',
            data: {
                showSubmitBtn: true,
                showCancelBtn: true,
                message: 'Are you sure you want to hide this media? It will be hidden from all users.',
                title: 'Hide Media',
                showHeader: true
            }
        });
        dialogRef.afterClosed().subscribe((resp): void => {
            if (resp) {
                this.adminService.hideMedia(this.flag.id).subscribe(r => {
                    this.snackbarService.openSnackBar('Media Hidden', 'success')
                    this.media.hidden = true;
                }, e => {
                    console.error(e)
                    this.snackbarService.openSnackBar(e, 'error')
                })
            }
        });
    }

    unhide(): void {
        this.adminService.unHideMedia(this.flag.id).subscribe(r => {
            this.snackbarService.openSnackBar('Media Un-hidden', 'success')
            this.media.hidden = false;
        }, e => {
            console.error(e)
            this.snackbarService.openSnackBar(e, 'error')
        })
    }

    delete(): void {
        const dialogRef: MatDialogRef<ConfirmDialogComponent> = this.dialog.open<ConfirmDialogComponent, ConfirmationDialogData>(ConfirmDialogComponent, {
            panelClass: 'mediumWindow',
            data: {
                showSubmitBtn: true,
                showCancelBtn: true,
                message: 'Are you sure you want to delete this media? You cannot undo this action.',
                title: 'Delete Media',
                showHeader: true
            }
        });
        dialogRef.afterClosed().subscribe((resp): void => {
            if (resp) {
                this.adminService.deleteMedia(this.flag.id).subscribe(r => {
                    this.snackbarService.openSnackBar('Media Deleted', 'success')
                }, e => {
                    console.error(e)
                    this.snackbarService.openSnackBar(e, 'error')
                })
            }
        });
    }

}
