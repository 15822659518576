import {Component} from '@angular/core';
import {MatIconRegistry} from "@angular/material/icon";
import {DomSanitizer} from "@angular/platform-browser";
import {ThemeService} from "./_services/theme.service";
import {AuthService} from "./_services/auth.service";
import {environment} from "../environments/environment";

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})

export class AppComponent {
    title: string = 'vmb';
    user: any;

    constructor(private matIconRegistry: MatIconRegistry,
                private domSanitizer: DomSanitizer,
                private themeService: ThemeService,
                private authService: AuthService
    ) {
        this.matIconRegistry.addSvgIcon(
            `boxIcon`,
            this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/images/box-solid.svg")
        );

        this.themeService.savePrimaryColor(environment.primaryColor)
        this.themeService.saveAccentColor(environment.accentColor)
        this.user = this.authService.getUser();
        this.authService.getDynamicSettings().then((r): void => {
            if (r && r.font_accessibility && this.user) {
                if (this.user?.font_size > 1) {
                    document.documentElement.style.setProperty('--font-size', ((this.user.font_size + 99) + '%'));
                }
                if (this.user?.font_family == 'FSMeBold') {
                    document.documentElement.style.setProperty('--font-family', 'AtkinsonHyperlegible');
                } else if (this.user?.font_family != 'roboto') {
                    document.documentElement.style.setProperty('--font-family', this.user.font_family);
                }
            }
        });
    }
}
