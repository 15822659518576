import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'removeBrackets',
    standalone: true
})
export class RemoveBracketsPipe implements PipeTransform {

    transform(value: string, ...args: unknown[]): unknown {
        let regex: RegExp = /\(.*?\)/g;
        return value.replace(regex, "");
    }

}
