import {Component} from '@angular/core';
import {MatDialog, MatDialogRef} from "@angular/material/dialog";
import {ActivatedRoute, Router} from "@angular/router";
import {SnackbarService} from "../../_services/snackbar.service";
import {AdminService} from "../../_services/admin.service";
import {LightboxComponent} from "../../shared/lightbox/lightbox.component";
import {ConfirmDialogComponent} from "../../shared/confirm-dialog/confirm-dialog.component";
import {ConfirmationDialogData} from "../../_types/shared";
import {ContentService} from "../../_services/content.service";
import {FormBuilder, FormGroup} from "@angular/forms";
import * as moment from 'moment';
import {AuthService} from "../../_services/auth.service";

@Component({
    selector: 'app-hidden-media-details',
    templateUrl: './hidden-media-details.component.html',
    styleUrl: './hidden-media-details.component.scss'
})
export class HiddenMediaDetailsComponent {
    media: any;
    id: any;
    hiddenForm: FormGroup;
    notes: any[] = [];
    user: any;
    today: Date = new Date();
    messageForm: FormGroup;
    loading: boolean = false;
    reLoading: boolean = false;

    constructor(public dialog: MatDialog,
                private route: ActivatedRoute,
                private router: Router,
                private authService: AuthService,
                private snackbarService: SnackbarService,
                private contentService: ContentService,
                private readonly formBuilder: FormBuilder,
                private adminService: AdminService) {
        this.user = this.authService.getUser();

        this.messageForm = this.formBuilder.group({
            message: [''],
        });

        this.route.params
            .subscribe(params => {
                this.id = params['id'];

                this.contentService.getMedia(this.id).subscribe(r => {
                    this.media = r;
                    this.hiddenForm.patchValue({
                        hidden_status: this.media.hidden_status,
                        hide_until: this.media.hide_until ? moment(this.media.hide_until).format('YYYY-MM-DD') : null,
                    })
                }, e => {
                    this.snackbarService.openSnackBar(e, 'error')
                    console.error(e)
                })
                this.contentService.getMediaNotes(this.id).subscribe(r => {
                    this.notes = r['Success'].reverse();
                    setTimeout((): void => {
                        // scroll to bottom of chat container
                        const element: HTMLElement | null = document.getElementById('chatContent');
                        if (element) {
                            element.scrollTop = element.scrollHeight;
                        }
                    }, 50);
                }, e => {
                    this.snackbarService.openSnackBar(e, 'error')
                    console.error(e)
                })

            }, error => {
                this.snackbarService.openSnackBar(error, 'error')
                console.error(error);
            });

        this.hiddenForm = this.formBuilder.group({
            hidden_status: [],
            hide_until: [''],
        })
    }

    patchMedia(): void {
        this.loading = true;
        let body: {
            hidden_status?: string | null,
            hide_until?: string | null,
        } = {
            hidden_status: this.hiddenForm.value.hidden_status,
            hide_until: this.hiddenForm.value.hide_until ? moment(this.hiddenForm.value.hide_until).utc().format("YYYY-MM-DDTHH:mm:ss") : null,
        }

        this.contentService.patchMedia(this.id, body).subscribe(r => {
            this.loading = false;
            this.media = r;
            this.hiddenForm.patchValue({
                hidden_status: this.media.hidden_status,
                hide_until: this.media.hide_until ? moment(this.media.hide_until).format('YYYY-MM-DD') : null,
            })
            this.snackbarService.openSnackBar('Media Updated', 'success')
        }, e => {
            this.loading = false;
            this.snackbarService.openSnackBar(e, 'error')
            console.error(e)
        })
    }

    viewImage(): void {
        // stop all active media
        document.querySelectorAll('video').forEach(vid => vid.pause());
        document.querySelectorAll('audio').forEach(vid => vid.pause());
        const dialogRef: MatDialogRef<LightboxComponent> = this.dialog.open(LightboxComponent, {
            data: {
                media: [this.media],
                index: 0,
                permission: 'View, Comment and Add',
            },
            panelClass: 'fullWidthWindow',
        });
    }

    hide(): void {
        if (this.user?.role.can_manage_hidden_media) {
            const dialogRef: MatDialogRef<ConfirmDialogComponent> = this.dialog.open<ConfirmDialogComponent, ConfirmationDialogData>(ConfirmDialogComponent, {
                panelClass: 'mediumWindow',
                data: {
                    showSubmitBtn: true,
                    showCancelBtn: true,
                    message: 'Are you sure you want to hide this media?',
                    title: 'Hide Media',
                    showHeader: true
                }
            });
            dialogRef.afterClosed().subscribe((resp): void => {
                if (resp) {
                    this.contentService.hideMedia(this.media.id).subscribe(r => {
                        this.snackbarService.openSnackBar('Media Hidden', 'success')
                        this.media = r;
                    }, e => {
                        console.error(e)
                        this.snackbarService.openSnackBar(e, 'error')
                    })
                }
            });
        } else {
            this.snackbarService.openSnackBar('This user cannot hide media', 'error')
        }
    }


    unhide(): void {
        if (this.user?.role.can_manage_hidden_media) {
            this.contentService.unHideMedia(this.media.id).subscribe(r => {
                this.snackbarService.openSnackBar('Media Restored', 'success')
                this.media = r;
            }, e => {
                console.error(e)
                this.snackbarService.openSnackBar(e, 'error')
            })
        } else {
            this.snackbarService.openSnackBar('This user cannot hide media', 'error')
        }
    }

    delete(): void {
        const dialogRef: MatDialogRef<ConfirmDialogComponent> = this.dialog.open<ConfirmDialogComponent, ConfirmationDialogData>(ConfirmDialogComponent, {
            panelClass: 'mediumWindow',
            data: {
                showSubmitBtn: true,
                showCancelBtn: true,
                message: 'Are you sure you want to delete this media? You cannot undo this action.',
                title: 'Delete Media',
                showHeader: true
            }
        });
        dialogRef.afterClosed().subscribe((resp): void => {
            if (resp) {
                this.adminService.deleteMedia(this.media.id).subscribe(r => {
                    this.snackbarService.openSnackBar('Media Deleted', 'success')
                }, e => {
                    console.error(e)
                    this.snackbarService.openSnackBar(e, 'error')
                })
            }
        });
    }


    addNote(): void {
        this.contentService.postMediaNote(this.media.id, this.messageForm.value.message).subscribe(r => {
            this.messageForm.reset();
            this.notes = r['Success'].reverse();
            setTimeout((): void => {
                // scroll to bottom of chat container
                const element: HTMLElement | null = document.getElementById('chatContent');
                if (element) {
                    element.scrollTop = element.scrollHeight;
                }
            }, 50);
        }, e => {
            this.snackbarService.openSnackBar(e, 'error')
            console.error(e)
        })
    }

    receivedToday(date: any): boolean {
        const messageDate: Date = new Date(date);
        return (this.today.toDateString() === messageDate.toDateString());
    }

    viewProfile(id: number): void {
        if (this.user?.role.can_access_profiles) {
            this.router.navigate(['/profile', id]);
        } else {
            this.snackbarService.openSnackBar('This user cannot access profiles', 'error')
        }
    }


    back(): void {
        history.back();
    }
}
