import {Component, EventEmitter, HostListener, Inject, OnDestroy} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {EditItemComponent} from "../edit-item/edit-item.component";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {AuthService} from "../../_services/auth.service";
import {ContentService} from "../../_services/content.service";
import {SnackbarService} from "../../_services/snackbar.service";
import {interval, Subject, takeUntil} from "rxjs";
import {KeyboardShortcutsComponent} from "./keyboard-shortcuts/keyboard-shortcuts.component";
import {ConfirmDialogComponent} from "../confirm-dialog/confirm-dialog.component";
import {ConfirmationDialogData} from "../../_types/shared";

@Component({
    selector: 'app-lightbox',
    templateUrl: './lightbox.component.html',
    styleUrl: './lightbox.component.scss'
})
export class LightboxComponent implements OnDestroy {
    media: any[] = [];
    categories: string[] = [];
    index: number = 0;
    currentMedia: any;
    loading: boolean = false;
    flagging: boolean = false;
    flagForm: FormGroup;
    permission: string = "View Only";
    user: any;
    commenting: boolean = false
    commentsLoading: boolean = true;
    canFavourite: boolean = false;
    timeline;
    refresh: EventEmitter<any> = new EventEmitter();
    reFetch: EventEmitter<any> = new EventEmitter();
    slideshowInProgress: boolean = false;
    private slideshow: Subject<void> = new Subject<void>();

    constructor(@Inject(MAT_DIALOG_DATA) private data: any,
                public dialog: MatDialog,
                public contentService: ContentService,
                protected dialogRef: MatDialogRef<LightboxComponent>,
                private formBuilder: FormBuilder,
                private snackBarService: SnackbarService,
                private authService: AuthService) {
        if (data && data.media) {
            this.media = data.media;
            this.index = data.index;
            this.canFavourite = data.canFavourite;
            this.currentMedia = this.media[this.index];
            this.timeline = data.timeline;
            if (data.permission) {
                this.permission = data.permission
            }
        }
        this.flagForm = this.formBuilder.group({
            title: [''],
            description: [''],
            category: ['', Validators.required],
        });
        this.user = this.authService.getUser();

        console.log(this.user);

    }

    @HostListener('document:keydown', ['$event'])
    handleKeyboardEvent(event: KeyboardEvent): void {
        if (!this.commenting && !this.flagging) {
            // Don't detect key press if in forms
            if (event.ctrlKey && event.shiftKey && event.keyCode == 32) {
                // space clicked toggle slideshow
                this.toggleSlideshow();
            } else if ((event.ctrlKey && event.shiftKey && event.keyCode == 39) || (event.ctrlKey && event.shiftKey && event.keyCode == 68)) {
                // right arrow or d go forward
                this.move()
            } else if ((event.ctrlKey && event.shiftKey && event.keyCode == 37) || (event.ctrlKey && event.shiftKey && event.keyCode == 65)) {
                // left arrow or a go back
                this.move(true)
            }

        }
    }

    openKeyboardShortcuts(): void {
        this.dialog.open(KeyboardShortcutsComponent);
    }

    ngOnDestroy(): void {
        this.slideshow.next();
        this.slideshow.complete();
        this.slideshow.unsubscribe();
    }

    move(back?: boolean): void {
        this.currentMedia = null;
        if (!back) {
            if (this.index == (this.media.length - 1)) {
                this.index = 0;
            } else {
                this.index++;
            }

        } else {
            if (this.index == 0) {
                this.index = (this.media.length - 1);
            } else {
                this.index--;
            }
        }
        this.currentMedia = this.media[this.index];
        this.commenting = false;
        this.flagging = false;
    }

    toggleFlagMedia(): void {
        if (this.categories.length == 0) {
            this.contentService.feedbackCategories().subscribe(r => {
                this.categories = r;
            }, e => {
                console.error(e)
                this.categories = ["Duplicate", "Move to another album", 'Inappropriate', 'Other']
            })
        }
        this.commenting = false;
        this.flagging = !this.flagging
    }

    flagMedia(): void {
        let body: {
            title: string | null,
            text: string | null,
            category: string,
            flagged_content_index: number | null,
            media_id: number | null,
            timeline_id: number | null
        } = {
            title: this.flagForm.value.title,
            text: this.flagForm.value.description,
            category: this.flagForm.value.category,
            flagged_content_index: this.index,
            media_id: this.media[this.index].id,
            timeline_id: this.timeline
        }
        this.contentService.feedback(body).subscribe(
            r => {
                this.flagging = !this.flagging;
                this.snackBarService.openSnackBar('Item has been successfully flagged for investigation', 'success')
                if (this.flagForm.value.category == 'Inappropriate' || this.flagForm.value.category == 'Duplicate') {
                    this.refresh.emit();
                }
                this.flagForm.reset();
            }, e => {
                console.error(e);
                this.snackBarService.openSnackBar(e, 'error');
            });
    }

    deleteMedia(): void {
        if (this.user.role.can_permanently_delete) {
            const dialogRef: MatDialogRef<ConfirmDialogComponent> = this.dialog.open<ConfirmDialogComponent, ConfirmationDialogData>(ConfirmDialogComponent, {
                panelClass: 'mediumWindow',
                data: {
                    showSubmitBtn: true,
                    showCancelBtn: true,
                    message: 'Are you sure you want to permanently delete this media? This cannot be undone.',
                    title: 'Delete Media',
                    showHeader: true
                }
            });
            dialogRef.afterClosed().subscribe((resp): void => {
                if (resp) {
                    this.contentService.deleteMedia(this.media[this.index].id).subscribe(r => {
                        this.snackBarService.openSnackBar('Media Deleted', 'success')
                        this.refresh.emit(this.index);
                    }, e => {
                        console.error(e)
                        this.snackBarService.openSnackBar(e, 'error')
                    })
                }
            });
        }
    }

    hideMedia(): void {
        if (this.user.role.can_hide_media) {
            let message: string = 'Are you sure you want to delete this media?'
            let title: string = 'Delete Media'
            let success: string = 'Media Deleted'
            if (this.user.role.can_permanently_delete) {
                message = 'Are you sure you want to hide this media?'
                title = 'Hide media'
                success = 'Media Hidden'
            }
            const dialogRef: MatDialogRef<ConfirmDialogComponent> = this.dialog.open<ConfirmDialogComponent, ConfirmationDialogData>(ConfirmDialogComponent, {
                panelClass: 'mediumWindow',
                data: {
                    showSubmitBtn: true,
                    showCancelBtn: true,
                    message,
                    title,
                    showHeader: true
                }
            });
            dialogRef.afterClosed().subscribe((resp): void => {
                if (resp) {
                    this.contentService.hideMedia(this.media[this.index].id).subscribe(r => {
                        this.snackBarService.openSnackBar(success, 'success')
                        this.refresh.emit(this.index);
                    }, e => {
                        console.error(e)
                        this.snackBarService.openSnackBar(e, 'error')
                    })
                }
            });
        }
    }


    unHideMedia() {
        if (this.user.role.can_hide_media) {
            this.contentService.unHideMedia(this.media[this.index].id).subscribe(r => {
                this.snackBarService.openSnackBar('Media Restored', 'success')
                this.refresh.emit(this.index);
            }, e => {
                console.error(e)
                this.snackBarService.openSnackBar(e, 'error')
            })
        }
    }


    toggleCommenting(): void {
        this.flagging = false;
        this.commenting = !this.commenting
        this.commentsLoading = true;
        this.contentService.getMediaComments(this.media[this.index].id).subscribe(
            r => {
                this.media[this.index].comments = r;
                this.commentsLoading = false;
                setTimeout((): void => {
                    const element: HTMLElement | null = document.getElementById('chatContent');
                    if (element) {
                        element.scrollTop = element.scrollHeight;
                    }
                }, 50);
            }, e => {
                this.commentsLoading = false;
                console.error(e)
            });
    }

    editItem(): void {
        this.dialogRef.close()
        const dialogRef: MatDialogRef<EditItemComponent> = this.dialog.open(EditItemComponent, {
            data: this.currentMedia,
            panelClass: 'largeWindow',
        });
        dialogRef.afterClosed().subscribe((): void => {
        });
    }

    favouriteMedia(): void {
        if (this.media[this.index].is_favourite) {
            this.contentService.unFavouriteMedia(this.media[this.index].id).subscribe(r => {
                this.media[this.index].is_favourite = false;
                this.reFetch.emit();
            }, e => {
                console.error(e)
            })
        } else {
            this.contentService.favouriteMedia(this.media[this.index].id).subscribe(r => {
                this.media[this.index].is_favourite = true;
            }, e => {
                console.error(e)
            })
        }
    }

    sendComment(event: any): void {
        this.contentService.createMediaComment(this.media[this.index].id, event.message).subscribe(r => {
            this.media[this.index].comments = r;
            this.commentsLoading = false;
            this.media[this.index].comment_count++
            setTimeout((): void => {
                const element: HTMLElement | null = document.getElementById('chatContent');
                if (element) {
                    element.scrollTop = element.scrollHeight;
                }
            }, 50);
        }, e => {
            this.commentsLoading = false;
            console.error(e)
        })
    }

    download(): void {
        let url: string = '';
        if (this.currentMedia.image) {
            url = this.currentMedia.image
        } else if (this.currentMedia.video) {
            url = this.currentMedia.video
        } else if (this.currentMedia.audio) {
            url = this.currentMedia.audio
        } else if (this.currentMedia.document) {
            url = this.currentMedia.document
        }

        this.contentService.downloadMedia(this.currentMedia).catch(e => {
            console.error(e)
            window.open(url, '_newtab');
        })
    }

    toggleSlideshow(): void {
        if (!this.slideshowInProgress) {
            this.slideshow = new Subject<void>();
            interval(4000)
                .pipe(takeUntil(this.slideshow))
                .subscribe(() => this.move());
        } else {
            this.slideshow.next();
            this.slideshow.complete();
            this.slideshow.unsubscribe();
        }
        this.slideshowInProgress = !this.slideshowInProgress;
    }

    stopSlideshow(): void {
        this.slideshow.next();
        this.slideshow.complete();
        this.slideshow.unsubscribe();
        this.slideshowInProgress = false;
    }
}
