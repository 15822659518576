import {Component} from '@angular/core';
import {ContentService} from "../_services/content.service";

@Component({
    selector: 'app-status-page',
    templateUrl: './status-page.component.html',
    styleUrl: './status-page.component.scss'
})
export class StatusPageComponent {
    cacheBackend: boolean | null = null;
    databaseBackend: boolean | null = null;
    defaultFileStorageHealthCheck: boolean | null = null;
    diskUsage: boolean | null = null;
    memoryUsage: boolean | null = null;
    migrationsHealthCheck: boolean | null = null;
    redisHealthCheck: boolean | null = null;
    s3Boto3StorageHealthCheck: boolean | null = null;
    trainingContentAPI: boolean | null = null;

    constructor(private contentService: ContentService) {
        this.contentService.healthCheck().subscribe(r => {
            for (const [key, value] of Object.entries(r)) {
                switch (key) {
                    case 'Cache backend: default':
                        this.cacheBackend = (value == 'working');
                        break
                    case 'DatabaseBackend':
                        this.databaseBackend = (value == 'working');
                        break
                    case 'DefaultFileStorageHealthCheck':
                        this.defaultFileStorageHealthCheck = (value == 'working');
                        break
                    case 'DiskUsage':
                        this.diskUsage = (value == 'working');
                        break
                    case 'MemoryUsage':
                        this.memoryUsage = (value == 'working');
                        break
                    case 'MigrationsHealthCheck':
                        this.migrationsHealthCheck = (value == 'working');
                        break
                    case 'RedisHealthCheck':
                        this.redisHealthCheck = (value == 'working');
                        break
                    case 'S3Boto3StorageHealthCheck':
                        this.s3Boto3StorageHealthCheck = (value == 'working');
                        break
                }
            }
        }, e => {

            let objects = e;
            if (!e.hasOwnProperty('DatabaseBackend')) {
                objects = {
                    'Cache backend: default': 'failed',
                    'DatabaseBackend': 'failed',
                    'DefaultFileStorageHealthCheck': 'failed',
                    'DiskUsage': 'failed',
                    'MemoryUsage': 'failed',
                    'MigrationsHealthCheck': 'failed',
                    'RedisHealthCheck': 'failed',
                    'S3Boto3StorageHealthCheck': 'failed',
                }
            }

            for (const [key, value] of Object.entries(objects)) {
                switch (key) {
                    case 'Cache backend: default':
                        this.cacheBackend = (value == 'working');
                        break
                    case 'DatabaseBackend':
                        this.databaseBackend = (value == 'working');
                        break
                    case 'DefaultFileStorageHealthCheck':
                        this.defaultFileStorageHealthCheck = (value == 'working');
                        break
                    case 'DiskUsage':
                        this.diskUsage = (value == 'working');
                        break
                    case 'MemoryUsage':
                        this.memoryUsage = (value == 'working');
                        break
                    case 'MigrationsHealthCheck':
                        this.migrationsHealthCheck = (value == 'working');
                        break
                    case 'RedisHealthCheck':
                        this.redisHealthCheck = (value == 'working');
                        break
                    case 'S3Boto3StorageHealthCheck':
                        this.s3Boto3StorageHealthCheck = (value == 'working');
                        break
                }
            }
        })

        this.contentService.videoGuides('adult').subscribe(r => {
            this.trainingContentAPI = true;
        }, e => {
            this.trainingContentAPI = false;
        })
    }
}
