import {Component} from '@angular/core';
import {MatDialogRef} from "@angular/material/dialog";

@Component({
    selector: 'app-keyboard-shortcuts',
    templateUrl: './keyboard-shortcuts.component.html',
    styleUrl: './keyboard-shortcuts.component.scss'
})
export class KeyboardShortcutsComponent {
    constructor(protected dialogRef: MatDialogRef<KeyboardShortcutsComponent>) {
    }

}
