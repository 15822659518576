import {Component, Inject, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {map, Observable, startWith} from "rxjs";
import {FormControl} from "@angular/forms";
import {MatAutocompleteSelectedEvent} from "@angular/material/autocomplete";
import {ContentService} from "../../_services/content.service";
import {SnackbarService} from "../../_services/snackbar.service";

@Component({
    selector: 'app-duplicate-form',
    templateUrl: './duplicate-form.component.html',
    styleUrl: './duplicate-form.component.scss'
})
export class DuplicateFormComponent {

    @ViewChild('userInput') userInput: any;
    filteredUsers: Observable<string[]> | any;
    selectedUsers: any[] = [];
    timelines: any[] = [];
    userSearchControl: FormControl = new FormControl();
    memoryId;
    loading: boolean = true;

    constructor(@Inject(MAT_DIALOG_DATA) private data: any,
                protected dialogRef: MatDialogRef<DuplicateFormComponent>,
                private snackBarService: SnackbarService,
                private contentService: ContentService) {
        this.filteredUsers = this.userSearchControl.valueChanges
            .pipe(
                startWith(''),
                map(value => this._filterUsers(value))
            );

        if (data?.id) {
            this.memoryId = data.id
        }
        this.contentService.getWriteAccessUsers().subscribe(r => {
            this.timelines = r.results;
            this.loading = false;
        }, e => {
            this.snackBarService.openSnackBar(e, 'error')
        })
    }

    selectUser(event: MatAutocompleteSelectedEvent): void {
        const user = event as any;
        this.selectedUsers.push(user);
        if (this.userInput && this.userInput.nativeElement) {
            this.userInput.nativeElement.value = '';
        }
    }

    removeUser(user: any): void {
        const i = this.selectedUsers.findIndex((x: any): boolean => x === user);
        if (i != null && i >= 0) {
            this.selectedUsers.splice(i, 1);
        }
    }

    checkIfInArray(item: any, otherArray: any): boolean {
        let i
        if (otherArray) {
            i = otherArray.findIndex((x: any): boolean => x === item);
        }
        return (i != null && i >= 0);
    }

    duplicate(): void {
        this.loading = true;
        if (this.memoryId) {
            for (let index = 0; index < this.selectedUsers.length; index++) {
                this.contentService.duplicateMemory(this.memoryId, this.selectedUsers[index].id).subscribe(r => {
                    if (index == (this.selectedUsers.length - 1)) {
                        this.snackBarService.openSnackBar('Media Successfully Duplicated', 'success')
                        this.dialogRef.close();
                        this.loading = false;
                    }
                }, e => {
                    this.snackBarService.openSnackBar(e, 'error')
                    if (index == (this.selectedUsers.length - 1)) {
                        this.loading = false;
                    }
                })
            }
        }
    }

    private _filterUsers(value: string): string[] {
        let filterValue: string = '';
        if (typeof value == 'string') {
            filterValue = value.toLowerCase();
        }
        let results: any[] = this.timelines.filter((option: any) => option.child.full_name.toLowerCase().includes(filterValue));
        results = results.filter((item: any, index: any): boolean => {
            return (results.indexOf(item) == index);
        });
        return results;
    }
}
