import {Pipe, PipeTransform} from '@angular/core';
import * as moment from 'moment';

@Pipe({
    name: 'timeFormat'
})
export class TimeFormatPipe implements PipeTransform {
    transform(value: string, parse: string | null, format: string): string {
        if (parse) {
            return moment(value, parse).format(format);
        } else {
            return moment(value).format(format);
        }
    }
}
