import {Component} from '@angular/core';
import {FeedbackComponent} from "../feedback/feedback.component";
import {MatDialog} from "@angular/material/dialog";
import {AuthService} from "../../_services/auth.service";

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrl: './footer.component.scss'
})
export class FooterComponent {
    dynamicSettings: any;

    constructor(public dialog: MatDialog, private authService: AuthService) {
        this.authService.getDynamicSettings().then((r): void => {
            this.dynamicSettings = r;
        });
    }

    feedback(): void {
        if (this.dialog.openDialogs.length == 0) {
            this.dialog.open(FeedbackComponent, {});
        }
    }
}
