import {Component} from '@angular/core';
import {AuthService} from "../../_services/auth.service";
import {ContentService} from "../../_services/content.service";
import {DomSanitizer, SafeResourceUrl} from "@angular/platform-browser";
import {MatDialog, MatDialogRef} from "@angular/material/dialog";
import {ViewGuideComponent} from "../written-guides/view-guide/view-guide.component";

@Component({
    selector: 'app-videoguides',
    templateUrl: './videoguides.component.html',
    styleUrl: './videoguides.component.scss'
})
export class VideoguidesComponent {
    user: any;
    trainingCentre: any[] = []
    loading: boolean = true;

    constructor(private authService: AuthService,
                private contentService: ContentService,
                private sanitizer: DomSanitizer,
                public dialog: MatDialog) {
        this.user = this.authService.getUser();
        this.authService.refreshUser();
        this.contentService.videoGuides(this.user?.role?.role_type != 'admin' ? this.user?.role?.role_type : 'adult').subscribe(r => {
            this.loading = false;
            this.trainingCentre = r;
        }, e => {
            this.loading = false;
            console.error(e)
        })

    }

    safeUrl(url: any): SafeResourceUrl {
        return this.sanitizer.bypassSecurityTrustResourceUrl(url);
    }

    openGuide(guide: any): void {
        this.authService.refreshUser();
        guide.isVideo = true;
        const dialogRef: MatDialogRef<ViewGuideComponent> = this.dialog.open(ViewGuideComponent, {
            data: guide,
            panelClass: 'fullWidthWindow',
        });
    }
}
