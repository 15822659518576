<mat-dialog-content class="d-flex flex-col" role="dialog" aria-modal="true">
    <div class="flex-row flex-space-between">
        <div>
            <mat-card-header class="p-0">
                <mat-card-title>
                    <h3 class="m-0">Leave Feedback</h3>
                </mat-card-title>
            </mat-card-header>
        </div>
        <div class="float-end">
            <button class="mat-dialog-close" mat-dialog-close mat-icon-button matTooltip="Close Window" (keyup.enter)="this.dialogRef.close();">
                <mat-icon>close</mat-icon>
            </button>
        </div>
    </div>
    <form [formGroup]="feedbackForm" class="pt-10 flex-col">
        <mat-form-field appearance="outline">
            <mat-label>Feedback Title</mat-label>
            <input formControlName="title" matInput placeholder="Feedback Title">
        </mat-form-field>
        <mat-form-field appearance="outline">
            <mat-label>Description</mat-label>
            <textarea formControlName="description" matInput
                      maxlength="400"></textarea>
        </mat-form-field>
        <div class="d-flex flex-space-around">

        </div>
    </form>
</mat-dialog-content>
<mat-dialog-actions>
    <div class="flex-row flex-space-between w-100">
        <button class="mat-elevation-z2" color="warn" mat-dialog-close mat-raised-button (keyup.enter)="this.dialogRef.close();"
                matTooltip="Cancel flag">
            Cancel
        </button>
        <button class="mat-elevation-z2" color="primary" mat-raised-button
                matTooltip="Submit Flag">
            Submit
        </button>
    </div>
</mat-dialog-actions>

