import {AfterViewInit, Component, HostListener, OnDestroy, ViewChild} from '@angular/core';
import {MatTableDataSource} from "@angular/material/table";
import {MatSort, Sort} from "@angular/material/sort";
import {MatPaginator, PageEvent} from "@angular/material/paginator";
import {SnackbarService} from "../../_services/snackbar.service";
import {AdminService} from "../../_services/admin.service";
import {MatDialog, MatDialogRef} from "@angular/material/dialog";
import {ConfirmDialogComponent} from "../../shared/confirm-dialog/confirm-dialog.component";
import {ConfirmationDialogData} from "../../_types/shared";
import {ActivatedRoute, Router} from "@angular/router";
import {Subscription} from "rxjs";

@Component({
    selector: 'app-invitees',
    templateUrl: './invitees.component.html',
    styleUrl: './invitees.component.scss'
})


export class InviteesComponent implements AfterViewInit, OnDestroy {
    loading: boolean = true;
    shiftIsPressed: boolean = false;
    displayedColumns: string[] = ['img', 'name', 'username', 'role', 'inviteDate', 'invitesSent', 'view'];
    dataSource: MatTableDataSource<any> = new MatTableDataSource();
    @ViewChild(MatPaginator) paginator: MatPaginator | null = null;
    @ViewChild(MatSort) sort: MatSort | null = null;
    lastSelect: any = null;
    allChecked: boolean = false;
    anySelected: boolean = false;
    typingTimer: any;
    searchTerm: any;
    role: any[] = [];
    cohort: any[] = [];
    status: string = 'All'


    roles: any[] = [];
    statuses: any[] = [];
    cohorts: any[] = [];
    locality: [] = [];
    localities: any[] = [];
    limit: number = 15;
    page: number = 0;
    count: number = 0;
    orphaned: string = 'All';
    direction: string | undefined = 'desc';
    order_by: string | undefined = 'invite_sent_date';
    matSortDefault: string = 'inviteDate';
    matSortDirectionDefault: "asc" | "desc" = "desc";
    largerTable: boolean = false;
    tableSizeSubscription: Subscription;

    constructor(private adminService: AdminService,
                private snackbarService: SnackbarService,
                public dialog: MatDialog,
                private route: ActivatedRoute,
                private router: Router) {
        this.largerTable = this.adminService.largeTable;
        this.tableSizeSubscription = this.adminService.largeTableChange.subscribe((value: boolean): void => {
            this.largerTable = value;
        });

        route.queryParams.subscribe(
            (data: any): void => {
                if (data['role']) {
                    let roles = data['role']
                    if (Array.isArray(roles)) {
                        for (let i = 0; i < roles.length; i++) {
                            this.role.push(JSON.parse(roles[i]))
                        }
                    } else {
                        this.role.push(JSON.parse(roles))
                    }
                    this.role = this.role.filter((value, index, array) =>
                        array.indexOf(value) === index)
                }

                if (data['cohort']) {
                    let cohorts = data['cohort']
                    if (Array.isArray(cohorts)) {
                        for (let i = 0; i < cohorts.length; i++) {
                            this.cohort.push(JSON.parse(cohorts[i]))
                        }
                    } else {
                        this.cohort.push(JSON.parse(cohorts))
                    }
                    this.cohort = this.cohort.filter((value, index, array) =>
                        array.indexOf(value) === index)
                }

                if (data['status']) {
                    this.status = data['status']
                }
                if (data['direction']) {
                    this.direction = data['direction']
                    if (this.direction == 'asc' || this.direction == 'desc') {
                        this.matSortDirectionDefault = this.direction;
                    }
                }
                if (data['order_by']) {
                    this.order_by = data['order_by']
                    if (this.order_by) {
                        if (this.order_by == 'full_name') {
                            this.matSortDefault = 'name'
                        } else if (this.order_by == 'invite_sent_date') {
                            this.matSortDefault = 'inviteDate'
                        } else if (this.order_by == 'number_of_invites_sent') {
                            this.matSortDefault = 'invitesSent'
                        }
                    }
                }
                if (data['page']) {
                    this.page = data['page']
                }
                if (data['is_orphan']) {
                    this.orphaned = data['is_orphan']
                }
                if (data['limit']) {
                    this.limit = data['limit']
                }
                if (data['searchTerm']) {
                    this.searchTerm = data['searchTerm']
                }
            })
        this.getInvitees();

        this.adminService.getCohorts(0, 1000).subscribe(r => {
            this.cohorts = r.results
        }, e => {
            this.snackbarService.openSnackBar(e, 'error');
        })
    }

    ngOnDestroy() {
        if (this.tableSizeSubscription) {
            this.tableSizeSubscription.unsubscribe();
        }
    }

    @HostListener('document:keydown', ['$event'])
    handleKeyboardEvent(event: KeyboardEvent): void {
        if (event.shiftKey) {
            this.shiftIsPressed = true;
        }
    }

    @HostListener('document:keyup', ['$event'])
    handleKeyboardUpEvent(event: KeyboardEvent): void {
        if (event.shiftKey) {
            this.shiftIsPressed = false;
            this.lastSelect = null;
        }
    }


    getInvitees(): void {
        let filters: any = {
            status: null,
            role: null,
            order_by: null,
            direction: null,
        }
        if (this.locality && this.locality.length > 0) {
            filters.locality = this.locality;
        }
        if (this.orphaned && this.orphaned != 'All') {
            filters.is_orphan = this.orphaned;
        }
        if (this.status && this.status != 'All') {
            filters.status = this.status.toLowerCase();
        }
        if (this.cohort && this.cohort.length > 0) {
            filters.cohort = this.cohort;
        }

        if (this.role && this.role.length > 0) {
            filters.role = this.role;
        }

        if (this.order_by && this.direction) {
            filters.order_by = this.order_by
        }
        if (this.direction) {
            filters.direction = this.direction
        }

        filters.page = this.page;
        filters.limit = this.limit;
        filters.searchTerm = this.searchTerm;
        this.router.navigate([], {
            relativeTo: this.route,
            queryParams: filters,
            queryParamsHandling: 'merge',
        });
        this.adminService.getAllInvites(this.page, this.limit, this.searchTerm, filters).subscribe(r => {
            this.count = r.count;
            if (this.statuses.length == 0) {
                this.statuses = r.filter_options.invite_status
            }
            if (this.roles.length == 0) {
                this.roles = r.filter_options.role
            }
            if (this.localities?.length == 0) {
                this.localities = r.filter_options.locality
            }
            this.dataSource = new MatTableDataSource(r.results);
            this.loading = false;
        }, e => {
            this.loading = false;
            this.snackbarService.openSnackBar(e, 'error');
        })
    }


    ngAfterViewInit(): void {
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
    }

    checkAll(): void {
        this.checkIfAnySelected();
        if (this.anySelected) {
            if (this.allChecked) {
                // if all selected select none
                this.allChecked = false;
            } else {
                // if some selected select all
                this.allChecked = true;
            }
        } else {
            // if none selected select all
            this.allChecked = true;
        }
        for (let i: number = 0; i < this.dataSource.data.length; i++) {
            this.dataSource.data[i].checked = this.allChecked
        }
        this.checkIfAnySelected();
    }

    reSendInvite(): void {
        let users: any[] = [];
        for (let i: number = 0; i < this.dataSource.data.length; i++) {
            if (this.dataSource.data[i].checked) {
                users.push(this.dataSource.data[i].id)
            }
        }
        this.adminService.reInviteUsers(users).subscribe(r => {
            this.snackbarService.openSnackBar('Invites successfully re-sent', 'success');
            for (let i: number = 0; i < this.dataSource.data.length; i++) {
                this.dataSource.data[i].checked = false;
            }
        }, e => {
            this.snackbarService.openSnackBar(e, 'error')
            console.error(e);
        })
    }

    bulkOptions(event: any, user: any): void {
        // Some Shift business
        if (this.shiftIsPressed && !this.lastSelect) {
            this.lastSelect = user.index;
        } else if (this.shiftIsPressed && this.lastSelect) {
            this.shiftIsPressed = false;
            //  shift was set for second time
            //  find out value of previous click then set all users between with the same value
            let i: number = this.dataSource.data.findIndex(x => x.index === this.lastSelect);
            let lastSelectValue: boolean = this.dataSource.data[i].checked;
            // list the indexes of the 2 shifted users
            let indexes: any[] = [(this.lastSelect - 1), (user.index - 1)]
            // Order indexes lowes first
            indexes = indexes.sort();
            for (let i: number = 0; i < this.dataSource.data.length; i++) {
                if (i >= indexes[0] && i <= indexes[1]) {
                    this.dataSource.data[i].checked = lastSelectValue
                }
            }
            this.lastSelect = false;
        }
        let i: number = this.dataSource.data.findIndex(x => x.id === user.id);
        this.dataSource.data[i].checked = event;
        this.checkIfAnySelected();
    }

    deactivate(): void {
        const dialogRef: MatDialogRef<ConfirmDialogComponent> = this.dialog.open<ConfirmDialogComponent, ConfirmationDialogData>(ConfirmDialogComponent, {
            panelClass: 'mediumWindow',
            data: {
                showSubmitBtn: true,
                showCancelBtn: true,
                message: 'Are you sure you want to deactivate the selected invites and the associated user accounts?',
                title: 'Deactivate Invites',
                showHeader: true
            }
        });
        dialogRef.afterClosed().subscribe((resp): void => {
            if (resp) {
                let users: any[] = [];
                for (let i: number = 0; i < this.dataSource.data.length; i++) {
                    if (this.dataSource.data[i].checked) {
                        users.push(this.dataSource.data[i].id)
                    }
                }
                this.adminService.deleteInvites(users).subscribe(r => {
                    this.snackbarService.openSnackBar('Users successfully deactivated', 'success');
                    location.reload();
                }, e => {
                    this.snackbarService.openSnackBar(e, 'error')
                    console.error(e);
                })
            }
        });
    }

    handlePageEvent(e: PageEvent): void {
        this.page = e.pageIndex;
        this.limit = e.pageSize;
        this.getInvitees();
    }

    sortData(event: Sort): void {
        this.order_by = event.active;

        if (this.order_by == 'name') {
            this.order_by = 'full_name'
        } else if (this.order_by == 'inviteDate') {
            this.order_by = 'invite_sent_date'
        } else if (this.order_by == 'invitesSent') {
            this.order_by = 'number_of_invites_sent'
        }
        this.direction = event.direction;
        this.getInvitees()
    }

    checkIfAnySelected(): void {
        this.anySelected = false;
        this.allChecked = true;
        for (let i: number = 0; i < this.dataSource.data.length; i++) {
            if (this.dataSource.data[i].checked) {
                this.anySelected = true;
            } else {
                this.allChecked = false;
            }
        }
    }

    keyup(): void {
        clearTimeout(this.typingTimer);
        const that: this = this;
        this.typingTimer = setTimeout((): void => {
            that.loading = true;
            this.getInvitees()

            if (this.dataSource.paginator) {
                this.dataSource.paginator.firstPage();
            }
        }, 500);
    }

    clearSearch(): void {
        this.searchTerm = '';
        this.getInvitees();
    }

    keydown(): void {
        clearTimeout(this.typingTimer);
    }
}
