import {AfterViewInit, Component, OnInit} from '@angular/core';
import {MatDialog, MatDialogRef} from "@angular/material/dialog";
import {MemoryFormComponent} from "../shared/memory-form/memory-form.component";
import {LightboxComponent} from "../shared/lightbox/lightbox.component";
import {ActivatedRoute, Router} from "@angular/router";
import {MediaFormComponent} from "../shared/media-form/media-form.component";
import {Loader} from "@googlemaps/js-api-loader";
import {AuthService} from "../_services/auth.service";
import {DuplicateFormComponent} from "../shared/duplicate-form/duplicate-form.component";
import {ContentService} from "../_services/content.service";
import {SnackbarService} from "../_services/snackbar.service";
import {Location} from "@angular/common";
import {CreateMediaComponent} from "../shared/create-media/create-media.component";
import {PageEvent} from "@angular/material/paginator";
import {CdkDragDrop, moveItemInArray} from "@angular/cdk/drag-drop";
import {ConfirmDialogComponent} from "../shared/confirm-dialog/confirm-dialog.component";
import {ConfirmationDialogData} from "../_types/shared";

const loader: Loader = new Loader({
    apiKey: "AIzaSyDTOlh36rmI508E-5UqTd4_wuaXqQRNyCQ",
    version: "weekly",
    libraries: ["places"],
    nonce: (document.querySelector('meta[name="CSP_NONCE"]') as HTMLMetaElement)?.content
});

@Component({
    selector: 'app-memory',
    templateUrl: './memory.component.html',
    styleUrl: './memory.component.scss'
})
export class MemoryComponent implements OnInit, AfterViewInit {
    googleLoaded: boolean = false;
    locationExpanded: boolean = true;
    commentsExpanded: boolean = true;
    commentsLoading: boolean = true;
    reOrder: boolean = false;
    permission: string | null = null;
    id: number = 0;
    index: number = 0;
    timelineId: number = 0;
    memory: any = null;
    tags: any[] = [];
    user: any;
    comments: any[] = [];
    page: number = 0;
    limit: number = 16;
    count: number = 0;
    loading: boolean = true;
    ownsTimeline: boolean = false;
    orderLoading: boolean = false;

    constructor(public dialog: MatDialog,
                private route: ActivatedRoute,
                private contentService: ContentService,
                private snackbarService: SnackbarService,
                private authService: AuthService,
                private router: Router,
                private location: Location,
    ) {
        if (window.innerWidth < 1600 && window.innerWidth > 1100) {
            this.limit = 15;
        }
        this.user = this.authService.getUser();
        this.route.params
            .subscribe(params => {
                this.id = params['id'];
                this.index = params['index'];
                this.getMemory().catch((err): void => {
                    console.error(err)
                });


            }, error => {
                this.snackbarService.openSnackBar(error, 'error')
                console.error(error);
            });
    }

    getMemory(): Promise<any> {
        return new Promise((resolve, reject): void => {
            this.contentService.getMemory(this.id).subscribe(
                async r => {
                    this.memory = r;
                    this.timelineId = r.timeline.id;
                    this.ownsTimeline = r.timeline?.child?.id == this.user?.id;
                    this.memory.longitude = JSON.parse(this.memory.longitude);
                    this.memory.latitude = JSON.parse(this.memory.latitude);
                    this.getMedia();
                    this.contentService.viewTimelinePermissions(this.memory.timeline.id).subscribe(
                        r => {
                            for (let permission of r) {
                                if (permission.user.id == this.user.id) {
                                    this.permission = permission.permission
                                }
                            }
                            if ((!this.permission || this.permission == 'No permission') && this.user?.role?.role_type !== 'admin') {
                                this.snackbarService.openSnackBar('Sorry you do not have permission to view this memory', 'error');
                                this.router.navigate(['/noAccess']);
                            } else if (this.user?.role?.role_type == 'admin') {
                                this.permission = 'View, Comment and Add';
                            }
                            if (this.permission == 'View and Comment' || this.permission == 'View, Comment and Add') {
                                this.contentService.getMemoryComments(this.id).subscribe(
                                    r => {
                                        this.comments = r;
                                        this.commentsLoading = false;
                                        setTimeout((): void => {
                                            // scroll to bottom of chat container
                                            const element: HTMLElement | null = document.getElementById('chatContent');
                                            if (element) {
                                                element.scrollTop = element.scrollHeight;
                                            }
                                        }, 50);
                                    }, e => {
                                        this.router.navigate(['/timeline']);
                                        this.snackbarService.openSnackBar(e, 'error')
                                    })
                            }
                        }, e => {
                            this.snackbarService.openSnackBar(e, 'error');
                            this.router.navigate(['/noAccess']);
                        })

                    this.contentService.getMemoryTags(this.id).subscribe(
                        r => {
                            this.memory.tags = r;
                        }, e => {
                            console.error(e)
                        })
                    resolve('Success')
                }, e => {
                    this.snackbarService.openSnackBar(e, 'error')
                    reject(e)
                })
        })
    }

    getMedia(): Promise<any> {
        return new Promise((resolve, reject): void => {
            this.loading = true;
            this.contentService.getMemoryMedia(this.id, this.page, this.limit).subscribe(r => {
                this.count = r.count;
                this.memory.media = r.results;
                if (this.index) {
                    // view image if index param
                    for (let i = 0; i < this.memory.media.length; i++) {
                        if (this.memory.media[i]?.id == this.index) {
                            this.viewImage(i)
                        }
                    }
                }
                this.loading = false;
                resolve('Success')
            }, e => {
                this.snackbarService.openSnackBar(e, 'error')
                this.loading = false;
                reject(e)
            })
        });
    }

    ngOnInit(): void {
        if (window.innerWidth <= 600) {
            this.locationExpanded = false;
            this.commentsExpanded = false;
        }
    }

    ngAfterViewInit(): void {
        // Load google maps
        loader
            .load()
            .then((google): void => {
                this.googleLoaded = true;
            })
            .catch(e => {
                this.snackbarService.openSnackBar(e, 'error')
            });
    }

    recordMedia(): void {
        if (this.dialog.openDialogs.length == 0) {
            const dialogRef: MatDialogRef<CreateMediaComponent> = this.dialog.open(CreateMediaComponent, {
                data: this.memory,
                panelClass: 'fullWidthWindow',
            });
            dialogRef.componentInstance.reload.subscribe((): void => {
                // Refresh memory if when media changes
                location.reload()
            });
        }
    }

    newMedia(): void {
        if (this.dialog.openDialogs.length == 0) {
            const dialogRef: MatDialogRef<MediaFormComponent> = this.dialog.open(MediaFormComponent, {
                data: this.memory,
                panelClass: 'fullWidthWindow',
            });
            dialogRef.componentInstance.reload.subscribe((): void => {
                // Refresh memory if when media changes
                location.reload()
            });
        }
    }

    editDetails(): void {
        if (this.dialog.openDialogs.length == 0) {
            window.scrollTo(0, 0);
            // stops google places location pop up from being in the wrong place
            const dialogRef: MatDialogRef<MemoryFormComponent> = this.dialog.open(MemoryFormComponent, {
                data: {memory: this.memory},
                panelClass: 'fullWidthWindow',
            });
            dialogRef.afterClosed().subscribe((resp): void => {
                location.reload();
            });
        }
    }

    viewImage(i: number): void {
        // stop all active media
        document.querySelectorAll('video').forEach(vid => vid.pause());
        document.querySelectorAll('audio').forEach(vid => vid.pause());
        this.dialog.closeAll();
        if (this.memory?.media[i]) {
            const dialogRef: MatDialogRef<LightboxComponent> = this.dialog.open(LightboxComponent, {
                data: {
                    media: this.memory.media,
                    index: i,
                    permission: this.permission,
                    timeline: this.memory.timeline.id,
                    canFavourite: this.ownsTimeline
                },
                panelClass: 'fullWidthWindow',
            });
            dialogRef.componentInstance.refresh.subscribe((index): void => {
                // Refresh memory if when media changes
                this.getMemory().then((): void => {
                    dialogRef.close();
                    if (index) {
                        this.viewImage(index)
                    }
                });
            });
            dialogRef.afterClosed().subscribe((): void => {
                // Remove index from param
                this.location.go('memory/' + this.memory.id);
            });
        }
    }

    duplicateAlbum(): void {
        if (this.dialog.openDialogs.length == 0) {
            const dialogRef: MatDialogRef<DuplicateFormComponent> = this.dialog.open(DuplicateFormComponent, {
                data: {
                    id: this.memory.id,
                },
            });
        }
    }

    sendComment(event: any): void {
        this.contentService.createMemoryComment(this.id, event.message).subscribe(r => {
            this.comments = r;
            this.commentsLoading = false;
            setTimeout((): void => {
                // scroll to bottom of chat box
                const element: HTMLElement | null = document.getElementById('chatContent');
                if (element) {
                    element.scrollTop = element.scrollHeight;
                }
            }, 50);
        }, e => {
            this.snackbarService.openSnackBar(e, 'error')
        })
    }

    handlePageEvent(e: PageEvent): void {
        this.page = e.pageIndex;
        this.getMedia();
    }

    viewProfile(id: number): void {
        if (this.user.role.can_access_profiles) {
            this.router.navigate(['/profile', id]);
        }
    }

    changeOrder(event: CdkDragDrop<string[]>): void {
        moveItemInArray(this.memory.media, event.previousIndex, event.currentIndex);
    }

    refresh(): void {
        window.location.reload();
    }

    initReOrder(): void {
        this.reOrder = true;
        this.limit = this.count;
        this.getMedia();
    }

    saveReorder() {
        this.orderLoading = true;
        let itemsInOrder: number[] = [];
        for (let i: number = 0; i < this.memory.media.length; i++) {
            // loop through all media before this one and set to new order as default order is max.
            itemsInOrder.push(this.memory.media[i].id);
        }
        this.contentService.reOrderMedia(itemsInOrder).subscribe(r => {
            this.snackbarService.openSnackBar('Order Saved Successfully', 'success')
            setTimeout((): void => {
                window.location.reload();
            }, 1000);
        }, e => {
            this.orderLoading = false;
            this.snackbarService.openSnackBar(e, 'error')
        })
    }

    deleteAlbum(): void {
        if (this.user.role.can_permanently_delete) {
            const dialogRef: MatDialogRef<ConfirmDialogComponent> = this.dialog.open<ConfirmDialogComponent, ConfirmationDialogData>(ConfirmDialogComponent, {
                panelClass: 'mediumWindow',
                data: {
                    showSubmitBtn: true,
                    showCancelBtn: true,
                    message: 'Are you sure you want to delete this album?',
                    title: 'Delete Album',
                    showHeader: true
                }
            });
            dialogRef.afterClosed().subscribe((resp): void => {
                if (resp) {
                    this.contentService.deleteAlbum(this.memory.id).subscribe(r => {
                        this.snackbarService.openSnackBar('Album Deleted', 'success')
                        this.router.navigate(['/timeline', this.timelineId]);
                    }, e => {
                        console.error(e)
                        this.snackbarService.openSnackBar(e, 'error')
                    })
                }
            });
        }
    }
}

