<mat-dialog-content class="d-flex flex-col" role="dialog" aria-modal="true">
    <div class="flex-row flex-space-between">
        <div>
            <mat-card-header class="p-0">
                <mat-card-title>
                    <h3 class="m-0">Keyboard Shortcuts</h3>
                </mat-card-title>
            </mat-card-header>
        </div>
        <div class="float-end">
            <button class="mat-dialog-close" mat-dialog-close mat-icon-button (keyup.enter)="this.dialogRef.close();"
                    matTooltip="Close Window" >
                <mat-icon>close</mat-icon>
            </button>
        </div>
    </div>

    <div class="d-flex flex-col shortcuts">
        <div class="d-flex flex-row align-items-center gap10">
            <div class="d-flex flex-row">
                <img matTooltip="Control Key" alt="Control Key" class="pr-10" src="assets/images/ctrl-key.png">
                <img matTooltip="Shift Key" alt="Shift Key" class="pr-10" src="assets/images/shift-key.png">
                <img matTooltip="Space Key" alt="Space Key" src="assets/images/space-key.png">
            </div>
            Pause/Play Slideshow
        </div>
        <div class="d-flex flex-row align-items-center gap10">
            <div class="d-flex flex-row">
                <img matTooltip="Control Key" alt="Control Key" class="pr-10" src="assets/images/ctrl-key.png">
                <img matTooltip="Shift Key" alt="Shift Key" class="pr-10" src="assets/images/shift-key.png">
                <img matTooltip="Left Arrow Key" alt="Left Arrow Key" src="assets/images/left-key.png">
                <span class="orText">/</span>
                <img matTooltip="Control Key" alt="Control Key" class="pr-10" src="assets/images/ctrl-key.png">
                <img matTooltip="Shift Key" alt="Shift Key" class="pr-10" src="assets/images/shift-key.png">
                <img matTooltip="a Key" alt="a Key" src="assets/images/a-key.png">
            </div>
            Navigate to the memory on the left
        </div>
        <div class="d-flex flex-row align-items-center gap10">
            <div class="d-flex flex-row">
                <img matTooltip="Control Key" alt="Control Key" class="pr-10" src="assets/images/ctrl-key.png">
                <img matTooltip="Shift Key" alt="Shift Key" class="pr-10" src="assets/images/shift-key.png">
                <img matTooltip="Right Arrow Key" alt="Right Arrow Key" src="assets/images/right-key.png">
                <span class="orText">/</span>
                <img matTooltip="Control Key" alt="Control Key" class="pr-10" src="assets/images/ctrl-key.png">
                <img matTooltip="Shift Key" alt="Shift Key" class="pr-10" src="assets/images/shift-key.png">
                <img matTooltip="d Key" alt="d Key" src="assets/images/d-key.png">
            </div>
            Navigate to the memory on the right
        </div>
    </div>

</mat-dialog-content>

