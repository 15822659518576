<main class="mediumContainer">
    <div class="d-flex flex-row">
        <button [routerLink]="['/permissionRequests']" aria-label="Back" mat-icon-button matTooltip="Back">
            <mat-icon>keyboard_backspace</mat-icon>
        </button>
    </div>
    <div class="flex-col gap-25" *ngIf="this.changeRequest">
        <mat-card>
            <mat-card-header>
                <mat-card-title>
                    {{ this.changeRequest?.requested_by.full_name }}'s Permission change request
                </mat-card-title>
                <mat-card-subtitle>
                    <p class="f-14 m-0">({{ this.changeRequest.created_date | date }})</p>
                </mat-card-subtitle>
            </mat-card-header>
            <mat-card-content>

                <p>
                    <a [routerLink]="['/user', this.changeRequest?.requested_by.id]">{{ this.changeRequest?.requested_by.full_name }}</a>
                    has requested to update
                    <a [routerLink]="['/user', this.changeRequest.timeline_user_access.user.id]">{{ this.changeRequest.timeline_user_access.user.full_name }}
                        's</a>
                    permissions
                    <ng-container *ngIf="this.changeRequest.timeline_user_access?.timeline?.child?.id"> on <a
                            [routerLink]="['/user', this.changeRequest.timeline_user_access.timeline.child.id]">{{ this.changeRequest.timeline_user_access.timeline.title }}</a>
                    </ng-container>
                    from <b>"{{ this.changeRequest.timeline_user_access.permission }}"</b> to
                    <b>"{{ this.changeRequest.change_permission_to }}"</b>.
                </p>
                <div class="d-flex flex-row align-items-center gap10 mt-10">
                    <b>Status:</b>
                    <div [class]="this.changeRequest?.status" class="statusContainer">
                        <p class="text-white">{{ this.changeRequest?.status }}</p>
                    </div>
                </div>
                <div *ngIf="this.changeRequest.message" class="d-flex flex-row align-items-center gap10">
                    <b>Update Message:</b>
                    <p>"{{ this.changeRequest.message }}"</p>
                </div>

                <div *ngIf="this.changeRequest.status_updated_by" class="d-flex flex-row align-items-center gap10">
                    <b>{{ this.changeRequest?.status }} By:</b>
                    <a [routerLink]="['/user', this.changeRequest.status_updated_by.id]">{{ this.changeRequest.status_updated_by.full_name || this.changeRequest.status_updated_by.email }}</a>
                </div>
                <div *ngIf="this.changeRequest.note" class="d-flex flex-row align-items-center gap10">
                    <b>Rejected Note:</b>
                    <p>"{{ this.changeRequest.note }}"</p>
                </div>
                <mat-form-field *ngIf="reject" appearance="outline" class="w-100 mt-10">
                    <mat-label>Explanation of Rejection:</mat-label>
                    <textarea [(ngModel)]="note" matInput maxlength="400"></textarea>
                </mat-form-field>
            </mat-card-content>
            <mat-card-actions *ngIf="!reject && this.changeRequest?.status =='Pending'"
                              class="flex-row flex-space-around mt-10 mobile-col">
                <button (click)="reject = true" color="warn" mat-raised-button>Reject Change Request</button>
                <button color="primary" mat-raised-button (click)="acceptRequest()">Accept Change Request</button>
            </mat-card-actions>
            <mat-card-actions *ngIf="reject && this.changeRequest?.status =='Pending'"
                              class="flex-row flex-space-around mt-10 mobile-col">
                <button (click)="reject = false" color="warn" mat-raised-button>Cancel</button>
                <button color="primary" mat-raised-button (click)="rejectRequest()">Reject Change Request</button>
            </mat-card-actions>
        </mat-card>
        <mat-card *ngIf="permissions && permissions.length">
            <mat-card-header class="fullWidth">
                <mat-card-title class="w-100">
                    <div class="d-flex flex-row flex-space-between w-100 align-items-center">
                        Users with access to {{ this.changeRequest?.timeline_user_access?.timeline?.title }}
                        <button (click)="newRelationship()" matTooltip="Add new permission" mat-raised-button
                                color="primary">
                            Add new permission
                            <mat-icon>add</mat-icon>
                        </button>
                    </div>
                </mat-card-title>
            </mat-card-header>
            <mat-card-content>
                <app-manage-access-table [permissions]="permissions"
                                         [canRequest]="true"></app-manage-access-table>
            </mat-card-content>
        </mat-card>
        <mat-card *ngIf="permissionRequests && permissionRequests.length">
            <mat-card-header>
                <mat-card-title>
                    Permission Requests for {{ this.changeRequest?.timeline_user_access?.timeline?.title }}
                </mat-card-title>
            </mat-card-header>
            <mat-card-content>
                <app-permission-requests-table [data]="permissionRequests"
                                               [count]="permissionRequestsCount"
                                               [includeOld]="true" [limit]="changeRequestLimit"
                                               (handlePageEvent)="permissionRequestsPageChange($event)"
                                               (orderChange)="changePermissionRequestsOrder($event)"></app-permission-requests-table>
            </mat-card-content>
        </mat-card>
    </div>
</main>
