<main *ngIf="memory" class="mediumContainer">
    <div class="d-flex flex-row">
        <button [routerLink]="['/timeline', this.timelineId]" aria-label="Back to timeline" mat-icon-button
                matTooltip="Back">
            <mat-icon>keyboard_backspace</mat-icon>
        </button>
    </div>
    <div class="d-flex flex-row flex-space-between pb-10 mobile-col" *ngIf="!memory?.duplication_in_progress">
        <div class="flex-col">
            <h1 class="my-0">{{ memory.title }}</h1>
        </div>
        <div *ngIf="permission == 'View, Comment and Add' || permission == 'Add but not View or Comment'"
             class="flex-row gap10 justify-content-end">

            <button (click)="newMedia()" aria-label="Add Memory" class="mat-elevation-z2" color="primary"
                    (keyup.enter)="newMedia()"
                    mat-mini-fab matTooltip="Add Memory">
                <mat-icon>
                    add_photo_alternate
                </mat-icon>
            </button>
            <button (click)="recordMedia()" aria-label="Record New Memory" class="mat-elevation-z2" color="primary"
                    mat-mini-fab matTooltip="Record New Memory" (keyup.enter)="recordMedia()">
                <mat-icon>
                    perm_camera_mic
                </mat-icon>
            </button>
            <button (click)="duplicateAlbum()" (keyup.enter)="duplicateAlbum()"
                    *ngIf="(this.user?.role?.role_type == 'adult' || this.user?.role?.role_type == 'admin') && user.role.role_type !== 'young_person'"
                    aria-label="Duplicate Album" class="mat-elevation-z2" color="primary"
                    mat-mini-fab matTooltip="Duplicate Album">
                <mat-icon>
                    filter_none
                </mat-icon>
            </button>
        </div>
    </div>
    <div class="memoryContainer" *ngIf="!memory.duplication_in_progress">
        <div>
            <div>
                <div *ngIf="loading" class="flex-row justify-content-center w-100">
                    <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
                </div>
                <div *ngIf="!loading" class="picsContainer">
                    <mat-card *ngFor="let file of memory.media; let i = index" class="clickable imageCard">

                        <app-media-display aria-label="View Media" (viewImage)="viewImage(i)" [file]="file"
                                           [small]="true"></app-media-display>

                        <mat-card-header (click)="viewImage(i)" tabindex="2" (keyup.enter)="viewImage(i)" class="pb-16">
                            <mat-card-title>{{ file.title | ellipsis:40 }}</mat-card-title>
                            <mat-card-subtitle *ngIf="file.description">{{ file.description  | ellipsis:65 }}
                            </mat-card-subtitle>
                        </mat-card-header>
                    </mat-card>
                </div>
                <mat-paginator (page)="handlePageEvent($event)" [hidePageSize]='true' [length]="count"
                               [pageSize]="limit"
                               aria-label="Select page"></mat-paginator>
            </div>
        </div>
        <div class="d-flex flex-col gap-15">
            <mat-card>
                <mat-card-header class="flex-space-between">
                    <mat-card-title class="f-20">Album Details</mat-card-title>
                </mat-card-header>
                <mat-card-content class="mt-10">
                    <ng-container *ngIf="memory.description">
                        <b>Description:</b>
                        <p class="mt-5">{{ memory.description }}</p>
                    </ng-container>
                    <ng-container *ngIf="memory.attendees">
                        <b>Who was there:</b>
                        <p class="mt-5">{{ memory.attendees }}</p>
                    </ng-container>
                    <ng-container *ngIf="memory.date">
                        <b>When it happened:</b>
                        <p class="mt-5">{{ memory.date| date:'mediumDate' }}</p>
                    </ng-container>
                    <div class="flex-col mb-10" *ngIf="memory.creator">
                        <b class="mb-10">Created By:</b>
                        <mat-chip class="smChip w-content"
                                  [class.clickable]="user.role.can_access_profiles"
                                  (click)="viewProfile(memory.creator?.id)"
                                  (keyup.enter)="viewProfile(memory.creator?.id)"
                                  [attr.aria-label]="user.role.can_access_profiles ? [memory.creator.full_name + ': Click to view profile'] : []">
                            <img *ngIf="memory.creator.photo"
                                 [alt]="memory.creator.full_name + ': Click to view profile'"
                                 [src]="memory.creator.thumbnail|| memory.creator.photo" loading="lazy"
                                 fetchPriority="low"
                                 matChipAvatar/>
                            {{ memory.creator.full_name }}
                        </mat-chip>
                    </div>
                    <ng-container *ngIf="memory?.tags?.length > 0">
                        <b class="mb-0">Tags:</b>
                        <mat-chip-listbox>
                            <mat-chip *ngFor="let tag of memory.tags" class="smChip" color="primary">
                                {{ tag.name }}
                            </mat-chip>
                        </mat-chip-listbox>
                    </ng-container>
                </mat-card-content>
                <mat-card-actions
                        *ngIf="permission == 'View, Comment and Add' || permission == 'Add but not View or Comment'"
                        align="end">
                    <button (click)="editDetails()" (keyup.enter)="editDetails()"
                            color="primary" mat-raised-button>Edit
                    </button>
                </mat-card-actions>
            </mat-card>
            <mat-card *ngIf="googleLoaded && memory.latitude" class="overflow-hidden">
                <mat-card-header class="mb-10 flex-space-between clickable"
                                 (click)="locationExpanded = !locationExpanded"
                                 (keyup.enter)="locationExpanded = !locationExpanded"
                                 role="button"
                                 [attr.aria-label]="!locationExpanded ? 'Expand Location Section' : 'Hide Location Section'">
                    <mat-card-title>Location</mat-card-title>
                    <mat-icon *ngIf="!locationExpanded">
                        keyboard_arrow_down
                    </mat-icon>
                    <mat-icon *ngIf="locationExpanded">
                        keyboard_arrow_up
                    </mat-icon>
                </mat-card-header>
                <mat-accordion>
                    <mat-expansion-panel [class.mat-expansion-panel]="false" [expanded]="locationExpanded" hideToggle>
                        <google-map [center]="{lat: memory.latitude, lng: memory.longitude}" tabindex="-1"
                                    [attr.aria-label]="'Location of memory: ' + memory.address "
                                    [options]="{ mapTypeControl: false, scaleControl: false, draggable: false, scrollwheel: false,  disableDefaultUI: true}"
                                    [zoom]="14" height="300px"
                                    mat-card-image/>
                    </mat-expansion-panel>
                </mat-accordion>
            </mat-card>
            <mat-card *ngIf="permission == 'View and Comment' || permission == 'View, Comment and Add'">
                <mat-card-header class="mb-10 clickable flex-space-between" role="button"
                                 [attr.aria-label]="!commentsExpanded ? 'Expand Comments Section' : 'Hide Comments Section'"
                                 (click)="commentsExpanded = !commentsExpanded"
                                 (keyup.enter)="commentsExpanded = !commentsExpanded">
                    <mat-card-title>Comments</mat-card-title>
                    <mat-icon *ngIf="!commentsExpanded">
                        keyboard_arrow_down
                    </mat-icon>
                    <mat-icon *ngIf="commentsExpanded">
                        keyboard_arrow_up
                    </mat-icon>
                </mat-card-header>
                <mat-accordion>
                    <mat-expansion-panel [class.mat-expansion-panel]="false" [expanded]="commentsExpanded" hideToggle>
                        <app-comments (comment)="sendComment($event)" [comments]="this.comments"
                                      [reLoading]="commentsLoading"></app-comments>
                    </mat-expansion-panel>
                </mat-accordion>
            </mat-card>
        </div>
    </div>
</main>

<div *ngIf="!memory || memory?.duplication_in_progress" class="centerLoading flex-col text-center">

    <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>

    <p class="errorMsg" *ngIf="memory?.duplication_in_progress">Memories for this album are being duplicated at the
        moment,<br> please
        check back soon to see the full album</p>
</div>
