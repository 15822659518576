import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse} from '@angular/common/http';
import {Observable, tap, throwError} from 'rxjs';
import {catchError} from "rxjs/operators";
import {Router} from "@angular/router";
import {AuthService} from "../_services/auth.service";

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

    constructor(private router: Router, private authService: AuthService) {
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(tap((httpEvent: HttpEvent<any>): void => {
            // Skip request
            if (httpEvent.type === 0) {
                return;
            }
            if (httpEvent instanceof HttpResponse) {
                if (httpEvent.headers.has('x-vmb-timeout-seconds')) {
                    let timeOut: string | number | null = httpEvent.headers.get('x-vmb-timeout-seconds')
                    if (timeOut && timeOut != 'None') {
                        timeOut = parseInt(timeOut);
                        // convert seconds to milliseconds
                        timeOut = timeOut * 1000
                        // Send a notification to the user before the timeout so they can refresh
                        this.authService.timeoutNotifier(timeOut)
                    }
                }
            }
        })).pipe(catchError(error => {
            let displayError: string = 'An error has occurred; please try again later.'
            // Format the hundreds of different error formats retrieved from the api to a standardised string.

            if (error.error?.detail) {
                displayError = error.error.detail;
            } else if (error.error?.message?.en) {
                displayError = error.error.message.en;
            } else if (error.error['name'] && error.error['name'].length > 0) {
                displayError = error.error['name'];
            } else if (error.error?.message) {
                if (typeof error.error.message == "object") {
                    let message: string = 'Error: '
                    for (const property in error.error.message) {
                        message = message + `${property}: ${error.error.message[property]}`
                    }
                    displayError = message;
                } else {
                    displayError = JSON.stringify(error.error.message);
                }
            } else if (error.error?.error) {
                displayError = error.error.error;
            } else if (error.message) {
                displayError = error.message;
            }

            if (error.error?.code == 'token_expired' || error.status === 408) {
                displayError = "For security reasons you have been logged out due to inactivity please login again for access"
                if (window.location.href.includes('returnUrl')) {
                    this.router.navigate(['/login'], {queryParams: {returnUrl: window.location.origin + window.location.pathname}});
                } else {
                    this.router.navigate(['/login'], {queryParams: {returnUrl: window.location.href}});
                }
                return throwError(displayError);
            } else if (error.status === 401 || error.status === 403) {
                // If forbidden status then direct user to no access page.
                this.router.navigate(['/noAccess']);
                return throwError(displayError);
            }

            return throwError(displayError);
        }));
    }
}
