import {Injectable} from '@angular/core';
import {Router} from "@angular/router";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {Observable} from "rxjs";
import {environment} from '../../environments/environment';
import {MatSnackBar, MatSnackBarRef} from "@angular/material/snack-bar";

@Injectable({
    providedIn: 'root'
})
export class AuthService {
    interval: any;
    timoutInterval: any;
    maxTime: number = 0;

    constructor(
        private router: Router,
        private http: HttpClient,
        private snackBar: MatSnackBar) {
    }


    disableTimeout(milliseconds: number): void {
        let that: this = this;
        // Refresh the user every minute;
        this.interval = setInterval(function (): void {
            that.refreshUser();
        }, 59999);


        // Cancel the timeout after the max time allowed
        setInterval(function (): void {
            that.stopTimeoutDisable();
        }, milliseconds);
    }

    stopTimeoutDisable(): void {
        clearInterval(this.interval)
    }

    login(username: string, password: string): Observable<any> {
        return this.http.post<any>(`${environment.apiUrl}v2/api/user/login/`, {username, password});
    }

    // After login save token and other values(if any) in localStorage
    setUser(user: any): void {
        localStorage.setItem('currentUser', JSON.stringify(user));
    }

    setToken(token: any): void {
        localStorage.setItem('access_token', token);
    }

    updatePassword(body: any): Observable<any> {
        return this.http.post<any>(`${environment.apiUrl}v2/api/user/password/change/`, body);
    }

    getDynamicSettings(): Promise<any> {
        return new Promise((resolve, reject): void => {
            if (localStorage.getItem('dynamicSettings')) {
                // If local storage exists then check its valid
                let lastGet = localStorage.getItem('lastDynamicCheck')
                let todaysDate = new Date();
                if (lastGet && new Date(lastGet).setHours(0, 0, 0, 0) == todaysDate.setHours(0, 0, 0, 0)) {
                    // If local storage was last cached today then its valid so return cache
                    resolve(JSON.parse(localStorage.getItem('dynamicSettings') as string))
                } else {
                    // If local storage wasn't last cached today then its not valid so fetch again and make new cache
                    this.replaceCache().then((r): void => {
                        resolve(r);
                    });

                }
            } else {
                // No cache
                this.replaceCache().then((r): void => {
                    resolve(r);
                });
            }
        });
    }

    replaceCache(): Promise<any> {
        return new Promise((resolve, reject): void => {
            this.fetchDynamicSettings().subscribe(r => {
                //get new cache and set cache date
                localStorage.setItem('lastDynamicCheck', JSON.stringify(new Date().toDateString()));
                localStorage.setItem('dynamicSettings', JSON.stringify(r));
                resolve(r)
            }, error => {
                console.error(error)
                reject()
            })
        });
    }

    fetchDynamicSettings(): Observable<any> {
        return this.http.get<any>(`${environment.apiUrl}v2/api/common/dynamic-settings/`);
    }

    patchUser(body: any): Observable<any> {
        return this.http.patch<any>(`${environment.apiUrl}v2/api/user/`, body);
    }

    // Checking if token is set
    isLoggedIn(): boolean {
        return localStorage.getItem('access_token') != null;
    }

    // After clearing localStorage redirect to login screen
    logout(): void {
        localStorage.removeItem('access_token');
        localStorage.removeItem('hijacked');
        localStorage.removeItem('currentUser');
        this.router.navigate(['/login']);
    }

    getUser(): any {
        return JSON.parse(localStorage.getItem('currentUser') as string)
    }

    refreshUser(): Promise<any> {
        return new Promise((resolve, reject): void => {
            this.http.get<any>(`${environment.apiUrl}v2/api/user/`).subscribe(r => {
                localStorage.setItem('currentUser', JSON.stringify(r));
                resolve(r)
            }, e => {
                reject()
                console.error(e);
            });
        });
    }

    async setUserWithToken(token: any, hijack: boolean) {
        return new Promise((resolve, reject): void => {
            if (hijack) {
                localStorage.setItem('hijacked', 'true');
            }
            localStorage.setItem('access_token', token);
            this.http.get<any>(`${environment.apiUrl}v2/api/user/`).subscribe(r => {
                localStorage.setItem('currentUser', JSON.stringify(r));
                resolve(r)
            }, e => {
                reject(e)
                console.error(e);
                location.reload();
            });
        });

        // Use token received from passthrough page to set the user.

    }

    request2fa(authToken: string): Observable<any> {
        const httpOptions = {
            headers: new HttpHeaders({'Authorization': 'Bearer ' + authToken})
        };
        return this.http.post<any>(`${environment.apiUrl}v2/api/2fa/request/`, {}, httpOptions);
    }

    verify2fa(body: any, authToken: string): Observable<any> {
        const httpOptions = {
            headers: new HttpHeaders({'Authorization': 'Bearer ' + authToken})
        };
        return this.http.post<any>(`${environment.apiUrl}v2/api/2fa/verify/`, body, httpOptions);
    }

    passwordResetRequest(username: string): Observable<any> {
        return this.http.post<any>(`${environment.apiUrl}v2/api/user/password/reset/request/`, {username: username});
    }

    usernameRequest(email: string): Observable<any> {
        return this.http.post<any>(`${environment.apiUrl}v2/api/user/username/request/`, {email: email});
    }

    resetPassword(body: any): Observable<any> {
        return this.http.post<any>(`${environment.apiUrl}v2/api/user/password/reset/token/`, body);
    }


    checkUserInvite(uid: string, token: string): Observable<any> {
        return this.http.get<any>(`${environment.apiUrl}v2/api/user/invite/accept/${uid}/${token}/`);
    }

    acceptUserInvite(uid: string, token: string, body: any): Observable<any> {
        return this.http.post<any>(`${environment.apiUrl}v2/api/user/invite/accept/${uid}/${token}/`, body);
    }

    acceptTerms(): Observable<any> {
        return this.http.post<any>(`${environment.apiUrl}v2/api/tos/accept/`, {accept: 'accept'});
    }

    userHeartbeat(): Observable<any> {
        return this.http.get<any>(`${environment.apiUrl}v2/api/heartbeat/`);
    }

    timeoutNotifier(time: number): void {
        this.maxTime = time;
        let that = this;
        // take timeout of timer from most recent call reset the timer and set it to be one minute before
        time = time - 60000
        clearTimeout(this.timoutInterval);
        this.timoutInterval = setTimeout((): void => {
            if (this.isLoggedIn()) {
                let userRefreshed: boolean = false;
                let snackBarRef: MatSnackBarRef<any> | null = that.snackBar.open('You will soon be timed out due to inactivity; please click to continue', 'Continue', {
                    duration: 60000,
                    panelClass: ['warn'],
                });
                snackBarRef.onAction().subscribe((): void => {
                    that.refreshUser()
                    userRefreshed = true;
                });
                snackBarRef.afterDismissed().subscribe((): void => {
                    if (!userRefreshed) {
                        that.snackBar.open("For security reasons you have been logged out due to inactivity please login again for access", 'Continue', {
                            duration: 9000,
                            panelClass: ['error'],
                        });
                        this.router.navigate(['/login']);
                    }
                });
            }
        }, time);
    }
}
