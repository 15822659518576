import {Component} from '@angular/core';
import {ReportingService} from "../../_services/reporting.service";
import {MatTableDataSource} from "@angular/material/table";
import {MatDialog, MatDialogRef} from "@angular/material/dialog";
import {ConfirmDialogComponent} from "../../shared/confirm-dialog/confirm-dialog.component";
import {ConfirmationDialogData} from "../../_types/shared";
import {SnackbarService} from "../../_services/snackbar.service";
import {AdminViewComponent} from "../admin-view/admin-view.component";
import {AdminService} from "../../_services/admin.service";
import {ActivatedRoute, Router} from "@angular/router";
import {Sort} from "@angular/material/sort";

@Component({
    selector: 'app-reports',
    templateUrl: './reports.component.html',
    styleUrl: './reports.component.scss'
})
export class ReportsComponent {
    loading: boolean = false;
    currentTab: string | null = null;
    matSortDefault: string = 'Name';
    matSortDirectionDefault: "asc" | "desc" = "desc";

    dataSource: MatTableDataSource<any> = new MatTableDataSource([
        {name: 'All Children\'s Memory Boxes', value: 'childrensData'},
        {name: 'Memory boxes with no memories', value: 'noMemories'},
        {name: 'Boxes With No Relationships', value: 'noRelationships'},
        {name: 'Users at invite pending stages', value: 'pendingInvites'},
        {name: 'Users with no permissions setup', value: 'noPermissions'},
        {name: 'Children and Young People without a Date of Birth', value: 'noDOB'},
        {name: 'All users with email addresses', value: 'userEmails'},
        {name: 'Duplicate user accounts', value: 'duplicates'},
    ]);
    displayedColumns: string[] = ['Name', 'View'];


    childrensData: MatTableDataSource<any> = new MatTableDataSource();
    childrensDataDisplayedColumns: string[] = ['Name', 'Users with access', 'Size (GB)', 'Total uploads', 'Total images', 'Total videos', 'Total documents', 'Total audios', 'view'];

    noMemoriesData: MatTableDataSource<any> = new MatTableDataSource();
    noMemoriesDataDisplayedColumns: string[] = ['Name', 'Role', 'Cohorts', 'Users with access', 'view'];

    noRelationshipsData: MatTableDataSource<any> = new MatTableDataSource();
    noRelationshipDataDisplayedColumns: string[] = ['Name', 'Role', 'Cohorts', 'Registration Date', 'view'];

    pendingInvitesData: MatTableDataSource<any> = new MatTableDataSource();
    pendingInvitesDataDisplayedColumns: string[] = ['Name', 'Email', 'Role', 'Cohorts', 'Invite sent date', 'Invite expiry date', 'Number of invites sent', 'Secondary Email', 'view'];

    noPermissionsData: MatTableDataSource<any> = new MatTableDataSource();
    noPermissionsDataDisplayedColumns: string[] = ['Name', 'Email', 'Role', 'Cohorts', 'Username', 'Secondary Email', 'view'];

    noDOBData: MatTableDataSource<any> = new MatTableDataSource();
    noDOBDataDisplayedColumns: string[] = ['Name', 'Email', 'Username', 'Secondary Email', 'Role Name', 'DOB', 'view'];

    userEmailsData: MatTableDataSource<any> = new MatTableDataSource();
    UserEmailsDataDisplayedColumns: string[] = ['Name', 'Username', 'Email', 'Active', 'Role', 'Cohort', 'DoB', 'Locality', 'Invite Date', 'Registration Date', 'Last Login', 'Last Upload'];

    duplicatesData: any[] = [];

    constructor(private reportingService: ReportingService,
                public dialog: MatDialog,
                private readonly snackBarService: SnackbarService,
                protected adminView: AdminViewComponent,
                private route: ActivatedRoute,
                private router: Router,
                private adminService: AdminService) {
        route.queryParams.subscribe(
            (data: any): void => {
                if (data['report']) {
                    this.fetchData(data['report'])
                }
            })

    }

    getChildrensData(csv?: boolean): void {
        this.loading = true;
        this.reportingService.timelineReport(csv).subscribe(r => {
            this.childrensData = new MatTableDataSource(r);

            if (csv) {
                const blob: Blob = new Blob([this.generateCSV(r)], {type: 'text/csv'});
                // Create a temporary URL for the Blob
                const url: string = window.URL.createObjectURL(blob);
                // Create a temporary <a> element to trigger the download
                const a: HTMLAnchorElement = document.createElement('a');
                a.href = url;
                a.download = 'childrensDataReport.csv'; // Set the file name
                a.click();
                // Clean up by revoking the URL
                window.URL.revokeObjectURL(url);
            }
            this.loading = false;
        }, error => {
            console.error(error)
            this.snackBarService.openSnackBar(error, 'error')
            this.loading = false;
        });
    }

    getNoMemoriesReport(csv?: boolean): void {
        this.loading = true;
        this.reportingService.noMemoriesReport().subscribe(r => {
            this.noMemoriesData = new MatTableDataSource(r);

            if (csv) {
                const blob: Blob = new Blob([this.generateCSV(r)], {type: 'text/csv'});
                // Create a temporary URL for the Blob
                const url: string = window.URL.createObjectURL(blob);
                // Create a temporary <a> element to trigger the download
                const a: HTMLAnchorElement = document.createElement('a');
                a.href = url;
                a.download = 'noMemoriesReport.csv'; // Set the file name
                a.click();
                // Clean up by revoking the URL
                window.URL.revokeObjectURL(url);
            }
            this.loading = false;
        }, error => {
            console.error(error)
            this.snackBarService.openSnackBar(error, 'error')
            this.loading = false;
        });
    }

    getNoRelationshipsReport(csv?: boolean): void {
        this.loading = true;
        this.reportingService.noRelationshipsReport().subscribe(r => {
            this.noRelationshipsData = new MatTableDataSource(r);

            if (csv) {
                const blob: Blob = new Blob([this.generateCSV(r)], {type: 'text/csv'});
                // Create a temporary URL for the Blob
                const url: string = window.URL.createObjectURL(blob);
                // Create a temporary <a> element to trigger the download
                const a: HTMLAnchorElement = document.createElement('a');
                a.href = url;
                a.download = 'noRelationshipsReport.csv'; // Set the file name
                a.click();
                // Clean up by revoking the URL
                window.URL.revokeObjectURL(url);
            }
            this.loading = false;
        }, error => {
            console.error(error)
            this.snackBarService.openSnackBar(error, 'error')
            this.loading = false;
        });
    }

    getInvitesPendingReport(csv?: boolean): void {
        this.loading = true;
        this.reportingService.invitesPendingReport().subscribe(r => {
            this.pendingInvitesData = new MatTableDataSource(r);

            if (csv) {
                const blob: Blob = new Blob([this.generateCSV(r)], {type: 'text/csv'});
                // Create a temporary URL for the Blob
                const url: string = window.URL.createObjectURL(blob);
                // Create a temporary <a> element to trigger the download
                const a: HTMLAnchorElement = document.createElement('a');
                a.href = url;
                a.download = 'invitesPendingReport.csv'; // Set the file name
                a.click();
                // Clean up by revoking the URL
                window.URL.revokeObjectURL(url);
            }
            this.loading = false;
        }, error => {
            console.error(error)
            this.snackBarService.openSnackBar(error, 'error')
            this.loading = false;
        });
    }

    getNoPermissionsReport(csv?: boolean): void {
        this.loading = true;
        this.reportingService.noPermissionsReport().subscribe(r => {
            this.noPermissionsData = new MatTableDataSource(r);

            if (csv) {
                const blob: Blob = new Blob([this.generateCSV(r)], {type: 'text/csv'});
                // Create a temporary URL for the Blob
                const url: string = window.URL.createObjectURL(blob);
                // Create a temporary <a> element to trigger the download
                const a: HTMLAnchorElement = document.createElement('a');
                a.href = url;
                a.download = 'noPermissionsReport.csv'; // Set the file name
                a.click();
                // Clean up by revoking the URL
                window.URL.revokeObjectURL(url);
            }
            this.loading = false;
        }, error => {
            console.error(error)
            this.snackBarService.openSnackBar(error, 'error')
            this.loading = false;
        });
    }

    getUsersEmailReport(csv?: boolean): void {
        this.loading = true;
        this.reportingService.usersEmailReport().subscribe(r => {
            this.userEmailsData = new MatTableDataSource(r);

            if (csv) {
                const blob: Blob = new Blob([this.generateCSV(r)], {type: 'text/csv'});
                // Create a temporary URL for the Blob
                const url: string = window.URL.createObjectURL(blob);
                // Create a temporary <a> element to trigger the download
                const a: HTMLAnchorElement = document.createElement('a');
                a.href = url;
                a.download = 'usersReport.csv'; // Set the file name
                a.click();
                // Clean up by revoking the URL
                window.URL.revokeObjectURL(url);
            }
            this.loading = false;
        }, error => {
            console.error(error)
            this.snackBarService.openSnackBar(error, 'error')

            this.loading = false;
        });
    }

    getNoDOBReport(csv?: boolean): void {
        this.loading = true;
        this.reportingService.noDOBReport().subscribe(r => {
            this.noDOBData = new MatTableDataSource(r);

            console.log(r)
            if (csv) {
                const blob: Blob = new Blob([this.generateCSV(r)], {type: 'text/csv'});
                // Create a temporary URL for the Blob
                const url: string = window.URL.createObjectURL(blob);
                // Create a temporary <a> element to trigger the download
                const a: HTMLAnchorElement = document.createElement('a');
                a.href = url;
                a.download = 'noDOBReport.csv'; // Set the file name
                a.click();
                // Clean up by revoking the URL
                window.URL.revokeObjectURL(url);
            }
            this.loading = false;
        }, error => {
            console.error(error)
            this.snackBarService.openSnackBar(error, 'error')

            this.loading = false;
        });
    }

    getDuplicateUsers(): void {
        this.loading = true;
        this.reportingService.getDuplicateAccounts().subscribe(r => {
            this.duplicatesData = [];
            for (const key in r) {
                if (r.hasOwnProperty(key)) {
                    this.duplicatesData.push(r[key])
                }
            }
            this.loading = false;
        }, error => {
            console.error(error)
            this.snackBarService.openSnackBar(error, 'error')
            this.loading = false;
        });
    }


    fetchData(key: string): void {
        switch (key) {
            case 'childrensData':
                this.currentTab = key;
                if (this.childrensData.data?.length <= 0) {
                    this.getChildrensData()
                }
                break
            case 'noMemories':
                this.currentTab = key;
                if (this.noMemoriesData.data?.length <= 0) {
                    this.getNoMemoriesReport()
                }
                break
            case 'noRelationships':
                this.currentTab = key;
                if (this.noRelationshipsData.data?.length <= 0) {
                    this.getNoRelationshipsReport()
                }
                break
            case 'pendingInvites':
                this.currentTab = key;
                if (this.pendingInvitesData.data?.length <= 0) {
                    this.getInvitesPendingReport()
                }
                break
            case 'noPermissions':
                this.currentTab = key;
                if (this.noPermissionsData.data?.length <= 0) {
                    this.getNoPermissionsReport()
                }
                break
            case 'noDOB':
                this.currentTab = key;
                if (this.noDOBData.data?.length <= 0) {
                    this.getNoDOBReport()
                }
                break
            case 'userEmails':
                this.currentTab = key;
                if (this.userEmailsData.data?.length <= 0) {
                    this.getUsersEmailReport()
                }
                break
            case 'duplicates':
                this.currentTab = key;
                if (this.duplicatesData?.length <= 0) {
                    this.getDuplicateUsers()
                }
                break
        }
    }

    generateCSV(data: any[]): string {
        const headers: string = Object.keys(data[0]).join(','); // Extract headers from the first object
        const rows: string[] = data.map(obj => Object.values(obj).join(',')); // Map each object to a row
        return [headers, ...rows].join('\n'); // Combine headers and rows with newline separators
    }

    mergeUsers(users: any): void {
        let masterUser: any = null;
        for (let user of users) {
            if (user.is_master) {
                masterUser = user.user;
            }
        }
        if (!masterUser) {
            this.snackBarService.openSnackBar('No Master User please try again later', 'error')
            return
        }
        const dialogRef: MatDialogRef<ConfirmDialogComponent> = this.dialog.open<ConfirmDialogComponent, ConfirmationDialogData>(ConfirmDialogComponent, {
            panelClass: 'mediumWindow',
            data: {
                showSubmitBtn: true,
                showCancelBtn: true,
                message: 'Are you sure you want to merge these users?',
                title: 'Merge Users',
                showHeader: true
            }
        });
        dialogRef.afterClosed().subscribe((resp): void => {
            if (resp) {
                this.reportingService.mergeUsers({
                    email: masterUser.email,
                    first_name: masterUser.first_name,
                    last_name: masterUser.last_name
                }).subscribe(r => {
                    this.snackBarService.openSnackBar('Users Merged Successfully', 'success')
                    this.getDuplicateUsers();
                }, e => {
                    this.snackBarService.openSnackBar(e, 'error')
                    console.error(e)
                })
            }
        });

    }


    saveDob(user: any): void {

        this.loading = true;
        let date: Date | string = new Date(user.DoB)
        date = date.toLocaleDateString('en-CA').slice(0, 10);


        this.adminService.patchUser(user.id, {dob: date}).subscribe(
            r => {
                this.loading = false;
                this.snackBarService.openSnackBar('Profile updated', 'success')
                this.getNoDOBReport();
            }, e => {
                this.loading = false;
                this.snackBarService.openSnackBar(e, 'error')
            })
    }

    getTabTitle(value: string) {
        const i: number = this.dataSource.data.findIndex((x: any) => x.value === value);
        return this.dataSource.data[i].name
    }

    clearTab() {
        this.router.navigate(['.'], {relativeTo: this.route, queryParams: {}});
        this.currentTab = null
    }

    sortData(event: Sort, table: string): void {

        console.error(table)
        switch (table) {
            case 'childrensData':
                this.childrensData.data = this.sortDataTable(this.childrensData.data, event)
                break
            case 'noMemories':
                this.noMemoriesData.data = this.sortDataTable(this.noMemoriesData.data, event)
                break
            case 'noRelationships':
                this.noRelationshipsData.data = this.sortDataTable(this.noRelationshipsData.data, event)
                break
            case 'pendingInvites':
                this.pendingInvitesData.data = this.sortDataTable(this.pendingInvitesData.data, event)
                break
            case 'noPermissions':
                this.noPermissionsData.data = this.sortDataTable(this.noPermissionsData.data, event)
                break
            case 'noDOB':
                this.noDOBData.data = this.sortDataTable(this.noDOBData.data, event)
                break
            case 'userEmails':
                this.userEmailsData.data = this.sortDataTable(this.userEmailsData.data, event)
                break
            case 'duplicates':
                this.duplicatesData = this.sortDataTable(this.duplicatesData, event)
                break
        }
    }

    sortDataTable(array: any, sort: Sort) {
        return array.sort((a: any, b: any) => {
            if (a[sort.active] < b[sort.active]) {
                return sort.direction === 'asc' ? -1 : 1;
            } else if (a[sort.active] > b[sort.active]) {
                return sort.direction === 'asc' ? 1 : -1;
            } else {
                return 0;
            }
        });
    }
}
