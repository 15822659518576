<main>
    <div class="d-flex flex-row justify-content-center">
        <h3 class="my-10">
            Users
        </h3>
    </div>
    <div class="largeContainer">
        <div *ngIf="loading" class="centerLoading">
            <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
        </div>
        <div *ngIf="!loading" class="mat-elevation-z6">
            <div class="bg-white">
                <mat-form-field class="w-100">
                    <mat-label>Search Users</mat-label>
                    <input (keydown)="keydown()" (keyup)="keyup()"
                           (keyup.enter)="getUsers()"
                           [(ngModel)]="searchTerm"
                           matInput
                           type="text"/>
                    <button (click)="getUsers()" [disabled]="!searchTerm"
                            aria-label="Search"
                            mat-icon-button matPrefix matTooltip="Search Users">
                        <mat-icon color="primary">
                            search
                        </mat-icon>
                    </button>
                    <button (click)="clearSearch()" *ngIf="searchTerm" aria-label="Clear Search" mat-icon-button
                            matSuffix>
                        <mat-icon>close</mat-icon>
                    </button>
                </mat-form-field>
            </div>
            <div class="flex-row gap-15 bg-white align-items-center flex-space-around filterExtraItems">

                <mat-form-field class="w-100 noBottomPadding" color="primary">
                    <mat-label>Role</mat-label>
                    <mat-select [(ngModel)]="role" (ngModelChange)="getUsers()" multiple>
                        <mat-option *ngFor="let userRole of roles"
                                    [value]="userRole.id">{{ userRole.name }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field class="w-100 noBottomPadding" color="primary">
                    <mat-label>Cohort</mat-label>
                    <mat-select [(ngModel)]="cohort" (ngModelChange)="getUsers()" multiple>
                        <mat-option *ngFor="let userCohort of cohorts"
                                    [value]="userCohort.id">{{ userCohort.name }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field class="w-100 noBottomPadding" color="primary">
                    <mat-label>Locality</mat-label>
                    <mat-select [(ngModel)]="locality" (ngModelChange)="getUsers()" multiple>
                        <mat-option *ngFor="let local of localities"
                                    [value]="local.id">{{ local.name }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field class="w-100 noBottomPadding" color="primary">
                    <mat-label>Status</mat-label>
                    <select [(ngModel)]="status" (ngModelChange)="getUsers()" matNativeControl>
                        <option [value]="'All'">All</option>
                        <option [value]="'Active'">Active</option>
                        <option [value]="'Deactivated'">Deactivated</option>
                        <option [value]="'locked'">Locked</option>
                    </select>
                </mat-form-field>
                <mat-form-field class="w-100 noBottomPadding" color="primary"
                                matTooltip="Filter users by status of their Timelines access">
                    <mat-label>Unlinked Boxes</mat-label>
                    <select [(ngModel)]="orphaned" (ngModelChange)="getUsers()" matNativeControl>
                        <option>All</option>
                        <option [value]="true">Only unlinked</option>
                    </select>
                </mat-form-field>
                <mat-form-field class="w-100 noBottomPadding" color="primary"
                                matTooltip="Filter to show users with no uploads">
                    <mat-label>No Uploads</mat-label>
                    <select [(ngModel)]="noUploads" (ngModelChange)="getUsers()" matNativeControl>
                        <option>All</option>
                        <option [value]="true">No Uploads</option>
                    </select>
                </mat-form-field>
            </div>
            <table [dataSource]="dataSource" mat-table matSort class="responsiveTable massiveTable"
                   [matSortActive]="matSortDefault" [matSortDirection]="matSortDirectionDefault"
                   [class.openSidebar]="largerTable"
                   (matSortChange)="sortData($event)">
                <ng-container matColumnDef="img">
                    <th *matHeaderCellDef mat-header-cell></th>
                    <td *matCellDef="let user" mat-cell>
                        <img [alt]="user.full_name" [src]="user.photo||'assets/images/blankProfile.png'"
                             class="smallProfilePicture">
                    </td>
                </ng-container>
                <ng-container matColumnDef="name">
                    <th *matHeaderCellDef mat-header-cell mat-sort-header> Name</th>
                    <td *matCellDef="let user" mat-cell><b class="d-none columnTitle">Name:</b> {{ user.full_name }}
                    </td>
                </ng-container>
                <ng-container matColumnDef="username">
                    <th *matHeaderCellDef mat-header-cell> Username</th>
                    <td *matCellDef="let user" mat-cell><b class="d-none columnTitle">Username:</b> {{ user.username }}
                    </td>
                </ng-container>
                <ng-container matColumnDef="roleType">
                    <th *matHeaderCellDef mat-header-cell> Role</th>
                    <td *matCellDef="let user" mat-cell><b class="d-none columnTitle">Role:</b> {{ user.role }}
                    </td>
                </ng-container>
                <ng-container matColumnDef="role">
                    <th *matHeaderCellDef mat-header-cell> Job Title</th>
                    <td *matCellDef="let user" mat-cell><b class="d-none columnTitle">Job
                        Title:</b> {{ user.job_title }}
                    </td>
                </ng-container>
                <ng-container matColumnDef="cohort">
                    <th *matHeaderCellDef mat-header-cell> Cohort</th>
                    <td *matCellDef="let user" mat-cell><b class="d-none columnTitle">Cohort:</b>
                        <ng-container *ngIf="user.cohorts.length > 0">
                            <ng-container *ngFor="let cohort of user.cohorts;let last = last;">{{ cohort.name }}
                                <ng-container *ngIf="!last">,</ng-container>
                            </ng-container>
                        </ng-container>
                    </td>
                </ng-container>
                <ng-container matColumnDef="locality">
                    <th *matHeaderCellDef mat-header-cell> Locality</th>
                    <td *matCellDef="let user" mat-cell><b
                            class="d-none columnTitle">Locality:</b> {{ user.locality?.name }}
                    </td>
                </ng-container>
                <ng-container matColumnDef="joined">
                    <th *matHeaderCellDef mat-header-cell mat-sort-header> Date Joined</th>
                    <td *matCellDef="let user" mat-cell><b class="d-none columnTitle">Date
                        Joined:</b> {{ user.date_joined |date:"dd/MM/yy" }}
                    </td>
                </ng-container>
                <ng-container matColumnDef="view">
                    <th *matHeaderCellDef mat-header-cell></th>
                    <td *matCellDef="let user" mat-cell>
                        <a mat-raised-button color="primary" class="text-center"
                           [routerLink]="user.timeline?.id ? ['/user', user.id, user.timeline?.id] : ['/user', user.id]">
                            View User
                        </a>
                    </td>
                </ng-container>
                <tr *matHeaderRowDef="displayedColumns" mat-header-row></tr>
                <tr *matRowDef="let row; columns: displayedColumns;" mat-row></tr>

                <tr *matNoDataRow class="mat-row">
                    <td *ngIf="searchTerm && role.length > 0 && cohort.length > 0 && locality.length > 0 else notFound"
                        [attr.colspan]="this.displayedColumns.length"
                        class="mat-cell text-center">No data matching these filters
                    </td>
                </tr>
            </table>
            <mat-paginator (page)="handlePageEvent($event)" [length]="count"
                           [pageSizeOptions]="[10, 15, 25, 50]"
                           [pageSize]="limit" aria-label="Select page"></mat-paginator>
        </div>
    </div>
</main>

<ng-template #notFound>
    <td *ngIf="!loading" [attr.colspan]="this.displayedColumns.length" class="mat-cell text-center">No users found
    </td>
</ng-template>
