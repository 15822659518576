import {Component, EventEmitter, Inject, OnDestroy} from '@angular/core';
import {ContentService} from "../../_services/content.service";
import {SnackbarService} from "../../_services/snackbar.service";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {FormBuilder, FormGroup} from "@angular/forms";
import {AuthService} from "../../_services/auth.service";

@Component({
    selector: 'app-create-media',
    templateUrl: './create-media.component.html',
    styleUrl: './create-media.component.scss'
})
export class CreateMediaComponent implements OnDestroy {
    mediaForm: FormGroup;
    memory: any;
    file: any = {
        file: '',
        type: '',
    }
    loading: boolean = false;
    width: number = 320;
    height: number = 240;
    streaming: boolean = false;
    video: any = null;
    canvas: any = null;
    photo: any = null;
    takingPhoto: boolean = false;
    audioRecord: boolean = false;
    audioMediaRecorder: any;
    audioRecording: boolean = false;
    videoRecord: boolean = false;
    videoMediaRecorder: any;
    videoRecording: boolean = false;
    reload: EventEmitter<any> = new EventEmitter();
    isMobile: boolean = true;
    maxFilesize: number = 30;

    constructor(@Inject(MAT_DIALOG_DATA) private data: any,
                protected dialogRef: MatDialogRef<CreateMediaComponent>,
                private contentService: ContentService,
                private snackBarService: SnackbarService,
                private readonly formBuilder: FormBuilder,
                private authService: AuthService) {
        if (data) {
            this.memory = data;
        }
        this.mediaForm = this.formBuilder.group({
            title: [''],
            description: [''],
        });
        const toMatch = [
            /Android/i,
            /iPhone/i,
            /iPad/i,
        ];

        this.isMobile = toMatch.some((toMatchItem) => {
            return navigator.userAgent.match(toMatchItem);
        });
        this.authService.refreshUser();
        this.authService.getDynamicSettings().then((r): void => {
            if (r?.maxsize) {
                // get maxfilesize from dynamic settings
                this.maxFilesize = r.maxsize
            }
        });
    }


    onVideoFileChanged(event: any) {
        this.file.file = event.target.files[0];
        this.file.type = 'video';
        const file = event.target.files[0]
        let filesize: number = parseFloat(((file.size / 1024) / 1024).toFixed(1)); // MB

        if (filesize > this.maxFilesize) {
            this.snackBarService.openSnackBar('File too large (' + filesize + 'MB) max file size is ' + this.maxFilesize + 'MB', 'error')
        }
        var mimeType = file.type;
        if (mimeType.match(/video\/*/) == null) {
            // check if image
            this.snackBarService.openSnackBar("Only images are supported.", 'error')
            return;
        }

        this.file.video = URL.createObjectURL(file);
    }


    onPhotoFileChanged(event: any): void {
        this.file.file = event.target.files[0];
        this.file.type = 'image';
        const file = event.target.files[0]

        var mimeType = file.type;
        if (mimeType.match(/image\/*/) == null) {
            // check if image
            this.snackBarService.openSnackBar("Only images are supported.", 'error')
            return;
        }

        var reader: FileReader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = (_event): void => {
            if (reader.result) {
                this.file.image = reader.result;
            } else {
                this.snackBarService.openSnackBar("Reader result returned null.", 'error')
            }
        }

    }

    ngOnDestroy(): void {
        this.videoMediaRecorder?.stream?.getTracks().forEach(function (track: any): void {
            track.stop();
        })
        this.audioMediaRecorder?.stream?.getTracks().forEach(function (track: any): void {
            track.stop();
        })
        this.video?.srcObject?.getTracks().forEach(function (track: any): void {
            track.stop();
        })
    }

    saveMedia(): void {

        this.loading = true;
        let body: { title: string, description: string, type: string, cropped: null | number } = {
            title: this.mediaForm.value.title,
            description: this.mediaForm.value.description,
            type: this.file.type,
            cropped: null
        }
        let file = this.file.file
        if (this.file.type == 'video') {
            file = new File(
                [this.file.file],
                "upload.mp4",
                {type: 'video/mp4'}
            );
        } else if (this.file.type == 'audio') {
            file = new File(
                [this.file.file],
                "upload.mp3",
                {type: 'audio/mp3'}
            );
        } else if (this.file.type == 'image') {
            body.cropped = 1;
        }
        this.contentService.uploadMedia(this.memory.id, body, file).subscribe(r => {
            this.snackBarService.openSnackBar('Media Successfully Uploaded', 'success')
            this.reload.emit();
            this.dialogRef.close();
            this.loading = false;
        }, e => {
            this.loading = false;
            console.error(e)
            this.snackBarService.openSnackBar(body.title + ':' + e, 'error')
        })
    }

    // Audio
    recordAudio(): void {
        this.file.file = null;
        this.file.audio = null;
        navigator.mediaDevices?.getUserMedia({video: false, audio: true})
            .then(stream => {
                this.audioRecord = true;
                this.audioRecording = true;
                this.audioMediaRecorder = new MediaRecorder(stream);
                this.audioMediaRecorder.start();
                this.audioMediaRecorder.audioBlobs = [];
                this.audioMediaRecorder.ondataavailable = (e: any): void => {
                    this.audioMediaRecorder.audioBlobs.push(e.data);
                };
            }).catch((err): void => {
            this.snackBarService.openSnackBar(`An error occurred: ${err}`, 'error')
        });
    }


    recordingAudioStop(): void {
        //save audio type to pass to set the Blob type
        let mimeType = this.audioMediaRecorder.mimeType;

        //listen to the stop event in order to create & return a single Blob object
        this.audioMediaRecorder.addEventListener("stop", (): void => {
            //create a single blob object, as we might have gathered a few Blob objects that needs to be joined as one
            let audioBlob: Blob = new Blob(this.audioMediaRecorder.audioBlobs, {type: mimeType});

            //resolve promise with the single audio blob representing the recorded audio
            this.file.file = audioBlob;
            this.file.type = 'audio';
            this.file.audio = URL.createObjectURL(audioBlob);

            this.audioRecording = false;
        });

        //stop the recording feature
        this.audioMediaRecorder.stop();
    }

    // Video


    recordVideo(): void {
        this.file.file = null;
        this.file.video = null;

        navigator.mediaDevices?.getUserMedia({
            video: {
                width: {max: 1920},
                height: {max: 1080}
            }, audio: true
        }).then(stream => {
            this.videoRecord = true;
            this.videoRecording = true;
            this.videoMediaRecorder = new MediaRecorder(stream);
            this.videoMediaRecorder.start();
            this.videoMediaRecorder.recordedBlobs = [];
            this.videoMediaRecorder.ondataavailable = (e: any): void => {
                this.videoMediaRecorder.recordedBlobs.push(e.data);
            };
        }).catch((err): void => {
            this.snackBarService.openSnackBar(`An error occurred: ${err}`, 'error')
        });
    }

    recordingVideoStop(): void {
        //save audio type to pass to set the Blob type
        let mimeType = this.videoMediaRecorder.mimeType;

        //listen to the stop event in order to create & return a single Blob object
        this.videoMediaRecorder.addEventListener("stop", (): void => {
            //create a single blob object, as we might have gathered a few Blob objects that needs to be joined as one
            let videoBlob: Blob = new Blob(this.videoMediaRecorder.recordedBlobs, {type: mimeType});
            //resolve promise with the single audio blob representing the recorded audio
            this.file.file = videoBlob;
            this.file.type = 'video';
            this.file.video = URL.createObjectURL(videoBlob);

            this.videoRecording = false;
        });

        //stop the recording feature
        this.videoMediaRecorder.stop();
    }

    // Photos
    takePhoto(): void {
        navigator.mediaDevices
            ?.getUserMedia({video: true, audio: false})
            .then((stream): void => {
                this.takingPhoto = true;
                this.video.srcObject = stream;
                this.video.play();
            })
            .catch((err): void => {
                this.snackBarService.openSnackBar(`An error occurred: ${err}`, 'error')
            });
        this.video = document.getElementById("video");
        this.canvas = document.getElementById("canvas");
        this.photo = document.getElementById("photo");

        this.video.addEventListener("canplay", (): void => {
                if (!this.streaming) {
                    this.height = this.video.videoHeight / (this.video.videoWidth / this.width);

                    if (isNaN(this.height)) {
                        this.height = this.width / (4 / 3);
                    }
                    this.video.setAttribute("width", this.width);
                    this.video.setAttribute("height", this.height);
                    this.canvas.setAttribute("width", this.width);
                    this.canvas.setAttribute("height", this.height);
                    this.streaming = true;
                }
            },
            false,
        );

        this.clearPhoto();
    }


    clearPhoto(): void {
        const context = this.canvas.getContext("2d");
        context.fillStyle = "#AAA";
        context.fillRect(0, 0, this.canvas.width, this.canvas.height);
        const data = this.canvas.toDataURL("image/png");
        this.photo.setAttribute("src", data);
    }

    takePicture(): void {
        const context = this.canvas.getContext("2d");
        if (this.width && this.height) {
            this.canvas.width = this.width;
            this.canvas.height = this.height;
            context.drawImage(this.video, 0, 0, this.width, this.height);
            const data = this.canvas.toDataURL("image/png");
            this.photo.setAttribute("src", data);
            this.file.type = 'image'
            this.file.file = this.convertBase64ToBlob(data)
        } else {
            this.clearPhoto();
        }
    }

    private convertBase64ToBlob(base64Image: string): Blob {
        // Split into two parts
        const parts: string[] = base64Image.split(';base64,');

        // Hold the content type
        const imageType: string = parts[0].split(':')[1];

        // Decode Base64 string
        const decodedData: string = window.atob(parts[1]);

        // Create UNIT8ARRAY of size same as row data length
        const uInt8Array: Uint8Array = new Uint8Array(decodedData.length);

        // Insert all character code into uInt8Array
        for (let i: number = 0; i < decodedData.length; ++i) {
            uInt8Array[i] = decodedData.charCodeAt(i);
        }

        // Return BLOB image after conversion
        return new Blob([uInt8Array], {type: imageType});
    }

    protected readonly location = location;
}
