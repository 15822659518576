import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";

@Component({
    selector: 'app-view-guide',
    templateUrl: './view-guide.component.html',
    styleUrl: './view-guide.component.scss'
})
export class ViewGuideComponent {

    constructor(@Inject(MAT_DIALOG_DATA) protected data: any,
                protected dialogRef: MatDialogRef<ViewGuideComponent>,) {
    }

    link(url: string, download: boolean, name?: string): void {
        if (download) {
            let downloadName: string = url
            if (name) {
                downloadName = name?.replace(/ /g, "_");
            }
            fetch(url)
                // check to make sure you didn't have an unexpected failure (may need to check other things here depending on use case / backend)
                .then(resp => resp.status === 200 ? resp.blob() : Promise.reject('something went wrong'))
                .then(blob => {
                    const url: string = window.URL.createObjectURL(blob);
                    const a: HTMLAnchorElement = document.createElement('a');
                    a.style.display = 'none';
                    a.href = url;
                    // the filename you want
                    a.download = downloadName;
                    document.body.appendChild(a);
                    a.click();
                    window.URL.revokeObjectURL(url);
                })
                .catch(() => function (): void {
                    console.error('catch error')
                    window.open(url, '_blank')?.focus();
                });
        } else {
            window.open(url, '_blank')?.focus();
        }
    }
}
