<main>
    <div class="largeContainer">
        <div class="d-flex flex-row flex-space-between">
            <button (click)="back()" aria-label="Back" mat-icon-button matTooltip="Back">
                <mat-icon>keyboard_backspace</mat-icon>
            </button>
            <div><h3 class="my-10">
                Bulk Invite
            </h3></div>
            <div></div>
        </div>
        <div class="flex-col gap-25">
            <mat-card>
                <mat-card-content>
                    <mat-stepper #stepper [linear]="true" class="stepper">
                        <mat-step>
                            <ng-template matStepLabel>Upload CSV</ng-template>
                            <div class="flex-col mt-10">
                                <div class="flex-col">
                                    <div>
                                        <button (click)="fileInput.click()" (keyup.enter)="fileInput.click()"
                                                color="accent" mat-raised-button>
                                            <span>Bulk Invite From CSV or Excel</span>
                                            <mat-icon>attach_file</mat-icon>
                                            <input #fileInput (change)="handleImport($event)"
                                                   accept="text/csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, .xltx"
                                                   class="d-none" mode="replace"
                                                   type="file"/>
                                        </button>
                                    </div>
                                    <small class="mt-5"><a download href="assets/VMB_users_invite_template.csv">Download
                                        CSV template</a></small>
                                </div>
                                <ng-container *ngIf="columnNames.length > 0">
                                    <div class="flex-row justify-content-center gap10">
                                        <mat-icon>keyboard_backspace</mat-icon>
                                        Scroll horizontally to view all columns
                                        <mat-icon class="flip">keyboard_backspace</mat-icon>
                                    </div>
                                    <div class="overflowTable">
                                        <table [formGroup]="headerSetting">
                                            <tr>
                                                <td *ngFor="let header of this.columnNames">{{ header }}</td>
                                            </tr>
                                            <tr>
                                                <td *ngFor="let optionName of this.columnNames let i = index"
                                                    [formGroup]="headerFormControl(i)">
                                                    <mat-form-field appearance="outline" class="w-100 mt-5"
                                                                    color="primary">
                                                        <mat-label>Header Matching</mat-label>
                                                        <mat-select formControlName="newHeader" #header>
                                                            <mat-option></mat-option>
                                                            <mat-option *ngFor="let name of this.displayedColumns"
                                                                        [value]="name">
                                                                {{ name }}
                                                            </mat-option>
                                                        </mat-select>
                                                        <mat-hint class="errorMsg"
                                                                  *ngIf="this.getOccurrence(header.value) > 1 && optionName">
                                                            Duplicate column
                                                        </mat-hint>
                                                    </mat-form-field>
                                                </td>
                                            </tr>
                                        </table>
                                    </div>
                                </ng-container>
                            </div>

                            <div class="validation"
                                 *ngIf="(getOccurrence('Email') == 0||getOccurrence('First Name') == 0|| getOccurrence('Last Name') == 0|| getOccurrence('User Type/Role') == 0 || hasAnyDuplicates()) && this.columnNames.length> 0">
                                <p class="invalid">Requirements:</p>
                                <ul>
                                    <li *ngIf="hasAnyDuplicates()" class="invalid">
                                        No Duplicated Columns
                                    </li>
                                    <li *ngIf="getOccurrence('First Name') == 0" class="invalid">
                                        First Name is Required
                                    </li>
                                    <li *ngIf="getOccurrence('Email') == 0" class="invalid">
                                        Email is Required
                                    </li>
                                    <li *ngIf=" getOccurrence('Last Name') == 0" class="invalid">
                                        Last Name is Required
                                    </li>
                                    <li *ngIf=" getOccurrence('User Type/Role') == 0" class="invalid">
                                        User Type/Role is Required
                                    </li>
                                </ul>
                            </div>
                            <div class="w-100 mt-5 text-end">
                                <button *ngIf="this.wb"
                                        [disabled]="getOccurrence('Email') == 0||!this.wb|| getOccurrence('First Name') == 0|| getOccurrence('Last Name') == 0|| getOccurrence('User Type/Role') == 0 || hasAnyDuplicates()"
                                        mat-raised-button color="primary"
                                        matStepperNext (click)="readData()">Next
                                </button>
                                <button *ngIf="!this.wb" class="ml-10"
                                        mat-raised-button color="primary"
                                        matStepperNext (click)="addUserRow(true)">Skip
                                </button>
                            </div>
                        </mat-step>
                        <mat-step>
                            <ng-template matStepLabel>User Details</ng-template>
                            <div class="flex-row justify-content-center gap10">
                                <mat-icon>keyboard_backspace</mat-icon>
                                Scroll horizontally to view all columns
                                <mat-icon class="flip">keyboard_backspace</mat-icon>
                            </div>
                            <div class="overflowTable">
                                <table [dataSource]="dataSource" mat-table class="responsiveTable w-100"
                                       *ngIf="this.dataSource.data.length > 0">
                                    <ng-container matColumnDef="Delete">
                                        <th *matHeaderCellDef mat-header-cell>Delete</th>
                                        <td *matCellDef="let user; let row = index" mat-cell>
                                            <button [disabled]="dataSource.data.length <= 1" mat-icon-button
                                                    color="warn" (click)="delete(row)">
                                                <mat-icon>delete_forever</mat-icon>
                                            </button>
                                        </td>
                                    </ng-container>
                                    <ng-container matColumnDef="First Name">
                                        <th *matHeaderCellDef mat-header-cell>First Name</th>
                                        <td *matCellDef="let user" mat-cell>
                                            <mat-form-field class="w-100 mt-5" appearance="outline" color="primary">
                                                <mat-label>First Name</mat-label>
                                                <input [(ngModel)]="user['First Name']"
                                                       [ngModelOptions]="{standalone: true}" matInput type="text">
                                                <mat-hint *ngIf="!user['First Name']" class="errorMsg">This field is
                                                    required
                                                </mat-hint>
                                            </mat-form-field>
                                        </td>
                                    </ng-container>
                                    <ng-container matColumnDef="Last Name">
                                        <th *matHeaderCellDef mat-header-cell>Last Name</th>
                                        <td *matCellDef="let user" mat-cell>
                                            <mat-form-field class="w-100 mt-5" appearance="outline" color="primary">
                                                <mat-label>Last Name</mat-label>
                                                <input [(ngModel)]="user['Last Name']"
                                                       [ngModelOptions]="{standalone: true}" matInput type="text">
                                                <mat-hint *ngIf="!user['Last Name']" class="errorMsg">This field is
                                                    required
                                                </mat-hint>
                                            </mat-form-field>
                                        </td>
                                    </ng-container>
                                    <ng-container matColumnDef="User Type/Role">
                                        <th *matHeaderCellDef mat-header-cell>User Type/Role</th>
                                        <td *matCellDef="let user" mat-cell>
                                            <mat-form-field appearance="outline" class="w-100 my-10" color="primary">
                                                <mat-label>Role</mat-label>
                                                <select [(ngModel)]="user['User Type/Role']" matNativeControl>
                                                    <option *ngFor="let role of roles"
                                                            [value]="role.id">{{ role.name }}
                                                    </option>
                                                </select>
                                                <mat-hint class="errorMsg"
                                                          *ngIf="user['roleError'] && !user['User Type/Role']"
                                                >{{ user['roleError'] }}
                                                </mat-hint>
                                            </mat-form-field>
                                        </td>
                                    </ng-container>
                                    <ng-container matColumnDef="Email">
                                        <th *matHeaderCellDef mat-header-cell> Email</th>
                                        <td *matCellDef="let user" mat-cell>
                                            <mat-form-field class="w-100 mt-5" appearance="outline" color="primary">
                                                <mat-label>Email</mat-label>
                                                <input [(ngModel)]="user['Email']"
                                                       [ngModelOptions]="{standalone: true}" matInput type="text">
                                                <mat-hint class="errorMsg"
                                                          *ngIf="!user['Email']?.match(this.emailPattern) || !user['Email']"> {{ !user['Email']?.match(this.emailPattern) ? 'A valid email is required' : 'This field is required' }}
                                                </mat-hint>
                                            </mat-form-field>
                                        </td>
                                    </ng-container>
                                    <ng-container matColumnDef="Date of Birth">
                                        <th *matHeaderCellDef mat-header-cell> Date of Birth</th>
                                        <td *matCellDef="let user" mat-cell>
                                            <mat-form-field appearance="outline" class="w-100">
                                                <mat-label>Date of Birth</mat-label>
                                                <input [(ngModel)]="user['Date of Birth']"
                                                       [ngModelOptions]="{standalone: true}" [matDatepicker]="picker"
                                                       matInput #dobNgModel="ngModel">
                                                <mat-datepicker-toggle [for]="picker"
                                                                       matIconSuffix></mat-datepicker-toggle>
                                                <mat-datepicker #picker></mat-datepicker>
                                                <mat-error *ngIf="dobNgModel.hasError('matDatepickerParse')">
                                                    Invalid date format. Please use DD/MM/YYYY.
                                                </mat-error>
                                                <mat-hint class="errorMsg"
                                                          *ngIf="!user['Date of Birth'] && isChild(user['User Type/Role'])">
                                                    This field is required for users with this role
                                                </mat-hint>
                                            </mat-form-field>
                                        </td>
                                    </ng-container>
                                    <ng-container matColumnDef="Secondary Email">
                                        <th *matHeaderCellDef mat-header-cell> Secondary Email</th>
                                        <td *matCellDef="let user" mat-cell>
                                            <mat-form-field class="w-100 mt-5" appearance="outline" color="primary">
                                                <mat-label>Secondary Email</mat-label>
                                                <input [(ngModel)]="user['Secondary Email']"
                                                       [ngModelOptions]="{standalone: true}" matInput type="text">
                                                <mat-hint class="errorMsg"
                                                          *ngIf="!user['Secondary Email']?.match(this.emailPattern) && user['Secondary Email']">
                                                    A valid email is required
                                                </mat-hint>
                                            </mat-form-field>
                                        </td>
                                    </ng-container>
                                    <ng-container matColumnDef="Case management number">
                                        <th *matHeaderCellDef mat-header-cell> Case management number
                                        </th>
                                        <td *matCellDef="let user" mat-cell>
                                            <mat-form-field class="w-100 mt-5" appearance="outline" color="primary">
                                                <mat-label>Case management number</mat-label>
                                                <input [(ngModel)]="user['Case management number']"
                                                       [ngModelOptions]="{standalone: true}" matInput type="text">
                                            </mat-form-field>
                                        </td>
                                    </ng-container>
                                    <ng-container matColumnDef="Locality">
                                        <th *matHeaderCellDef mat-header-cell> Locality</th>
                                        <td *matCellDef="let user" mat-cell>
                                            <mat-form-field appearance="outline" class="w-100 mt-5" color="primary">
                                                <mat-label>Locality</mat-label>
                                                <select [(ngModel)]="user['Locality']" matNativeControl>
                                                    <option></option>
                                                    <option *ngFor="let locality of localities"
                                                            [value]="locality.id">{{ locality.name }}
                                                    </option>
                                                </select>
                                                <button (click)="newLocalityModal()" aria-label="Add Cohort"
                                                        mat-icon-button
                                                        matPrefix
                                                        matTooltip="Add a new cohort">
                                                    <mat-icon>add</mat-icon>
                                                </button>
                                                <mat-hint class="errorMsg"
                                                          *ngIf="user['localityError']&& !user['Locality']">
                                                    {{ user['localityError'] }}
                                                </mat-hint>
                                            </mat-form-field>
                                        </td>
                                    </ng-container>
                                    <ng-container matColumnDef="Cohort">
                                        <th *matHeaderCellDef mat-header-cell> Cohort</th>
                                        <td *matCellDef="let user" mat-cell>
                                            <mat-form-field appearance="outline" class="w-100 mt-5" color="primary">
                                                <mat-label>Cohort</mat-label>
                                                <select [(ngModel)]="user['Cohort']" matNativeControl>
                                                    <option></option>
                                                    <option *ngFor="let cohort of cohorts"
                                                            [value]="cohort.id">{{ cohort.name }}
                                                    </option>
                                                </select>
                                                <button (click)="newCohortModal()" aria-label="Add Cohort"
                                                        mat-icon-button
                                                        matPrefix
                                                        matTooltip="Add a new cohort">
                                                    <mat-icon>add</mat-icon>
                                                </button>
                                                <mat-hint class="errorMsg"
                                                          *ngIf="user['cohortError']&& !user['Cohort']">
                                                    {{ user['cohortError'] }}
                                                </mat-hint>
                                            </mat-form-field>
                                        </td>
                                    </ng-container>
                                    <ng-container matColumnDef="Job title">
                                        <th *matHeaderCellDef mat-header-cell> Job title</th>
                                        <td *matCellDef="let user" mat-cell>
                                            <mat-form-field class="w-100 mt-5" appearance="outline" color="primary">
                                                <mat-label>Job title</mat-label>
                                                <input [(ngModel)]="user['Job title']"
                                                       [ngModelOptions]="{standalone: true}" matInput type="text">
                                            </mat-form-field>
                                        </td>
                                    </ng-container>

                                    <tr *matHeaderRowDef="tableColumns" mat-header-row></tr>
                                    <tr *matRowDef="let row; columns: tableColumns;let i = dataIndex" mat-row></tr>
                                </table>
                            </div>
                            <div class="validation"
                                 *ngIf="!validDataCheck()">
                                <p class="invalid">Please fill in all required fields:</p>
                                <ul>
                                    <li class="invalid">
                                        First Name
                                    </li>
                                    <li class="invalid">
                                        Email
                                    </li>
                                    <li class="invalid">
                                        Last Name
                                    </li>
                                    <li class="invalid">
                                        User Type/Role
                                    </li>
                                    <li class="invalid">
                                        DOB for all users with roles: Child (under 12), Young Person (12-16) or Care
                                        Leaver (16-25)
                                    </li>
                                </ul>
                            </div>
                            <div class="w-100 mt-5 flex-row flex-space-between">
                                <button mat-raised-button color="primary" (click)="backCheck()">Back
                                </button>
                                <button class="pl-10"
                                        mat-raised-button color="primary" (click)="addUserRow()">Add row
                                </button>
                                <button mat-raised-button color="primary" [disabled]="!validDataCheck()"
                                        (click)="sendInvites()"
                                        matStepperNext>Send Invites
                                </button>
                            </div>
                        </mat-step>
                    </mat-stepper>
                </mat-card-content>
                <mat-card-footer *ngIf="loading">
                    <mat-progress-bar color="primary" mode="indeterminate"></mat-progress-bar>
                </mat-card-footer>
            </mat-card>
        </div>
    </div>
</main>

