import {AfterViewInit, Component, HostListener, OnDestroy, OnInit} from '@angular/core';
import {MatDrawerMode} from "@angular/material/sidenav";
import {AuthService} from "../../_services/auth.service";
import {MatDialog} from "@angular/material/dialog";
import {FeedbackComponent} from "../../shared/feedback/feedback.component";
import {ContentService} from "../../_services/content.service";
import {Router} from "@angular/router";
import {NotificationsService} from "../../_services/notifications.service";
import {AdminService} from "../../_services/admin.service";

@Component({
    selector: 'app-admin-view',
    templateUrl: './admin-view.component.html',
    styleUrl: './admin-view.component.scss'
})
export class AdminViewComponent implements OnInit, AfterViewInit, OnDestroy {
    user: any;
    isMobile: boolean = false;
    minified: boolean = false;
    mode: MatDrawerMode = 'side';
    sideNav: boolean = true;
    notificationNavOpened: boolean = false;
    hijacking: boolean = false;
    notificationsCount: string | null = null;
    interval;

    constructor(private authService: AuthService,
                public contentService: ContentService,
                public dialog: MatDialog,
                private notificationService: NotificationsService,
                private router: Router,
                private adminService: AdminService) {
        this.user = this.authService.getUser();
        if (localStorage.getItem('hijacked')) {
            this.hijacking = true;
        }
        let that = this;
        this.getNotificationCount();
        this.interval = setInterval(function (): void {
            // Check user every minute to ensure the user hasn't timed out or been forcibly timed out by an admin
            if (that.authService.isLoggedIn()) {
                that.authService.userHeartbeat().subscribe(r => {
                }, e => {
                    console.error(e)
                })
            }
        }, 60000);
        router.events.subscribe((val): void => {
            this.notificationNavOpened = false;
        });
        this.adminService.changeTableSize(this.sideNav && !this.minified);
    }

    getNotificationCount(): void {
        this.notificationService.getNotificationsCount().subscribe(r => {
            if (r.count > 99) {
                this.notificationsCount = '99+';
            } else {
                this.notificationsCount = JSON.stringify(r.count);
            }
        }, e => {
            console.error(e);
        })
    }

    ngOnDestroy(): void {
        clearTimeout(this.interval);
    }

    @HostListener('window:resize', ['$event'])
    onResize(event: any): void {
        this.isMobile = window.innerWidth <= 700;
        if (this.isMobile) {
            this.mode = 'over';
            this.sideNav = false;
        } else {
            this.mode = 'side';
            this.sideNav = true;
        }
    }

    ngOnInit(): void {
        this.isMobile = window.innerWidth <= 700;
        if (this.isMobile) {
            this.mode = 'over';
            this.sideNav = false;
        } else {
            this.mode = 'side';
            this.sideNav = true;
        }
    }

    ngAfterViewInit(): void {
        setTimeout((): void => {
            // This fixes weird gap between sidebar and content
            window.dispatchEvent(new Event('resize'));
        });
    }

    logout(): void {
        this.authService.logout();
    }

    feedback(): void {
        if (this.dialog.openDialogs.length == 0) {
            this.dialog.open(FeedbackComponent, {});
        }
    }

    openNotificationNav(): void {
        if (this.isMobile) {
            this.sideNav = false;
        }
        this.notificationNavOpened = true;
    }


    minifySideWindow(): void {
        this.minified = !this.minified
        this.adminService.changeTableSize(this.sideNav && !this.minified);
        window.dispatchEvent(new Event('resize'));
    }

    openDropdown(): void {
        this.minified = false;
        this.sideNav = true;
        this.adminService.changeTableSize(this.sideNav && !this.minified);
        window.dispatchEvent(new Event('resize'));
    }

    changeSideNav(newSideNavValue: boolean) {
        this.sideNav = newSideNavValue;
        this.adminService.changeTableSize(this.sideNav && !this.minified);
    }

}
