<table (matSortChange)="sortData($event)" [dataSource]="dataSource" class="responsiveTable" mat-table matSort>
    <ng-container matColumnDef="requestedBy">
        <th *matHeaderCellDef mat-header-cell mat-sort-header>Requested By</th>
        <td *matCellDef="let request" mat-cell><b class="d-none columnTitle">Requested By:</b>
            <button [routerLink]="['/user', request.requested_by.id ]" mat-button>{{ request.requested_by?.full_name }}
            </button>
        </td>
    </ng-container>
    <ng-container matColumnDef="timeline">
        <th *matHeaderCellDef mat-header-cell>Timeline</th>
        <td *matCellDef="let request" mat-cell><b class="d-none columnTitle">Timeline:</b>
            <ng-container *ngIf="request.timeline_user_access?.timeline.child else noTimeline">
                <button [routerLink]="['/user', request.timeline_user_access?.timeline?.child?.id ]"
                        mat-button>{{ request.timeline_user_access?.timeline?.title }}
                </button>
            </ng-container>
            <ng-template #noTimeline>{{ request.timeline || timeline }}</ng-template>
        </td>
    </ng-container>
    <ng-container matColumnDef="newPermission">
        <th *matHeaderCellDef mat-header-cell>New Permission</th>
        <td *matCellDef="let request" mat-cell><b class="d-none columnTitle">New
            Permission:</b> {{ request.change_permission_to }}
        </td>
    </ng-container>
    <ng-container matColumnDef="createdDate">
        <th *matHeaderCellDef mat-header-cell mat-sort-header>Created Date</th>
        <td *matCellDef="let request" mat-cell><b class="d-none columnTitle">Created
            Date:</b> {{ request.created_date |date }}
        </td>
    </ng-container>
    <ng-container matColumnDef="status">
        <th *matHeaderCellDef mat-header-cell>Status</th>
        <td *matCellDef="let request" mat-cell><b class="d-none columnTitle">Status:</b> {{ request.status }}</td>
    </ng-container>
    <ng-container matColumnDef="view">
        <th *matHeaderCellDef mat-header-cell></th>
        <td *matCellDef="let request" mat-cell>
            <button [routerLink]="['/permissionRequest', request.id]" color="primary" mat-raised-button>
                View Request
            </button>
        </td>
    </ng-container>
    <tr *matHeaderRowDef="displayedColumns" mat-header-row></tr>
    <tr *matRowDef="let row; columns: displayedColumns;"
        mat-row></tr>

    <tr *matNoDataRow class="mat-row">
        <td *ngIf="input" [attr.colspan]="this.displayedColumns.length" class="mat-cell text-center">No data matching
            the filter "{{ input }}"
        </td>
        <td *ngIf="!input" [attr.colspan]="this.displayedColumns.length" class="mat-cell text-center">
            No permission requests found
        </td>
    </tr>
</table>
<mat-paginator (page)="this.handlePageEvent.emit($event)" *ngIf="count" [length]="count"
               [pageSizeOptions]="[10, 15, 25, 50]"
               [pageSize]="limit" aria-label="Select page"></mat-paginator>

