import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {FormBuilder, FormGroup} from "@angular/forms";
import {DomSanitizer} from "@angular/platform-browser";
import {ContentService} from "../../_services/content.service";
import {SnackbarService} from "../../_services/snackbar.service";
import {environment} from "../../../environments/environment";

@Component({
    selector: 'app-edit-item',
    templateUrl: './edit-item.component.html',
    styleUrl: './edit-item.component.scss'
})
export class EditItemComponent {
    currentMedia;
    editItemForm: FormGroup;
    cropping: boolean = false;
    rotation: number = 0;
    loading: boolean = false;


    constructor(@Inject(MAT_DIALOG_DATA) private data: any,
                protected dialogRef: MatDialogRef<EditItemComponent>,
                public dialog: MatDialog, private formBuilder: FormBuilder,
                private sanitizer: DomSanitizer,
                private contentService: ContentService,
                private snackbarService: SnackbarService) {

        this.editItemForm = this.formBuilder.group({
            title: [''],
            description: [''],
            coverImage: [''],
        });

        if (data) {
            this.currentMedia = data;
            this.editItemForm.patchValue({
                title: this.currentMedia.title,
                description: this.currentMedia.description,
                coverImage: this.currentMedia.cover_image
            });
        }

    }


    initiateCrop(): void {
        this.contentService.checkMedia(this.currentMedia).then((r): void => {
            this.currentMedia = r;
            let that = this;
            this.contentService.toDataURL(`${environment.apiUrl}v2/api/media/` + this.currentMedia.id + '/download/').then(function (r) {
                that.currentMedia.image = r;
                that.currentMedia.cropping = true;
                that.cropping = true;
            });

        }).catch((e): void => {
            this.snackbarService.openSnackBar(e, 'error')
        });
        var myDiv: HTMLElement | null = document.getElementById('content');
        if (myDiv) {
            myDiv.scrollTop = 0;
        }
    }

    imageCropped(file: any, event: any): void {
        file.croppedFile = event.blob;
        file.tempFile = this.sanitizer.bypassSecurityTrustUrl(event.objectUrl);
    }


    saveCrop(file: any): void {
        this.loading = true;
        this.contentService.patchMedia(this.currentMedia.id, {
            type: 'image',
            cropped: 1
        }, file.croppedFile).subscribe(r => {
            this.snackbarService.openSnackBar('Media Successfully Updated', 'success');
            file.image = file.tempFile;
            this.rotation = 0;
            file.cropping = false;
            this.cropping = false;
            this.loading = false;
        }, e => {
            this.loading = false;
            console.error(e)
            this.snackbarService.openSnackBar(e, 'error')
        })
    }

    cancelCrop(file: any): void {
        this.rotation = 0;
        file.tempFile = null;
        file.cropping = false;
        this.cropping = false;
    }

    rotateCanvas(): void {
        this.rotation++
    }

    saveMedia(): void {
        let body = {
            title: this.editItemForm.value.title,
            description: this.editItemForm.value.description,
            cover_image: this.editItemForm.value.coverImage,
        }
        this.contentService.patchMedia(this.currentMedia.id, body).subscribe(r => {
            this.snackbarService.openSnackBar('Media Successfully Updated', 'success')
            this.dialogRef.close();
            location.reload();
        }, e => {
            console.error(e)
            this.snackbarService.openSnackBar(body.title + ':' + e, 'error')
        })
    }

    checkIfMemory(): boolean {
        // Only show if accessing lightbox from memory
        return location.href.includes('memory');
    }
}
