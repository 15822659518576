<mat-toolbar class="d-flex flex-space-between mat-elevation-z3" color="primary">
    <button [routerLink]="['/timeline']" aria-label="Home" mat-icon-button matTooltip="Home">
        <img alt="Virtual Memory Box Logo" src="assets/images/vmb.svg">
    </button>
</mat-toolbar>
<main class="notFoundBannerBg minFullHeight" role="main">
    <div class="container d-flex justify-content-center">
        <div class="d-flex flex-column">

            <div class="text-center">
                <h1 class="mx-auto">403</h1>
                <h2>Access Denied</h2>
                <button (click)="goToHome()" (keyup.enter)="goToHome()" class="mx-auto my-3 mx-10" color="primary"
                        mat-flat-button>
                    Back to safety
                </button>
                <button (click)="logout()" (keyup.enter)="logout()" class="mx-auto my-3 mx-10" color="warn"
                        mat-flat-button>
                    Logout
                </button>
                <p>
                    If you believe this is incorrect please
                </p>
                <button (click)="feedback()" (keyup.enter)="feedback()" class="mx-auto my-3 mx-10" color="accent"
                        mat-flat-button>
                    Contact Us
                </button>
            </div>
        </div>
    </div>
</main>
<app-footer></app-footer>
