import {Component} from '@angular/core';
import {AdminService} from "../../_services/admin.service";
import {SnackbarService} from "../../_services/snackbar.service";
import {PageEvent} from "@angular/material/paginator";
import {Sort} from "@angular/material/sort";

const media: any = {
    flagType: 'Duplicate',
    date: new Date(),
    user: {full_name: 'Chris Brock'},
    box: "Chris Brock's timeline",
    id: 1
};

@Component({
    selector: 'app-resolved-feedback',
    templateUrl: './resolved-feedback.component.html',
    styleUrl: './resolved-feedback.component.scss'
})
export class ResolvedFeedbackComponent {
    loading: boolean = false;
    checkedResolvedMedia: any[] = [];
    resolvedData: any[] = []
    typingTimer: any;
    searchTerm: any;
    limit: number = 15;
    page: number = 0;
    count: number = 0;
    direction: string | undefined;
    order_by: string | undefined;
    category: string = 'All';
    categories = ['Duplicate', 'Move to another album', 'Inappropriate', 'Other'];

    constructor(private adminService: AdminService,
                private snackbarService: SnackbarService) {
        this.getFlaggedMedia();
    }

    getFlaggedMedia(): void {
        this.loading = true;
        let filters: any = {
            order_by: null,
            direction: null,
            resolution_status: 'Resolved,Actioned'
        }
        if (this.category && this.category != 'All') {
            filters.category = this.category;
        }
        if (this.order_by) {
            filters.order_by = this.order_by
        }
        if (this.direction) {
            filters.direction = this.direction
        }

        this.adminService.getAllUserFeedback(this.page, this.limit, this.searchTerm, filters).subscribe(r => {
            this.count = r.count;
            this.resolvedData = r.results;
            this.loading = false;
        }, e => {
            this.loading = false;
            this.snackbarService.openSnackBar(e, 'error');
        })
    }

    checked(mediaId: any): void {
        let array: any[] = this.checkedResolvedMedia
        let index: number = array.indexOf(mediaId)
        if (index > 0) {
            array.push(mediaId)
        } else {
            array.splice(index, 1);
        }
    }

    pageChange(e: PageEvent): void {
        this.page = e.pageIndex;
        this.getFlaggedMedia();
    }

    changeOrder(event: Sort): void {
        this.order_by = event.active;

        if (this.order_by == 'box') {
            this.order_by = 'timeline'
        } else if (this.order_by == 'user') {
            this.order_by = 'creator'
        } else if (this.order_by == 'date') {
            this.order_by = 'created_date'
        }
        this.direction = event.direction;
        this.getFlaggedMedia()
    }

    keyup(): void {
        clearTimeout(this.typingTimer);
        const that: this = this;
        this.typingTimer = setTimeout((): void => {
            that.loading = true;
            this.getFlaggedMedia()
        }, 1000);
    }


    clearSearch(): void {
        this.searchTerm = '';
        this.getFlaggedMedia();
    }

    keydown(): void {
        clearTimeout(this.typingTimer);
    }
}
