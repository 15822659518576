<main *ngIf="profile" class="mediumContainer">
    <div class="d-flex flex-row">
        <button (click)="back()" (keyup.enter)="back()" aria-label="Back" mat-icon-button matTooltip="Back">
            <mat-icon>keyboard_backspace</mat-icon>
        </button>
    </div>
    <div class="profileContainer">
        <div>
            <mat-card>

                <img [src]="profile.photo ||  'assets/images/blankProfile.png'" alt="Photo of {{ profile.full_name }}"
                     class="squareImage" mat-card-image>
                <mat-card-content>
                    <p *ngIf="profile.job_title"><strong>Name</strong>: {{ profile.full_name }}
                    </p>
                    <p *ngIf="profile.role"><strong>User Role</strong>: {{ profile?.role?.name | removeBrackets }}
                    </p>
                    <p *ngIf="profile.job_title && user?.id !== id && profile.role?.role_type != 'young_person'">
                        <strong>Job Title</strong>: {{ profile.job_title }}
                    </p>
                    <p *ngIf="profile.reference_number"><strong>Liquid Logic</strong>: {{ profile.reference_number }}
                    </p>
                    <p *ngIf="profile.contact_number"><strong>Number</strong>: <a
                            href="tel:{{ profile.contact_number }}">{{ profile.contact_number }}</a></p>
                    <p *ngIf="profile.email"><strong>Email</strong>: <a
                            href="mailto:{{ profile.email }}">{{ profile.email }}</a>
                    </p>
                    <p *ngIf="profile.locality?.name"><strong>Locality</strong>: {{ profile.locality?.name }}</p>
                    <p *ngIf="profile.dob"><strong>Date of Birth</strong>: {{ profile.dob | date :'dd/MM/yyyy' }}</p>
                </mat-card-content>
                <mat-card-actions *ngIf="timelineId || user?.id == id" class="flex-space-around btnStack mt-10">
                    <button *ngIf="timelineId" [routerLink]="['/timeline', this.timelineId]" color="primary"
                            mat-raised-button>View Memory Box
                    </button>
                    <button *ngIf="user?.id == id" [routerLink]="['/settings']" color="primary" mat-raised-button>Edit
                        Profile
                    </button>
                    <button *ngIf="this.timelineId && this.id && (this.user?.role?.role_type == 'admin' || this.user?.role?.name == 'Social Worker') && this.user?.role?.can_hide_media"
                            [routerLink]="['/hiddenMedia', this.id, timelineId]" color="primary"
                            mat-raised-button>View Hidden Media
                    </button>
                </mat-card-actions>
            </mat-card>
        </div>
        <div class="d-flex flex-col gap-15">
            <mat-card *ngIf="profile.about_me">
                <mat-card-header class="flex-space-between">
                    <mat-card-title><strong>About Me</strong></mat-card-title>
                </mat-card-header>
                <mat-card-content>
                    <p>{{ profile.about_me }}</p>
                </mat-card-content>
            </mat-card>
            <mat-card
                    *ngIf="((this.user?.role?.role_type == 'adult' && this.user?.role.name != 'Birth Family' && this.permissions) || this.user?.role?.role_type == 'admin' || profile.id == user.id) && this.timelineId">
                <mat-card-header class="flex-space-between">
                    <mat-card-title>Permissions</mat-card-title>
                </mat-card-header>
                <mat-card-content>
                    <app-manage-access-table [canRequest]="user?.id == id|| this.user?.role?.can_request_permission_changes" [child]="profile.id"
                                             [permissions]="permissions"
                                             [timeline]="this.timelineId"></app-manage-access-table>
                </mat-card-content>
            </mat-card>
        </div>
    </div>
</main>

<div *ngIf="!profile" class="centerLoading">
    <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
</div>
