<main>
    <div class="d-flex flex-row justify-content-center">
        <h3 class="my-10">
            Invites
        </h3>
    </div>
    <div class="largeContainer">
        <div class="flex-row align-items-end mb-10 gap10 justify-content-end">
<!--            <div [matTooltip]="anySelected ? 'Deactivate selected users' : 'Select users to deactivate'">-->
            <!--                <button (click)="deactivate()"-->
            <!--                        [disabled]="!anySelected" color="warn" mat-raised-button>Deactivate Users-->
            <!--                </button>-->
            <!--            </div>-->
            <!--            <div [matTooltip]="anySelected  ? 'Re-Invite selected users' : 'Select users to Re-Invite'">-->
            <!--                <button (click)="reSendInvite()"-->
            <!--                        [disabled]="!anySelected" color="primary" mat-raised-button>Re-Invite users-->
            <!--                </button>-->
            <!--            </div>-->


            <button [routerLink]="['/invite']" color="primary" mat-raised-button>Invite New User</button>
            <button [routerLink]="['/bulkInvite']" color="primary" mat-raised-button>Bulk Invite Users</button>
            <button [routerLink]="['/bulkInvites']" color="primary" mat-raised-button>View Bulk Invite Jobs</button>
        </div>
        <div *ngIf="loading" class="centerLoading">
            <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
        </div>
        <div *ngIf="!loading" class="mat-elevation-z6">
            <div class="flex-row gap-15 bg-white align-items-center flex-space-around filterItems">
                <mat-form-field class="noBottomPadding w-100">
                    <mat-label>Search Invitees</mat-label>
                    <input (keydown)="keydown()" (keyup)="keyup()"
                           (keyup.enter)="getInvitees()"
                           [(ngModel)]="searchTerm"
                           matInput
                           type="text"/>
                    <button (click)="getInvitees()" [disabled]="!searchTerm"
                            aria-label="Search"
                            mat-icon-button matPrefix matTooltip="Search Invitees">
                        <mat-icon color="primary">
                            search
                        </mat-icon>
                    </button>
                    <button (click)="clearSearch()" *ngIf="searchTerm" aria-label="Clear Search" mat-icon-button
                            matSuffix>
                        <mat-icon>close</mat-icon>
                    </button>
                </mat-form-field>
                <mat-form-field class="w-100 noBottomPadding" color="primary">
                    <mat-label>Role</mat-label>
                    <mat-select [(ngModel)]="role" (ngModelChange)="getInvitees()" multiple>
                        <mat-option *ngFor="let userRole of roles"
                                    [value]="userRole.id">{{ userRole.name }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field class="w-100 noBottomPadding" color="primary">
                    <mat-label>Cohort</mat-label>
                    <mat-select [(ngModel)]="cohort" (ngModelChange)="getInvitees()" multiple>
                        <mat-option *ngFor="let userCohort of cohorts"
                                    [value]="userCohort.id">{{ userCohort.name }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field class="w-100 noBottomPadding" color="primary">
                    <mat-label>Locality</mat-label>
                    <mat-select [(ngModel)]="locality" multiple (ngModelChange)="getInvitees()">
                        <mat-option *ngFor="let local of localities"
                                    [value]="local.id">{{ local.name }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field class="w-100 noBottomPadding" color="primary"
                                matTooltip="Filter users by status of their Timelines access">
                    <mat-label>Unlinked Boxes</mat-label>
                    <select matNativeControl [(ngModel)]="orphaned" (ngModelChange)="getInvitees()">
                        <option>All</option>
                        <option [value]="true">Only unlinked</option>
                    </select>
                </mat-form-field>
            </div>
            <table [dataSource]="dataSource" class="responsiveTable" mat-table matSort [matSortActive]="matSortDefault"
                   [matSortDirection]="matSortDirectionDefault"
                   [class.openSidebar]="largerTable"
                   (matSortChange)="sortData($event)">
                <ng-container matColumnDef="checkbox">
                    <th *matHeaderCellDef mat-header-cell>
                        <mat-checkbox [(ngModel)]="allChecked" (ngModelChange)="checkAll()"
                        ></mat-checkbox>
                    </th>
                    <td *matCellDef="let user" mat-cell>
                        <mat-checkbox [(ngModel)]="user.checked" (ngModelChange)="bulkOptions($event, user)"
                        ></mat-checkbox>
                    </td>
                </ng-container>
                <ng-container matColumnDef="img">
                    <th *matHeaderCellDef mat-header-cell></th>
                    <td *matCellDef="let user" mat-cell>
                        <img [alt]="user.user?.full_name" [src]="user.user?.photo||'assets/images/blankProfile.png'"
                             class="smallProfilePicture">
                    </td>
                </ng-container>
                <ng-container matColumnDef="name">
                    <th *matHeaderCellDef mat-header-cell mat-sort-header> Name</th>
                    <td *matCellDef="let user" mat-cell><b
                            class="d-none columnTitle">Name:</b> {{ user.user?.full_name }}
                    </td>
                </ng-container>
                <ng-container matColumnDef="username">
                    <th *matHeaderCellDef mat-header-cell mat-sort-header> Username</th>
                    <td *matCellDef="let user" mat-cell><b
                            class="d-none columnTitle">Username:</b> {{ user.user?.username }}
                    </td>
                </ng-container>
                <ng-container matColumnDef="role">
                    <th *matHeaderCellDef mat-header-cell> Role</th>
                    <td *matCellDef="let user" mat-cell><b class="d-none columnTitle">Role:</b> {{ user.role }}
                    </td>
                </ng-container>
                <ng-container matColumnDef="inviteDate">
                    <th *matHeaderCellDef mat-header-cell mat-sort-header> Invite Date</th>
                    <td *matCellDef="let user" mat-cell><b class="d-none columnTitle">Invite
                        Date:</b> {{ user.invite_sent_date | date:"dd/MM/yy" }}
                    </td>
                </ng-container>
                <ng-container matColumnDef="invitesSent">
                    <th *matHeaderCellDef mat-header-cell mat-sort-header> # Invites Sent</th>
                    <td *matCellDef="let user" mat-cell><b class="d-none columnTitle"># Invites
                        Sent:</b> {{ user.number_of_invites_sent }}
                    </td>
                </ng-container>
                <ng-container matColumnDef="view">
                    <th *matHeaderCellDef mat-header-cell></th>
                    <td *matCellDef="let user" mat-cell>
                        <a mat-raised-button color="primary" class="text-center"
                           [routerLink]="['/invitee', user.id]">
                            View Invite
                        </a>
                    </td>
                </ng-container>
                <tr *matHeaderRowDef="displayedColumns" mat-header-row></tr>
                <tr *matRowDef="let row; columns: displayedColumns;" mat-row></tr>
                <tr *matNoDataRow class="mat-row">
                    <td *ngIf="searchTerm && status != 'all' else notFound"
                        [attr.colspan]="this.displayedColumns.length"
                        class="mat-cell text-center">No data matching these filters
                    </td>
                </tr>
            </table>
            <mat-paginator (page)="handlePageEvent($event)" [length]="count" [pageSizeOptions]="[10, 15, 25, 50]"
                           [pageSize]="limit" aria-label="Select page"></mat-paginator>
        </div>
    </div>
</main>
<ng-template #notFound>
    <td *ngIf="!loading" [attr.colspan]="this.displayedColumns.length" class="mat-cell text-center">No invitees found
    </td>
</ng-template>
