import {Component, EventEmitter, Inject, OnDestroy} from '@angular/core';
import {FormControl} from "@angular/forms";
import {FileInputValue} from "@ngx-dropzone/cdk";
import {DomSanitizer} from "@angular/platform-browser";
import {ContentService} from "../../_services/content.service";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {AuthService} from "../../_services/auth.service";
import {SnackbarService} from "../../_services/snackbar.service";

@Component({
    selector: 'app-media-form',
    templateUrl: './media-form.component.html',
    styleUrl: './media-form.component.scss'
})
export class MediaFormComponent implements OnDestroy {
    loadingProgress: number = 100;
    fileCtrl: FormControl<any> = new FormControl();
    cropping: boolean = false;
    files: any[] = [];
    results: any[] = [];
    rotation: number = 0;
    croppableImageTypes: string[] = ['jpeg', 'jpg', 'png', 'heic']
    otherImageTypes: string[] = ['tiff', 'tif', 'gif', 'bmp', 'heic', 'webp']
    documentTypes: string[] = ['doc', 'docx', 'pdf']
    audioTypes: string[] = ['mp3', 'wav', 'audio/mpeg', 'audio/x-m4a']
    videoTypes: string[] = ['mp4', 'avi', 'mov', 'wmv', 'mpeg', 'video/quicktime']
    memory: any;
    dynamicSettings: any;
    maxFilesize: number = 30;
    reload: EventEmitter<any> = new EventEmitter();

    constructor(@Inject(MAT_DIALOG_DATA) private data: any,
                protected dialogRef: MatDialogRef<MediaFormComponent>,
                private snackBarService: SnackbarService,
                private sanitizer: DomSanitizer,
                private contentService: ContentService,
                private authService: AuthService
    ) {

        // disable timeout for 30 minutes;
        this.authService.disableTimeout(1800000);

        if (data) {
            this.memory = data;
        }
        this.authService.getDynamicSettings().then((r): void => {
            if (r?.maxsize) {
                // get maxfilesize from dynamic settings
                this.maxFilesize = r.maxsize
            }
        });

        this.fileCtrl = new FormControl<FileInputValue>(null);
        this.fileCtrl.valueChanges.subscribe(
            async (selectedValue): Promise<void> => {
                for (let index = 0; index < selectedValue.length; index++) {
                    this.loadingProgress = (100 * index) / selectedValue.length;
                    let filesize: number = parseFloat(((selectedValue[index].size / 1024) / 1024).toFixed(1)); // MB

                    let created_at = new Date(Date.now() + Number((index + 1) + '000'));
                    let mediaItem = {
                        // title: this.removeExtension(selectedValue[index].name),
                        title: '',
                        preview: '',
                        description: '',
                        coverPhoto: false,
                        thumbnail: '',
                        originalFile: selectedValue[index],
                        type: 'image',
                        isHeic: selectedValue[index].type == 'image/heic',
                        created_at: created_at.toISOString()
                    }

                    let fileSrc: any;
                    await this.checkFileType(selectedValue[index]).then(function (response): void {
                        if (response) {
                            mediaItem.type = response.type
                            if (response.type != 'image') {
                                fileSrc = '/assets/images/' + response.type + '.png'
                                if (response.type == 'video' || response.type == 'audio') {
                                    mediaItem.preview = URL.createObjectURL(selectedValue[index]);
                                }
                            } else {
                                fileSrc = response.file;
                            }
                            if (response.type == 'video') {

                            }
                        } else {
                            console.error('No response received from fil type checker')
                        }
                    })

                    const i: number = this.files.findIndex((x: any): boolean => x.originalFile === selectedValue[index]);

                    // Files seems to persist after each upload. if I upload twice the files from the previous upload appear also.
                    // clearing doesn't seem to work so checking for duplicates. Would much rather clear though.
                    this.results = [];
                    if (i < 0) {
                        if (filesize > this.maxFilesize) {
                            this.results.push({
                                name: 'File too large (' + filesize + 'MB) max file size is ' + this.maxFilesize + 'MB',
                                color: 'warn'
                            })
                        } else if (fileSrc) {
                            mediaItem.thumbnail = fileSrc;
                            if (!this.files.includes(mediaItem)) {
                                this.files.push(mediaItem)
                            }
                        } else {
                            this.results.push({
                                name: selectedValue[index].name + ' is not an allowed file type',
                                color: 'warn'
                            })
                        }
                    }
                    if (index == (selectedValue.length - 1)) {
                        this.loadingProgress = 100;
                    }
                }
            }
        );


    }

    ngOnDestroy(): void {
        this.authService.stopTimeoutDisable()
    }

    async checkFileType(file: any): Promise<any> {
        return await new Promise((resolve, reject): void => {
            if (this.hasFileType(file.type, this.documentTypes)) {
                resolve({type: 'document'})
            } else if (this.hasFileType(file.type, this.audioTypes)) {
                resolve({type: 'audio'})
            } else if (this.hasFileType(file.type, this.videoTypes)) {
                resolve({type: 'video'})
            } else if (this.hasFileType(file.type, this.croppableImageTypes) || this.hasFileType(file.type, this.otherImageTypes)) {
                let reader: FileReader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = (): void => {
                    resolve({type: 'image', file: reader.result})
                };
            } else {
                resolve(null)
            }
        })
    }

    rotateCanvas(): void {
        this.rotation++
    }

    initiateCrop(file: any): void {
        this.cropping = true;
        file.cropping = true;
        var myDiv: HTMLElement | null = document.getElementById('content');
        if (myDiv) {
            myDiv.scrollTop = 0;
        }
    }

    imageCropped(file: any, event: any): void {
        file.croppedFile = event.blob;
        file.tempFile = this.sanitizer.bypassSecurityTrustUrl(event.objectUrl);

    }

    saveCrop(file: any): void {
        file.thumbnail = file.tempFile;
        file.cropped = true;
        this.rotation = 0;
        file.cropping = false;
        this.cropping = false;
    }

    cancelCrop(file: any): void {
        this.rotation = 0;
        file.tempFile = null;
        file.croppedFile = null;
        file.cropping = false;
        file.cropped = false;
        this.cropping = false;
    }

    removeResult(result: any): void {
        const index: number = this.results.indexOf(result);
        if (index > -1) {
            this.results.splice(index, 1);
        }
    }

    hasFileType(fileType: string, typeList: string[]): boolean {
        let included: boolean = false;
        for (let type of typeList) {
            if (fileType && fileType?.toLowerCase().includes(type)) {
                included = true;
            }
        }
        return included
    }

    saveMedia(): void {
        for (let index = 0; index < this.files.length; index++) {
            // show loading progress bar with percentage complete
            this.loadingProgress = (100 * index) / this.files.length;
            let body: { title: string, description: string, type: string, cropped: any, added_at: string } = {
                title: this.files[index].title,
                description: this.files[index].description,
                type: this.files[index].type,
                cropped: null,
                added_at: this.files[index].created_at
            }
            if (this.files[index].cropped) {
                body.cropped = 1;
            }
            if (!this.files[index].uploaded) {
                this.contentService.uploadMedia(this.memory.id, body, (this.files[index].croppedFile || this.files[index].originalFile)).subscribe(r => {
                    this.files[index].uploaded = true;
                    let allComplete: boolean = true
                    for (let i = 0; i < this.files.length; i++) {
                        if (!this.files[i].uploaded) {
                            allComplete = false;
                            break
                        }
                    }
                    if (allComplete) {
                        this.loadingProgress = 100;
                        this.snackBarService.openSnackBar('Media Successfully Uploaded', 'success')
                        this.reload.emit();
                        this.dialogRef.close();
                    }
                }, e => {
                    if (index == (this.files.length - 1)) {
                        this.loadingProgress = 100;
                    }
                    console.error(e)
                    this.snackBarService.openSnackBar(body.title + ':' + e, 'error')
                })
            } else {
                let allComplete: boolean = true
                for (let i = 0; i < this.files.length; i++) {
                    if (!this.files[i].uploaded) {
                        allComplete = false;
                        break
                    }
                }
                if (allComplete) {
                    this.loadingProgress = 100;
                    this.snackBarService.openSnackBar('Media Successfully Uploaded', 'success')
                    this.reload.emit();
                    this.dialogRef.close();
                }
            }
        }
    }

    setDescription(file: any, event: any): void {
        file.description = event
    }

    setTitle(file: any, event: any): void {
        file.title = event
    }


    removeExtension(fileName: string): string | undefined {
        return fileName.split('.').shift();
    }
}
