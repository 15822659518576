import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {LoginComponent} from "./login/login.component";
import {TimelineComponent} from "./timeline/timeline.component";
import {AuthviewComponent} from "./authview/authview.component";
import {AuthGuard} from "./_guards/auth.guard";
import {AdminGuard} from "./_guards/admin.guard";
import {NotfoundComponent} from "./notfound/notfound.component";
import {SettingsComponent} from "./settings/settings.component";
import {MemoryComponent} from "./memory/memory.component";
import {PeopleComponent} from "./people/people.component";
import {ProfileComponent} from "./profile/profile.component";
import {FaqsComponent} from "./trainingCentre/faqs/faqs.component";
import {TermsComponent} from "./terms/terms.component";
import {PrivacyComponent} from "./privacy/privacy.component";
import {TimeoutComponent} from "./timeout/timeout.component";
import {NoAccessComponent} from "./noAccess/noAccess.component";
import {FavouritesComponent} from "./favourites/favourites.component";
import {firstLoginGuard} from "./_guards/first-login.guard";
import {SignupComponent} from "./signup/signup.component";
import {PassthroughComponent} from "./passthrough/passthrough.component";
import {AdminViewComponent} from "./admin/admin-view/admin-view.component";
import {UsersComponent} from "./admin/users/users.component";
import {ListFeedbackComponent} from "./admin/list-feedback/list-feedback.component";
import {PasswordResetComponent} from "./password-reset/password-reset.component";
import {AcceptInviteComponent} from "./accept-invite/accept-invite.component";
import {InviteesComponent} from "./admin/invitees/invitees.component";
import {FlaggedMediaComponent} from "./admin/flagged-media/flagged-media.component";
import {UserComponent} from "./admin/users/user/user.component";
import {ResolvedFeedbackComponent} from "./admin/resolved-feedback/resolved-feedback.component";
import {InviteeComponent} from "./admin/invitees/invitee/invitee.component";
import {CohortsComponent} from "./admin/cohorts/cohorts.component";
import {LocationsComponent} from "./admin/locations/locations.component";
import {PermissionRequestComponent} from "./admin/permission-requests/permission-request/permission-request.component";
import {PermissionRequestsComponent} from "./admin/permission-requests/permission-requests.component";
import {DownloadTimelineComponent} from "./download-timeline/download-timeline.component";
import {ExportRequestsComponent} from "./admin/export-requests/export-requests.component";
import {ReportingComponent} from "./admin/reporting/reporting.component";
import {LocationComponent} from "./admin/locations/location/location.component";
import {CohortComponent} from "./admin/cohorts/cohort/cohort.component";
import {ResourcesComponent} from "./trainingCentre/resources/resources.component";
import {VideoguidesComponent} from "./trainingCentre/videoguides/videoguides.component";
import {WrittenGuidesComponent} from "./trainingCentre/written-guides/written-guides.component";
import {StatusPageComponent} from "./status-page/status-page.component";
import {UserInviteComponent} from "./admin/user-invite/user-invite.component";
import {HiddenMediaComponent} from "./hidden-media/hidden-media.component";
import {ReportsComponent} from "./admin/reports/reports.component";
import {InviteJobsComponent} from "./admin/user-invite/invite-jobs/invite-jobs.component";
import {InviteJobComponent} from "./admin/user-invite/invite-job/invite-job.component";
import {HiddenMediaDetailsComponent} from "./hidden-media/hidden-media-details/hidden-media-details.component";
import {DeletionQueueComponent} from "./admin/deletion-queue/deletion-queue.component";

const routes: Routes = [
    {path: 'passthrough', component: PassthroughComponent},
    {path: '', redirectTo: 'timeline', pathMatch: 'full'},
    {path: 'login', component: LoginComponent, title: 'VMB - Login'},
    {path: 'password/reset/:token', component: PasswordResetComponent, title: 'VMB - Password Reset'},
    {path: '404', component: NotfoundComponent, title: 'VMB - 404 NOT FOUND'},
    {path: 'noAccess', component: NoAccessComponent, title: 'VMB - Access Denied'},
    {path: 'terms', component: TermsComponent, title: 'VMB - Terms and Conditions'},
    {path: 'invite/:uid/:token', component: AcceptInviteComponent, title: 'VMB - Accept Invite'},
    {path: 'privacy', component: PrivacyComponent, title: 'VMB - Privacy Policy'},
    {path: 'timeout', component: TimeoutComponent, title: 'VMB - Timeout'},
    {path: 'statusPage', component: StatusPageComponent, title: 'VMB - Status'},
    {path: 'signup', component: SignupComponent, title: "VMB - Sign Up", canActivate: [AuthGuard]},
    {
        path: '', component: AuthviewComponent, children: [
            {path: 'timeline', component: TimelineComponent, title: 'VMB - Timeline'},
            {path: 'timeline/:id', component: TimelineComponent, title: 'VMB - Timeline'},
            {path: 'settings', component: SettingsComponent, title: 'VMB - Settings'},
            {path: 'favourites', component: FavouritesComponent, title: 'VMB - Favourites'},
            {path: 'favourites/:id', component: FavouritesComponent, title: 'VMB - Favourites'},
            {path: 'hiddenMedia/:id/:timeline', component: HiddenMediaComponent, title: 'VMB - Hidden Media'},
            {path: 'memory/:id', component: MemoryComponent, title: 'VMB - Memory'},
            {path: 'memory/:id/:index', component: MemoryComponent, title: 'VMB - Memory'},
            {path: 'people', component: PeopleComponent, title: 'VMB - People'},
            {path: 'profile/:id', component: ProfileComponent, title: 'VMB - Profile'},
            {path: 'profile/:id/:timelineId', component: ProfileComponent, title: 'VMB - Profile'},
            {path: 'faqs', component: FaqsComponent, title: "VMB - FAQs"},
            {path: 'resources', component: ResourcesComponent, title: "VMB - Resources"},
            {path: 'videoGuides', component: VideoguidesComponent, title: "VMB - Video Guides"},
            {path: 'writtenGuides', component: WrittenGuidesComponent, title: "VMB - Written Guides"},
            {path: 'download/timeline/:uuid', component: DownloadTimelineComponent, title: "VMB - Download Timeline"},
            {path: 'hiddenMedia/:id', component: HiddenMediaDetailsComponent, title: 'VMB - Hidden Media'},

        ], canActivate: [AuthGuard, firstLoginGuard],
    },
    {
        path: '', component: AdminViewComponent, children: [
            {path: 'users', component: UsersComponent, title: 'VMB - Users'},
            {path: 'user/:id/:timelineId', component: UserComponent, title: 'VMB - Users'},
            {path: 'user/:id', component: UserComponent, title: 'VMB - Users'},
            {path: 'invitees', component: InviteesComponent, title: 'VMB - Invitees'},
            {path: 'invite', component: InviteeComponent, title: 'VMB - Invitees'},
            {path: 'reporting', component: ReportingComponent, title: 'VMB - Reporting'},
            {path: 'reports', component: ReportsComponent, title: 'VMB - Reports'},
            {path: 'invitee/:id', component: InviteeComponent, title: 'VMB - Invitees'},
            {path: 'profileSettings', component: SettingsComponent, title: 'VMB - Settings'},
            {path: 'flaggedMedia', component: ListFeedbackComponent, title: "VMB - Flagged Media"},
            {path: 'hiddenMedia', component: HiddenMediaComponent, title: "VMB - Hidden Media"},
            {path: 'resolved', component: ResolvedFeedbackComponent, title: "VMB - Flagged Media History"},
            {path: 'flaggedMedia/:id', component: FlaggedMediaComponent, title: 'VMB - Flagged Media'},
            {path: 'cohorts', component: CohortsComponent, title: 'VMB - Cohorts'},
            {path: 'cohort/:id', component: CohortComponent, title: 'VMB - Cohorts'},
            {path: 'locations', component: LocationsComponent, title: 'VMB - Locations'},
            {path: 'location/:id', component: LocationComponent, title: 'VMB - Locations'},
            {path: 'exports', component: ExportRequestsComponent, title: 'VMB - Export Requests'},
            {path: 'permissionRequests', component: PermissionRequestsComponent, title: 'VMB - Permission Requests'},
            {path: 'permissionRequest/:id', component: PermissionRequestComponent, title: 'VMB - Permission Request'},
            {path: 'adminFaqs', component: FaqsComponent, title: "VMB - FAQs"},
            {path: 'adminResources', component: ResourcesComponent, title: "VMB - Resources"},
            {path: 'adminVideoGuides', component: VideoguidesComponent, title: "VMB - Video Guides"},
            {path: 'adminWrittenGuides', component: WrittenGuidesComponent, title: "VMB - Written Guides"},
            {path: 'bulkInvite', component: UserInviteComponent, title: "VMB - Bulk Invite"},
            {path: 'bulkInvite/:id', component: InviteJobComponent, title: "VMB - Bulk Invite Job"},
            {path: 'bulkInvites', component: InviteJobsComponent, title: "VMB - Bulk Invite Jobs"},
            {path: 'deletionQueue', component: DeletionQueueComponent, title: "VMB - Deletion Queue"},
        ], canActivate: [AuthGuard, AdminGuard, firstLoginGuard],
    },
    {path: '**', redirectTo: '404'}
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {
        scrollPositionRestoration: 'enabled'
    })],
    exports: [RouterModule]
})
export class AppRoutingModule {

}
