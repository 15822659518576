import {Component, HostListener, OnDestroy, OnInit} from '@angular/core';
import {AuthService} from "../_services/auth.service";
import {MatDrawerMode} from "@angular/material/sidenav";
import {MatDialog} from "@angular/material/dialog";
import {ManageAccessComponent} from "../shared/manage-access/manage-access.component";
import {FeedbackComponent} from "../shared/feedback/feedback.component";
import {ContentService} from "../_services/content.service";
import {SnackbarService} from "../_services/snackbar.service";
import {GreetingPipe} from "../_pipes/greeting.pipe";
import {NotificationsService} from "../_services/notifications.service";

@Component({
    selector: 'app-authview',
    templateUrl: './authview.component.html',
    styleUrls: ['./authview.component.scss'],
})
export class AuthviewComponent implements OnInit, OnDestroy {
    user: any;
    isMobile: boolean = false;
    mode: MatDrawerMode = 'over';
    sideNav: boolean = false;
    notificationNavOpened: boolean = false;
    hijacking: boolean = false;
    notificationsCount: string | null = null;
    timelineId: any;
    protected readonly GreetingPipe = GreetingPipe;
    interval;

    constructor(private authService: AuthService,
                public dialog: MatDialog,
                public contentService: ContentService,
                private notificationService: NotificationsService,
                private snackBarService: SnackbarService) {
        this.user = this.authService.getUser();

        if (localStorage.getItem('hijacked')) {
            this.hijacking = true;
        }
        this.getNotificationCount();
        if (this.user?.role?.role_type == 'young_person') {
            // if child then hide timeline if no timelines present
            this.contentService.viewMyTimeline().subscribe(r => {
                this.timelineId = r.id
            }, e => {
                console.error(e)
            })
        }

        let that = this;
        this.interval = setInterval(function (): void {
            // Check user every minute to ensure the user hasn't timed out or been forcibly timed out by an admin
            if (that.authService.isLoggedIn()) {
                that.authService.userHeartbeat().subscribe(r => {
                }, e => {
                    console.error(e)
                })
            }
        }, 60000);
    }

    getNotificationCount(): void {
        this.notificationService.getNotificationsCount().subscribe(r => {
            if (r.count > 99) {
                this.notificationsCount = '99+';
            } else {
                this.notificationsCount = JSON.stringify(r.count);
            }
        }, e => {
            console.error(e);
        })
    }

    ngOnDestroy(): void {
        clearTimeout(this.interval);
    }

    @HostListener('window:resize', ['$event'])
    onResize(event: any): void {
        this.isMobile = window.innerWidth <= 600;
    }

    ngOnInit(): void {
        this.isMobile = window.innerWidth <= 600;
    }

    logout(): void {
        this.authService.logout();
    }

    feedback(): void {
        if (this.dialog.openDialogs.length == 0) {
            this.dialog.open(FeedbackComponent, {});
        }
    }

    manageAccess(): void {
        if (this.timelineId && this.user.id && this.dialog.openDialogs.length == 0) {
            this.dialog.open(ManageAccessComponent, {
                data: {context: 'My Timeline', id: this.timelineId, userId: this.user.id},
                panelClass: 'fullWidthWindow',
            });
        }
    }

    openNotificationNav(): void {
        this.sideNav = false;
        this.notificationNavOpened = true;
    }
}
