<mat-dialog-content [formGroup]="mediaForm" role="dialog" aria-modal="true">
    <h1 class="f-18">
        Record New Memory
    </h1>
    <div *ngIf="!takingPhoto && !audioRecord && !videoRecord && !file.video && !file.image"
         class="flex-row justify-content-center gap-15">
        <button (click)="recordVideo()" (keyup.enter)="recordVideo()" color="primary" mat-raised-button
                *ngIf="!this.isMobile">
            Record Video
        </button>
        <button (click)="fileInput.click()" (keyup.enter)="fileInput.click()" color="primary" mat-raised-button
                *ngIf="this.isMobile">
            <span>Record Video</span>
            <input #fileInput (change)="onVideoFileChanged($event)"
                   accept="video/*" capture="environment" class="d-none"
                   type="file"/>
        </button>
        <button (click)="recordAudio()" (keyup.enter)="recordAudio()" color="primary" mat-raised-button>
            Record Audio
        </button>
        <button (click)="takePhoto()" (keyup.enter)="takePhoto()" color="primary" mat-raised-button
                *ngIf="!this.isMobile">
            Take a Photo
        </button>
        <button (click)="fileInput.click()" (keyup.enter)="fileInput.click()" color="primary" mat-raised-button
                *ngIf="this.isMobile">
            <span>Take a Photo</span>
            <input #fileInput (change)="onPhotoFileChanged($event)"
                   accept="image/*" capture="environment" class="d-none"
                   type="file"/>
        </button>

    </div>
    <div *ngIf="(file.image || file.video)&& this.isMobile" class="outputRow">
        <img *ngIf="file.image" alt="" [src]="file.image">
        <video *ngIf="file.video" [src]="file.video" controls>Video stream not
            available.
        </video>
    </div>
    <div [hidden]="!takingPhoto" class="w-100">
        <div class="photoRow justify-content-center gap-15">
            <div class="camera text-center">
                <video [style.height]="height +'px'" [style.width]="width +'px'" id="video">Video stream not
                    available.
                </video>
                <button (click)="takePicture()" color="primary" mat-raised-button>Take photo</button>
            </div>
            <canvas [style.height]="height +'px'" [style.width]="width +'px'" id="canvas"></canvas>
            <div class="output">
                <img [style.height]="height +'px'" [style.width]="width +'px'"
                     alt="The screen capture will appear in this box." id="photo"/>
            </div>
        </div>
    </div>
    <div *ngIf="audioRecord" class="flex-col align-items-center justify-content-center">
        <app-audio-player *ngIf="file.audio && file.type == 'audio'" [file]="file.audio"
                          class="w-100"></app-audio-player>
        <button (click)="recordingAudioStop()" *ngIf="audioRecording" color="primary" mat-raised-button>Stop Recording
            <mat-icon class="recordingIcon">fiber_manual_record</mat-icon>
        </button>
        <button (click)="recordAudio()" *ngIf="!audioRecording" color="primary" mat-raised-button>
            Record Audio
        </button>
    </div>
    <div *ngIf="videoRecord" class="flex-col align-items-center justify-content-center">
        <video (contextmenu)="false" *ngIf="file.video && file.type == 'video'" class="videoControl mb-10" controls
               controlsList="nodownload noplaybackrate">
            <source [src]="file.video">
            Your browser does not support the video tag.
        </video>

        <button (click)="recordingVideoStop()" *ngIf="videoRecording" color="primary" mat-raised-button>Stop Recording
            <mat-icon class="recordingIcon">fiber_manual_record</mat-icon>
        </button>
        <button (click)="recordVideo()" *ngIf="!videoRecording" color="primary" mat-raised-button>
            Record Video
        </button>
    </div>


    <mat-form-field appearance="outline" class="w-100 pt-10">
        <mat-label>Title</mat-label>
        <input formControlName="title" matInput maxlength="100" placeholder="Title">
    </mat-form-field>
    <mat-form-field appearance="outline" class="w-100">
        <mat-label>Description</mat-label>
        <input formControlName="description" matInput maxlength="255" placeholder="Description">
    </mat-form-field>
</mat-dialog-content>
<mat-dialog-actions class="flex-space-around">
    <button color="warn" mat-raised-button (click)="dialogRef.close()">
        Cancel
    </button>
    <button (click)="saveMedia()" [disabled]="loading || !this.file.file" color="primary"
            mat-raised-button>
        Save
        <mat-icon *ngIf="loading">
            <mat-spinner color="primary"></mat-spinner>
        </mat-icon>
    </button>
</mat-dialog-actions>
<mat-card-footer *ngIf="loading">
    <mat-progress-bar color="primary" mode="indeterminate"></mat-progress-bar>
</mat-card-footer>
