import {AfterViewInit, Component, HostListener, ViewChild} from '@angular/core';
import {AdminService} from "../../../_services/admin.service";
import {ActivatedRoute, Router} from "@angular/router";
import {SnackbarService} from "../../../_services/snackbar.service";
import {MatTableDataSource} from "@angular/material/table";
import {MatSort} from "@angular/material/sort";
import {MatDialog, MatDialogRef} from "@angular/material/dialog";
import {UserSelectComponent} from "../../shared/user-select/user-select.component";
import {ConfirmDialogComponent} from "../../../shared/confirm-dialog/confirm-dialog.component";
import {ConfirmationDialogData} from "../../../_types/shared";
import {EditNameComponent} from "../../shared/edit-name/edit-name.component";

@Component({
    selector: 'app-cohort',
    templateUrl: './cohort.component.html',
    styleUrl: './cohort.component.scss'
})
export class CohortComponent implements AfterViewInit {
    id: any;
    loading: boolean = true;
    cohort: any;
    shiftIsPressed: boolean = false;
    displayedColumns: string[] = ['checkbox', 'img', 'name', 'role'];
    dataSource: MatTableDataSource<any> = new MatTableDataSource();
    @ViewChild(MatSort) sort: MatSort | null = null;
    lastSelect: any = null;
    allChecked: boolean = false;
    typingTimer: any;
    searchTerm: any;
    role: string = 'All'
    status: string = 'All'
    order: any;
    anySelected: boolean = false;
    cohortData: any;

    constructor(private adminService: AdminService,
                private route: ActivatedRoute,
                private snackbarService: SnackbarService,
                private router: Router,
                public dialog: MatDialog,) {
        this.route.params
            .subscribe(params => {
                this.id = params['id'];
                if (this.id) {
                    this.getCohorts();
                }
                this.adminService.cohortReport(this.id).subscribe(r => {
                    this.cohortData = r;
                }, e => {
                    console.error(e);
                    this.snackbarService.openSnackBar(e, 'error');
                })
            }, error => {
                this.snackbarService.openSnackBar(error, 'error')
                console.error(error);
                this.loading = false;
            });


    }

    @HostListener('document:keydown', ['$event'])
    handleKeyboardEvent(event: KeyboardEvent): void {
        if (event.shiftKey) {
            this.shiftIsPressed = true;
        }
    }

    @HostListener('document:keyup', ['$event'])
    handleKeyboardUpEvent(event: KeyboardEvent): void {
        if (event.shiftKey) {
            this.shiftIsPressed = false;
            this.lastSelect = null;
        }
    }

    ngAfterViewInit(): void {
        this.dataSource.sort = this.sort;
    }

    getCohorts(): void {
        this.adminService.cohortReport(this.id).subscribe(r => {
            this.cohort = r;
            this.loading = false;
            let users: any[] = [];
            for (let user of r.users) {
                users.push(user[0])
                //  TODO remove when dylan fixes nested array issue
            }
            this.dataSource = new MatTableDataSource(users);
        }, e => {
            console.error(e);
            this.loading = false;
        })
    }

    checkAll(): void {
        this.checkIfAnySelected();
        if (this.anySelected) {
            if (this.allChecked) {
                // if all selected select none
                this.allChecked = false;
            } else {
                // if some selected select all
                this.allChecked = true;
            }
        } else {
            // if none selected select all
            this.allChecked = true;
        }
        for (let i: number = 0; i < this.dataSource.data.length; i++) {
            this.dataSource.data[i].checked = this.allChecked
        }
        this.checkIfAnySelected();
    }

    bulkOptions(event: any, user: any): void {

        // Some Shift business
        if (this.shiftIsPressed && !this.lastSelect) {
            this.lastSelect = user.index;
        } else if (this.shiftIsPressed && this.lastSelect) {
            this.shiftIsPressed = false;
            //  shift was set for second time
            //  find out value of previous click then set all users between with the same value
            let i: number = this.dataSource.data.findIndex(x => x.index === this.lastSelect);
            let lastSelectValue: boolean = this.dataSource.data[i].checked;
            // list the indexes of the 2 shifted users
            let indexes: any[] = [(this.lastSelect - 1), (user.index - 1)]
            // Order indexes lowes first
            indexes = indexes.sort();
            for (let i: number = 0; i < this.dataSource.data.length; i++) {
                if (i >= indexes[0] && i <= indexes[1]) {
                    this.dataSource.data[i].checked = lastSelectValue
                }
            }
            this.lastSelect = false;
        }
        let i: number = this.dataSource.data.findIndex(x => x.id === user.id);
        this.dataSource.data[i].checked = event;
        this.checkIfAnySelected();
    }

    removeUsers(): void {
        const dialogRef: MatDialogRef<ConfirmDialogComponent> = this.dialog.open<ConfirmDialogComponent, ConfirmationDialogData>(ConfirmDialogComponent, {
            panelClass: 'mediumWindow',
            data: {
                showSubmitBtn: true,
                showCancelBtn: true,
                message: 'Are you sure you want to remove the selected users from this cohort?',
                title: 'Delete Cohort',
                showHeader: true
            }
        });
        dialogRef.afterClosed().subscribe((resp): void => {
            if (resp) {
                let user_ids: number[] = [];
                for (let user of this.dataSource.data) {
                    if (user.checked) {
                        user_ids.push(user.id)
                    }
                }
                this.adminService.removeUsersFromCohort({
                    cohort_ids: [this.cohort.cohort?.id],
                    user_ids: user_ids
                }).subscribe(r => {
                    this.snackbarService.openSnackBar('Users removed successfully', 'success');
                    this.getCohorts();
                    this.anySelected = false;
                }, e => {
                    console.error(e)
                    this.snackbarService.openSnackBar(e, 'error');
                })
            }
        });
    }


    checkIfAnySelected(): void {
        this.anySelected = false;
        this.allChecked = true;
        for (let i: number = 0; i < this.dataSource.data.length; i++) {
            if (this.dataSource.data[i].checked) {
                this.anySelected = true;
            } else {
                this.allChecked = false;
            }
        }
    }

    delete(): void {
        const dialogRef: MatDialogRef<ConfirmDialogComponent> = this.dialog.open<ConfirmDialogComponent, ConfirmationDialogData>(ConfirmDialogComponent, {
            panelClass: 'mediumWindow',
            data: {
                showSubmitBtn: true,
                showCancelBtn: true,
                message: 'Are you sure you want to delete ' + this.cohort?.cohort.name,
                title: 'Delete ' + this.cohort?.cohort.name,
                showHeader: true
            }
        });
        dialogRef.afterClosed().subscribe((resp): void => {
            if (resp) {
                this.adminService.deleteCohort(this.cohort?.cohort.id).subscribe(r => {
                    this.router.navigate(['/cohorts']);
                }, e => {
                    console.error(e);
                    this.snackbarService.openSnackBar(e, 'error')
                })
            }
        });
    }

    addUsersModal(): void {
        if (this.dialog.openDialogs.length == 0) {
            const dialogRef: MatDialogRef<UserSelectComponent> = this.dialog.open(UserSelectComponent, {
                data: {
                    title: 'Add users to ' + (this.cohort?.cohort?.name || 'Cohort'),
                    filter: {name: 'cohort', value: '!' + this.cohort?.cohort.id},
                    bulkInvite: true
                },
            });
            dialogRef.afterClosed().subscribe((resp): void => {
                if (resp) {
                    this.addUsers(resp)
                }
            });
        }
    }

    addUsers(users: any[]): void {
        let user_ids: number[] = [];
        for (let user of users) {
            user_ids.push(user.id)
        }
        this.adminService.addUsersToCohort({
            cohort_ids: [this.cohort.cohort?.id],
            user_ids: user_ids
        }).subscribe(r => {
            this.snackbarService.openSnackBar('Users added successfully', 'success');
            this.getCohorts();
        }, e => {
            console.error(e)
            this.snackbarService.openSnackBar(e, 'error');
        })
    }

    editCohortModal(): void {
        const dialogRef: MatDialogRef<EditNameComponent> = this.dialog.open(EditNameComponent, {
            data: {
                title: 'Edit Cohort Name (' + this.cohort?.cohort?.name + ')',
                name: this.cohort?.cohort?.name
            },
        });
        dialogRef.afterClosed().subscribe((resp): void => {
            if (resp) {
                this.adminService.editCohort(resp, this.cohort.cohort?.id).subscribe(r => {
                    this.snackbarService.openSnackBar('Name successful updated', 'success');
                    this.cohort.cohort.name = resp;
                }, e => {
                    console.error(e);
                    this.snackbarService.openSnackBar(e, 'error');
                })
            }
        });
    }

    back(): void {
        history.back();
    }
}
