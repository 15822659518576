import {Component, EventEmitter, Input, OnChanges, Output} from '@angular/core';
import {ContentService} from "../../_services/content.service";
import {DomSanitizer} from "@angular/platform-browser";

@Component({
    selector: 'app-media-display',
    templateUrl: './media-display.component.html',
    styleUrl: './media-display.component.scss'
})
export class MediaDisplayComponent implements OnChanges {
    maxBars: any = 100;
    width: any = '4px';
    @Input() file: any;
    @Input() small: boolean = false;
    @Input() croppable: boolean = false;
    @Output() initiateCrop: EventEmitter<any> = new EventEmitter();
    @Output() viewImage: EventEmitter<any> = new EventEmitter();
    @Output() pauseSlideshow: EventEmitter<boolean> = new EventEmitter();
    croppableImageTypes: string[] = ['jpeg', 'jpg', 'png', 'heic', 'blob']
    browser: string = '';
    loading: boolean = false;

    constructor(public contentService: ContentService,
                private sanitizer: DomSanitizer,) {
        if (this.small) {
            this.maxBars = null;
            this.width = null;
        }
        this.browser = this.getBrowserName();
    }

    ngOnChanges(): void {
        if (this.file.document) {
            if (this.browser !== 'safari') {
                // Remove the ability to download the file to stop s3 timeout issue
                this.file.safeDocument = this.sanitizer.bypassSecurityTrustResourceUrl(this.file.document + "#toolbar=0&navpanes=0");
            } else if (!this.file.thumbnail) {
                // Safari cant use bypassSecurityTrustResourceUrl for some reason so just show them a thumbnail if one doesn't already exist.
                this.file.thumbnail = 'assets/images/document.png'
            }
        }

        if (this.file && !this.small) {
            // TODO If you open lightbox and spam move then the image sticks.
            this.contentService.checkMedia(this.file).then((r): void => {
                this.file = r;
            }).catch((e): void => {
                console.error(e);
            });
        }
    }

    getBrowserName(): string {
        const agent: string = window.navigator.userAgent.toLowerCase()
        switch (true) {
            case agent.indexOf('edge') > -1:
                return 'edge';
            case agent.indexOf('opr') > -1 && !!(<any>window).opr:
                return 'opera';
            case agent.indexOf('chrome') > -1 && !!(<any>window).chrome:
                return 'chrome';
            case agent.indexOf('trident') > -1:
                return 'ie';
            case agent.indexOf('firefox') > -1:
                return 'firefox';
            case agent.indexOf('safari') > -1:
                return 'safari';
            default:
                return 'other';
        }
    }

    correctDocumentFileType(fileURL: string): boolean {
        return fileURL.toLocaleLowerCase().includes('pdf') || fileURL.toLocaleLowerCase().includes('jpg') || fileURL.toLocaleLowerCase().includes('png')
    }

    hasFileType(fileUrl: any, typeList: string[]): boolean {
        let included: boolean = false;
        if (typeof fileUrl == 'string') {

            let extension: string | undefined = fileUrl.split(/[#?]/)[0].split('.').pop()?.trim();
            if (extension) {
                for (let type of typeList) {
                    if (extension.toLowerCase().includes(type)) {
                        included = true;
                    }
                }
            }
        }
        return included
    }

    checkIfSmallBeforePause(): void {
        if (!this.small) {
            this.pauseSlideshow.emit(true);
        }
    }

    checkUrl(url: string, key: string, video: HTMLVideoElement): void {
        let that: this = this;

        this.checkIfSmallBeforePause();
        // Check if video already loaded (means s3 hasn't expired)
        if (video.readyState < 3) {
            this.loading = true
            // Check if s3 has expired
            this.contentService.checkIfMediaUrlExpired(url, key, this.file.id).then(function (response): void {
                if (response && response != url) {
                    that.file[key] = response;
                    if (video) {
                        // Set new url then load the video.
                        setTimeout((): void => {
                            video.load();
                            video.addEventListener('canplay', playVideo);
                        }, 50);
                    }
                } else {
                    that.loading = false;
                    console.error('No response received from validity checker')
                }
            }).catch(e => {
                console.error(e)
            });
        }

        function playVideo(): void {
            // Play video once it loads
            video.play()

            // Remove event listener so it doesnt happen every pause -> play
            video.removeEventListener('canplay', playVideo)
            that.loading = false;
        }
    }

    mediaClick(event: any): void {
        if (this.small) {
            event?.stopPropagation();
            this.viewImage.emit()
        }
    }
}
