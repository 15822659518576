import {Component} from '@angular/core';
import {AuthService} from "../_services/auth.service";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ActivatedRoute, Router} from "@angular/router";
import {SnackbarService} from "../_services/snackbar.service";

@Component({
    selector: 'app-password-reset',
    templateUrl: './password-reset.component.html',
    styleUrl: './password-reset.component.scss'
})
export class PasswordResetComponent {
    councilLogo: string | null = null;
    authorityName: string | null = null;
    loading: boolean = false;
    passwordForm: FormGroup;
    token: string | null = null;

    constructor(private readonly formBuilder: FormBuilder,
                private authService: AuthService,
                private route: ActivatedRoute,
                private readonly router: Router,
                private snackbarService: SnackbarService) {
        this.route.params
            .subscribe(params => {
                this.token = params['token'];
            }, error => {
                this.snackbarService.openSnackBar(error, 'error')
                console.error(error);
            });
        this.passwordForm = this.formBuilder.group({
            password: ['', [Validators.required, Validators.minLength(8), Validators.pattern('^(?=.*\\d)(?=.*[a-z])(?=.*[A-Z])(?=.*\\W)(?!\\s).{8,}$')]],
            confirmPassword: ['', [Validators.required, Validators.minLength(8), Validators.pattern('^(?=.*\\d)(?=.*[a-z])(?=.*[A-Z])(?=.*\\W)(?!\\s).{8,}$')]],
        })
        this.authService.getDynamicSettings().then((r): void => {
            this.councilLogo = r.authority_logo;
            this.authorityName = r.authority_name;
            if (r.minimum_password_length) {
                this.passwordForm.controls['password'].setValidators([Validators.required, Validators.minLength(r.minimum_password_length), Validators.pattern('^(?=.*\\d)(?=.*[a-z])(?=.*[A-Z])(?=.*\\W)(?!\\s).{' + r.minimum_password_length + ',}$')]);
                this.passwordForm.controls['confirmPassword'].setValidators([Validators.required, Validators.minLength(r.minimum_password_length), Validators.pattern('^(?=.*\\d)(?=.*[a-z])(?=.*[A-Z])(?=.*\\W)(?!\\s).{' + r.minimum_password_length + ',}$')]);
            }
        });


    }

    resetPassword(): void {
        this.loading = true;
        let body = {
            token_id: this.token,
            password: this.passwordForm.value.password,
            password_confirm: this.passwordForm.value.confirmPassword
        }
        this.authService.resetPassword(body).subscribe(r => {
            this.loading = false;
            this.snackbarService.openSnackBar('Password reset successful', 'success')
            this.router.navigate(['/login']);
        }, e => {
            this.loading = false;
            this.snackbarService.openSnackBar(e, 'error')
        })

    }
}
