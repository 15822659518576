import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {ConfirmationDialogData} from "../../_types/shared";

@Component({
    selector: 'app-confirm-dialog',
    templateUrl: './confirm-dialog.component.html',
    styleUrl: './confirm-dialog.component.scss'
})
export class ConfirmDialogComponent {
    confirm: string = '';
    verificationCode: string | undefined;

    constructor(
        private readonly matDialog: MatDialogRef<ConfirmDialogComponent>,
        @Inject(MAT_DIALOG_DATA) protected data: ConfirmationDialogData | null
    ) {
    }

    checkConfirm(): boolean {
        return this.confirm.toLowerCase() === this.data?.user?.username.toLowerCase();
    }

    submitVerification(): void {
        this.matDialog.close({code: this.verificationCode, username: this.confirm});
    }
}
