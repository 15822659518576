<table [dataSource]="permissions" class="mat-elevation-z0 responsiveTable" mat-table>
    <ng-container matColumnDef="profile">
        <th *matHeaderCellDef mat-header-cell> Profile</th>
        <td *matCellDef="let user" mat-cell>
            <a *ngIf="user?.user && user?.user.id != child"
               [class.clickable]="currentUser.role.can_access_profiles"
               (click)="viewProfile(user.user.id)" (keyup.enter)="viewProfile(user.user.id)"
               [matTooltip]="currentUser.role.can_access_profiles ? 'View ' +  user.user.full_name + '\'s Profile' : user.user.full_name + '\'s Profile Picture'"
               class="p-0">
                <img [alt]="user.user.full_name" *ngIf="user.user?.thumbnail || user.user?.photo"
                     [src]="user.user?.thumbnail || user.user?.photo|| 'assets/images/blankProfile.png'"
                     class="w-100 userImg" loading="lazy" fetchPriority="low">
                <p class="initial m-10"
                   *ngIf="!user.usxer?.thumbnail && !user.user?.photo">{{ user.user.first_name | slice: 0 : 1 }} {{ user.user.last_name | slice: 0 : 1 }}</p>
            </a>
            <img [alt]="user.user.full_name"
                 *ngIf="user?.user && user?.user.id == child && (user.user?.thumbnail || user.user?.photo)"
                 [src]="user.user?.thumbnail || user.user?.photo|| 'assets/images/blankProfile.png'"
                 class="w-100 userImg" loading="lazy" fetchPriority="low">
            <p class="initial m-10"
               *ngIf="user?.user && user?.user.id == child && !user.user?.thumbnail && !user.user?.photo">{{ user.user.first_name | slice: 0 : 1 }} {{ user.user.last_name | slice: 0 : 1 }}</p>
        </td>
    </ng-container>

    <ng-container matColumnDef="name">
        <th *matHeaderCellDef mat-header-cell> Name</th>
        <td *matCellDef="let user" mat-cell><b class="d-none columnTitle">Name:</b>{{ user.user?.full_name }}</td>
    </ng-container>

    <ng-container matColumnDef="role">
        <th *matHeaderCellDef mat-header-cell>User Role</th>
        <td *matCellDef="let user" mat-cell>
            <b class="d-none columnTitle">User Role:</b>
            <ng-container
                    *ngIf="user.user?.role?.name">{{ user.user?.role?.name | removeBrackets }}
            </ng-container>
            <ng-container
                    *ngIf="user.user?.role.length > 0 ">{{ user.user?.role | removeBrackets }}
            </ng-container>
        </td>
    </ng-container>

    <ng-container matColumnDef="permission">
        <th *matHeaderCellDef mat-header-cell> Permission</th>
        <td *matCellDef="let user" mat-cell>
            <ng-container *ngIf="this.canRequest">
                <mat-form-field appearance="outline" class=" w-100 pt-10 noPaddingBottom" color="primary">
                    <mat-label>Permission</mat-label>
                    <mat-select [(value)]="user.newPermission" [value]="user.permission">
                        <mat-option value="View Only">View Only</mat-option>
                        <mat-option value="View and Comment">View and Comment</mat-option>
                        <mat-option value="View, Comment and Add">View, Comment and Add</mat-option>
                        <mat-option value="Add but not View or Comment">Add Only</mat-option>
                        <mat-option value="No Permission">No Access</mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field
                        [class.hidden]="user.newPermission == user.permission || !user.newPermission || this.currentUser?.role?.role_type == 'admin'"
                        appearance="outline" class="w-100">
                    <mat-label>Permission Change Request Description</mat-label>
                    <textarea #description [(ngModel)]="user.changeRequest" [ngModelOptions]="{standalone: true}"
                              matInput
                              maxlength="400"></textarea>
                    <mat-hint align="end">{{ description?.value?.length }} / 400</mat-hint>
                </mat-form-field>
                <button (click)="requestPermissionUpdate(user)" (keyup.enter)="requestPermissionUpdate(user)"
                        *ngIf="user.newPermission && user.newPermission != user.permission"
                        [disabled]="!user.newPermission || user.permission == user.newPermission" class="mb-10"
                        color="primary"
                        mat-raised-button>
                    <ng-container *ngIf="this.currentUser?.role?.role_type != 'admin'">Send Permission Update Request
                    </ng-container>
                    <ng-container *ngIf="this.currentUser?.role?.role_type === 'admin'">Update Permission</ng-container>
                </button>
            </ng-container>
            <ng-container *ngIf="!this.canRequest">{{ user.permission }}</ng-container>
        </td>
    </ng-container>
    <tr *matHeaderRowDef="displayedColumns" mat-header-row></tr>
    <tr *matRowDef="let row; columns: displayedColumns;" mat-row></tr>
</table>
