<mat-toolbar class="d-flex flex-space-between mat-elevation-z3" color="primary">
    <div class="flex-row align-items-center">
        <button [routerLink]="['/reporting']" aria-label="Home"
                mat-icon-button matTooltip="Home">
            <img alt="Virtual Memory Box Logo" src="assets/images/vmb.svg">
        </button>
        <p class="m-0 greeting">{{ user?.first_name | greeting }}</p>
    </div>
    <div>
        <button (click)="changeSideNav(!sideNav)" *ngIf="isMobile" aria-label="Open Side Menu"
                mat-icon-button>
            <mat-icon>menu</mat-icon>
        </button>
        <button (click)="openNotificationNav()" (keyup.enter)="openNotificationNav()" *ngIf="!isMobile"
                [matBadgeHidden]="!notificationsCount"
                [matBadge]="notificationsCount" aria-label="Notifications"
                class="notificationBadge" mat-icon-button matBadgeColor="warn"
                matBadgePosition="after" matBadgeSize="small" matTooltip="Notifications">
            <mat-icon [routerLinkActive]="'mat-accent'">notifications</mat-icon>
        </button>
    </div>
</mat-toolbar>
<mat-sidenav-container class="minFullHeight ">
    <mat-sidenav (closed)="changeSideNav(false)" [mode]="mode" [opened]="sideNav">
        <mat-nav-list [class.minified]="minified" class="p-0">
            <mat-list-item [routerLinkActive]="['mat-background-accent', 'accentContrast']"
                           [routerLink]="['/reporting']"
                           class="align-items-center" disableRipple matTooltip="Dashboard">
                <mat-icon class="pr-10" matListItemIcon>pie_chart</mat-icon>
                <div matListItemTitle>Dashboard</div>
            </mat-list-item>
            <mat-list-item [routerLinkActive]="['mat-background-accent', 'accentContrast']"
                           [routerLink]="['/reports']"
                           class="align-items-center" disableRipple matTooltip="Reporting">
                <mat-icon class="pr-10" matListItemIcon>list_alt</mat-icon>
                <div matListItemTitle>Reports</div>
            </mat-list-item>
            <mat-list-item (click)="openNotificationNav()" (keyup.enter)="openNotificationNav()" *ngIf="isMobile"
                           [routerLinkActive]="['mat-background-primary', 'primaryContrast']"
                           class="align-items-center" disableRipple matTooltip="Notifications">
                <mat-icon [matBadgeHidden]="!notificationsCount" [matBadge]="notificationsCount" class="pr-10 notificationBadge"
                          matBadgeColor="warn" matBadgePosition="after" matBadgeSize="small"
                          matListItemIcon>
                    notifications
                </mat-icon>
                <div matListItemTitle>Notifications</div>
            </mat-list-item>
            <mat-list-item [routerLinkActive]="['mat-background-accent', 'accentContrast']" [routerLink]="['/invitees']"
                           class="align-items-center" disableRipple matTooltip="Invites">
                <mat-icon class="pr-10" matListItemIcon>contact_mail</mat-icon>
                <div matListItemTitle>Invites</div>
            </mat-list-item>
            <mat-list-item [routerLinkActive]="['mat-background-accent', 'accentContrast']" [routerLink]="['/users']"
                           class="align-items-center" disableRipple matTooltip="Users">
                <mat-icon class="pr-10" matListItemIcon>supervised_user_circle</mat-icon>
                <div matListItemTitle>Users</div>
            </mat-list-item>
            <mat-list-item [routerLinkActive]="['mat-background-accent', 'accentContrast']"
                           [routerLink]="['/permissionRequests']"
                           class="align-items-center" disableRipple matTooltip="Permission Requests">
                <mat-icon class="pr-10" matListItemIcon>lock</mat-icon>
                <div matListItemTitle>Permission Requests</div>
            </mat-list-item>
            <mat-list-item [routerLinkActive]="['mat-background-accent', 'accentContrast']"
                           [routerLink]="['/flaggedMedia']"
                           class="align-items-center" disableRipple matTooltip="Flagged Media">
                <mat-icon class="pr-10" matListItemIcon>feedback</mat-icon>
                <div matListItemTitle>Flagged Media</div>
            </mat-list-item>
            <mat-list-item [routerLinkActive]="['mat-background-accent', 'accentContrast']"
                           [routerLink]="['/hiddenMedia']"
                           class="align-items-center" disableRipple matTooltip="Hidden Media">
                <mat-icon class="pr-10" matListItemIcon>visibility_off</mat-icon>
                <div matListItemTitle>Hidden Media</div>
            </mat-list-item>
            <mat-list-item [routerLinkActive]="['mat-background-accent', 'accentContrast']"
                           [routerLink]="['/deletionQueue']"
                           class="align-items-center" disableRipple matTooltip="Deletion Queue">
                <mat-icon class="pr-10" matListItemIcon>delete_sweep</mat-icon>
                <div matListItemTitle>Deletion Queue</div>
            </mat-list-item>
            <mat-list-item [routerLinkActive]="['mat-background-accent', 'accentContrast']" [routerLink]="['/people']"
                           class="align-items-center" disableRipple matTooltip="Memory Boxes">
                <mat-icon class="pr-10 customIcon" matListItemIcon svgIcon="boxIcon"></mat-icon>
                <div matListItemTitle>View Memory Boxes</div>
            </mat-list-item>
            <mat-list-item [routerLinkActive]="['mat-background-accent', 'accentContrast']" [routerLink]="['/cohorts']"
                           class="align-items-center" disableRipple matTooltip="Cohort Management">
                <mat-icon class="pr-10" matListItemIcon>group_work</mat-icon>
                <div matListItemTitle>Cohort Management</div>
            </mat-list-item>
            <mat-list-item [routerLinkActive]="['mat-background-accent', 'accentContrast']"
                           [routerLink]="['/locations']"
                           class="align-items-center" disableRipple matTooltip="Locality Management">
                <mat-icon class="pr-10" matListItemIcon>person_pin_circle</mat-icon>
                <div matListItemTitle>Locality Management</div>
            </mat-list-item>
            <!--            <mat-expansion-panel class="dropdownMenuItem mat-elevation-z0" [class.minifiedDropdown]="minified"-->
            <!--                                 hideToggle (opened)="openDropdown()">-->
            <!--                <mat-expansion-panel-header>-->
            <!--                    <mat-panel-title>-->
            <!--                        <mat-icon class="pr-10" matListItemIcon matTooltip="Training Centre">school</mat-icon>-->
            <!--                        <ng-container *ngIf="!minified"> Training Centre</ng-container>-->
            <!--                    </mat-panel-title>-->
            <!--                </mat-expansion-panel-header>-->
            <!--                <mat-list-item [routerLink]="['/adminFaqs']" class="align-items-center" disableRipple>-->
            <!--                    <div matListItemTitle>FAQs</div>-->
            <!--                </mat-list-item>-->
            <!--                &lt;!&ndash;                <mat-list-item [routerLink]="['/adminResources']" class="align-items-center" disableRipple>&ndash;&gt;-->
            <!--                &lt;!&ndash;                    <div matListItemTitle>Resources</div>&ndash;&gt;-->
            <!--                &lt;!&ndash;                </mat-list-item>&ndash;&gt;-->
            <!--                <mat-list-item [routerLink]="['/adminVideoGuides']" class="align-items-center" disableRipple>-->
            <!--                    <div matListItemTitle>Video Guides</div>-->
            <!--                </mat-list-item>-->
            <!--                <mat-list-item [routerLink]="['/adminWrittenGuides']" class="align-items-center" disableRipple>-->
            <!--                    <div matListItemTitle>Written Guides</div>-->
            <!--                </mat-list-item>-->
            <!--            </mat-expansion-panel>-->
            <mat-list-item [routerLinkActive]="['mat-background-accent', 'accentContrast']"
                           [routerLink]="['/exports']"
                           class="align-items-center" disableRipple matTooltip="Export Requests">
                <mat-icon class="pr-10" matListItemIcon>cloud_download</mat-icon>
                <div matListItemTitle>Export Requests</div>
            </mat-list-item>
            <mat-list-item [routerLinkActive]="['mat-background-accent', 'accentContrast']"
                           [routerLink]="['/profileSettings']"
                           class="align-items-center" disableRipple matTooltip="Settings">
                <mat-icon class="pr-10" matListItemIcon>settings</mat-icon>
                <div matListItemTitle>Settings</div>
            </mat-list-item>

            <mat-list-item (click)="logout()" (keyup.enter)="logout()" class="align-items-center" disableRipple
                           matTooltip="Log Out">
                <mat-icon class="pr-10" matListItemIcon>exit_to_app</mat-icon>
                <div matListItemTitle>Log-out</div>
            </mat-list-item>
            <mat-list-item (click)="minifySideWindow()" *ngIf="!this.isMobile" class="align-items-center" disableRipple>
                <mat-icon *ngIf="!minified" class="pr-10" matListItemIcon matTooltip="Collapse Side Menu">
                    keyboard_arrow_left
                </mat-icon>
                <mat-icon *ngIf="minified" class="pr-10" matListItemIcon matTooltip="Expand Side Menu">
                    keyboard_arrow_right
                </mat-icon>
                <div matListItemTitle>Collapse Side Menu</div>
            </mat-list-item>
        </mat-nav-list>
    </mat-sidenav>

    <mat-sidenav (closed)="notificationNavOpened = false;" [opened]="notificationNavOpened" class="alertMenu p-10"
                 mode="over" position="end">
        <div class="flex-row d-flex flex-space-between">
            <h3>Notifications</h3>
            <button (click)="notificationNavOpened = false;" (keyup.enter)="notificationNavOpened = false;"
                    mat-icon-button tabindex="-1">
                <mat-icon>close</mat-icon>
            </button>
        </div>
        <app-notification-sidebar (getCount)="this.getNotificationCount()"
                                  [getNotifications]="this.notificationNavOpened">
        </app-notification-sidebar>
    </mat-sidenav>
    <mat-sidenav-content>
        <div class="minFullHeight adminDashboard">
            <router-outlet #outlet="outlet"></router-outlet>
        </div>
        <app-footer></app-footer>
    </mat-sidenav-content>
</mat-sidenav-container>
