import {Component} from '@angular/core';
import {Router} from "@angular/router";
import {AuthService} from "../_services/auth.service";
import {ContentService} from "../_services/content.service";
import {SnackbarService} from "../_services/snackbar.service";

@Component({
    selector: 'app-terms',
    templateUrl: './terms.component.html',
    styleUrl: './terms.component.scss'
})
export class TermsComponent {
    user: any;
    settings: any;
    termsDynamic: string = '';

    constructor(private router: Router,
                private authService: AuthService,
                private contentService: ContentService,
                private snackbar: SnackbarService) {
        this.user = this.authService.getUser();
        this.authService.getDynamicSettings().then((r): void => {
            this.settings = r;
        });
        this.contentService.getTerms().subscribe(r => {
            if (r && r.rendered) {
                this.termsDynamic = r.rendered;
            }
        }, e => {
            console.error(e)
        })
    }

    acceptTerms(): void {
        this.authService.acceptTerms().subscribe(r => {
            this.user.agreed_tos = true;
            this.authService.setUser(this.user);
            if (this.user.role.role_type == "young_person") {
                this.router.navigate(['/timeline']);
            } else if (this.user.role.role_type == "admin") {
                this.router.navigate(['/reporting']);
            } else {
                this.router.navigate(['/people']);
            }
        }, e => {
            this.snackbar.openSnackBar(e, 'error')
        })
    }
}
