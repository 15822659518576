import {Component} from '@angular/core';
import {AuthService} from "../../../_services/auth.service";
import {MatDialog, MatDialogRef} from "@angular/material/dialog";
import {NewPermissionComponent} from "../../users/user/new-permission/new-permission.component";
import {ActivatedRoute, Event} from "@angular/router";
import {SnackbarService} from "../../../_services/snackbar.service";
import {AdminService} from "../../../_services/admin.service";
import {PageEvent} from "@angular/material/paginator";
import {ContentService} from "../../../_services/content.service";

@Component({
    selector: 'app-permission-request',
    templateUrl: './permission-request.component.html',
    styleUrl: './permission-request.component.scss'
})
export class PermissionRequestComponent {
    user: any;
    reject: boolean = false;
    changeRequest: any;
    note: string = '';
    id: number = 0;
    permissions: any[] = [];
    permissionRequests: any[] = [];
    changeRequestLimit: number = 5;
    changeRequestPage: number = 0;
    permissionRequestsCount: number = 0;

    constructor(private authService: AuthService,
                public dialog: MatDialog,
                private adminService: AdminService,
                public snackbarService: SnackbarService,
                private route: ActivatedRoute,
                private contentService: ContentService) {
        this.user = this.authService.getUser();
        this.route.params
            .subscribe(params => {
                if (params['id']) {
                    this.id = params['id']
                    this.getRequest();
                }

            }, error => {
                this.snackbarService.openSnackBar(error, 'error')
                console.error(error);
            });
    }

    getRequest(): void {
        this.adminService.getAccessRequest(this.id).subscribe(r => {
            this.changeRequest = r;
            this.getPermissionChangeRequests()
            this.contentService.viewTimelinePermissions(this.changeRequest?.timeline_user_access?.timeline.id).subscribe(res => {
                this.permissions = res;
            }, e => {
                this.snackbarService.openSnackBar(e, 'error')
            })

        }, e => {
            this.snackbarService.openSnackBar(e, 'error')
        })
    }

    rejectRequest(): void {
        let body = {
            status: "Rejected",
            note: this.note
        }
        this.adminService.accessRequestUpdate(this.id, body).subscribe(r => {
            this.getRequest()
            this.reject = false;
            this.snackbarService.openSnackBar('Change Request Rejected', 'success')
        }, e => {
            this.snackbarService.openSnackBar(e, 'error')
        })
    }

    acceptRequest(): void {
        let body = {
            status: "Accepted",
        }
        this.adminService.accessRequestUpdate(this.id, body).subscribe(r => {
            this.getRequest()
            this.snackbarService.openSnackBar('Change Request Accepted', 'error')
        }, e => {
            this.snackbarService.openSnackBar(e, 'error')
        })
    }

    getPermissionChangeRequests(event?: any): void {
        this.adminService.getUserAccessRequests(this.changeRequest.timeline_user_access.timeline.id, this.changeRequestPage, this.changeRequestLimit, event).subscribe(r => {
            this.permissionRequestsCount = r.count;
            this.permissionRequests = r.results;
        }, e => {
            this.snackbarService.openSnackBar(e, 'error')
        })
    }

    changePermissionRequestsOrder(event: Event): void {
        this.getPermissionChangeRequests(event)
    }

    permissionRequestsPageChange(e: PageEvent): void {
        this.changeRequestPage = e.pageIndex;
        this.changeRequestLimit = e.pageSize;
        this.getPermissionChangeRequests();
    }

    newRelationship(): void {
        const dialogRef: MatDialogRef<NewPermissionComponent> = this.dialog.open(NewPermissionComponent, {
            data: {
                user: this.user
            },
        });
    }
}
