import {AfterViewInit, Component, ViewChild} from '@angular/core';
import {MatTableDataSource} from "@angular/material/table";
import {MatSort, Sort} from "@angular/material/sort";
import {MatPaginator, PageEvent} from "@angular/material/paginator";
import {AdminService} from "../../_services/admin.service";
import {SnackbarService} from "../../_services/snackbar.service";
import {AdminViewComponent} from "../admin-view/admin-view.component";
import {ActivatedRoute, Router} from "@angular/router";

@Component({
    selector: 'app-users',
    templateUrl: './users.component.html',
    styleUrl: './users.component.scss'
})
export class UsersComponent implements AfterViewInit {
    loading: boolean = true;
    displayedColumns: string[] = ['img', 'name', 'username', 'roleType', 'role', 'cohort', 'locality', 'joined', 'view'];
    dataSource: MatTableDataSource<any> = new MatTableDataSource();
    @ViewChild(MatPaginator) paginator: MatPaginator | null = null;
    @ViewChild(MatSort) sort: MatSort | null = null;
    role: any[] = [];
    cohort: any[] = [];
    locality: any[] = [];
    status: string = 'Active';
    orphaned: string = 'All';
    noUploads: string = 'All';
    typingTimer: any;
    searchTerm: any;
    limit: number = 15;
    page: number = 0;
    count: number = 0;
    direction: string | undefined = 'desc';
    order_by: string | undefined = 'full_name';
    localities: any[] = [];
    cohorts: any[] = [];
    roles: any[] = [];
    matSortDefault: string = 'name';
    matSortDirectionDefault: "asc" | "desc" = "desc";

    constructor(private adminService: AdminService,
                private route: ActivatedRoute,
                private router: Router,
                private snackbarService: SnackbarService,
                protected adminView: AdminViewComponent) {

        route.queryParams.subscribe(
            (data: any): void => {
                if (data['role']) {
                    let roles = data['role']
                    if (Array.isArray(roles)) {
                        for (let i = 0; i < roles.length; i++) {
                            this.role.push(JSON.parse(roles[i]))
                        }
                    } else {
                        this.role.push(JSON.parse(roles))
                    }
                    this.role = this.role.filter((value, index, array) =>
                        array.indexOf(value) === index)
                }

                if (data['cohort']) {
                    let cohorts = data['cohort']
                    if (Array.isArray(cohorts)) {
                        for (let i = 0; i < cohorts.length; i++) {
                            this.cohort.push(JSON.parse(cohorts[i]))
                        }
                    } else {
                        this.cohort.push(JSON.parse(cohorts))
                    }
                    this.cohort = this.cohort.filter((value, index, array) =>
                        array.indexOf(value) === index)
                }

                if (data['locality']) {
                    let localities = data['locality']
                    if (Array.isArray(localities)) {
                        for (let i = 0; i < localities.length; i++) {
                            this.locality.push(JSON.parse(localities[i]))
                        }
                    } else {
                        this.locality.push(JSON.parse(localities))
                    }
                    this.locality = this.locality.filter((value, index, array) =>
                        array.indexOf(value) === index)
                }
                if (data['orphaned']) {
                    this.orphaned = data['orphaned']
                }
                if (data['noUploads']) {
                    this.noUploads = data['noUploads']
                }
                if (data['status']) {
                    this.status = data['status']
                }
                if (data['direction']) {
                    this.direction = data['direction']
                    if (this.direction == 'asc' || this.direction == 'desc') {
                        this.matSortDirectionDefault = this.direction;
                    }
                }

                if (data['order_by']) {
                    this.order_by = data['order_by']
                    if (this.order_by) {
                        if (this.order_by == 'full_name') {
                            this.matSortDefault = 'name'
                        } else if (this.order_by == 'date_joined') {
                            this.matSortDefault = 'joined'
                        }
                    }
                }
                if (data['page']) {
                    this.page = data['page']
                }
                if (data['limit']) {
                    this.limit = data['limit']
                }
                if (data['searchTerm']) {
                    this.searchTerm = data['searchTerm']
                }
            })
        this.getUsers();
    }

    getUsers(): void {
        let filters: any = {
            locality: null,
            cohort: null,
            role: null,
            order_by: null,
            direction: null,
        }
        if (this.locality && this.locality.length > 0) {
            filters.locality = this.locality;
        }
        if (this.cohort && this.cohort.length > 0) {
            filters.cohort = this.cohort;
        }

        if (this.role && this.role.length > 0) {
            filters.role = this.role;
        }

        if (this.orphaned && this.orphaned != 'All') {
            filters.is_orphan = this.orphaned;
        }
        if (this.noUploads && this.noUploads != 'All') {
            filters.no_uploads = this.noUploads;
        }
        if (this.status == 'Active') {
            filters.is_active = true;
        } else if (this.status == 'Deactivated') {
            filters.deactivated = true;
        } else if (this.status == 'locked') {
            filters.locked = true;
        }
        if (this.order_by && this.direction) {
            filters.order_by = this.order_by
        }
        if (this.direction) {
            filters.direction = this.direction
        }

        filters.page = this.page;
        filters.limit = this.limit;
        filters.searchTerm = this.searchTerm;
        this.router.navigate([], {
            relativeTo: this.route,
            queryParams: filters,
            queryParamsHandling: 'merge',
        });
        this.adminService.getAllUsers(this.page, this.limit, this.searchTerm, filters).subscribe(r => {
            this.count = r.count;
            if (this.localities.length == 0) {
                this.localities = r.filter_options.locality
            }
            if (this.cohorts.length == 0) {
                this.cohorts = r.filter_options.cohorts__cohort
            }
            if (this.roles.length == 0) {
                this.roles = r.filter_options.role
            }
            this.dataSource = new MatTableDataSource(r.results);
            this.loading = false;
        }, e => {
            this.loading = false;
            this.snackbarService.openSnackBar(e, 'error');
        })
    }

    ngAfterViewInit(): void {
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
    }

    keyup(): void {
        clearTimeout(this.typingTimer);
        const that: this = this;
        this.typingTimer = setTimeout((): void => {
            that.loading = true;
            this.getUsers()

            if (this.dataSource.paginator) {
                this.dataSource.paginator.firstPage();
            }
        }, 500);
    }

    clearSearch(): void {
        this.searchTerm = '';
        this.getUsers();
    }

    keydown(): void {
        clearTimeout(this.typingTimer);
    }

    handlePageEvent(e: PageEvent): void {
        this.page = e.pageIndex;
        this.limit = e.pageSize;
        this.getUsers();
    }

    sortData(event: Sort): void {
        this.order_by = event.active;

        if (this.order_by == 'name') {
            this.order_by = 'full_name'
        } else if (this.order_by == 'joined') {
            this.order_by = 'date_joined'
        }
        this.direction = event.direction;
        this.getUsers()
    }
}
