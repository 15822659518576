import {CanActivate, Router} from '@angular/router';
import {AuthService} from "../_services/auth.service";
import {Injectable} from "@angular/core";

@Injectable({
    providedIn: 'root'
})
export class AdminGuard implements CanActivate {

    user: any;

    constructor(
        private router: Router,
        private authService: AuthService
    ) {
        this.user = this.authService.getUser();
    }

    canActivate(): boolean {
        // If user role is not admin then dont allow acces and redirect to timeline.
        this.user = this.authService.getUser();
        if (this.user?.role?.role_type !== 'admin') {
            this.router.navigate(['/timeline']);
            return false;
        } else {
            return true;
        }
    }
}
