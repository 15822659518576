<mat-dialog-content class="d-flex flex-col" role="dialog" aria-modal="true">
    <div class="flex-row flex-space-between">
        <div>
            <mat-card-header class="p-0">
                <mat-card-title>
                    <h3 class="m-0">Duplicate Album</h3>
                </mat-card-title>
            </mat-card-header>
        </div>
        <div class="float-end">
            <button class="mat-dialog-close" mat-dialog-close mat-icon-button (keyup.enter)="this.dialogRef.close();"
                    matTooltip="Close Window">
                <mat-icon>close</mat-icon>
            </button>
        </div>
    </div>
    <form class="pt-10">
        <mat-form-field appearance="outline" class="w-100" color="primary">
            <mat-label>Duplicate to which Memory Boxes?</mat-label>
            <input #userInput [formControl]="userSearchControl"
                   [matAutocomplete]="auto"
                   [matChipInputFor]="chipList"
                   matInput
                   placeholder="Select Users">
            <mat-autocomplete #auto="matAutocomplete"
                              (optionSelected)="selectUser($event.option.value)">
                <ng-container *ngFor="let user of filteredUsers | async">
                    <mat-option *ngIf="!checkIfInArray(user, this.selectedUsers)"
                                [value]="user">
                        {{ user.child && user.child.full_name && user.child.full_name != ' ' ? user.child.full_name + ' (' + user.child.username + ')' : (user.title || user.child.username || user.child.email) }}
                    </mat-option>
                </ng-container>
            </mat-autocomplete>
        </mat-form-field>
        <mat-chip-grid #chipList aria-label="Users Selection">
            <mat-chip (removed)="removeUser(user)" *ngFor="let user of this.selectedUsers"
                      class="smChip">
                {{ user.child && user.child.full_name && user.child.full_name != ' ' ? user.child.full_name + ' (' + user.child.username + ')' : (user.title || user.child.username || user.child.email) }}
                <button matChipRemove>
                    <mat-icon>cancel</mat-icon>
                </button>
            </mat-chip>
        </mat-chip-grid>
    </form>
</mat-dialog-content>
<mat-dialog-actions class="flex-space-around">
    <button [disabled]="loading" color="warn" mat-dialog-close mat-raised-button (keyup.enter)="this.dialogRef.close();">
        Cancel
    </button>
    <button (click)="duplicate()" (keyup.enter)="duplicate()" [disabled]="loading || this.selectedUsers.length<1"
            color="primary" mat-raised-button>
        Duplicate
        <mat-icon *ngIf="loading">
            <mat-spinner color="primary" width="40px"></mat-spinner>
        </mat-icon>
    </button>
</mat-dialog-actions>

