<ng-container *ngIf="notification">
    <mat-expansion-panel class="my-10" *ngIf="!read else unreadNotification">
        <mat-expansion-panel-header>
            <mat-panel-title>
                {{ notification.title }}
            </mat-panel-title>
            <mat-panel-description>
                <small>{{ notification.created_date|dateAgo }}</small>
            </mat-panel-description>
        </mat-expansion-panel-header>
        <p class="p-5"> {{ notification.content }}</p>
        <div class="actionRow">
            <a *ngIf="this.link?.url"
               [href]="this.link?.url" color="primary"
               mat-raised-button>
                {{ this.link?.title }}
            </a>
            <button (click)="markRead(notification)" (keyup.enter)="markRead(notification)"
                    color="warn"
                    mat-raised-button>
                Dismiss
            </button>
        </div>
    </mat-expansion-panel>

    <ng-template #unreadNotification>
        <mat-expansion-panel class="my-10">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    {{ notification.title }}
                </mat-panel-title>
                <mat-panel-description>
                    <small>{{ notification.created_date|dateAgo }}</small>
                </mat-panel-description>
            </mat-expansion-panel-header>
            <p class="p-5"> {{ notification.content }}</p>
            <div class="actionRow">
                <a *ngIf="this.link?.url"
                   [href]="this.link?.url" color="primary"
                   mat-raised-button>
                    {{ this.link?.title }}
                </a>
            </div>
        </mat-expansion-panel>
    </ng-template>
</ng-container>
