import {Component} from '@angular/core';

@Component({
    selector: 'app-timeout',
    templateUrl: './timeout.component.html',
    styleUrl: './timeout.component.scss'
})
export class TimeoutComponent {

}
