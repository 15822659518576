import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthService} from "../_services/auth.service";
import {NotificationsService} from "../_services/notifications.service";

@Component({
    selector: 'app-passthrough',
    templateUrl: './passthrough.component.html',
    styleUrls: ['./passthrough.component.scss']
})
export class PassthroughComponent implements OnInit {

    constructor(private route: ActivatedRoute,
                private readonly router: Router,
                private authService: AuthService,
                private notificationService: NotificationsService,
    ) {
    }

    ngOnInit(): void {
        this.route.queryParams
            .subscribe(params => {
                    let that = this
                    if (params['token'] && !params['notification']) {
                        // Pass token to hijack the user
                        this.authService.setUserWithToken(params['token'], true).then(function (): void {
                            that.router.navigate(['/timeline']);
                        }).catch((err): void => {
                            this.router.navigate(['/login']);
                        });
                    } else if (params['notification']) {
                        if (this.authService.isLoggedIn()) {
                            // is logged in so redirect to correct notification link
                            this.getNotificationLink(params['notification'])
                        } else if (params['token']) {
                            // is not logged in but has token so login then redirect
                            this.authService.setUserWithToken(params['token'], false).then(function (): void {
                                that.getNotificationLink(params['notification'])
                            }).catch((err): void => {
                                this.router.navigate(['/login']);
                            });
                        } else {
                            // Go back to login and come back here when logged in
                            this.router.navigate(['/login'], {queryParams: {returnUrl: window.location.href}});
                        }
                    } else {
                        // No useful params why are you here?
                        this.router.navigate(['/login']);
                    }
                }
            );
    }

    getNotificationLink(id: number): void {
        this.notificationService.getNotification(id).subscribe(r => {
            let link = this.notificationService.getNotificationLink(r).url
            if (link) {
                window.location.href = link;
            } else {
                this.router.navigate(['/timeline']);
            }
        }, e => {
            console.error(e)
        })
    }
}
