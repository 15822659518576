<main>
    <div class="d-flex flex-row justify-content-center">
        <h3 class="my-10">
            Export Memory Box Requests
        </h3>
    </div>
    <div class="mediumContainer">
        <div *ngIf="loading" class="centerLoading">
            <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
        </div>
        <div class="mat-elevation-z6" *ngIf="!loading">
            <table [dataSource]="dataSource" class="responsiveTable" mat-table matSort>
                <ng-container matColumnDef="name">
                    <th *matHeaderCellDef mat-header-cell> Memory Box</th>
                    <td *matCellDef="let export" mat-cell>{{ export.timeline.title }}
                    </td>
                </ng-container>
                <ng-container matColumnDef="expiry">
                    <th *matHeaderCellDef mat-header-cell>Export Expires On</th>
                    <td *matCellDef="let export" mat-cell>
                        {{ export.expiry_date | date: 'medium' }}
                    </td>
                </ng-container>
                <ng-container matColumnDef="actions">
                    <th *matHeaderCellDef mat-header-cell> Actions</th>
                    <td *matCellDef="let export" mat-cell>
                        <ng-container *ngIf="isExpired(export.expiry_date)">Export has expired</ng-container>
                        <button *ngIf="!isExpired(export.expiry_date)" color="primary" mat-raised-button
                                (click)="exportTimeline(export.uid)">Download
                        </button>
                    </td>
                </ng-container>
                <tr *matHeaderRowDef="displayedColumns" mat-header-row></tr>
                <tr *matNoDataRow class="mat-row">
                    <td class="mat-cell text-center" [attr.colspan]="this.displayedColumns.length">
                        No export requests found
                    </td>
                </tr>
                <tr *matRowDef="let row; columns: displayedColumns;" mat-row></tr>
            </table>
        </div>
    </div>
</main>
