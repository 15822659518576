import {Component} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {AuthService} from "../_services/auth.service";
import {ActivatedRoute, Router, UrlTree} from "@angular/router";
import {SnackbarService} from "../_services/snackbar.service";
import {MatDialog} from "@angular/material/dialog";

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrl: './login.component.scss'
})
export class LoginComponent {
    loginForm: FormGroup;
    requestPasswordForm: FormGroup;
    requestUsernameForm: FormGroup;
    otpForm: FormGroup;
    loading: boolean = false;
    passwordType: string = 'password';
    view: String = 'Login'
    councilLogo: string | null = null;
    authorityName: string | null = null;
    login_support_text_rendered: string | null = null;
    loginData: any;
    timer: number = 0;
    sentTo: any = null;
    returnUrl: string | null = null;

    constructor(private readonly formBuilder: FormBuilder,
                private authService: AuthService,
                private readonly router: Router,
                private readonly snackBarService: SnackbarService,
                public dialog: MatDialog,
                private readonly route: ActivatedRoute) {
        this.dialog.closeAll();
        this.loginForm = this.formBuilder.group({
            username: ['', [Validators.required, Validators.pattern('\\S+')]],
            password: ['', Validators.required],
        });
        this.requestPasswordForm = this.formBuilder.group({
            username: ['', [Validators.required, Validators.pattern('\\S+')]],
        });
        this.requestUsernameForm = this.formBuilder.group({
            email: ['', [Validators.required, Validators.email]],
        });
        this.otpForm = this.formBuilder.group({
            code: ['', [Validators.required]],
        });
        localStorage.clear();
        this.authService.getDynamicSettings().then((r): void => {
            this.councilLogo = r.authority_logo;
            this.authorityName = r.authority_name;
            this.login_support_text_rendered = r.login_support_text_rendered
        });
        route.queryParams.subscribe(
            (data: any): void => {
                if (data['username']) {
                    this.loginForm.patchValue({
                        username: data['username']
                    });
                }
                this.validateReturnUrl(decodeURIComponent(data['returnUrl']))

            })
    }

    validateReturnUrl(url: string): void {
        const validPathRegex = /^[a-zA-Z0-9\-._~\/]*$/;
        // Check if the URL matches the valid path pattern
        if (validPathRegex.test(url)) {
            try {
                const urlTree: UrlTree = this.router.parseUrl(url);
                // If parseUrl doesn't throw an error, the URL is considered valid
                this.returnUrl = url;
            } catch (e) {
                console.warn('Invalid URL:', url);
                // Handle invalid URL scenario
            }
        }
    }

    showPassword(): void {
        if (this.passwordType == 'password') {
            this.passwordType = 'text';
        } else {
            this.passwordType = 'password';
        }
    }

    request2fa(): void {
        this.authService.request2fa(this.loginData.token).subscribe(r => {
            this.view = 'Code'
            this.sentTo = r;
            this.timer = 25;
            let timeleft: number = 25;
            let that: this = this;
            const downloadTimer = setInterval(function (): void {
                if (timeleft <= 0) {
                    clearInterval(downloadTimer);
                }
                that.timer = timeleft;
                timeleft -= 1;
            }, 1000);
        }, e => {
            this.snackBarService.openSnackBar(e, 'error')
        })
    }

    login(): void {
        this.loading = true;
        this.authService.login(this.loginForm.value.username, this.loginForm.value.password)
            .subscribe(
                (data: any): void => {

                    this.loginData = data.data
                    this.request2fa();
                    this.loading = false;
                },
                (error: any): void => {
                    this.snackBarService.openSnackBar(error, 'error')
                    this.loading = false;
                });
    }


    finishLogin(): void {
        this.loading = true;
        this.authService.verify2fa({code: this.otpForm.value.code}, this.loginData.token).subscribe(r => {
            this.authService.setUser(this.loginData.user);
            this.authService.setToken(this.loginData.token);
            //  If admin then the child guard will redirect them to users page
            if (this.loginData.user.role.role_type == "young_person") {
                // Will be child or adult or admin in prod

                if (this.returnUrl) {
                    this.router.navigateByUrl(this.returnUrl);
                } else {
                    this.router.navigate(['/timeline']);
                }

            } else if (this.loginData.user.role.role_type == "admin") {
                if (this.returnUrl) {
                    this.router.navigateByUrl(this.returnUrl);
                } else {
                    this.router.navigate(['/reporting']);
                }
            } else {
                if (this.returnUrl) {
                    this.router.navigateByUrl(this.returnUrl);
                } else {
                    this.router.navigate(['/people']);
                }
            }
        }, error => {
            this.loading = false;
            this.snackBarService.openSnackBar(error, 'error')
        })
    }

    passwordResetRequest(): void {
        this.loading = true;
        this.authService.passwordResetRequest(this.requestPasswordForm.value.username).subscribe(r => {
            this.snackBarService.openSnackBar('User successfully updated. Check your email for password reset link.', 'success');
            this.loading = false;
        }, e => {
            this.loading = false;
            this.snackBarService.openSnackBar(e, 'error')
        })
    }

    usernameRequest(): void {
        this.loading = true;
        this.authService.usernameRequest(this.requestUsernameForm.value.email).subscribe(r => {
            this.snackBarService.openSnackBar('Check your email for usernames ', 'success');
            this.view = 'Login';
            this.loading = false;
        }, e => {
            this.loading = false;
            this.snackBarService.openSnackBar(e, 'error')
        })
    }

    loginIfValid(invalid: boolean): void {
        if (!invalid) {
            this.login();
        }
    }

    finishLoginIfValid(invalid: boolean): void {
        if (!invalid) {
            this.finishLogin();
        }
    }

    usernameRequestIfValid(invalid: boolean): void {
        if (!invalid) {
            this.usernameRequest();
        }
    }

    passwordResetRequestIfValid(invalid: boolean): void {
        if (!invalid) {
            this.passwordResetRequest();
        }
    }
}
