import {Injectable} from '@angular/core';
import {Observable} from "rxjs";
import {environment} from "../../environments/environment";
import {HttpClient, HttpParams} from "@angular/common/http";

@Injectable({
    providedIn: 'root'
})
export class NotificationsService {

    constructor(private http: HttpClient) {
    }

    getActiveNotifications(): Observable<any> {
        return this.http.get<any>(`${environment.apiUrl}v2/api/notifications/active/`);
    }

    getNotification(id: number): Observable<any> {
        return this.http.get<any>(`${environment.apiUrl}v2/api/notifications/` + id + '/');
    }

    getNotifications(page: number, limit: number, read?: boolean): Observable<any> {
        const offset: number = (page * limit);
        let parameters: HttpParams = new HttpParams();
        parameters = parameters.set('limit', limit);
        parameters = parameters.set('offset', offset);

        if (read) {
            parameters = parameters.set('read', true);
        }
        return this.http.get<any>(`${environment.apiUrl}v2/api/notifications/all/`, {params: parameters,});
    }

    getNotificationsCount(): Observable<any> {
        return this.http.get<any>(`${environment.apiUrl}v2/api/notifications/count/`);
    }

    markNotificationRead(id: number): Observable<any> {
        return this.http.post<any>(`${environment.apiUrl}v2/api/notifications/read/` + id + '/', {});
    }


    getNotificationLink(notification: any): any {
        let user = JSON.parse(localStorage.getItem('currentUser') as string)
        if (!notification) {
            return
        }
        let link: any = {
            title: 'View ' + notification.key,
            url: null
        }

        if (notification.link) {
            link.url = notification.link;
            return link;
        }


        switch (notification.key) {
            case 'Album':
                if (notification.memory?.id) {
                    link.url = '/memory/' + notification.memory.id
                }
                break
            case 'User':
                if (notification.from_user && user?.role?.role_type === 'admin') {
                    link.url = '/user/' + notification.from_user
                }
                break
            case 'Media':
                if (notification.memory?.id && notification.media) {
                    link.url = '/memory/' + notification.memory?.id + '/' + notification.media
                }
                break
            case 'Comment':
                if (notification.comment?.memory?.id || notification.memory?.id) {
                    link.url = '/memory/' + notification.comment.memory.id || notification.memory?.id
                }
                break
            case 'Feedback':
                if (notification.feedback.id && user?.role?.role_type === 'admin') {
                    link.title = 'View Feedback';
                    link.url = '/flaggedMedia/' + notification.feedback.id
                } else if (notification.feedback?.memory_id) {
                    link.title = 'View Feedback';
                    link.url = '/memory/' + notification.feedback.memory_id
                    if (notification.feedback?.content) {
                        link.url = link.url + '/#' + notification.feedback.content;
                    }
                }
                break
            case 'Permission Change Request':
                if (user?.role?.role_type === 'admin' && notification.permission_change_request?.id) {
                    link.url = '/permissionRequest/' + notification.permission_change_request?.id
                } else if (notification.timeline && user?.role?.role.can_edit_permissions) {
                    link.url = '/profile/0/' + notification.timeline
                } else if (notification.timeline) {
                    link.url = '/timeline/' + notification.timeline
                }
                break
            case 'Permission':
                if (notification.timeline) {
                    link.url = '/timeline/' + notification.timeline
                }
                break
            case 'TimelineExport':
                link.url = '/exports/'
                break
            case 'Timeline':
                if (notification.timeline) {
                    link.url = '/timeline/' + notification.timeline
                }
                break
            case 'Media Comment':
                if (notification.media_comment?.media?.memory && notification.media_comment?.media?.id) {
                    link.url = '/memory/' + notification.media_comment.media.memory + '/' + notification.media_comment.media.id
                }
                break
            default:
                link.url = null;
        }

        return link.url ? link : null
    }
}
