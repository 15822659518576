import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';
import {AppModule} from './app/app.module';
import * as Sentry from "@sentry/angular";
import {enableProdMode, isDevMode} from "@angular/core";
if (!isDevMode()) {
    Sentry.init({
        dsn: "https://41984dbfdc72fdbb8e354521143b165c@log.nebulalabs.cc/18",
        integrations: [
            Sentry.browserTracingIntegration(),
            Sentry.replayIntegration({
                workerUrl: "/assets/js/worker.min.js",
            }),
        ],
        // Performance Monitoring
        tracesSampleRate: 1.0, //  Capture 100% of the transactions
        tracePropagationTargets: ["localhost", /^https:\/\/.*\.virtualmemorybox\.co.uk/],
        // Session Replay
        replaysSessionSampleRate: 0.8,
        replaysOnErrorSampleRate: 1.0,
    });
} else {
    enableProdMode();
    Sentry.init({
        dsn: "https://41984dbfdc72fdbb8e354521143b165c@log.nebulalabs.cc/18",
        integrations: [
            Sentry.browserTracingIntegration(),
        ],
        // Performance Monitoring
        tracesSampleRate: 0.8, //  Capture 80% of the transactions
        tracePropagationTargets: ["localhost", /^https:\/\/.*\.virtualmemorybox\.co.uk/],
    });
}

platformBrowserDynamic()
    .bootstrapModule(AppModule)
    .catch((err) => console.error(err));
