<footer>
    <div class="top_divider"></div>
    <div class="mat-background-primary w-100 justify-around py-15 footerRow">
        <div class="d-flex flex-row flex-space-evenly gap10 footerBntRow">
            <a [routerLink]="['/privacy']">Privacy
                Policy</a>
            <a [routerLink]="['/terms']">Terms of Use</a>
        </div>
        <div>
            <small>©{{ today| date: 'yyyy' }} Virtual Memory Box co-produced with South Tyneside Council</small>
        </div>
        <div>
            <a *ngIf="dynamicSettings?.contact_email"
               [href]=" 'mailto:' + dynamicSettings.contact_email +'?subject=Help with Virtual Memory Box'">
                Click here for account support
            </a>
        </div>
    </div>
</footer>
