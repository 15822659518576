<mat-toolbar class="d-flex flex-space-between mat-elevation-z3" color="primary">
    <button [routerLink]="['/timeline']" aria-label="Home" mat-icon-button matTooltip="Home">
        <img alt="Virtual Memory Box Logo" src="assets/images/vmb.svg">
    </button>
</mat-toolbar>
<main>
    <div class="mediumContainer">
        <div class="d-flex justify-content-center">
            <h1 class="mx-auto">Privacy Policy</h1>
        </div>
        <div *ngIf="!privacyDynamic && settings">
            <h2 class="text-center"><strong>{{ settings.authority_name }}, Children's Social Care</strong></h2>
            <h3 class="text-center"><strong>Virtual Memory Box Privacy Notice</strong></h3>
            <strong>1. Introduction</strong>
            <div class="ml-2">
                <p>This notice provides additional privacy information
                    regarding {{ settings.authority_name }}'s Virtual Memory Box.</p>
                <p>We will keep our privacy notice under regular review and will advise you of any
                    updates on our website.</p>
                <p>This Notice was last reviewed in October 2024.</p>

            </div>
            <strong>2. Who we are</strong>
            <div class="ml-2">
                <p>For the purposes of Data Protection legislation, {{ settings.authority_name }} is the
                    Data Controller.</p>
            </div>
            <strong>3. What type of personal information do we collect?</strong>
            <div class="ml-2">
                <p>We may collect the following types of personal information:</p>
                <ul>
                    <li>Name</li>
                    <li>Date of Birth</li>
                    <li>Email address</li>
                    <li>Photographs</li>
                    <li>Videos</li>
                    <li>Sound Recordings</li>
                    <li>Scanned images &amp; documents</li>
                    <li>Virtual Memory Box usernames &amp; passwords</li>
                    <li>Names of carers</li>
                    <li>Names of Social Workers</li>
                    <li>Case management unique identifier</li>
                    <li>Content comments and tags</li>
                    <li>I.P address</li>
                </ul>
            </div>
            <strong>4. How do we collect personal information?</strong>
            <div class="ml-2">
                <p>We may collect your personal information in a number of ways, for example:</p>
                <ul>
                    <li><span>In writing &ndash; for example letter or email</span></li>
                    <li><span>Electronic data file transfers</span></li>
                    <li><span>Online forms</span></li>
                    <li><span>Referrals from another department</span></li>
                    <li><span>Using our case management software</span></li>
                    <li><span>Family members/carers acting on your behalf</span></li>
                    <li><span>Social Care Staff and care staff acting on your behalf</span>
                    </li>
                </ul>
            </div>
            <strong>5. Why do we collect your personal information?</strong>
            <div class="ml-2">

                <p>TVirtual Memory Box is a secure method to store and record memories and life events across a living
                    timeline for children in care and care leavers. These files may be photographs, scanned documents
                    and other media type files. The platform creates an individual level child folder system that the
                    child, the child's responsible adults and social care staff have access to and can contribute
                    to.</p>
                <p>To create a unique Virtual Memory Box for each child we require basic demographic information such as
                    name and case management number to be able to identify individual users and ensure the relevant
                    adults and social care staff also have access. To confirm access is appropriate, details are
                    confirmed using the case management system, information from social care staff and carers.</p>
                <p>Passwords and usernames are stored by admin to facilitate access and recover when lost, content also
                    needs to be stored and readily available to access.</p>
            </div>
            <strong>6. Who might we share your personal information with?</strong>
            <div class="ml-2">


                <p>The information within each memory box will be shared with the social care staff, carers and child
                    connected to that child's memory box. The information will also be shared
                    with {{ settings.authority_name }} staff for the purpose of maintaining, administering and
                    developing the system.</p>
                <p>Nebula Labs are the software developer responsible for supporting the Virtual Memory Box. Nebula Labs
                    will have access to the contents of the system for the purpose of maintaining, developing and
                    ensuring the security of the contents of the system</p>
                <p>We will not share your personal information with any other third parties unless you have specifically
                    asked us to, or if we have a legal obligation to do so.</p>
            </div>
            <strong>7. How long will we keep your personal information?</strong>
            <div class="ml-2">
                <p>Given this is not part of your formal care record we will only hold your records until such a time as
                    we are safely able to transfer the contents to the child/care leaver. We will retain a copy of your
                    Virtual Memory Box for one year after the transfer date. Retention and transfer dates will be made
                    on a case-by-case decision dependent on the best interests of the child or young person.</p>
                <p> Generally, the contents will be transferred at the age of 25 but this will be reviewed to ensure it
                    is in the best interests of the young person to do so. Agreement will be reached by all parties if
                    it becomes obvious that it is in the child's best interest to transfer the contents before the age
                    of 25.</p>
            </div>
            <strong>8. What is our legal basis for using your personal information?</strong>
            <div class="ml-2">

                <p>To use your personal information there must be a lawful basis to do this, such as, through a
                    contract, performing a public task or where there is a legal obligation.</p>
                <p>Under the General Data Protection Regulation (GDPR), the lawful bases we rely on for processing this
                    information are:</p>
                <p>(a) Your consent. You are able to remove your consent at any time. You can do this by
                    contacting {{ settings.authority_name }} Children's Social Care</p>
                <p>(b) We have a legitimate interest.</p>
            </div>
            <strong>9. How do we keep your personal information secure?</strong>
            <div class="ml-2">
                <p>Information shared via the Virtual Memory Box is held securely on cloud storage network. Access to
                    the contents is via unique username and password and strengthened with multifactor
                    authentication.Only those that have been vetted by our local authority and deemed fit to access will
                    have access to the system and its contents. In order to prevent total loss, the contents will be
                    backed up on a periodic basis.</p>
            </div>
            <strong>10. Is your personal information used overseas?</strong>
            <div class="ml-2">
                <p>We will not process your personal information outside of the European Economic Area
                    (EEA).</p>
            </div>
            <strong>11. What are your rights?</strong>
            <div class="ml-2">
                <p>Your individual rights are set out in law. Subject to some legal exemptions, you have the following
                    rights:</p>
                <ul>
                    <li><span>Right to be informed</span></li>
                    <li><span>Right of access</span></li>
                    <li><span>Right to rectification</span></li>
                    <li><span>Right to erasure</span></li>
                    <li><span>Right to restrict processing</span></li>
                    <li><span>Right to data portability</span></li>
                    <li><span>Right to object</span></li>
                    <li><span>Rights in relating to automated decision making and profiling</span></li>
                </ul>
            </div>
            <strong>12. Contact us</strong>
            <p>If you would like to exercise your rights in relation to your personal information, or you feel that
                something has gone wrong with your personal information, please contact us by phone, email or
                writing. </p>
            <ol>
                <li>
                    By email:
                    <a href="mailto:{{ settings.authority_data_protection_email }}">{{ settings.authority_data_protection_email }}</a>
                </li>
                <li *ngIf="settings.authority_phone">By telephone: <a
                        href="tel:{{ settings.authority_phone }}">{{ settings.authority_phone }}</a></li>
                <li>In Writing: {{ settings.authority_name }}, {{ settings.authority_address }}</li>
            </ol>
            <p>If you feel that the Council has not handled your information correctly you can contact the
                Information Commissioner&rsquo;s Office (ICO).The
                ICO is the Government&rsquo;s Independent Body responsible for overseeing data protection.In most cases
                the ICO will only review cases that have exhausted the Council&rsquo;s internal procedures.</p>
            <p>The ICO&rsquo;s contact details are as follows: Information Commissioner&rsquo;s Office,
                Wycliffe House, Water Lane, Wilmslow, SK9 5AF. More information can be found on the ICO&rsquo;s website
                at <a href="http://www.ico.org.uk">www.ico.org.uk</a>.
            </p>
        </div>
        <div [innerHTML]="privacyDynamic">

        </div>
    </div>
</main>
<app-footer></app-footer>
