<mat-dialog-content class="d-flex flex-col">
    <ng-container *ngIf="currentMedia">

        <div class="flex-row flex-space-between">
            <div>
                <mat-card-header class="p-0">
                    <mat-card-title *ngIf="currentMedia?.title">
                        <h3 class="m-0">Move {{ currentMedia?.title }}</h3>
                    </mat-card-title>
                </mat-card-header>
            </div>
            <div class="float-end">
                <button class="mat-dialog-close " mat-dialog-close mat-icon-button matTooltip="Close Modal"
                        tabindex="-1">
                    <mat-icon>close</mat-icon>
                </button>
            </div>
        </div>
        <div class="d-flex flex-row mobile-col justify-content-center">
            <div class="leftSection flex-col flex-space-between">

                <div class="flex-col">
                    <mat-form-field appearance="outline" class="w-100 noBottomPadding" color="primary">
                        <mat-label>Album:</mat-label>
                        <select [(ngModel)]="album" matNativeControl>
                            <option *ngFor="let memory of albums" [selected]="album == memory.id"
                                    [value]="memory.id">{{ memory.title }}
                            </option>
                        </select>
                    </mat-form-field>
                    <div class="mt-10">
                        <button (click)="moveMedia()" [disabled]="!album" color="primary" mat-raised-button>Move
                        </button>
                    </div>
                </div>
                <div class="flex-col">
                    <small *ngIf=" currentMedia?.description">Description: {{ currentMedia?.description }}</small>
                    <small *ngIf="currentMedia.user">Author: {{ currentMedia.user?.full_name }}</small>
                    <small>Date: {{ currentMedia.created_date | date }}</small>
                </div>
            </div>
            <div class="flex-col w-100 px-10 pb-10">
                <app-media-display [file]="currentMedia"></app-media-display>

            </div>
        </div>
    </ng-container>
    <div *ngIf="!currentMedia" class="centerLoading">
        <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
    </div>
</mat-dialog-content>

