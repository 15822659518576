<main>
    <div class="d-flex flex-row justify-content-center text-center">
        <h3 *ngIf="timeline" class="m-10">
            {{ timeline.title }}
        </h3>

    </div>
    <div class="flex-col align-items-center">
        <p *ngIf="permission"
           [matTooltip]="permission == 'Add but not View or Comment' ? 'Without view access timeline results only include albums you have created': ''"
           class="clear text-center m-10">
            You can {{ permission == 'Add but not View or Comment' ? 'Add Only' : permission|lowercase }}
            <ng-container></ng-container>
        </p>
    </div>
    <div class="mediumContainer">
        <div class="d-flex flex-row align-items-center searchRow">
            <mat-form-field appearance="outline" class="w-100 noBottomPadding" color="primary">
                <mat-label>Search</mat-label>
                <input (keydown)="keydown()" (keyup)="keyup()"
                       (keyup.enter)="getTimeline()"
                       [(ngModel)]="searchTerm"
                       matInput
                       type="text"/>
                <button (click)="getTimeline()" (keyup.enter)="getTimeline()" [disabled]="!searchTerm"
                        aria-label="Search"
                        mat-icon-button matPrefix matTooltip="Search Memories">
                    <mat-icon color="primary">
                        search
                    </mat-icon>
                </button>
                <button (click)="clearSearch()" (keyup.enter)="clearSearch()" *ngIf="searchTerm"
                        aria-label="Clear Search" mat-icon-button matSuffix>
                    <mat-icon>close</mat-icon>
                </button>
            </mat-form-field>

            <button (click)="newMemory()" (keyup.enter)="newMemory()"
                    *ngIf="permission == 'View, Comment and Add' || permission == 'Add but not View or Comment'"
                    aria-label="Clear" class="mat-elevation-z2" color="primary"
                    mat-mini-fab matPrefix matTooltip="New Album">
                <mat-icon>
                    add
                </mat-icon>
            </button>
            <button (click)="exportTimeline()" (keyup.enter)="exportTimeline()"
                    *ngIf="this.user && this.user.role && this.user.role.role_type && this.user.role.role_type == 'admin'"
                    aria-label="Clear" class="mat-elevation-z2" color="primary"
                    mat-mini-fab matPrefix matTooltip="Request export">
                <mat-icon>
                    cloud_download
                </mat-icon>
            </button>
        </div>
        <div class="filtersRow">
            <div>
                <mat-form-field appearance="outline" class="w-100 noBottomPadding" color="primary">
                    <mat-label>Enter a date range</mat-label>
                    <mat-date-range-input [max]="maxDate" [min]="minDate" [rangePicker]="picker">
                        <input (dateInput)="this.dateChange($event)" [formControl]="startDateField" [readonly]="true"
                               matInput
                               matStartDate placeholder="Start date">
                        <input (dateInput)="this.dateChange($event, true)" [formControl]="endDateField"
                               [readonly]="true" matEndDate
                               matInput placeholder="End date">
                    </mat-date-range-input>
                    <mat-datepicker-toggle [for]="picker" matIconSuffix></mat-datepicker-toggle>
                    <mat-date-range-picker #picker></mat-date-range-picker>
                    <button (click)="clearDate()" (keyup.enter)="clearDate()" *ngIf="this.endDate || this.startDate"
                            aria-label="Clear Date"
                            mat-icon-button matSuffix>
                        <mat-icon>close</mat-icon>
                    </button>
                    <mat-error>
                        Please enter the date in a dd/mm/yyyy format
                    </mat-error>
                </mat-form-field>
            </div>
            <div>
                <mat-form-field appearance="outline" class="w-100 noBottomPadding" color="primary">
                    <mat-label>Order</mat-label>
                    <select [ngModel]="order" (ngModelChange)="getTimeline($event)" matNativeControl>
                        <option selected value="desc">Newest - Oldest</option>
                        <option value="asc">Oldest - Newest</option>
                    </select>
                </mat-form-field>
            </div>
            <div>
                <mat-form-field appearance="outline" class="w-100 noBottomPadding" color="primary">
                    <mat-label>Tags</mat-label>
                    <input #tagInput [formControl]="tagSearchControl"
                           [matAutocomplete]="auto"
                           [matChipInputFor]="chipList"
                           matInput
                           placeholder="Select Tags">
                    <mat-autocomplete #auto="matAutocomplete"
                                      (optionSelected)="selectTag($event.option.value)">
                        <ng-container *ngFor="let tag of filteredTags | async">
                            <mat-option *ngIf="!checkIfInArray(tag, this.selectedTags)"
                                        [value]="tag.id">
                                {{ tag.name }}
                            </mat-option>
                        </ng-container>
                    </mat-autocomplete>
                </mat-form-field>
                <mat-chip-grid #chipList aria-label="Tags Selection">
                    <ng-container *ngFor="let tag of this.tags">
                        <mat-chip (removed)="removeTag(tag.id)"
                                  *ngIf="this.selectedTags.includes(tag.id)" class="smChip">
                            {{ tag.name }}
                            <button matChipRemove>
                                <mat-icon>cancel</mat-icon>
                            </button>
                        </mat-chip>
                    </ng-container>
                </mat-chip-grid>
            </div>
        </div>
        <mat-progress-bar *ngIf="loading" color="primary" mode="indeterminate"></mat-progress-bar>
        <div *ngIf="(!years || (years.length == 1 && years[0].memories.length < 1)) && !loading" class="text-center">
            <p *ngIf="this.tags.length < 0|| this.endDate || this.startDate||this.searchTerm else noFilters">
                No albums available with selected filters.
            </p>
            <ng-template #noFilters>
                <p>No albums available yet!</p>
            </ng-template>

        </div>

        <ng-container *ngFor="let year of years; let i = index">
            <ng-container *ngIf="year.memories.length > 0">
                <div class="flex-row align-items-center">
                    <hr class="w-100 h-fit">
                    <p class="year">{{ year.year }}</p>
                    <hr class="w-100 h-fit">
                </div>
                <div class="memoriesContainer">
                    <mat-card
                            (keydown.enter)="this.timeline.child.id == this.user.id ? navigate('/favourites') : navigate('/favourites', this.timeline.child.id);"
                            *ngIf="i == 0 && permission && permission !== 'Add but not View or Comment'"
                            [attr.aria-label]="'View Favourites'"
                            [routerLink]="this.timeline.child.id == this.user.id ? ['/favourites' ] : ['/favourites' , this.timeline.child.id] "
                            class="clickable">
                        <img (contextmenu)="false"
                             [ngClass]="favouritesThumbnail ? 'squareImage' :'favImage'"
                             [src]="favouritesThumbnail"
                             alt="" class="primaryBg" mat-card-image>
                        <mat-card-header class="flex-space-between">
                            <mat-card-title>Favourite Memories</mat-card-title>
                        </mat-card-header>
                        <mat-card-content>
                        </mat-card-content>
                    </mat-card>

                    <mat-card *ngFor="let memory of year.memories"
                              [attr.aria-label]="'View Album:' + memory.title "
                              [routerLink]="['/memory', memory.id] " class="clickable">
                        <img (contextmenu)="false"
                             [ngClass]="memory.cover_image?.thumbnail || memory.cover_image?.image ? 'squareImage' :'favImage'"
                             [src]="memory.cover_image?.thumbnail || memory.cover_image?.image||'assets/images/vmb.png' "
                             alt="" class="primaryBg" mat-card-image>
                        <mat-card-header class="flex-space-between">
                            <mat-card-title>{{ memory.title }}</mat-card-title>
                        </mat-card-header>
                        <mat-card-content>
                            <small>{{ memory.date | date:'MMM dd' }}</small>
                        </mat-card-content>
                    </mat-card>
                </div>
            </ng-container>
        </ng-container>

    </div>
</main>
