import {Component} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {AuthService} from "../_services/auth.service";
import {ContentService} from "../_services/content.service";
import {SnackbarService} from "../_services/snackbar.service";

@Component({
    selector: 'app-profile',
    templateUrl: './profile.component.html',
    styleUrl: './profile.component.scss'
})
export class ProfileComponent {
    user: any;
    profile: any;
    id: number = 0;
    timelineId: number = 0;
    permissions: any[] | null = null;

    constructor(private snackBarService: SnackbarService,
                private route: ActivatedRoute,
                private authService: AuthService,
                private contentService: ContentService,
                private router: Router) {
        this.user = this.authService.getUser();
        this.route.params
            .subscribe(params => {
                this.id = params['id'];
                this.timelineId = params['timelineId'];
                if (this.id == 0 && this.timelineId) {
                    this.contentService.viewTimeline(this.timelineId).subscribe(r => {
                        this.profile = r.child;
                        this.id = r.child.id;
                        if (!this.user.role.can_access_profiles && this.user.id != this.id) {
                            this.snackBarService.openSnackBar('Sorry your user does not have access to view this profile', 'error')
                            this.router.navigate(['/noAccess']);
                        }
                        this.contentService.viewTimelinePermissions(this.timelineId).subscribe(res => {
                            this.permissions = res;
                            // Filter out current user?
                            // this.permissions = [];
                            // for (let permission of res) {
                            //     if (permission.user.id !== this.id) {
                            //         this.permissions.push(permission)
                            //     }
                            // }
                        }, e => {
                            this.snackBarService.openSnackBar(e, 'error')
                        })
                    }, e => {
                        this.router.navigate(['/people']);
                        this.snackBarService.openSnackBar(e, 'error')
                    })
                } else {
                    if (!this.user.role.can_access_profiles && this.user.id != this.id) {
                        this.snackBarService.openSnackBar('Sorry your user does not have access to view this profile', 'error')
                        this.router.navigate(['/noAccess']);
                    }

                    this.contentService.getUserProfile(this.id).subscribe(r => {
                        this.profile = r;
                    }, e => {
                        this.router.navigate(['/people']);
                        this.snackBarService.openSnackBar(e, 'error')
                    })
                }

            }, error => {
                this.snackBarService.openSnackBar(error, 'error')
                console.error(error);
            });

    }

    back(): void {
        history.back();
    }

}
