import {Component} from '@angular/core';
import {AuthService} from "../_services/auth.service";
import {ContentService} from "../_services/content.service";

@Component({
    selector: 'app-privacy',
    templateUrl: './privacy.component.html',
    styleUrl: './privacy.component.scss'
})
export class PrivacyComponent {
    privacyDynamic: string = '';
    settings: any;

    constructor(private authService: AuthService,
                private contentService: ContentService) {
        this.authService.getDynamicSettings().then((r): void => {
            this.settings = r;
        });

        this.contentService.getPrivacyPolicy().subscribe(r => {
            if (r && r.rendered) {
                this.privacyDynamic = r.rendered;
            }
        }, e => {
            console.error(e)
        })

    }
}
