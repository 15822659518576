import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from "@angular/common/http";
import {environment} from "../../environments/environment";
import {Observable} from "rxjs";

@Injectable({
    providedIn: 'root'
})
export class ContentService {

    constructor(private http: HttpClient) {
    }


    dynamicSettings(): Observable<any> {
        return this.http.get<any>(`${environment.apiUrl}v2/api/common/dynamic-settings/`);
    }

    getPrivacyPolicy(): Observable<any> {
        return this.http.get<any>(`${environment.apiUrl}v2/api/tos/privacy/`);
    }

    getTerms(): Observable<any> {
        return this.http.get<any>(`${environment.apiUrl}v2/api/tos/terms/`);
    }

    //  Training Centre

    faqs(userType?: string): Observable<any> {
        let parameters: HttpParams = new HttpParams();
        if (userType) {
            parameters = parameters.set('faq_type', userType);
        }
        return this.http.get<any>(`https://content.virtualmemorybox.co.uk/content/faqs/`, {
            params: parameters,
        });
    }

    libraryCategories(): Observable<any> {
        return this.http.get<any>(`https://content.virtualmemorybox.co.uk/content/library/`);
    }

    helpfulLinks(): Observable<any> {
        return this.http.get<any>(`https://content.virtualmemorybox.co.uk/content/helpful-links/`);
    }

    videoGuides(userType?: string): Observable<any> {
        let parameters: HttpParams = new HttpParams();
        if (userType) {
            parameters = parameters.set('training_center_type', userType);
        }
        return this.http.get<any>(`https://content.virtualmemorybox.co.uk/content/training/`, {
            params: parameters,
        });
    }

    writtenGuides(userType?: string): Observable<any> {
        let parameters: HttpParams = new HttpParams();
        if (userType) {
            parameters = parameters.set('training_center_type', userType);
        }
        return this.http.get<any>(`https://content.virtualmemorybox.co.uk/content/guides/`, {
            params: parameters,
        });
    }


    // Feedback
    feedbackCategories(): Observable<any> {
        return this.http.get<any>(`${environment.apiUrl}v2/api/feedback/categories/`);
    }

    feedback(body: any): Observable<any> {
        return this.http.post<any>(`${environment.apiUrl}v2/api/feedback/`, body);
    }

    getAllTags(): Observable<any> {
        return this.http.get<any>(`${environment.apiUrl}v2/api/tag-options/`);
    }


    // Users
    getUserProfile(id: number): Observable<any> {
        return this.http.get<any>(`${environment.apiUrl}v2/api/profile/` + id + '/');
    }

    getUserTimeline(id: number): Observable<any> {
        return this.http.get<any>(`${environment.apiUrl}v2/api/timelines/user/` + id + '/');
    }

    getRelatedUsers(search?: string, page: number = 0, limit: number = 2000): Observable<any> {
        let parameters: HttpParams = new HttpParams();
        const offset: number = (page * limit);
        parameters = parameters.set('offset', offset);
        parameters = parameters.set('limit', limit);
        if (search) {
            parameters = parameters.set('search', search);
        }
        return this.http.get<any>(`${environment.apiUrl}v2/api/timelines/`, {
            params: parameters,
        });
    }

    getWriteAccessUsers(): Observable<any> {
        return this.http.get<any>(`${environment.apiUrl}v2/api/timelines/write-access/`);
    }

    // Timelines
    viewTimeline(id: number): Observable<any> {
        return this.http.get<any>(`${environment.apiUrl}v2/api/timelines/` + id + '/');
    }

    viewMyTimeline(): Observable<any> {
        return this.http.get<any>(`${environment.apiUrl}v2/api/timelines/me/`);
    }

    viewTimelineList(id: number, sort?: string, min?: string, max?: string, search?: string, tags?: any[]) {
        let parameters: HttpParams = new HttpParams();
        if (min) {
            parameters = parameters.set('min', min);
        }
        if (max) {
            parameters = parameters.set('max', max);
        }
        if (search) {
            parameters = parameters.set('search', search);
        }
        if (tags) {
            for (let tag of tags) {
                parameters = parameters.append('tags[]', tag)
            }
        }
        if (sort) {
            parameters = parameters.set('sort', sort);
        }

        return this.http.get<any>(`${environment.apiUrl}v2/api/timelines/years/` + id + '/', {
            params: parameters,
        });
    }


    // Memories
    getMemory(id: number): Observable<any> {
        return this.http.get<any>(`${environment.apiUrl}v2/api/memory/` + id + '/');
    }

    getMemoryMedia(id: number, page: number, limit: number): Observable<any> {
        const offset: number = (page * limit);
        return this.http.get<any>(`${environment.apiUrl}v2/api/memory/` + id + '/media/?offset=' + offset + '&limit=' + limit);
    }

    getMemoryTags(id: number): Observable<any> {
        return this.http.get<any>(`${environment.apiUrl}v2/api/memory/` + id + '/tags/');
    }

    patchMemory(id: number, body: any): Observable<any> {
        return this.http.patch<any>(`${environment.apiUrl}v2/api/memory/` + id + '/', body);
    }

    createMemory(id: number, body: any): Observable<any> {
        return this.http.post<any>(`${environment.apiUrl}v2/api/timelines/` + id + '/', body);
    }

    duplicateMemory(memoryId: number, timelineId: number): Observable<any> {
        return this.http.post<any>(`${environment.apiUrl}v2/api/memory/` + memoryId + '/duplicate/' + timelineId + '/', {});
    }

    getMemoryComments(id: number): Observable<any> {
        return this.http.get<any>(`${environment.apiUrl}v2/api/memory/` + id + '/comments/');
    }

    createMemoryComment(id: number, message: string): Observable<any> {
        return this.http.post<any>(`${environment.apiUrl}v2/api/memory/` + id + '/comments/', {content: message});
    }

    addTag(id: number, tag: string): Observable<any> {
        return this.http.post<any>(`${environment.apiUrl}v2/api/memory/` + id + '/tags/', {name: tag});
    }

    deleteTag(id: number, tag: string): Observable<any> {
        let parameters: HttpParams = new HttpParams();
        parameters = parameters.set('name', tag);

        return this.http.delete<any>(`${environment.apiUrl}v2/api/memory/` + id + '/tags/', {
            params: parameters,
        });

    }

    getParamFromUrl(urlString: string, searchParam: string) {
        // Split the URL string by the '?' character to separate parameters
        const urlParams = urlString?.split('?');

        if (urlParams.length < 2) {
            return null; // No query parameters found
        }

        // Loop through each parameter (separated by '&')
        for (const param of urlParams[1].split('&')) {
            const [key, value] = param.split('=');

            // Check if the key matches param
            if (key === searchParam) {
                return value;
            }
        }
        return null;
    }


    addHyphenToDate(date: string): string {
        // Re add essential elements to utc format
        date = date.slice(0, 4) + '-' + date.slice(4);
        date = date.slice(0, 7) + '-' + date.slice(7);
        date = date.slice(0, 13) + ':' + date.slice(13);
        date = date.slice(0, 16) + ':' + date.slice(16);
        date = date.slice(0, 19) + '.000' + date.slice(19);
        return date
    }

    // Media
    // Detect if s3 bucket url has expired if so fetch media and return the correct url.
    checkIfMediaUrlExpired(url: string, key: string, id: number): Promise<any> {
        return new Promise((resolve, reject): void => {

            this.getMedia(id).subscribe(r => {
                resolve(r[key])
            }, e => {
                reject(e)
            })
            //  No longer want to check if s3 bucket is expired want to call the api each time for session timeout reasons
            // let expiryDate = null
            // let creationDate = null
            // let expiresInSecs = null
            // if (url) {
            //     creationDate = this.getParamFromUrl(url, 'X-Amz-Date');
            //     expiresInSecs = this.getParamFromUrl(url, 'X-Amz-Expires');
            // }
            // if (creationDate && expiresInSecs) {
            //     creationDate = new Date(this.addHyphenToDate(creationDate));
            //     expiryDate = new Date(creationDate.getTime() + (JSON.parse(expiresInSecs) * 1000));
            // }
            // let isExpired: boolean = true;
            //
            // if (expiryDate) {
            //     isExpired = expiryDate.getTime() < new Date().getTime();
            // }
            // // Check from url if expired. If so then re-fetch the url
            // if (isExpired) {
            //     this.getMedia(id).subscribe(r => {
            //         resolve(r[key])
            //     }, e => {
            //         reject(e)
            //     })
            //
            // } else {
            //     resolve(url)
            // }
        })
    }

    async toDataURL(url: string): Promise<any> {
        const blob = await fetch(url, {headers: new Headers({'Authorization': 'Bearer ' + localStorage.getItem('access_token')})}).then(res => res.blob());
        return URL.createObjectURL(blob);
    }

    async downloadMedia(media: any): Promise<void> {
        const a: HTMLAnchorElement = document.createElement("a");
        a.href = await this.toDataURL(`${environment.apiUrl}v2/api/media/` + media.id + '/download/');
        a.download = (media.title || 'download');
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    }

    async checkMedia(media: any): Promise<any> {
        return new Promise((resolve, reject): void => {
            // Check if s3 bucket link is expired replace if so
            if (media.image) {
                this.checkIfMediaUrlExpired(media.image, 'image', media.id).then(function (response) {
                    if (response) {
                        media.image = response;
                        resolve(media)
                    } else {
                        console.error('No response received from validity checker')
                    }
                }).catch(e => {
                    console.error(e)
                });
            } else if (media.document) {
                this.checkIfMediaUrlExpired(media.document, 'document', media.id).then(function (response) {
                    if (response) {
                        media.document = response;
                        resolve(media)
                    } else {
                        console.error('No response received from validity checker')
                    }
                }).catch(e => {
                    console.error(e)
                });
            } else if (media.audio) {
                this.checkIfMediaUrlExpired(media.audio, 'audio', media.id).then(function (response) {
                    if (response) {
                        media.audio = response;
                        resolve(media)
                    } else {
                        console.error('No response received from validity checker')
                    }
                }).catch(e => {
                    console.error(e)
                });
            } else if (media.video) {
                this.checkIfMediaUrlExpired(media.video, 'video', media.id).then(function (response) {
                    if (response) {
                        media.video = response;
                        resolve(media)
                    } else {
                        console.error('No response received from validity checker')
                    }
                }).catch(e => {
                    console.error(e)
                });
            }
        });
    }


    getMediaComments(id: number): Observable<any> {
        return this.http.get<any>(`${environment.apiUrl}v2/api/media/` + id + '/comments/');
    }

    getMedia(id: number): Observable<any> {
        return this.http.get<any>(`${environment.apiUrl}v2/api/media/` + id + '/');
    }

    deleteMedia(memoryId: number): Observable<any> {
        return this.http.delete<any>(`${environment.apiUrl}v2/api/media/` + memoryId + '/hard-delete/', {});
    }

    deleteAlbum(albumId: number): Observable<any> {
        return this.http.delete<any>(`${environment.apiUrl}v2/api/memory/` + albumId + '/hard-delete/', {});
    }

    hideMedia(memoryId: number): Observable<any> {
        return this.http.post<any>(`${environment.apiUrl}v2/api/media/` + memoryId + '/hide/', {});
    }

    unHideMedia(memoryId: number): Observable<any> {
        return this.http.delete<any>(`${environment.apiUrl}v2/api/media/` + memoryId + '/hide/');
    }

    createMediaComment(id: number, message: string): Observable<any> {
        return this.http.post<any>(`${environment.apiUrl}v2/api/media/` + id + '/comments/', {content: message});
    }

    uploadMedia(memoryId: number, media: any, file: any): Observable<any> {
        const headerAttachmentNameValue: string = 'attachment; filename="' + media.title + '"';
        const headers = {
            'Content-Disposition': headerAttachmentNameValue,
        };
        const fd: FormData = new FormData();
        fd.append('title', media.title)
        fd.append('description', media.description)
        fd.append('type', media.type)
        if (file) {
            fd.append('file', file);
        }
        if (media.cropped) {
            fd.append('cropped', media.cropped);
        }

        if (media.added_at) {
            fd.append('added_at', media.added_at);
        }
        return this.http.post<any>(`${environment.apiUrl}v2/api/upload/` + memoryId + '/', fd, {
            headers,
        });
    }

    patchMedia(mediaId: number, media: any, file?: any): Observable<any> {
        const headerAttachmentNameValue: string = 'attachment; filename="' + media.title + '"';
        const headers = {
            'Content-Disposition': headerAttachmentNameValue,
        };
        const fd: FormData = new FormData();
        if (media.title != undefined) {
            fd.append('title', media.title)
        }
        if (media.description != undefined) {
            fd.append('description', media.description)
        }
        if (media.cover_image != undefined) {
            fd.append('cover_image', media.cover_image)
        }
        if (media.type) {
            fd.append('type', media.type)
        }
        if (media.cropped) {
            fd.append('cropped', media.cropped)
        }
        if (media.order != null) {
            fd.append('order', media.order)
        }
        if (file) {
            fd.append('file', file);
        }
        return this.http.patch<any>(`${environment.apiUrl}v2/api/media/` + mediaId + '/', fd, {
            headers,
        });
    }

    getFavouriteMedia(page: number, limit: number, id?: number, type?: string): Observable<any> {
        let parameters: HttpParams = new HttpParams();
        if (type) {
            parameters = parameters.set('type', type);
        }
        const offset: number = (page * limit);
        if (id) {
            return this.http.get<any>(`${environment.apiUrl}v2/api/favourites/media/${id}/?offset=` + offset + '&limit=' + limit, {
                params: parameters,
            });
        } else {
            return this.http.get<any>(`${environment.apiUrl}v2/api/favourites/media/?offset=` + offset + '&limit=' + limit, {
                params: parameters,
            });
        }
    }

    getHiddenMedia(id: number, page: number, limit: number, type?: string): Observable<any> {
        let parameters: HttpParams = new HttpParams();
        if (type) {
            parameters = parameters.set('type', type);
        }
        const offset: number = (page * limit);
        return this.http.get<any>(`${environment.apiUrl}v2/api/timelines/hidden/${id}/media/hidden/?offset=` + offset + '&limit=' + limit, {
            params: parameters,
        });
    }

    favouriteMedia(id: number): Observable<any> {
        return this.http.post<any>(`${environment.apiUrl}v2/api/favourites/media/`, {media: id});
    }

    unFavouriteMedia(id: number): Observable<any> {
        let parameters: HttpParams = new HttpParams();
        if (id) {
            parameters = parameters.set('media', id);
        }
        return this.http.delete<any>(`${environment.apiUrl}v2/api/favourites/media/`,
            {params: parameters,});
    }

    moveMedia(media_id: number, memory_id: number): Observable<any> {
        return this.http.post<any>(`${environment.apiUrl}v2/api/media/${media_id}/move/${memory_id}/`, {});
    }

    reOrderMedia(order: any): Observable<any> {
        return this.http.post<any>(`${environment.apiUrl}v2/api/media/reorder/`, {items: order});
    }

    // Notifications


    // permissions
    rejectPermissionRequest(id: number): Observable<any> {
        return this.http.post<any>(`${environment.apiUrl}v2/api/permission/request/reject/`, {
            permission_change_request: id
        });
    }

    acceptPermissionRequest(id: number): Observable<any> {
        return this.http.post<any>(`${environment.apiUrl}v2/api/permission/request/accept/`, {
            permission_change_request: id
        });
    }

    viewTimelinePermissions(id: number): Observable<any> {
        return this.http.get<any>(`${environment.apiUrl}v2/api/timelines/permissions/` + id + '/');
    }

    requestPermissionUpdate(id: number, body: any): Observable<any> {
        return this.http.post<any>(`${environment.apiUrl}v2/api/timelines/permissions/` + id + '/change/request/', body);
    }


//  Export

    retrieveTimelineExport(uuid: number): Observable<any> {
        return this.http.get<any>(`${environment.apiUrl}v2/api/timeline/exports/` + uuid + '/');
    }

    downloadTimelineExport(uuid: string): Observable<any> {
        return this.http.get<any>(`${environment.apiUrl}v2/api/timeline/exports/` + uuid + '/download/');
    }

    requestExport(id: number): Observable<any> {
        return this.http.post<any>(`${environment.apiUrl}v2/api/timelines/export/request/` + id + '/', {});
    }


//     Comments
    editComment(comment_id: number, content: string, mediaComment: boolean): Observable<any> {
        let parameters: HttpParams = new HttpParams();
        if (mediaComment) {
            parameters = parameters.set('is_media', true);
        }
        return this.http.patch<any>(`${environment.apiUrl}v2/api/comment/` + comment_id, {content}, {params: parameters});
    }

    deleteComment(comment_id: number, mediaComment: boolean): Observable<any> {
        let parameters: HttpParams = new HttpParams();
        if (mediaComment) {
            parameters = parameters.set('is_media', true);
        }
        return this.http.delete<any>(`${environment.apiUrl}v2/api/comment/` + comment_id, {
            params: parameters,
        });
    }


//   Other
    healthCheck(): Observable<any> {
        return this.http.get<any>(`${environment.apiUrl}ht/`, {});
    }


}
