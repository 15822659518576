<mat-dialog-content aria-modal="true" class="d-flex flex-col" role="dialog">
    <ng-container *ngIf="currentMedia">

        <div class="flex-row flex-space-between">
            <div>
                <mat-card-header class="p-0">
                    <mat-card-title *ngIf="currentMedia?.title">
                        <h3 class="m-0">{{ currentMedia?.title }}</h3>
                    </mat-card-title>
                </mat-card-header>
            </div>
            <div class="float-end">
                <button (keyup.enter)="this.dialogRef.close();" class="mat-dialog-close " mat-dialog-close
                        mat-icon-button
                        matTooltip="Close Window">
                    <mat-icon>close</mat-icon>
                </button>
            </div>
        </div>

        <div [class.commentControl]="commenting || flagging" class="d-flex flex-row mobile-col justify-content-center">
            <app-comments (comment)="sendComment($event)" *ngIf="commenting"
                          [comments]="this.media[this.index].comments"
                          [reLoading]="commentsLoading"
                          class="flex-grow"></app-comments>
            <ng-container *ngIf="flagging">
                <div class="leftSection">
                    <form [formGroup]="flagForm" class="formFieldContainer ">
                        <h3>Flag Media</h3>
                        <mat-form-field appearance="outline" class="w-100 noBottomPadding" color="primary">
                            <mat-label>Feedback Type</mat-label>
                            <select formControlName="category" matNativeControl>
                                <option *ngFor="let category of categories" [value]="category">{{ category }}</option>
                            </select>
                        </mat-form-field>
                        <mat-form-field appearance="outline">
                            <mat-label>Title</mat-label>
                            <input formControlName="title" matInput placeholder="Title">
                        </mat-form-field>
                        <mat-form-field appearance="outline">
                            <mat-label>Description</mat-label>
                            <textarea formControlName="description" matInput
                                      maxlength="400"></textarea>
                        </mat-form-field>

                        <div class="d-flex flex-space-around">
                            <button (click)="flagging =!flagging" (keyup.enter)="flagging =!flagging"
                                    class="mat-elevation-z2" color="warn"
                                    mat-raised-button
                                    matTooltip="Cancel flag">
                                Cancel
                            </button>
                            <button (click)="flagMedia()" (keyup.enter)="flagMedia()" [disabled]="flagForm.invalid"
                                    class="mat-elevation-z2"
                                    color="primary" mat-raised-button
                                    matTooltip="Submit Flag">
                                Submit
                            </button>
                        </div>
                    </form>
                </div>
            </ng-container>
            <div class="flex-col w-100 p-10">
                <app-media-display (pauseSlideshow)="stopSlideshow()" [file]="currentMedia"></app-media-display>
                <small *ngIf=" currentMedia?.description">Description: {{ currentMedia?.description }}</small>
                <small *ngIf="currentMedia.user">Uploaded by: {{ currentMedia.user?.full_name }}</small>
                <small>Uploaded on: {{ currentMedia.created_date | date }}</small>
            </div>
        </div>
    </ng-container>
    <div *ngIf="!currentMedia" class="centerLoading">
        <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
    </div>
</mat-dialog-content>
<mat-dialog-actions *ngIf="currentMedia">
    <mat-progress-bar *ngIf="loading" class="col-12" color="primary" mode="indeterminate"></mat-progress-bar>
    <div class="flex-row flex-space-between w-100 align-items-center">
        <button (click)="move(true)" (keyup.enter)="move(true)" *ngIf="media && this.media.length > 1"
                aria-label="Show Previous Item"
                color="primary" mat-icon-button
                matTooltip="Previous item">
            <mat-icon>keyboard_arrow_left</mat-icon>
        </button>
        <div class="btnRow">
            <button (click)="download()" (keyup.enter)="download()" *ngIf="currentMedia"
                    aria-label="Download" class="mat-elevation-z2"
                    color="primary" mat-mini-fab matTooltip="Download">
                <mat-icon>get_app</mat-icon>
            </button>
            <button (click)="editItem()" (keyup.enter)="editItem()"
                    *ngIf="permission == 'View, Comment and Add' || permission == 'Add but not View or Comment'"
                    aria-label="Edit Media" class="mat-elevation-z2" color="primary" mat-mini-fab matTooltip="Edit">
                <mat-icon>edit</mat-icon>
            </button>
            <button (click)="favouriteMedia()" (keyup.enter)="favouriteMedia()"
                    *ngIf="canFavourite"
                    [attr.aria-label]="currentMedia.is_favourite ? 'Unfavourite Media' : 'Favourite Media'"
                    [matTooltip]="currentMedia.is_favourite ? 'Unfavourite Media' : 'Favourite Media'"
                    class="mat-elevation-z2"
                    color="primary"
                    mat-mini-fab>
                <mat-icon *ngIf="!currentMedia.is_favourite">
                    favorite_border
                </mat-icon>
                <mat-icon *ngIf="currentMedia.is_favourite" class="mat-accent" color="accent">
                    favorite
                </mat-icon>
            </button>
            <button (click)="toggleCommenting()" (keyup.enter)="toggleCommenting()"
                    *ngIf="permission == 'View, Comment and Add' || permission == 'View and Comment'"
                    [attr.aria-label]="commenting ? 'Hide Comments' : 'Show Comments'"
                    [color]="!this.commenting? 'primary' : 'accent'"
                    [matBadgeHidden]="currentMedia.comment_count < 1"
                    [matBadge]="currentMedia.comment_count"
                    [matTooltip]="commenting ? 'Hide Comments' : 'Show Comments'"
                    class="mat-elevation-z2" color="primary"
                    mat-mini-fab matBadgeColor="accent">
                <mat-icon>
                    chat
                </mat-icon>
            </button>
            <button (click)="hideMedia()" (keyup.enter)="hideMedia()"
                    *ngIf="this.user.role.can_hide_media && !currentMedia.hidden"
                    aria-label="Delete Media"
                    class="mat-elevation-z2"
                    color="warn"
                    mat-mini-fab [matTooltip]="this.user.role.can_permanently_delete?'Hide Media' :'Delete Media'">
                <mat-icon *ngIf="this.user.role.can_permanently_delete">visibility_off</mat-icon>
                <mat-icon *ngIf="!this.user.role.can_permanently_delete">delete</mat-icon>
            </button>
            <button (click)="deleteMedia()" (keyup.enter)="deleteMedia()"
                    *ngIf="this.user.role.can_permanently_delete"
                    aria-label="Permanently Delete Media"
                    class="mat-elevation-z2"
                    color="warn"
                    mat-mini-fab matTooltip="Permanently Delete Media">
                <mat-icon>delete_forever</mat-icon>
            </button>
            <button (click)="unHideMedia()" (keyup.enter)="unHideMedia()"
                    *ngIf="this.user.role.can_hide_media && currentMedia.hidden"
                    aria-label="Restore Media"
                    class="mat-elevation-z2"
                    color="accent"
                    mat-mini-fab matTooltip="Restore Media">
                <mat-icon>restore_from_trash</mat-icon>
            </button>
            <button (click)="toggleFlagMedia()" (keyup.enter)="toggleFlagMedia()"
                    [attr.aria-label]="flagging ? 'Close Flag Media' : 'Flag Form'"
                    [color]="!this.flagging? 'primary' : 'accent'"
                    [matTooltip]="flagging ? 'Close Flag Media' : 'Flag Form'"
                    class="mat-elevation-z2" color="warn"
                    mat-mini-fab>
                <mat-icon>flag</mat-icon>
            </button>
            <button (click)="toggleSlideshow()" (keyup.enter)="toggleSlideshow()" *ngIf="media && this.media.length > 1"
                    [attr.aria-label]="slideshowInProgress ? 'Pause Slideshow' : 'Play Slideshow'"
                    [color]="!this.slideshowInProgress? 'primary' : 'accent'"
                    [matTooltip]="slideshowInProgress ? 'Pause Slideshow' : 'Play Slideshow'"
                    class="mat-elevation-z2"
                    mat-mini-fab>
                <mat-icon *ngIf="!slideshowInProgress">play_arrow</mat-icon>
                <mat-icon *ngIf="slideshowInProgress">stop</mat-icon>
            </button>
            <button (click)="openKeyboardShortcuts()" (keyup.enter)="openKeyboardShortcuts()"
                    class="mat-elevation-z2"
                    color="primary"
                    mat-mini-fab matTooltip="View Keyboard Shortcuts">
                <mat-icon>keyboard</mat-icon>
            </button>
        </div>
        <button (click)="move()" (keyup.enter)="move()" *ngIf="media && this.media.length > 1"
                aria-label="Show Next Item" cdkFocusInitial
                class="ms-auto"
                color="primary"
                mat-icon-button matTooltip="Next item">
            <mat-icon>keyboard_arrow_right</mat-icon>
        </button>
    </div>

</mat-dialog-actions>

