import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {AuthService} from "../_services/auth.service";
import {SnackbarService} from "../_services/snackbar.service";
import {ContentService} from "../_services/content.service";

@Component({
    selector: 'app-download-timeline',
    templateUrl: './download-timeline.component.html',
    styleUrl: './download-timeline.component.scss'
})
export class DownloadTimelineComponent implements OnInit {
    user: any;

    constructor(private route: ActivatedRoute,
                private readonly router: Router,
                private authService: AuthService,
                private contentService: ContentService,
                private snackbarService: SnackbarService
    ) {
        this.user = this.authService.getUser();
    }

    ngOnInit(): void {
        this.route.params
            .subscribe(params => {
                    if (params['uuid'] && (this.user && this.user.role && this.user.role.role_type && this.user.role.role_type == 'admin')) {
                        this.contentService.retrieveTimelineExport(params['uuid']).subscribe(r => {
                            this.contentService.downloadTimelineExport(params['uuid']).subscribe(r => {
                                if (r.success && r.url) {
                                    window.open(r.url, '_blank')?.focus();
                                    this.snackbarService.openSnackBar('Download in progress. If your download does not start, please disable any pop-up blockers for this page.', 'success')
                                    if (this.user.role.role_type == "young_person") {
                                        // Will be child or adult or admin in prod
                                        this.router.navigate(['/timeline']);
                                    } else {
                                        this.router.navigate(['/people']);
                                    }
                                }
                            }, e => {
                                this.snackbarService.openSnackBar(e, 'error')
                            })
                        }, e => {
                            this.snackbarService.openSnackBar(e, 'error')
                        })
                    } else {
                        this.snackbarService.openSnackBar('Sorry you do not have permission to download this timeline export', 'error')
                        this.router.navigate(['/noAccess']);
                    }
                }
            );
    }
}
