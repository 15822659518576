import {Component} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {SnackbarService} from "../../../_services/snackbar.service";
import {ActivatedRoute, Router} from "@angular/router";
import {AuthService} from "../../../_services/auth.service";
import {AdminService} from "../../../_services/admin.service";
import {DatePipe} from "@angular/common";
import {MatDialog, MatDialogRef} from "@angular/material/dialog";
import {ConfirmDialogComponent} from "../../../shared/confirm-dialog/confirm-dialog.component";
import {ConfirmationDialogData} from "../../../_types/shared";
import {EditNameComponent} from "../../shared/edit-name/edit-name.component";

@Component({
    selector: 'app-invitee',
    templateUrl: './invitee.component.html',
    styleUrl: './invitee.component.scss'
})
export class InviteeComponent {
    user: any;
    profile: any;
    id: number = 0;
    permissions: any[] | null = null;
    inviteForm: FormGroup;
    invite: any;
    cohorts: any[] = []
    localities: any[] = []
    roles: any[] = []
    loading: boolean = true;
    childRole: boolean = false;
    selectedCohorts: any[] = [];
    newCohort: any;

    constructor(private snackBarService: SnackbarService,
                private route: ActivatedRoute,
                private adminService: AdminService,
                private authService: AuthService,
                private formBuilder: FormBuilder,
                private router: Router,
                public dialog: MatDialog,) {
        this.inviteForm = this.formBuilder.group({
            first_name: ['', Validators.required],
            last_name: ['', Validators.required],
            email: ['', [Validators.required, Validators.email]],
            role: ['', Validators.required],
            secondEmail: [''],
            dob: [''],
            reference_number: [''],
            locality: [''],
            cohort: [''],
            sendToSecondary: [''],
        });


        this.route.params
            .subscribe(params => {
                this.id = params['id'];
                if (this.id) {
                    this.adminService.getUserInvite(this.id).subscribe(r => {
                        this.invite = r;
                        this.localities = r.possible_localities;
                        this.roles = r.possible_roles;
                        this.cohorts = r.possible_cohorts;
                        if (r.status == 'Accepted') {
                            this.inviteForm.disable();
                        }
                        let roleIndex: number = this.roles.findIndex(x => x.name === r.role);
                        let localityIndex: number = this.localities.findIndex(x => x.name === r.locality);
                        this.selectedCohorts = r.user?.cohorts
                            .map(({
                                      id: id,
                                      name: name,
                                      ...rest
                                  }: any) => ({
                                name,
                                id,
                            }));
                        this.inviteForm.patchValue({
                            first_name: r.first_name,
                            last_name: r.last_name,
                            dob: new DatePipe('en-GB').transform(r.dob, 'yyyy-MM-dd'),
                            reference_number: r.reference_number,
                            email: r.email_address,
                            secondEmail: r.secondary_email,
                            sendToSecondary: r.send_to_secondary_email_address_only,
                            role: this.roles[roleIndex]?.id,
                            locality: this.localities[localityIndex]?.id,
                        })
                        this.loading = false;
                    }, e => {
                        console.error(e)
                        this.snackBarService.openSnackBar(e, 'error')
                    })
                } else {
                    this.getCohorts();
                    this.getLocalities();
                    this.adminService.getUserRoles().subscribe(r => {
                        this.roles = r.results;
                    }, e => {
                        this.snackBarService.openSnackBar(e, 'error');
                    })
                    this.loading = false;
                }
            }, error => {
                this.snackBarService.openSnackBar(error, 'error')
                console.error(error);
            });
        this.user = this.authService.getUser();
    }


    getCohorts(): void {
        this.adminService.getCohorts(0, 1000).subscribe(r => {
            this.cohorts = r.results
        }, e => {
            this.snackBarService.openSnackBar(e, 'error');
        })
    }

    getLocalities(): void {
        this.adminService.getLocalities(0, 1000).subscribe(r => {
            this.localities = r.results
        }, e => {
            this.snackBarService.openSnackBar(e, 'error');
        })
    }

    saveInvite(): void {
        this.loading = true;
        let date
        if (this.inviteForm.value.dob) {
            date = new Date(this.inviteForm.value.dob)
            date = date.toLocaleDateString('en-CA').slice(0, 10);
        }

        let user: any = {
            first_name: this.inviteForm.value.first_name,
            last_name: this.inviteForm.value.last_name,
            dob: date,
            reference_number: this.user.reference_number,
            email_address: this.inviteForm.value.email,
            secondary_email: this.inviteForm.value.secondEmail,
            role: this.inviteForm.value.role,
            locality: this.inviteForm.value.locality,
            cohorts: [],
            send_to_secondary_email_address_only: this.inviteForm.value.sendToSecondary,
        }
        if (this.selectedCohorts && this.selectedCohorts.length > 0) {
            for (let cohort of this.selectedCohorts) {
                user.cohorts.push(cohort.id)
            }
        }
        if (this.id) {
            this.adminService.patchInvite(this.id, this.clean(user)).subscribe(
                r => {
                    this.loading = false;
                    this.snackBarService.openSnackBar('Invite updated', 'success')
                }, e => {
                    this.loading = false;
                    this.snackBarService.openSnackBar(e, 'error')
                })
        } else {
            this.adminService.sendInvite(this.clean(user)).subscribe(
                r => {
                    this.loading = false;
                    this.snackBarService.openSnackBar('Invite created', 'success')
                    this.router.navigate(['/user', r?.user.id]);
                }, e => {
                    this.loading = false;
                    this.snackBarService.openSnackBar(e, 'error')
                })
        }
    }

    onFileChanged(event: any): void {
        const file = event.target.files[0];
        var mimeType = file.type;
        if (mimeType.match('text/csv') == null) {
            // check if CSV
            this.snackBarService.openSnackBar("Only CSV are supported.", 'error');
            return;
        }
        var reader: FileReader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = (_event): void => {
            if (reader.result) {
                const dialogRef: MatDialogRef<ConfirmDialogComponent> = this.dialog.open<ConfirmDialogComponent, ConfirmationDialogData>(ConfirmDialogComponent, {
                    panelClass: 'mediumWindow',
                    data: {
                        showSubmitBtn: true,
                        showCancelBtn: true,
                        message: 'Are you sure you want to invite all users on this CSV?',
                        title: 'Invite Users',
                        showHeader: true
                    }
                });
                dialogRef.afterClosed().subscribe((resp): void => {
                    if (resp) {
                        this.adminService.sendInvites(file).subscribe(r => {
                            if (r.detail?.not_found_users && Object.values(r.detail?.not_found_users).toString().length > 0) {
                                this.snackBarService.openSnackBar("The following Users were not found: " + Object.values(r.detail?.not_found_users).toString(), 'error')
                            } else {
                                this.snackBarService.openSnackBar("Users successfully added", 'success')
                            }
                        }, e => {
                            console.error(e);
                            if (e.detail?.not_found_users) {
                                this.snackBarService.openSnackBar("The following Users were not found: " + Object.values(e.detail?.not_found_users).toString(), 'error')
                            } else {
                                this.snackBarService.openSnackBar(e, 'error')
                            }
                        })
                    }
                });
            } else {
                this.snackBarService.openSnackBar("Reader result returned null.", 'error')
            }
        }
    }

    reSendInvite(): void {
        this.adminService.reInviteUsers([this.id]).subscribe(r => {
            this.snackBarService.openSnackBar('Invite successfully re-sent', 'success')
        }, e => {
            this.snackBarService.openSnackBar(e, 'error')
            console.error(e);
        })
    }

    deleteUser(): void {
        const dialogRef: MatDialogRef<ConfirmDialogComponent> = this.dialog.open<ConfirmDialogComponent, ConfirmationDialogData>(ConfirmDialogComponent, {
            panelClass: 'mediumWindow',
            data: {
                showSubmitBtn: true,
                showCancelBtn: true,
                message: 'Are you sure you want to deactivate the selected invite and it\'s associated user account?',
                title: 'Deactivate User',
                showHeader: true
            }
        });
        dialogRef.afterClosed().subscribe((resp): void => {
            if (resp) {
                this.adminService.deleteInvites([this.id]).subscribe(r => {
                    this.snackBarService.openSnackBar('Invite Successfully deleted', 'success');
                    this.router.navigate(['/invitees']);
                }, e => {
                    this.snackBarService.openSnackBar(e, 'error')
                    console.error(e);
                })
            }
        });
    }

    checkRole(): void {
        let i: number = this.roles.findIndex(x => x.id == this.inviteForm.value.role);
        let userRole = this.roles[i]?.name;
        this.childRole = (userRole == 'Young Person (12-16)' || userRole == 'Child (under 12)' || userRole == 'Care Leaver (16-25)')
        if (this.childRole) {
            //     Add validation
            this.inviteForm.controls['dob'].setValidators([Validators.required]);
            this.inviteForm.controls['dob'].updateValueAndValidity();
        } else {
            //     Remove validation
            this.inviteForm.controls['dob'].setValidators([]);
            this.inviteForm.controls['dob'].updateValueAndValidity();
        }
    }

    clean(obj: any) {
        // remove empty keys
        for (var propName in obj) {
            if (obj[propName] === null || obj[propName] === undefined || obj[propName] === '') {
                delete obj[propName];
            }
        }
        return obj
    }

    removeCohort(cohort: any): void {
        const i: number = this.selectedCohorts.findIndex((x: any) => x.id === cohort.id);
        this.selectedCohorts.splice(i, 1);
    }

    selectCohort(cohort: any): void {
        const i: number = this.cohorts.findIndex((x: any): boolean => x.id == cohort);
        if (i >= 0) {
            this.selectedCohorts.push(this.cohorts[i])
        }
    }

    checkIfInArray(item: any, otherArray: any): boolean {
        const i = otherArray.findIndex((x: any): boolean => x.id === item.id);
        return (i != null && i >= 0);
    }

    newCohortModal(): void {
        const dialogRef: MatDialogRef<EditNameComponent> = this.dialog.open(EditNameComponent, {
            data: {
                title: 'Create Cohort',
            },
        });
        dialogRef.afterClosed().subscribe((resp): void => {
            if (resp) {
                this.adminService.newCohort(resp).subscribe(r => {
                    this.snackBarService.openSnackBar('New cohort created', 'success');
                    this.getCohorts();
                }, e => {
                    console.error(e);
                    this.snackBarService.openSnackBar(e, 'error')
                })
            }
        });
    }

    newLocalityModal(): void {
        const dialogRef: MatDialogRef<EditNameComponent> = this.dialog.open(EditNameComponent, {
            data: {
                title: 'Create locality',
            },
        });
        dialogRef.afterClosed().subscribe((resp): void => {
            if (resp) {
                this.adminService.newLocality(resp).subscribe(r => {
                    this.snackBarService.openSnackBar('New locality created', 'success');
                    this.getLocalities();
                }, e => {
                    console.error(e);
                    this.snackBarService.openSnackBar(e, 'error')
                })
            }
        });
    }
}
