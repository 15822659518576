<main *ngIf="!currentTab">
    <div class="largeContainer">
        <div class="d-flex flex-row justify-content-center">
            <h3 class="my-10">
                Reports
            </h3>
        </div>
        <div *ngIf="loading">
            <mat-progress-bar mode="indeterminate"></mat-progress-bar>
        </div>
        <div class="mat-elevation-z6">
            <table [dataSource]="dataSource" mat-table class="responsiveTable">
                <ng-container matColumnDef="Name">
                    <th *matHeaderCellDef mat-header-cell> Report</th>
                    <td *matCellDef="let user" mat-cell><b class="d-none columnTitle">Report:</b> {{ user.name }}
                    </td>
                </ng-container>
                <ng-container matColumnDef="View">
                    <th *matHeaderCellDef mat-header-cell>View</th>
                    <td *matCellDef="let user" mat-cell>
                        <button mat-raised-button color="primary" class="text-center"
                                (click)="fetchData(user.value)">
                            View Report
                        </button>
                    </td>
                </ng-container>
                <tr *matHeaderRowDef="displayedColumns" mat-header-row></tr>
                <tr *matRowDef="let row; columns: displayedColumns;" mat-row></tr>
            </table>
        </div>
    </div>
</main>
<main *ngIf="currentTab">
    <div class="largeContainer">
        <div class="d-flex flex-row flex-space-between">
            <button (click)="clearTab()" (keyup.enter)="clearTab()" aria-label="Back"
                    mat-icon-button matTooltip="Back">
                <mat-icon>keyboard_backspace</mat-icon>
            </button>
            <h3 class="my-10">
                {{ getTabTitle(currentTab) }}
            </h3>
            <div>

            </div>
        </div>
        <div *ngIf="loading">
            <mat-progress-bar mode="indeterminate"></mat-progress-bar>
        </div>
        <ng-container *ngIf="currentTab == 'childrensData'">
            <div class="flex-row justify-content-end">
                <button mat-raised-button color="primary" (click)="this.getChildrensData(true)">Download</button>
            </div>
            <table [matSortActive]="matSortDefault" [matSortDirection]="matSortDirectionDefault"
                   [dataSource]="childrensData" mat-table
                   class="responsiveTable massiveTable mt-10 mat-elevation-z6" matSort
                   (matSortChange)="sortData($event, 'childrensData')">
                <ng-container matColumnDef="Name">
                    <th *matHeaderCellDef mat-header-cell mat-sort-header> Name</th>
                    <td *matCellDef="let user" mat-cell><b class="d-none columnTitle">Name:</b> {{ user['Name'] }}
                    </td>
                </ng-container>
                <ng-container matColumnDef="Users with access">
                    <th *matHeaderCellDef mat-header-cell> Access</th>
                    <td *matCellDef="let user" mat-cell><b
                            class="d-none columnTitle">Access:</b> {{ user['Users with access'] }}
                    </td>
                </ng-container>
                <ng-container matColumnDef="Size (GB)">
                    <th *matHeaderCellDef mat-header-cell mat-sort-header> Size (GB)</th>
                    <td *matCellDef="let user" mat-cell><b class="d-none columnTitle">Size
                        (GB):</b> {{ user['Size (GB)'] | number:'1.0-2' }}
                    </td>
                </ng-container>
                <ng-container matColumnDef="Total uploads">
                    <th *matHeaderCellDef mat-header-cell mat-sort-header> Total uploads</th>
                    <td *matCellDef="let user" mat-cell><b class="d-none columnTitle">Total
                        uploads:</b> {{ user['Total uploads'] }}
                    </td>
                </ng-container>
                <ng-container matColumnDef="Total images">
                    <th *matHeaderCellDef mat-header-cell mat-sort-header> Total images</th>
                    <td *matCellDef="let user" mat-cell><b class="d-none columnTitle">Total
                        images:</b> {{ user['Total images'] }}
                    </td>
                </ng-container>
                <ng-container matColumnDef="Total videos">
                    <th *matHeaderCellDef mat-header-cell mat-sort-header> Total videos</th>
                    <td *matCellDef="let user" mat-cell><b class="d-none columnTitle">Total
                        videos:</b> {{ user['Total videos'] }}
                    </td>
                </ng-container>
                <ng-container matColumnDef="Total documents">
                    <th *matHeaderCellDef mat-header-cell mat-sort-header> Total documents</th>
                    <td *matCellDef="let user" mat-cell><b class="d-none columnTitle">Total
                        documents:</b> {{ user['Total documents'] }}
                    </td>
                </ng-container>
                <ng-container matColumnDef="Total audios">
                    <th *matHeaderCellDef mat-header-cell mat-sort-header> Total audios</th>
                    <td *matCellDef="let user" mat-cell><b class="d-none columnTitle">Total
                        audios:</b> {{ user['Total audios'] }}
                    </td>
                </ng-container>
                <ng-container matColumnDef="view">
                    <th *matHeaderCellDef mat-header-cell>View</th>
                    <td *matCellDef="let user" mat-cell>
                        <a mat-raised-button color="primary" class="text-center"
                           [routerLink]="['/user', user['User ID']]"
                           target="_blank">
                            View User
                        </a>
                    </td>
                </ng-container>
                <tr *matHeaderRowDef="childrensDataDisplayedColumns" mat-header-row></tr>
                <tr *matRowDef="let row; columns: childrensDataDisplayedColumns;" mat-row></tr>
            </table>
        </ng-container>
        <ng-container *ngIf="currentTab=='noMemories'">
            <div class="flex-row justify-content-end">
                <button mat-raised-button color="primary" (click)="this.getNoMemoriesReport(true)">Download</button>
            </div>
            <table [matSortActive]="matSortDefault" [matSortDirection]="matSortDirectionDefault"
                   [dataSource]="noMemoriesData" mat-table
                   class="responsiveTable massiveTable mt-10 mat-elevation-z6" matSort
                   (matSortChange)="sortData($event, 'noMemories')">
                <ng-container matColumnDef="Name">
                    <th *matHeaderCellDef mat-header-cell mat-sort-header> Name</th>
                    <td *matCellDef="let user" mat-cell><b class="d-none columnTitle">Name:</b> {{ user['Name'] }}
                    </td>
                </ng-container>
                <ng-container matColumnDef="Role">
                    <th *matHeaderCellDef mat-header-cell mat-sort-header>Role</th>
                    <td *matCellDef="let user" mat-cell><b class="d-none columnTitle">Role:</b> {{ user['Role'] }}
                    </td>
                </ng-container>
                <ng-container matColumnDef="Cohorts">
                    <th *matHeaderCellDef mat-header-cell mat-sort-header>Cohort</th>
                    <td *matCellDef="let user" mat-cell><b class="d-none columnTitle">Cohorts:</b>
                        <ng-container *ngFor="let cohort of user['Cohorts']">{{ cohort.name }}</ng-container>
                    </td>
                </ng-container>
                <ng-container matColumnDef="Users with access">
                    <th *matHeaderCellDef mat-header-cell> Access</th>
                    <td *matCellDef="let user" mat-cell><b
                            class="d-none columnTitle">Access:</b> {{ user['Users with access'] }}
                    </td>
                </ng-container>
                <ng-container matColumnDef="view">
                    <th *matHeaderCellDef mat-header-cell>View</th>
                    <td *matCellDef="let user" mat-cell>
                        <a mat-raised-button color="primary" class="text-center"
                           [routerLink]="['/user', user['User ID']]"
                           target="_blank">
                            View User
                        </a>
                    </td>
                </ng-container>
                <tr *matHeaderRowDef="noMemoriesDataDisplayedColumns" mat-header-row></tr>
                <tr *matRowDef="let row; columns: noMemoriesDataDisplayedColumns;" mat-row></tr>
            </table>
        </ng-container>
        <ng-container *ngIf="currentTab=='noRelationships'">
            <div class="flex-row justify-content-end">
                <button mat-raised-button color="primary" (click)="this.getNoRelationshipsReport(true)">Download
                </button>
            </div>
            <table [matSortActive]="matSortDefault" [matSortDirection]="matSortDirectionDefault"
                   [dataSource]="noRelationshipsData" mat-table
                   class="responsiveTable massiveTable mt-10 mat-elevation-z6" matSort
                   (matSortChange)="sortData($event, 'noRelationships')">
                <ng-container matColumnDef="Name">
                    <th *matHeaderCellDef mat-header-cell mat-sort-header>Name</th>
                    <td *matCellDef="let user" mat-cell><b class="d-none columnTitle">Name:</b> {{ user['Name'] }}
                    </td>
                </ng-container>
                <ng-container matColumnDef="Role">
                    <th *matHeaderCellDef mat-header-cell mat-sort-header>Role</th>
                    <td *matCellDef="let user" mat-cell><b class="d-none columnTitle">Role:</b> {{ user['Role'] }}
                    </td>
                </ng-container>
                <ng-container matColumnDef="Cohorts">
                    <th *matHeaderCellDef mat-header-cell mat-sort-header>Cohorts</th>
                    <td *matCellDef="let user" mat-cell><b class="d-none columnTitle">Cohorts:</b>
                        <ng-container *ngFor="let cohort of user['Cohorts']">{{ cohort.name }}</ng-container>
                    </td>
                </ng-container>
                <ng-container matColumnDef="Registration Date">
                    <th *matHeaderCellDef mat-header-cell mat-sort-header>Registration Date</th>
                    <td *matCellDef="let user" mat-cell><b class="d-none columnTitle">Registration
                        Date:</b> {{ user['Registration Date']|date : 'dd/MM/yyyy' }}
                    </td>
                </ng-container>
                <ng-container matColumnDef="view">
                    <th *matHeaderCellDef mat-header-cell>View</th>
                    <td *matCellDef="let user" mat-cell>
                        <a mat-raised-button color="primary" class="text-center"
                           [routerLink]="['/user', user['User ID']]"
                           target="_blank">
                            View User
                        </a>
                    </td>
                </ng-container>
                <tr *matHeaderRowDef="noRelationshipDataDisplayedColumns" mat-header-row></tr>
                <tr *matRowDef="let row; columns: noRelationshipDataDisplayedColumns;" mat-row></tr>
            </table>
        </ng-container>
        <ng-container *ngIf="currentTab=='pendingInvites'">
            <div class="flex-row justify-content-end">
                <button mat-raised-button color="primary" (click)="this.getInvitesPendingReport(true)">Download
                </button>
            </div>
            <table [matSortActive]="matSortDefault" [matSortDirection]="matSortDirectionDefault"
                   [dataSource]="pendingInvitesData" mat-table
                   class="responsiveTable massiveTable mt-10 mat-elevation-z6" matSort
                   (matSortChange)="sortData($event, 'pendingInvites')">
                <ng-container matColumnDef="Name">
                    <th *matHeaderCellDef mat-header-cell mat-sort-header> Name</th>
                    <td *matCellDef="let user" mat-cell><b class="d-none columnTitle">Name:</b> {{ user['Name'] }}
                    </td>
                </ng-container>
                <ng-container matColumnDef="Email">
                    <th *matHeaderCellDef mat-header-cell mat-sort-header> Email</th>
                    <td *matCellDef="let user" mat-cell><b
                            class="d-none columnTitle">Email:</b> {{ user['Email'] }}
                    </td>
                </ng-container>
                <ng-container matColumnDef="Role">
                    <th *matHeaderCellDef mat-header-cell mat-sort-header>Role</th>
                    <td *matCellDef="let user" mat-cell><b class="d-none columnTitle">Role:</b> {{ user['Role'] }}
                    </td>
                </ng-container>
                <ng-container matColumnDef="Cohorts">
                    <th *matHeaderCellDef mat-header-cell mat-sort-header>Cohorts</th>
                    <td *matCellDef="let user" mat-cell><b class="d-none columnTitle">Cohorts:</b>
                        <ng-container *ngFor="let cohort of user['Cohorts']">{{ cohort.name }}</ng-container>
                    </td>
                </ng-container>
                <ng-container matColumnDef="Invite sent date">
                    <th *matHeaderCellDef mat-header-cell mat-sort-header>Invite sent date</th>
                    <td *matCellDef="let user" mat-cell><b class="d-none columnTitle">Invite sent
                        date:</b> {{ user['Invite sent date'] | date : 'dd/MM/yyyy' }}
                    </td>
                </ng-container>
                <ng-container matColumnDef="Invite expiry date">
                    <th *matHeaderCellDef mat-header-cell mat-sort-header>Invite expiry date</th>
                    <td *matCellDef="let user" mat-cell><b class="d-none columnTitle">Invite expiry
                        date:</b> {{ user['Invite expiry date'] | date : 'dd/MM/yyyy' }}
                    </td>
                </ng-container>
                <ng-container matColumnDef="Number of invites sent">
                    <th *matHeaderCellDef mat-header-cell mat-sort-header> Number of invites sent</th>
                    <td *matCellDef="let user" mat-cell><b class="d-none columnTitle">Number of invites
                        sent:</b> {{ user['Number of invites sent'] }}
                    </td>
                </ng-container>
                <ng-container matColumnDef="Secondary Email">
                    <th *matHeaderCellDef mat-header-cell mat-sort-header> Secondary Email</th>
                    <td *matCellDef="let user" mat-cell><b class="d-none columnTitle">Secondary
                        Email:</b> {{ user['Secondary Email'] }}
                    </td>
                </ng-container>
                <ng-container matColumnDef="view">
                    <th *matHeaderCellDef mat-header-cell>View</th>
                    <td *matCellDef="let user" mat-cell>
                        <a mat-raised-button color="primary" class="text-center"
                           [routerLink]="['/invitee', user.id]"
                           target="_blank">
                            View Invite
                        </a>
                    </td>
                </ng-container>
                <tr *matHeaderRowDef="pendingInvitesDataDisplayedColumns" mat-header-row></tr>
                <tr *matRowDef="let row; columns: pendingInvitesDataDisplayedColumns;" mat-row></tr>
            </table>
        </ng-container>
        <ng-container *ngIf="currentTab=='noPermissions'">
            <div class="flex-row justify-content-end">
                <button mat-raised-button color="primary" (click)="this.getNoPermissionsReport(true)">Download
                </button>
            </div>
            <table [matSortActive]="matSortDefault" [matSortDirection]="matSortDirectionDefault"
                   [dataSource]="noPermissionsData" mat-table
                   class="responsiveTable massiveTable mt-10 mat-elevation-z6" matSort
                   (matSortChange)="sortData($event, 'noPermissions')">
                <ng-container matColumnDef="Name">
                    <th *matHeaderCellDef mat-header-cell mat-sort-header> Name</th>
                    <td *matCellDef="let user" mat-cell><b class="d-none columnTitle">Name:</b> {{ user['Name'] }}
                    </td>
                </ng-container>
                <ng-container matColumnDef="Email">
                    <th *matHeaderCellDef mat-header-cell mat-sort-header> Email</th>
                    <td *matCellDef="let user" mat-cell><b
                            class="d-none columnTitle">Email:</b> {{ user['Email'] }}
                    </td>
                </ng-container>
                <ng-container matColumnDef="Role">
                    <th *matHeaderCellDef mat-header-cell mat-sort-header>Role</th>
                    <td *matCellDef="let user" mat-cell><b class="d-none columnTitle">Role:</b> {{ user['Role'] }}
                    </td>
                </ng-container>
                <ng-container matColumnDef="Cohorts">
                    <th *matHeaderCellDef mat-header-cell mat-sort-header>Cohorts</th>
                    <td *matCellDef="let user" mat-cell><b class="d-none columnTitle">Cohorts:</b>
                        <ng-container *ngFor="let cohort of user['Cohorts']">{{ cohort.name }}</ng-container>
                    </td>
                </ng-container>
                <ng-container matColumnDef="Username">
                    <th *matHeaderCellDef mat-header-cell mat-sort-header> Username</th>
                    <td *matCellDef="let user" mat-cell><b
                            class="d-none columnTitle">Username:</b> {{ user['Username'] }}
                    </td>
                </ng-container>
                <ng-container matColumnDef="Role">
                    <th *matHeaderCellDef mat-header-cell mat-sort-header> Role</th>
                    <td *matCellDef="let user" mat-cell><b class="d-none columnTitle">Role:</b> {{ user['Role'] }}
                    </td>
                </ng-container>
                <ng-container matColumnDef="Secondary Email">
                    <th *matHeaderCellDef mat-header-cell mat-sort-header> Secondary Email</th>
                    <td *matCellDef="let user" mat-cell><b class="d-none columnTitle">Secondary
                        Email:</b> {{ user['Secondary Email'] }}
                    </td>
                </ng-container>
                <ng-container matColumnDef="view">
                    <th *matHeaderCellDef mat-header-cell>View</th>
                    <td *matCellDef="let user" mat-cell>
                        <a mat-raised-button color="primary" class="text-center"
                           [routerLink]="['/user', user.id]"
                           target="_blank">
                            View User
                        </a>
                    </td>
                </ng-container>
                <tr *matHeaderRowDef="noPermissionsDataDisplayedColumns" mat-header-row></tr>
                <tr *matRowDef="let row; columns: noPermissionsDataDisplayedColumns;" mat-row></tr>
            </table>
        </ng-container>
        <ng-container *ngIf="currentTab=='noDOB'">
            <div class="flex-row justify-content-end">
                <button mat-raised-button color="primary" (click)="this.getNoDOBReport(true)">Download
                </button>
            </div>
            <table [dataSource]="noDOBData" mat-table class="responsiveTable massiveTable mt-10 mat-elevation-z6"
                   matSort [matSortActive]="matSortDefault" [matSortDirection]="matSortDirectionDefault"
                   (matSortChange)="sortData($event, 'noDOB')">
                <ng-container matColumnDef="Name">
                    <th *matHeaderCellDef mat-header-cell mat-sort-header>Name</th>
                    <td *matCellDef="let user" mat-cell><b class="d-none columnTitle">Name:</b> {{ user['Name'] }}
                    </td>
                </ng-container>
                <ng-container matColumnDef="Email">
                    <th *matHeaderCellDef mat-header-cell mat-sort-header> Email</th>
                    <td *matCellDef="let user" mat-cell><b class="d-none columnTitle">Email:</b> {{ user['Email'] }}
                    </td>
                </ng-container>
                <ng-container matColumnDef="Username">
                    <th *matHeaderCellDef mat-header-cell mat-sort-header> Username</th>
                    <td *matCellDef="let user" mat-cell><b
                            class="d-none columnTitle">Username:</b> {{ user['Username'] }}
                    </td>
                </ng-container>
                <ng-container matColumnDef="Secondary Email">
                    <th *matHeaderCellDef mat-header-cell mat-sort-header> Secondary Email</th>
                    <td *matCellDef="let user" mat-cell><b class="d-none columnTitle">Secondary
                        Email:</b> {{ user['Secondary Email'] }}
                    </td>
                </ng-container>
                <ng-container matColumnDef="Role Name">
                    <th *matHeaderCellDef mat-header-cell mat-sort-header>Role</th>
                    <td *matCellDef="let user" mat-cell><b
                            class="d-none columnTitle">Role:</b> {{ user['Role Name'] }}
                    </td>
                </ng-container>
                <ng-container matColumnDef="DOB">
                    <th *matHeaderCellDef mat-header-cell mat-sort-header>DOB</th>
                    <td *matCellDef="let user" mat-cell><b
                            class="d-none columnTitle">DOB:</b>
                        <mat-form-field appearance="outline" class="w-100 mt-10">
                            <mat-label>DOB</mat-label>
                            <input [matDatepicker]="picker" [(ngModel)]="user.DoB" matInput
                                   name="stockName"
                                   #dob="ngModel">
                            <mat-datepicker-toggle [for]="picker" matIconSuffix></mat-datepicker-toggle>
                            <mat-datepicker #picker></mat-datepicker>
                            <button color="primary" aria-label="Save" mat-icon-button matIconPrefix
                                    [disabled]="dob.invalid || !user.DoB"
                                    matTooltip="Save new date of birth" (click)="saveDob(user)">
                                <mat-icon>save</mat-icon>
                            </button>
                            <mat-error *ngIf="dob.hasError('matDatepickerParse')">
                                Invalid date format. Please use DD/MM/YYYY.
                            </mat-error>
                        </mat-form-field>
                    </td>
                </ng-container>
                <ng-container matColumnDef="view">
                    <th *matHeaderCellDef mat-header-cell>View</th>
                    <td *matCellDef="let user" mat-cell>
                        <a mat-raised-button color="primary" class="text-center"
                           [routerLink]="['/user', user.id]"
                           target="_blank">
                            View User
                        </a>
                    </td>
                </ng-container>
                <tr *matHeaderRowDef="noDOBDataDisplayedColumns" mat-header-row></tr>
                <tr *matRowDef="let row; columns: noDOBDataDisplayedColumns;" mat-row></tr>
            </table>
        </ng-container>
        <ng-container *ngIf="currentTab=='userEmails'">
            <div class="flex-row justify-content-end">
                <button mat-raised-button color="primary" (click)="this.getUsersEmailReport(true)">Download
                </button>
            </div>
            <table [matSortActive]="matSortDefault" [matSortDirection]="matSortDirectionDefault"
                   [dataSource]="userEmailsData" mat-table
                   class="responsiveTable massiveTable mt-10 mat-elevation-z6" matSort
                   (matSortChange)="sortData($event, 'userEmails')">
                <ng-container matColumnDef="Name">
                    <th *matHeaderCellDef mat-header-cell mat-sort-header>Name</th>
                    <td *matCellDef="let user" mat-cell><b class="d-none columnTitle">Name:</b> {{ user['Name'] }}
                    </td>
                </ng-container>
                <ng-container matColumnDef="Username">
                    <th *matHeaderCellDef mat-header-cell mat-sort-header>Username</th>
                    <td *matCellDef="let user" mat-cell><b
                            class="d-none columnTitle">Username:</b> {{ user['Username'] }}
                    </td>
                </ng-container>
                <ng-container matColumnDef="Email">
                    <th *matHeaderCellDef mat-header-cell mat-sort-header>Email</th>
                    <td *matCellDef="let user" mat-cell><b class="d-none columnTitle">Email:</b> {{ user['Email'] }}
                    </td>
                </ng-container>
                <ng-container matColumnDef="Active">
                    <th *matHeaderCellDef mat-header-cell mat-sort-header>Active</th>
                    <td *matCellDef="let user" mat-cell><b
                            class="d-none columnTitle">Active:</b> {{ user['Active'] ? "Yes" : "No" }}
                    </td>
                </ng-container>
                <ng-container matColumnDef="Role">
                    <th *matHeaderCellDef mat-header-cell mat-sort-header>Role</th>
                    <td *matCellDef="let user" mat-cell><b class="d-none columnTitle">Role:</b> {{ user['Role'] }}
                    </td>
                </ng-container>
                <ng-container matColumnDef="Cohort">
                    <th *matHeaderCellDef mat-header-cell mat-sort-header>Cohort</th>
                    <td *matCellDef="let user" mat-cell><b class="d-none columnTitle">Cohort:</b>
                        {{ user['Cohort'] }}
                    </td>
                </ng-container>
                <ng-container matColumnDef="DoB">
                    <th *matHeaderCellDef mat-header-cell mat-sort-header>DOB</th>
                    <td *matCellDef="let user" mat-cell><b class="d-none columnTitle">Date of
                        birth:</b> {{ user['DoB']|date : 'dd/MM/yyyy' }}
                    </td>
                </ng-container>
                <ng-container matColumnDef="Locality">
                    <th *matHeaderCellDef mat-header-cell mat-sort-header>Locality</th>
                    <td *matCellDef="let user" mat-cell><b
                            class="d-none columnTitle">Locality:</b> {{ user['Locality'] }}
                    </td>
                </ng-container>
                <ng-container matColumnDef="Invite Date">
                    <th *matHeaderCellDef mat-header-cell mat-sort-header>Invite</th>
                    <td *matCellDef="let user" mat-cell><b class="d-none columnTitle">Invite Date:</b>
                        {{ user['Invite Date']|date : 'dd/MM/yyyy' }}
                    </td>
                </ng-container>
                <ng-container matColumnDef="Registration Date">
                    <th *matHeaderCellDef mat-header-cell mat-sort-header>Registration</th>
                    <td *matCellDef="let user" mat-cell><b class="d-none columnTitle">Registration Date:</b>
                        {{ user['Registration Date']|date : 'dd/MM/yyyy' }}
                    </td>
                </ng-container>
                <ng-container matColumnDef="Last Login">
                    <th *matHeaderCellDef mat-header-cell mat-sort-header>Last Login</th>
                    <td *matCellDef="let user" mat-cell><b class="d-none columnTitle">Last Login:</b>
                        {{ user['Last Login']|date : 'dd/MM/yyyy' }}
                    </td>
                </ng-container>
                <ng-container matColumnDef="Last Upload">
                    <th *matHeaderCellDef mat-header-cell mat-sort-header>Last Upload</th>
                    <td *matCellDef="let user" mat-cell><b class="d-none columnTitle">Last Upload:</b>
                        {{ user['Last Upload']|date  : 'dd/MM/yyyy' }}
                    </td>
                </ng-container>
                <tr *matHeaderRowDef="UserEmailsDataDisplayedColumns" mat-header-row></tr>
                <tr *matRowDef="let row; columns: UserEmailsDataDisplayedColumns;" mat-row></tr>
            </table>
        </ng-container>
        <ng-container *ngIf="currentTab=='duplicates'">
            <div class="bg-white customTableHolder mat-elevation-z6">
                <ng-container *ngFor="let group of duplicatesData">
                    <table class=" mat-mdc-table mdc-data-table__table cdk-table responsiveTable massiveTable customTable"
                           [class.openSidebar]="this.adminView.sideNav && !this.adminView.minified">
                        <thead>
                        <tr class="mat-mdc-header-row mdc-data-table__header-row cdk-header-row">
                            <td class="mat-mdc-header-cell mdc-data-table__header-cell cdk-header-cell">Photo</td>
                            <td class="mat-mdc-header-cell mdc-data-table__header-cell cdk-header-cell">First Name</td>
                            <td class="mat-mdc-header-cell mdc-data-table__header-cell cdk-header-cell">Last Name</td>
                            <td class="mat-mdc-header-cell mdc-data-table__header-cell cdk-header-cell">Email</td>
                            <td class="mat-mdc-header-cell mdc-data-table__header-cell cdk-header-cell">Role</td>
                            <td class="mat-mdc-header-cell mdc-data-table__header-cell cdk-header-cell">Last Login</td>
                            <td class="mat-mdc-header-cell mdc-data-table__header-cell cdk-header-cell">Registration
                                Date
                            </td>
                            <td class="mat-mdc-header-cell mdc-data-table__header-cell cdk-header-cell">View
                            </td>
                            <td class="mat-mdc-header-cell mdc-data-table__header-cell cdk-header-cell">
                                <button mat-raised-button color="primary" class="text-center"
                                        (click)="mergeUsers(group)">
                                    Merge
                                </button>
                            </td>
                        </tr>
                        </thead>
                        <tbody class="mdc-data-table__content">
                        <tr *ngFor="let user of group" class="mat-mdc-row mdc-data-table__row cdk-row">
                            <td class="mat-mdc-cell mdc-data-table__cell cdk-cell cdk">
                                <img [alt]="user.user.first_name"
                                     [src]="user.user.photo||'assets/images/blankProfile.png'"
                                     class="smallProfilePicture">
                            </td>
                            <td class="mat-mdc-cell mdc-data-table__cell cdk-cell cdk">
                                <b class="d-none columnTitle">First Name:</b>
                                {{ user.user.first_name }}
                            </td>
                            <td class="mat-mdc-cell mdc-data-table__cell cdk-cell cdk">
                                <b class="d-none columnTitle">Last Name:</b>
                                {{ user.user.last_name }}
                            </td>
                            <td class="mat-mdc-cell mdc-data-table__cell cdk-cell cdk">
                                <b class="d-none columnTitle">Email:</b>
                                {{ user.user.email }}
                            </td>
                            <td class="mat-mdc-cell mdc-data-table__cell cdk-cell cdk">
                                <b class="d-none columnTitle">Role:</b>
                                {{ user.user.role.name }}
                            </td>
                            <td class="mat-mdc-cell mdc-data-table__cell cdk-cell cdk">
                                <b class="d-none columnTitle">Last Login:</b>
                                {{ user.user.last_login  | date : 'dd/MM/yyyy' }}
                            </td>
                            <td class="mat-mdc-cell mdc-data-table__cell cdk-cell cdk">
                                <b class="d-none columnTitle">Registration Date:</b>
                                {{ user.user.registration_date  | date : 'dd/MM/yyyy' }}
                            </td>
                            <td class="mat-mdc-cell mdc-data-table__cell cdk-cell cdk">
                                <a mat-raised-button color="primary"
                                   class="text-center"
                                   [routerLink]="['/user', user.user.id]"
                                   target="_blank">
                                    View User
                                </a>
                            </td>
                            <td class="mat-mdc-cell mdc-data-table__cell cdk-cell cdk">
                                <b class="d-none columnTitle" *ngIf="user.is_master">
                                    <button mat-raised-button color="primary" class="text-center"
                                            (click)="mergeUsers(group)">
                                        Merge
                                    </button>
                                </b>
                                <span class="hideMobile">
                                {{ user.is_master ? "Merge Target" : "" }}
                                    </span>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                    <hr>
                </ng-container>
            </div>
        </ng-container>
    </div>
</main>
