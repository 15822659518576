<div class="validation">
    <p [ngClass]="{'invalid' :  oneNumber == false || oneLower == false|| oneUpper == false|| minLength == false|| whiteSpace || oneSpecial == false}">
        Password Requirements:</p>
    <ul>
        <li [ngClass]="{'invalid' : minLength == false}">Must be at least {{ length }} characters long.</li>
        <li [ngClass]="{'invalid' : oneNumber == false }">Must contain at least 1 number.</li>
        <li [ngClass]="{'invalid' : oneLower == false}">Must contain at least 1 lowercase character.
        </li>
        <li [ngClass]="{'invalid' : oneUpper == false}">Must contain at least 1 uppercase character.
        </li>
        <li [ngClass]="{'invalid' : oneSpecial == false}">Must contain at least 1 special character.
        </li>
        <li [ngClass]="{'invalid' : whiteSpace}">Password cannot contain whitespace
        </li>
        <li [ngClass]="{'invalid' : matching}">Passwords must match
        </li>
    </ul>
</div>
