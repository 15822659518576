import {Component} from '@angular/core';
import {FormBuilder, FormGroup} from "@angular/forms";
import {MatDialogRef} from "@angular/material/dialog";

@Component({
    selector: 'app-feedback',
    templateUrl: './feedback.component.html',
    styleUrl: './feedback.component.scss'
})
export class FeedbackComponent {
    feedbackForm: FormGroup;

    constructor(private formBuilder: FormBuilder,
                protected dialogRef: MatDialogRef<FeedbackComponent>,) {
        this.feedbackForm = this.formBuilder.group({
            title: [''],
            description: [''],
        });
    }
}
