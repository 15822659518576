import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {AdminService} from "../../../_services/admin.service";
import {SnackbarService} from "../../../_services/snackbar.service";

@Component({
    selector: 'app-update-password',
    templateUrl: './update-password.component.html',
    styleUrl: './update-password.component.scss'
})
export class UpdatePasswordComponent {
    profile: any;
    loading: boolean = false;
    passwordForm: FormGroup;

    constructor(@Inject(MAT_DIALOG_DATA) protected data: any,
                private formBuilder: FormBuilder,
                private adminService: AdminService,
                protected dialogRef: MatDialogRef<UpdatePasswordComponent>,
                private snackBarService: SnackbarService,) {
        if (data.profile?.id) {
            this.profile = data.profile;
        } else {
            this.snackBarService.openSnackBar('Profile Not Found', 'error')
        }

        this.passwordForm = this.formBuilder.group({
            password: ['', [Validators.required, Validators.minLength(8), Validators.pattern('^(?=.*\\d)(?=.*[a-z])(?=.*[A-Z])(?=.*\\W)(?!\\s).{8,}$')]],
            confirmPassword: ['', [Validators.required, Validators.minLength(8), Validators.pattern('^(?=.*\\d)(?=.*[a-z])(?=.*[A-Z])(?=.*\\W)(?!\\s).{8,}$')]],
        })
    }


    savePassword(): void {
        this.adminService.updateUserPassword(this.profile.id, this.passwordForm.value.password).subscribe(r => {
            this.snackBarService.openSnackBar('Password Updated', 'success');
            this.dialogRef.close();
        }, e => {
            this.snackBarService.openSnackBar(e, 'error')
        })
    }
}
