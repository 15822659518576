<main>
    <div class="d-flex flex-row justify-content-center">
        <h3 class="my-10">
            Dashboard
        </h3>
    </div>
    <div class="largeContainer">
        <div class="statsGrid">
            <mat-card class="mat-elevation-z6 flex-grow">
                <mat-card-header>
                    <mat-card-title>Invites</mat-card-title>
                </mat-card-header>
                <mat-card-content>
                    <div *ngIf="!adminOverview" class="flex-row align-items-center justify-content-center">
                        <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
                    </div>
                    <ng-container *ngIf="adminOverview">
                        <apx-chart class="noPointerEvents"
                                   *ngIf="(adminOverview.invites_accepted && adminOverview.invites_accepted > 0 )||
                                     (adminOverview.invites_pending && adminOverview.invites_pending > 0 )||
                                     (adminOverview.invites_expired && adminOverview.invites_expired > 0 )"
                                   [series]="[adminOverview.invites_pending||0, adminOverview.invites_expired||0]"
                                   [chart]="{ width: '100%', type: 'donut'}"
                                   [legend]="{show: false}"
                                   [tooltip]="{enabled: false}"
                                   [colors]="['#FFAC1C','#D2042D']"
                                   [labels]="[ 'Pending', 'Expired']"
                        ></apx-chart>
                        <div class="d-flex flex-row gap-15 flex-wrap justify-content-center">
                            <div class="flex-row align-items-center justify-content-center gap10 pb-10"
                                 *ngIf="adminOverview.invites_pending != null">
                                <button mat-button [routerLink]="['/invitees']">Pending:</button>
                                <div class="statCircle warning">{{ adminOverview.invites_pending }}</div>
                            </div>
                            <div class="flex-row align-items-center justify-content-center gap10 pb-10"
                                 *ngIf="adminOverview.invites_expired != null">
                                <button mat-button [routerLink]="['/invitees']">Expired:</button>
                                <div class="statCircle danger">{{ adminOverview.invites_expired }}</div>
                            </div>
                            <div class="flex-row align-items-center justify-content-center gap10 pb-10">
                                <button mat-button [routerLink]="['/invitees']">Total:</button>
                                <div class="statCircle black">{{ adminOverview.invites_pending || 0 + adminOverview.invites_expired || 0 }}</div>
                            </div>
                        </div>
                    </ng-container>
                </mat-card-content>
            </mat-card>

            <mat-card class="mat-elevation-z6 flex-grow">
                <mat-card-header>
                    <mat-card-title>Flagged Media</mat-card-title>
                </mat-card-header>
                <mat-card-content>
                    <div *ngIf="!adminOverview && !reportingOverview?.error"
                         class="flex-row align-items-center justify-content-center">
                        <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
                    </div>
                    <ng-container *ngIf="adminOverview && adminOverview.flagged">
                        <apx-chart class="noPointerEvents" *ngIf="(adminOverview.flagged.actioned && adminOverview.flagged.actioned > 0 )||
                        (adminOverview.flagged.unactioned  && adminOverview.flagged.unactioned > 0 )"
                                   [series]="[adminOverview.flagged.actioned||0, adminOverview.flagged.unactioned||0]"
                                   [chart]="{ width: '100%', type: 'donut'}"
                                   [legend]="{show: false}"
                                   [tooltip]="{enabled: false}"
                                   [colors]="['#67B688','#D2042D',]"
                                   [labels]="['Actioned', 'Unactioned',]"
                        ></apx-chart>
                        <div class="d-flex flex-row gap-15 flex-wrap justify-content-center">
                            <div class="flex-row align-items-center justify-content-center gap10 pb-10"
                                 *ngIf="adminOverview.flagged.actioned != null">
                                <button mat-button [routerLink]="['/resolved']">Actioned:</button>
                                <div class="statCircle success">{{ adminOverview.flagged.actioned | number:'1.0-2' }}</div>
                            </div>
                            <div class="flex-row align-items-center justify-content-center gap10 pb-10"
                                 *ngIf="adminOverview.flagged.unactioned != null">
                                <button mat-button [routerLink]="['/flaggedMedia']">Unactioned:</button>
                                <div class="statCircle danger">{{ adminOverview.flagged.unactioned| number:'1.0-2' }}</div>
                            </div>
                            <div class="flex-row align-items-center justify-content-center gap10 pb-10"
                                 *ngIf="adminOverview.flagged.total != null">
                                <button mat-button [routerLink]="['/flaggedMedia']">Total:</button>
                                <div class="statCircle black">{{ adminOverview.flagged.total | number:'1.0-2' }}</div>
                            </div>
                            <div class="flex-row align-items-center justify-content-center gap10 pb-10"
                                 *ngIf="adminOverview.flagged.average_response_time != null"> Average Response Time:
                                <div class="statCircle black">{{ adminOverview.flagged.average_response_time | number:'1.0-2' }}
                                    Days
                                </div>
                            </div>
                        </div>

                    </ng-container>
                </mat-card-content>
            </mat-card>

            <mat-card class="mat-elevation-z6 flex-grow">
                <mat-card-header>
                    <mat-card-title>User Statistics</mat-card-title>
                </mat-card-header>
                <mat-card-content class="h-100">
                    <div *ngIf="!reportingOverview && !reportingOverview?.error"
                         class="flex-row align-items-center justify-content-center">
                        <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
                    </div>
                    <div *ngIf="reportingOverview" class="flex-col pt-10 flex-space-around h-100 align-items-center">
                        <apx-chart class="w-100 noPointerEvents"
                                   [series]="[{name:'Memory Box', data:[childrenNumber,carerNumber]}]"
                                   [chart]="{ width: '100%', type: 'bar'}"
                                   [xaxis]="{categories: ['Children', 'Carers']}"
                                   [tooltip]="{enabled: false}"
                                   [colors]="['#67B688', '#FFAC1C']"
                                   [fill]="{colors:['#67B688', '#FFAC1C']}"
                        ></apx-chart>
                        <div class="d-flex flex-row gap-15 flex-wrap justify-content-center">
                            <div class="flex-row align-items-center justify-content-center gap10 pb-10">

                                <a href="/users?role=12,13,11" mat-button>Children:</a>
                                <div class="statCircle success">{{ childrenNumber }}</div>
                            </div>
                            <div class="flex-row align-items-center justify-content-center gap10 pb-10">
                                <a mat-button href="/users?role=6">Carers:</a>
                                <div class="statCircle warning">{{ carerNumber }}</div>
                            </div>

                            <div class="flex-row align-items-center justify-content-center gap10 pb-10"
                                 *ngIf="reportingOverview.total_users != null">
                                <button mat-button [routerLink]="['/users']"> Total Users:</button>
                                <div class="statCircle black">{{ reportingOverview.total_users| number:'1.0-2' }}</div>
                            </div>
                            <div class="flex-row align-items-center justify-content-center gap10 pb-10"
                                 *ngIf="reportingOverview.total_size != null">
                                Total Memory Box Size:
                                <div class="statCircle black">{{ reportingOverview.total_size| number:'1.0-2' }}GB
                                </div>
                            </div>

                        </div>
                    </div>
                </mat-card-content>
            </mat-card>
            <mat-card class="mat-elevation-z6 flex-grow">
                <mat-card-header>
                    <mat-card-title>Memory Box Statistics</mat-card-title>
                </mat-card-header>
                <mat-card-content class="h-100">
                    <div *ngIf="!reportingOverview && !reportingOverview?.error"
                         class="flex-row align-items-center justify-content-center">
                        <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
                    </div>
                    <div *ngIf="reportingOverview" class="flex-col pt-10 flex-space-around h-100 align-items-center">
                        <apx-chart class="w-100 noPointerEvents"
                                   [series]="[{name:'Memory Box', data:[reportingOverview.total_memory_boxes||0,reportingOverview.orphan_boxes||0,reportingOverview.total_memory_boxes_with_no_uploads||0 ]}]"
                                   [chart]="{ width: '100%', type: 'bar'}"
                                   [xaxis]="{categories: ['Total','No Relationships', 'No Uploads']}"
                                   [tooltip]="{enabled: false}"
                                   [fill]="{colors:['#67B688', '#FFAC1C']}"
                        ></apx-chart>
                        <div class="d-flex flex-row gap-15 flex-wrap justify-content-center">
                            <div class="flex-row align-items-center justify-content-center gap10 pb-10"
                                 *ngIf="reportingOverview.total_users != null">Total Memory Boxes:
                                <div class="statCircle black">{{ reportingOverview.total_memory_boxes| number:'1.0-2' }}</div>
                            </div>
                            <div class="flex-row align-items-center justify-content-center gap10 pb-10">Unlinked Memory
                                Boxes
                                <div class="statCircle danger">{{ reportingOverview.orphan_boxes| number:'1.0-2' }}</div>
                            </div>

                            <div class="flex-row align-items-center justify-content-center gap10 pb-10"
                                 *ngIf="reportingOverview.total_size != null">Memory Boxes With No Uploads:
                                <div class="statCircle warning">{{ reportingOverview.total_memory_boxes_with_no_uploads| number:'1.0-2' }}
                                </div>
                            </div>

                        </div>
                    </div>
                </mat-card-content>
            </mat-card>
        </div>
    </div>
</main>
