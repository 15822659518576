import {Injectable} from '@angular/core';
import {CanActivate, Router} from '@angular/router';
import {AuthService} from "../_services/auth.service";

@Injectable({
    providedIn: 'root'
})
export class AuthGuard implements CanActivate {

    constructor(
        private router: Router,
        private authService: AuthService
    ) {
    }

    canActivate(): boolean {
        // If user is not authenticated redirect to login screen
        if (!this.authService.isLoggedIn()) {
            this.router.navigate(['/login']);
            return false;
        } else {
            return true;
        }
    }
}
