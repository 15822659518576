import {Injectable} from '@angular/core';
import {isReadable, mostReadable, TinyColor} from "@ctrl/tinycolor";

export interface Color {
    name: string;
    hex: string;
    darkContrast: boolean;
}

@Injectable({
    providedIn: 'root'
})
export class ThemeService {
    primaryColorPalette: Color[] = [];
    accentColorPalette: Color[] = [];

    // Service to generate css variables from 2 colours for use in the theme.scss for angular themeing
    savePrimaryColor(color: string): void {
        this.primaryColorPalette = this.computeColors(color);
        document.documentElement.style.setProperty(
            `--primary`, color
        );
        let metaTheme = document.querySelector('meta[name="theme-color"]')
        if (metaTheme) {
            metaTheme.setAttribute('content', color)
        }
        let metaThemeTile = document.querySelector('meta[name="msapplication-TileColor"]')
        if (metaThemeTile) {
            metaThemeTile.setAttribute('content', color)
        }
        let metaStatusBar = document.querySelector('meta[name="apple-mobile-web-app-status-bar-style"]')
        if (metaStatusBar) {
            metaStatusBar.setAttribute('content', color)
        }
        document.documentElement.style.setProperty(
            `--primary-text`, this.getReadableColor(color)
        );
        this.updateTheme(this.primaryColorPalette, 'primary');
    }

    saveAccentColor(color: string): void {
        this.accentColorPalette = this.computeColors(color);
        document.documentElement.style.setProperty(
            `--accent`, color
        );
        document.documentElement.style.setProperty(
            `--accent-text`, this.getReadableColor(color)
        );

        this.updateTheme(this.accentColorPalette, 'accent');
    }

    getReadableColor(color: string): string {
        let fallback: string = new TinyColor(color).isLight() ? 'rgba(black, 0.87)' : 'white';
        let mostReadableColor = mostReadable(color, ["#ffffff", "#000000", "#808080", "#C0C0C0"], {includeFallbackColors: true})?.toHexString()
        if (!mostReadableColor) {
            mostReadableColor = fallback;
        }
        if (!isReadable(color, mostReadableColor, {level: "AAA", size: "large"})) {
            alert('Please choose another colour palate ' + color + ' does not meet WCAG AAA rating')
        }
        return mostReadableColor
    }


    updateTheme(colors: Color[], theme: string): void {
        colors.forEach(color => {
            document.documentElement.style.setProperty(
                `--theme-${theme}-${color.name}`,
                color.hex
            );
            document.documentElement.style.setProperty(
                `--theme-${theme}-contrast-${color.name}`,
                this.getReadableColor(color.hex)
            );
        });
    }

    computeColors(hex: string): Color[] {
        return [
            this.getColorObject(new TinyColor(hex).lighten(52), '50'),
            this.getColorObject(new TinyColor(hex).lighten(37), '100'),
            this.getColorObject(new TinyColor(hex).lighten(26), '200'),
            this.getColorObject(new TinyColor(hex).lighten(12), '300'),
            this.getColorObject(new TinyColor(hex).lighten(6), '400'),
            this.getColorObject(new TinyColor(hex), '500'),
            this.getColorObject(new TinyColor(hex).darken(6), '600'),
            this.getColorObject(new TinyColor(hex).darken(12), '700'),
            this.getColorObject(new TinyColor(hex).darken(18), '800'),
            this.getColorObject(new TinyColor(hex).darken(24), '900'),
            this.getColorObject(new TinyColor(hex).lighten(50).saturate(30), 'A100'),
            this.getColorObject(new TinyColor(hex).lighten(30).saturate(30), 'A200'),
            this.getColorObject(new TinyColor(hex).lighten(10).saturate(15), 'A400'),
            this.getColorObject(new TinyColor(hex).lighten(5).saturate(5), 'A700')
        ];
    }

    getColorObject(value: any, name: string): Color {
        const c: TinyColor = new TinyColor(value);
        return {
            name: name,
            hex: c.toHexString(),
            darkContrast: c.isLight()
        };
    }


}
