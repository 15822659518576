<main>
    <div class="d-flex flex-row justify-content-center">
        <h3 class="my-10">
            Deletion Queue
        </h3>
    </div>


    <div class="largeContainer">
        <div class="mat-elevation-z6 bg-white">
            <mat-tab-group #MatTabGroup (selectedTabChange)="getData($event)">
                <mat-tab label="Users">
                    <div *ngIf="userLoading" class="centerLoading">
                        <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
                    </div>
                    <div [hidden]="userLoading">
                        <table [dataSource]="userDataSource" mat-table matSort class="responsiveTable massiveTable"
                               [class.openSidebar]="largerTable"
                               [matSortActive]="userSortDefault" [matSortDirection]="userSortDirectionDefault"
                               (matSortChange)="sortUsers($event)">
                            <ng-container matColumnDef="name">
                                <th *matHeaderCellDef mat-header-cell mat-sort-header> Name</th>
                                <td *matCellDef="let item" mat-cell><b
                                        class="d-none columnTitle">Name:</b> {{ item.full_name }}
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="username">
                                <th *matHeaderCellDef mat-header-cell mat-sort-header> Username</th>
                                <td *matCellDef="let user" mat-cell><b
                                        class="d-none columnTitle">Username:</b> {{ user.username }}
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="role">
                                <th *matHeaderCellDef mat-header-cell mat-sort-header> Role</th>
                                <td *matCellDef="let user" mat-cell><b
                                        class="d-none columnTitle">Role:</b> {{ user.role }}
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="cohort">
                                <th *matHeaderCellDef mat-header-cell> Cohort</th>
                                <td *matCellDef="let user" mat-cell><b class="d-none columnTitle">Cohort:</b>
                                    <ng-container *ngIf="user.cohorts.length > 0">
                                        <ng-container
                                                *ngFor="let cohort of user.cohorts;let last = last;">{{ cohort.name }}
                                            <ng-container *ngIf="!last">,</ng-container>
                                        </ng-container>
                                    </ng-container>
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="locality">
                                <th *matHeaderCellDef mat-header-cell> Locality</th>
                                <td *matCellDef="let user" mat-cell><b
                                        class="d-none columnTitle">Locality:</b> {{ user.locality?.name }}
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="deletion_requested_date">
                                <th *matHeaderCellDef mat-header-cell mat-sort-header>Deletion Date</th>
                                <td *matCellDef="let item" mat-cell><b class="d-none columnTitle">Delete Request
                                    Date:</b> {{ item.deletion_requested_date | date }}
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="deletion_date">
                                <th *matHeaderCellDef mat-header-cell mat-sort-header>Final Delete Date</th>
                                <td *matCellDef="let item" mat-cell><b class="d-none columnTitle">Permanent Delete
                                    Date:</b> {{ item.deletion_date | date }}
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="view">
                                <th *matHeaderCellDef mat-header-cell>Context</th>
                                <td *matCellDef="let user" mat-cell>
                                    <a mat-raised-button color="primary" class="text-center"
                                       [routerLink]="user.timeline?.id ? ['/user', user.id, user.timeline?.id] : ['/user', user.id]">
                                        View User
                                    </a>
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="restore">
                                <th *matHeaderCellDef mat-header-cell></th>
                                <td *matCellDef="let item" mat-cell>
                                    <a mat-raised-button color="accent" class="text-center" (click)="restoreUser(item)">
                                        Restore
                                    </a>
                                </td>
                            </ng-container>
                            <tr *matHeaderRowDef="userColumns" mat-header-row></tr>
                            <tr *matRowDef="let row; columns: userColumns;" mat-row></tr>

                            <tr *matNoDataRow class="mat-row">
                                <td *ngIf="false else notFound"
                                    [attr.colspan]="this.userColumns.length"
                                    class="mat-cell text-center">No data matching these filters
                                </td>
                            </tr>
                        </table>
                        <mat-paginator #userPaginator (page)="handleUserPageEvent($event)" [length]="userCount"
                                       [pageSizeOptions]="[10, 15, 25, 50]"
                                       [pageSize]="userLimit" aria-label="Select page"></mat-paginator>
                    </div>
                    <ng-template #notFound>
                        <td *ngIf="!userLoading" [attr.colspan]="this.userColumns.length" class="mat-cell text-center">
                            No items found
                        </td>
                    </ng-template>
                </mat-tab>
                <mat-tab label="Albums">
                    <div *ngIf="albumLoading" class="centerLoading">
                        <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
                    </div>
                    <div [hidden]="albumLoading">
                        <table [dataSource]="albumDataSource" mat-table matSort class="responsiveTable massiveTable"
                               [class.openSidebar]="largerTable" [matSortActive]="albumSortDefault"
                               [matSortDirection]="albumSortDirectionDefault"
                               (matSortChange)="sortAlbums($event)">
                            <ng-container matColumnDef="title">
                                <th *matHeaderCellDef mat-header-cell mat-sort-header> Title</th>
                                <td *matCellDef="let item" mat-cell><b
                                        class="d-none columnTitle">Title:</b> {{ item.title }}
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="deletion_requested_date">
                                <th *matHeaderCellDef mat-header-cell mat-sort-header> Delete Date</th>
                                <td *matCellDef="let item" mat-cell><b class="d-none columnTitle">Delete Request
                                    Date:</b> {{ item.deletion_requested_date | date }}
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="deletion_date">
                                <th *matHeaderCellDef mat-header-cell mat-sort-header> Final Delete Date</th>
                                <td *matCellDef="let item" mat-cell><b class="d-none columnTitle">Permanent Delete
                                    Date:</b> {{ item.deletion_date | date }}
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="view">
                                <th *matHeaderCellDef mat-header-cell>Context</th>
                                <td *matCellDef="let item" mat-cell>
                                    <a *ngIf="item.timeline.id" mat-raised-button color="primary" class="text-center"
                                       [routerLink]="['/timeline', item.timeline.id]">
                                        View Memory Box
                                    </a>
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="restore">
                                <th *matHeaderCellDef mat-header-cell></th>
                                <td *matCellDef="let item" mat-cell>
                                    <a mat-raised-button color="accent" class="text-center"
                                       (click)="restoreAlbum(item)">
                                        Restore
                                    </a>
                                </td>
                            </ng-container>
                            <tr *matHeaderRowDef="albumColumns" mat-header-row></tr>
                            <tr *matRowDef="let row; columns: albumColumns;" mat-row></tr>

                            <tr *matNoDataRow class="mat-row">
                                <td *ngIf="false else notFound"
                                    [attr.colspan]="this.albumColumns.length"
                                    class="mat-cell text-center">No data matching these filters
                                </td>
                            </tr>
                        </table>
                        <mat-paginator #albumPaginator (page)="handleAlbumPageEvent($event)" [length]="albumCount"
                                       [pageSizeOptions]="[10, 15, 25, 50]"
                                       [pageSize]="albumLimit" aria-label="Select page"></mat-paginator>
                    </div>
                    <ng-template #notFound>
                        <td *ngIf="!albumLoading" [attr.colspan]="this.albumColumns.length"
                            class="mat-cell text-center">
                            No items found
                        </td>
                    </ng-template>
                </mat-tab>
                <mat-tab label="Memories">
                    <div *ngIf="mediaLoading" class="centerLoading">
                        <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
                    </div>
                    <div [hidden]="mediaLoading">
                        <table [dataSource]="mediaDataSource" mat-table matSort class="responsiveTable massiveTable"
                               [class.openSidebar]="largerTable" [matSortActive]="mediaSortDefault"
                               [matSortDirection]="mediaSortDirectionDefault"
                               (matSortChange)="sortMedia($event)">
                            <ng-container matColumnDef="img">
                                <th *matHeaderCellDef mat-header-cell></th>
                                <td *matCellDef="let media" mat-cell>
                                    <img alt="Media Thumbnail" [src]="media.thumbnail||'assets/images/image.png'"
                                         class="smallPreview">
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="title">
                                <th *matHeaderCellDef mat-header-cell mat-sort-header> Title</th>
                                <td *matCellDef="let item" mat-cell><b
                                        class="d-none columnTitle">Title:</b> {{ item.title }}
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="deletion_requested_date">
                                <th *matHeaderCellDef mat-header-cell mat-sort-header> Delete Date</th>
                                <td *matCellDef="let item" mat-cell><b class="d-none columnTitle">Delete Request
                                    Date:</b> {{ item.deletion_requested_date | date }}
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="deletion_date">
                                <th *matHeaderCellDef mat-header-cell mat-sort-header> Final Delete Date</th>
                                <td *matCellDef="let item" mat-cell><b class="d-none columnTitle">Permanent Delete
                                    Date:</b>  {{ item.deletion_date | date }}
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="view">
                                <th *matHeaderCellDef mat-header-cell>Context</th>
                                <td *matCellDef="let item" mat-cell>
                                    <a *ngIf="item.memory" mat-raised-button color="primary" class="text-center"
                                       [routerLink]="['/memory', item.memory]">
                                        View Album
                                    </a>
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="restore">
                                <th *matHeaderCellDef mat-header-cell></th>
                                <td *matCellDef="let item" mat-cell>
                                    <a mat-raised-button color="accent" class="text-center"
                                       (click)="restoreMedia(item)">
                                        Restore
                                    </a>
                                </td>
                            </ng-container>
                            <tr *matHeaderRowDef="mediaColumns" mat-header-row></tr>
                            <tr *matRowDef="let row; columns: mediaColumns;" mat-row></tr>

                            <tr *matNoDataRow class="mat-row">
                                <td *ngIf="false else notFound"
                                    [attr.colspan]="this.mediaColumns.length"
                                    class="mat-cell text-center">No data matching these filters
                                </td>
                            </tr>
                        </table>
                        <mat-paginator #mediaPaginator (page)="handleMediaPageEvent($event)" [length]="mediaCount"
                                       [pageSizeOptions]="[10, 15, 25, 50]"
                                       [pageSize]="mediaLimit" aria-label="Select page"></mat-paginator>
                    </div>
                    <ng-template #notFound>
                        <td *ngIf="!mediaLoading" [attr.colspan]="this.mediaColumns.length"
                            class="mat-cell text-center">
                            No items found
                        </td>
                    </ng-template>
                </mat-tab>
            </mat-tab-group>
        </div>
    </div>
</main>


