<mat-toolbar class="d-flex flex-space-between mat-elevation-z3" color="primary">
    <button [routerLink]="['/login']" aria-label="Home" mat-icon-button matTooltip="Home">
        <img alt="Virtual Memory Box Logo" src="assets/images/vmb.svg">
    </button>
</mat-toolbar>
<main class="notFoundBannerBg minFullHeight" role="main">
    <div class="container d-flex justify-content-center">
        <div class="d-flex flex-column">

            <div class="text-center">
                <mat-card>

                    <mat-card-header>
                        <mat-card-title>VMB Status</mat-card-title>
                    </mat-card-header>
                    <mat-card-content>

                        <div class="flex-row">
                            <mat-list>
                                <mat-list-item>
                                    <mat-icon class="success" matListItemIcon>check_circle</mat-icon>
                                    <div matListItemTitle>Serving Frontend</div>
                                    <div matListItemLine>Running</div>
                                </mat-list-item>
                                <mat-list-item>
                                    <mat-icon matListItemIcon [class.error]="cacheBackend == false"
                                              [class.success]="cacheBackend"
                                              *ngIf="cacheBackend !== null else loading">
                                        <ng-container *ngIf="cacheBackend else block">check_circle</ng-container>
                                    </mat-icon>
                                    <div matListItemTitle>Backend Cache</div>
                                    <div matListItemLine>
                                        <ng-container *ngIf="cacheBackend !== null else checking">
                                            <ng-container *ngIf="cacheBackend else failed">Running</ng-container>
                                        </ng-container>
                                    </div>
                                </mat-list-item>
                                <mat-list-item>
                                    <mat-icon matListItemIcon [class.error]="databaseBackend == false"
                                              [class.success]="databaseBackend"
                                              *ngIf="databaseBackend !== null else loading">
                                        <ng-container *ngIf="databaseBackend else block">check_circle</ng-container>
                                    </mat-icon>
                                    <div matListItemTitle>Database</div>
                                    <div matListItemLine>
                                        <ng-container *ngIf="databaseBackend !== null else checking">
                                            <ng-container *ngIf="databaseBackend else failed">Running</ng-container>
                                        </ng-container>
                                    </div>
                                </mat-list-item>
                                <mat-list-item>
                                    <mat-icon matListItemIcon [class.error]="defaultFileStorageHealthCheck == false"
                                              [class.success]="defaultFileStorageHealthCheck"
                                              *ngIf="defaultFileStorageHealthCheck !== null else loading">
                                        <ng-container *ngIf="defaultFileStorageHealthCheck else block">check_circle
                                        </ng-container>
                                    </mat-icon>
                                    <div matListItemTitle>File Storage</div>
                                    <div matListItemLine>
                                        <ng-container *ngIf="defaultFileStorageHealthCheck !== null else checking">
                                            <ng-container *ngIf="defaultFileStorageHealthCheck else failed">Running
                                            </ng-container>
                                        </ng-container>
                                    </div>
                                </mat-list-item>
                                <mat-list-item>
                                    <mat-icon matListItemIcon [class.error]="diskUsage == false"
                                              [class.success]="diskUsage"
                                              *ngIf="diskUsage !== null else loading">
                                        <ng-container *ngIf="diskUsage else block">check_circle</ng-container>
                                    </mat-icon>
                                    <div matListItemTitle>Disk</div>
                                    <div matListItemLine>
                                        <ng-container *ngIf="diskUsage !== null else checking">
                                            <ng-container *ngIf="diskUsage else failed">Running</ng-container>
                                        </ng-container>
                                    </div>
                                </mat-list-item>
                            </mat-list>
                            <mat-list>
                                <mat-list-item>
                                    <mat-icon matListItemIcon [class.error]="memoryUsage == false"
                                              [class.success]="memoryUsage"
                                              *ngIf="memoryUsage !== null else loading">
                                        <ng-container *ngIf="memoryUsage else block">check_circle</ng-container>
                                    </mat-icon>
                                    <div matListItemTitle>Memory</div>
                                    <div matListItemLine>
                                        <ng-container *ngIf="memoryUsage !== null else checking">
                                            <ng-container *ngIf="memoryUsage else failed">Running</ng-container>
                                        </ng-container>
                                    </div>
                                </mat-list-item>
                                <mat-list-item>
                                    <mat-icon matListItemIcon [class.error]="migrationsHealthCheck == false"
                                              [class.success]="migrationsHealthCheck"
                                              *ngIf="migrationsHealthCheck !== null else loading">
                                        <ng-container *ngIf="migrationsHealthCheck else block">check_circle
                                        </ng-container>
                                    </mat-icon>
                                    <div matListItemTitle>Migrations</div>
                                    <div matListItemLine>
                                        <ng-container *ngIf="migrationsHealthCheck !== null else checking">
                                            <ng-container *ngIf="migrationsHealthCheck else failed">Running
                                            </ng-container>
                                        </ng-container>
                                    </div>
                                </mat-list-item>
                                <mat-list-item>
                                    <mat-icon matListItemIcon [class.error]="redisHealthCheck == false"
                                              [class.success]="redisHealthCheck"
                                              *ngIf="redisHealthCheck !== null else loading">
                                        <ng-container *ngIf="redisHealthCheck else block">check_circle</ng-container>
                                    </mat-icon>
                                    <div matListItemTitle>Redis</div>
                                    <div matListItemLine>
                                        <ng-container *ngIf="redisHealthCheck !== null else checking">
                                            <ng-container *ngIf="redisHealthCheck else failed">Running</ng-container>
                                        </ng-container>
                                    </div>
                                </mat-list-item>
                                <mat-list-item>
                                    <mat-icon matListItemIcon [class.error]="s3Boto3StorageHealthCheck == false"
                                              [class.success]="s3Boto3StorageHealthCheck"
                                              *ngIf="s3Boto3StorageHealthCheck !== null else loading">
                                        <ng-container *ngIf="s3Boto3StorageHealthCheck else block">check_circle
                                        </ng-container>
                                    </mat-icon>
                                    <div matListItemTitle>S3 Storage</div>
                                    <div matListItemLine>
                                        <ng-container *ngIf="s3Boto3StorageHealthCheck !== null else checking">
                                            <ng-container *ngIf="s3Boto3StorageHealthCheck else failed">Running
                                            </ng-container>
                                        </ng-container>
                                    </div>
                                </mat-list-item>
                                <mat-list-item>
                                    <mat-icon matListItemIcon [class.error]="trainingContentAPI == false"
                                              [class.success]="trainingContentAPI"
                                              *ngIf="trainingContentAPI !== null else loading">
                                        <ng-container *ngIf="trainingContentAPI else block">check_circle</ng-container>
                                    </mat-icon>
                                    <div matListItemTitle>Training Content</div>
                                    <div matListItemLine>
                                        <ng-container *ngIf="trainingContentAPI !== null else checking">
                                            <ng-container *ngIf="trainingContentAPI else failed">Running</ng-container>
                                        </ng-container>
                                    </div>
                                </mat-list-item>
                            </mat-list>
                        </div>
                    </mat-card-content>
                </mat-card>
            </div>
        </div>
    </div>
</main>
<app-footer></app-footer>

<ng-template #checking>
    Checking...
</ng-template>
<ng-template #failed>
    Failed
</ng-template>
<ng-template #loading>
    <mat-icon matListItemIcon>
        <mat-spinner color="primary" width="40px"></mat-spinner>
    </mat-icon>
</ng-template>
<ng-template #block>
    block
</ng-template>
