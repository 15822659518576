import {Component} from '@angular/core';
import {ContentService} from "../../_services/content.service";

@Component({
    selector: 'app-resources',
    templateUrl: './resources.component.html',
    styleUrl: './resources.component.scss'
})
export class ResourcesComponent {
    helpfulLinks: any[] = [];
    resources: any[] = [];
    loading: boolean = false;

    constructor(private contentService: ContentService) {
        this.contentService.libraryCategories().subscribe(r => {
            this.resources = r;
            this.loading = false;
        }, e => {
            console.error(e)
            this.loading = false;
        })
        this.contentService.helpfulLinks().subscribe(r => {
            this.helpfulLinks = r;
            this.loading = false;
        }, e => {
            this.loading = false;
            console.error(e)
        })
    }

    link(url: string, download: boolean, name?: string): void {
        if (download) {
            let downloadName: string = url
            if (name) {
                downloadName = name?.replace(/ /g, "_");
            }
            fetch(url)
                // check to make sure you didn't have an unexpected failure (may need to check other things here depending on use case / backend)
                .then(resp => resp.status === 200 ? resp.blob() : Promise.reject('something went wrong'))
                .then(blob => {
                    const url: string = window.URL.createObjectURL(blob);
                    const a: HTMLAnchorElement = document.createElement('a');
                    a.style.display = 'none';
                    a.href = url;
                    // the filename you want
                    a.download = downloadName;
                    document.body.appendChild(a);
                    a.click();
                    window.URL.revokeObjectURL(url);
                })
                .catch(() => function (): void {
                    console.error('catch error')
                    window.open(url, '_blank')?.focus();
                });
        } else {
            window.open(url, '_blank')?.focus();
        }
    }

}
