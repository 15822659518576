import {Component, EventEmitter, Input, Output} from '@angular/core';
import {FormBuilder, FormGroup} from "@angular/forms";
import {AuthService} from "../../_services/auth.service";
import {Router} from "@angular/router";

@Component({
    selector: 'app-comments',
    templateUrl: './comments.component.html',
    styleUrl: './comments.component.scss'
})
export class CommentsComponent {
    @Input() comments: any[] = [];
    @Input() reLoading: boolean = false;
    endOfChat: boolean = true;
    page: number = 0;
    today: Date = new Date();
    messageForm: FormGroup;
    conversation: any = null;
    user: any;
    @Output() comment: EventEmitter<any> = new EventEmitter();

    constructor(private formBuilder: FormBuilder,
                private authService: AuthService,
                private router: Router,) {
        this.messageForm = this.formBuilder.group({
            message: [''],
        });
        this.user = this.authService.getUser();
    }

    loadMore(): void {
        if (this.conversation) {
            this.reLoading = true;
            this.page++;
            this.getMessages();
        }
    }

    receivedToday(date: any): boolean {
        const messageDate: Date = new Date(date);
        return (this.today.toDateString() === messageDate.toDateString());
    }


    sendMessage(): void {
        let comment = {
            user: this.user.id,
            message: this.messageForm.value.message
        }
        this.messageForm.reset();
        this.comment.emit(comment);
    }

    getMessages(update?: any): void {
        if (this.conversation && !this.endOfChat) {
            // this.contentService.getChat(this.conversation.id, this.page).subscribe(
            //     r => {
            //         if (r.results.length > 0 && !update) {
            //             this.conversation = this.conversation.concat(r.results);
            //         } else if (r.results.length && update) {
            //             this.conversation = r.results;
            //         } else {
            //             this.endOfChat = true;
            //         }
            //         if (this.page == 0) {
            //             setTimeout((): void => {
            //                 const element: HTMLElement | null = document.getElementById('chatContent');
            //                 if (element) {
            //                     element.scrollTop = element.scrollHeight;
            //                 }
            //             }, 50);
            //         }
            //         this.reLoading = false;
            //     }, e => {
            //         this.reLoading = false;
            //         console.error(e);
            //     });
        } else {
            this.reLoading = false;
        }
    }

    viewProfile(id: number): void {
        if (this.user.role.can_access_profiles) {
            this.router.navigate(['/profile', id]);
        }
    }

    setEmoji(event: Event): void {
        this.messageForm.patchValue({
            message: this.messageForm.value.message + event
        })
    }
}
