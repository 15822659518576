import {Component} from '@angular/core';
import {AdminService} from "../../_services/admin.service";
import {SnackbarService} from "../../_services/snackbar.service";
import {PageEvent} from "@angular/material/paginator";
import {Sort} from "@angular/material/sort";
import {ActivatedRoute, Router} from "@angular/router";

const media: any = {
    flagType: 'Duplicate',
    date: new Date(),
    user: {full_name: 'Chris Brock'},
    box: "Chris Brock's timeline",
    id: 1
};

@Component({
    selector: 'app-resolved-feedback',
    templateUrl: './resolved-feedback.component.html',
    styleUrl: './resolved-feedback.component.scss'
})
export class ResolvedFeedbackComponent {
    loading: boolean = false;
    checkedResolvedMedia: any[] = [];
    resolvedData: any[] = []
    typingTimer: any;
    searchTerm: any;
    limit: number = 15;
    page: number = 0;
    count: number = 0;
    category: string = 'All';
    categories = ['Duplicate', 'Move to another album', 'Inappropriate', 'Other'];
    direction: string | undefined = 'desc';
    order_by: string | undefined = 'created_date';
    matSortDefault: string = 'date';
    matSortDirectionDefault: "asc" | "desc" = "desc";
    constructor(private adminService: AdminService,
                private snackbarService: SnackbarService,
                private route: ActivatedRoute,
                private router: Router,) {
        route.queryParams.subscribe(
            (data: any): void => {
                if (data['direction']) {
                    this.direction = data['direction']
                    if (this.direction == 'asc' || this.direction == 'desc') {
                        this.matSortDirectionDefault = this.direction;
                    }
                }

                if (data['order_by']) {
                    this.order_by = data['order_by']
                    if (this.order_by) {
                        if (this.order_by == 'timeline') {
                            this.matSortDefault = 'timeline'
                        } else if (this.order_by == 'creator') {
                            this.matSortDefault = 'user'
                        } else if (this.order_by == 'created_date') {
                            this.matSortDefault = 'date'
                        }
                    }
                }
                if (data['page']) {
                    this.page = data['page']
                }
                if (data['limit']) {
                    this.limit = data['limit']
                }
                if (data['searchTerm']) {
                    this.searchTerm = data['searchTerm']
                }
            })
        this.getFlaggedMedia();
    }

    getFlaggedMedia(): void {
        this.loading = true;
        let filters: any = {
            order_by: null,
            direction: null,
            resolution_status: 'Resolved,Actioned'
        }
        if (this.category && this.category != 'All') {
            filters.category = this.category;
        }
        if (this.order_by) {
            filters.order_by = this.order_by
        }
        if (this.direction) {
            filters.direction = this.direction
        }
        filters.page = this.page;
        filters.limit = this.limit;
        filters.searchTerm = this.searchTerm;
        this.router.navigate([], {
            relativeTo: this.route,
            queryParams: filters,
            queryParamsHandling: 'merge',
        });
        this.adminService.getAllUserFeedback(this.page, this.limit, this.searchTerm, filters).subscribe(r => {
            this.count = r.count;
            this.resolvedData = r.results;
            this.loading = false;
        }, e => {
            this.loading = false;
            this.snackbarService.openSnackBar(e, 'error');
        })
    }

    checked(mediaId: any): void {
        let array: any[] = this.checkedResolvedMedia
        let index: number = array.indexOf(mediaId)
        if (index > 0) {
            array.push(mediaId)
        } else {
            array.splice(index, 1);
        }
    }

    pageChange(e: PageEvent): void {
        this.page = e.pageIndex;
        this.getFlaggedMedia();
    }

    changeOrder(event: Sort): void {
        this.order_by = event.active;

        if (this.order_by == 'box') {
            this.order_by = 'timeline'
        } else if (this.order_by == 'user') {
            this.order_by = 'creator'
        } else if (this.order_by == 'date') {
            this.order_by = 'created_date'
        }
        this.direction = event.direction;
        this.getFlaggedMedia()
    }

    keyup(): void {
        clearTimeout(this.typingTimer);
        const that: this = this;
        this.typingTimer = setTimeout((): void => {
            that.loading = true;
            this.getFlaggedMedia()
        }, 500);
    }


    clearSearch(): void {
        this.searchTerm = '';
        this.getFlaggedMedia();
    }

    keydown(): void {
        clearTimeout(this.typingTimer);
    }
}
