import {AfterViewInit, Component, EventEmitter, Input, Output} from '@angular/core';
import {ContentService} from "../../_services/content.service";

@Component({
    selector: 'app-audio-player',
    templateUrl: './audio-player.component.html',
    styleUrl: './audio-player.component.scss'
})
export class AudioPlayerComponent implements AfterViewInit {
    @Input() file: any = null;
    @Input() mediaId: any = null;
    @Input() small: boolean = false;
    @Output() loading: EventEmitter<boolean> = new EventEmitter();
    @Output() pauseSlideShow: EventEmitter<boolean> = new EventEmitter();
    @Output() viewImage: EventEmitter<boolean> = new EventEmitter();

    maxBars: number = 100;
    width: string = '4px';
    state: string = 'play';
    waveform: any[] = []
    src: any = null;

    constructor(public contentService: ContentService) {

    }

    ngAfterViewInit(): void {
        if (this.small || window.innerWidth <= 600) {
            this.maxBars = 40;
            this.width = '2px'
        }
        for (let i = 0; i < this.maxBars; i++) {
            let delay: number = Math.random() * (0.7 - 0.2) + 0.2
            this.waveform.push({delay: delay + 's'})
        }

        setTimeout((): void => {
            this.state = 'paused';
        }, 200);

        if (this.mediaId) {
            let that: this = this
            this.contentService.checkIfMediaUrlExpired(this.file, 'audio', this.mediaId).then(function (response): void {
                // Refresh media to stop s3 timeout issue
                if (response) {
                    that.src = response;
                } else {
                    console.error('No response received from validity checker. Please try again', 'error')
                }
            }).catch(e => {
                console.error(e)
            });
        } else {
            this.src = this.file;
        }
    }

    thumbnailClick(element: HTMLAudioElement, state: string): void {
        if (this.small && state !== 'play') {
            this.viewImage.emit();
        } else {
            if (state == 'play') {
                element.pause()
            } else {
                element.play();
            }
        }

    }


    checkUrl(url: string, key: string, audio: HTMLAudioElement): void {
        let that: this = this;
        // Check if audio already loaded (means s3 hasn't expired)
        this.pauseSlideShow.emit(true)
        if (audio.readyState < 3) {
            this.loading.emit(true)
            // Check if s3 has expired
            this.contentService.checkIfMediaUrlExpired(url, key, this.mediaId).then(function (response): void {
                if (response && response != url) {
                    // Set new url then load the audio.
                    that.src = response;
                    if (audio) {
                        setTimeout((): void => {
                            audio.load();
                            audio.addEventListener('canplay', playVideo);
                        }, 50);
                    }
                } else {
                    that.loading.emit(false)
                    console.error('No response received from validity checker')
                }
            }).catch(e => {
                console.error(e)
            });
        } else {
            that.state = 'play'
        }

        function playVideo(): void {
            // Play audio once it loads
            that.state = 'play'
            audio.play()
            // Remove event listener so it doesnt happen every pause -> play
            audio.removeEventListener('canplay', playVideo)
            that.loading.emit(false)
        }
    }
}
